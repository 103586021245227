import { makeStyles, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "../../../../Icons/CloseIcon";
import DialogModal from "../../../Extras/DialogModal";
import styles from "./BottomBar.module.scss";
const useStyles = makeStyles((theme) => ({
	cancel: {
		position: "absolute",
		right: "0",
		cursor: "pointer",
		color: "#ff5b5b",
	},
	icon: {
		position: "absolute",
		right: "0",
		cursor: "pointer",
		marginTop: "10px",
		marginRight: "5rem",
		"@media (max-width:780px)": {
			marginRight: "3.5rem",
		},
	},
}));
const SettingsModal = (props) => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const isMobile = window.screen.width < 724;
	const [data, setData] = useState("Audio");

	return (
		<DialogModal
			{...props}
			width={isMobile ? "lg" : "sm"}
			maxWidth="true"
			fullWidth={isMobile ? "true" : "false"}
			style={{ padding: "1.5rem" }}
		>
			<div style={{ padding: "2rem" }}>
				<span
					className={classes.cancel + " " + styles.crossIcon}
					onClick={props.onHide}
				>
					<CloseIcon />
				</span>
				<div className={styles.modalBody}>
					<div className={styles.links}>
						<span
							className={
								data === "Audio" ? styles.selected : styles.link
							}
							onClick={() => {
								setData("Audio");
							}}
						>
							Audio Settings
						</span>

						<span
							className={
								data === "Video" ? styles.selected : styles.link
							}
							onClick={() => {
								setData("Video");
							}}
						>
							Video Settings
						</span>
					</div>
					{data === "Audio" ? (
						<div className={styles.settings}>
							<h6 className={styles.dropHeading}>Microphone</h6>
							<select
								className={styles.dropdown}
								variant="outlined"
								name="Mic"
							>
								<option style={{ border: "none" }} value={null}>
									Microphone name
								</option>
								<option value="Default">Default Speaker</option>
							</select>

							<h6 className={styles.dropHeading1}>Speaker</h6>
							<select
								className={styles.dropdown}
								variant="outlined"
								name="Speaker"
							>
								<option value={null}>Speaker name</option>
								<option value="Default">Default Speaker</option>
							</select>
						</div>
					) : (
						<div className={styles.settings}>
							<h6 className={styles.dropHeading}>Camera</h6>
							<select
								className={styles.dropdown}
								variant="outlined"
								name="Mic"
							>
								<option style={{ border: "none" }} value={null}>
									Camera name
								</option>
								<option value="Default">Default camera</option>
							</select>

							<h6 className={styles.dropHeading1}>
								Send resolution
							</h6>
							<select
								className={styles.dropdown}
								variant="outlined"
								name="resolution"
							>
								<option value={null}>Resolution </option>
								<option value="Default">
									Default resolution
								</option>
							</select>
							<h6 className={styles.dropHeading1}>
								Recieve resolution
							</h6>
							<select
								className={styles.dropdown}
								variant="outlined"
								name="resolution"
							>
								<option value={null}>Resolution </option>
								<option value="Default">
									Default resolution
								</option>
							</select>
						</div>
					)}
				</div>
			</div>
		</DialogModal>
	);
};

export default SettingsModal;
