import React from "react";

const OptionsIcon = ({ width = "18", height = "6", color = "#7F89BE" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7837 2.52531C16.7837 1.80349 16.203 1.2163 15.4893 1.2163C14.7748 1.2163 14.1949 1.80349 14.1949 2.52531C14.1949 3.24632 14.7748 3.8327 15.4893 3.8327C16.203 3.8327 16.7837 3.24632 16.7837 2.52531ZM12.9781 2.52556C12.9781 1.13301 14.1046 -1.70273e-07 15.489 -1.09758e-07C16.8735 -4.9242e-08 18 1.13301 18 2.52556C18 3.91729 16.8735 5.0495 15.489 5.0495C14.1046 5.0495 12.9781 3.91729 12.9781 2.52556Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.2954 2.52531C10.2954 1.80349 9.71472 1.2163 9.00101 1.2163C8.28648 1.2163 7.7066 1.80349 7.7066 2.52531C7.7066 3.24632 8.28648 3.8327 9.00101 3.8327C9.71472 3.8327 10.2954 3.24632 10.2954 2.52531ZM6.4898 2.52556C6.4898 1.13301 7.61632 -1.70273e-07 9.00076 -1.09758e-07C10.3852 -4.9242e-08 11.5117 1.13301 11.5117 2.52556C11.5117 3.91729 10.3852 5.0495 9.00076 5.0495C7.61632 5.0495 6.4898 3.91729 6.4898 2.52556Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.80713 2.52531C3.80713 1.80349 3.22643 1.2163 2.51272 1.2163C1.7982 1.2163 1.21831 1.80349 1.21831 2.52531C1.21831 3.24632 1.7982 3.8327 2.51272 3.8327C3.22643 3.8327 3.80713 3.24632 3.80713 2.52531ZM0.0015148 2.52556C0.00151486 1.13301 1.12804 -1.70273e-07 2.51248 -1.09758e-07C3.89691 -4.9242e-08 5.02344 1.13301 5.02344 2.52556C5.02344 3.91729 3.89691 5.0495 2.51248 5.0495C1.12804 5.0495 0.00151474 3.91729 0.0015148 2.52556Z"
				fill={color}
			/>
		</svg>
	);
};

export default OptionsIcon;
