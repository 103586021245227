export default function LogoutIcon(props) {
	return (
		<svg
			style={{ margin: "auto 10px auto 0", width: 26, height: 26 }}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 5V3C12 2.46957 11.7893 1.96086 11.4142 1.58579C11.0391 1.21071 10.5304 1 10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H10C10.5304 17 11.0391 16.7893 11.4142 16.4142C11.7893 16.0391 12 15.5304 12 15V13M6 9H20M20 9L16 5M20 9L16 13"
				stroke={props.color || "#D9DEF4"}
				strokeWidth="1.3"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
