import config, { environments } from "../config";

const Log = {
  debug(message) {
    if (config.ENV !== environments.PROD && config.ENV !== environments.TEST) {
      console.log(message);
    }
  },
  warn(message) {
    if (config.ENV !== environments.PROD && config.ENV !== environments.TEST) {
      console.warn(message);
    }
  },
  error(message) {
    console.error(message);
  },
};

export default Log;
