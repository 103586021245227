import React, { useState } from "react";
import axiosConfig from "../../../Utils/axiosConfig";
import { reportIssueURL } from "../../../Utils/Constants";
import { Form } from "react-bootstrap";
import styles from "../Info.module.css";
import { CircularProgress, IconButton } from "@material-ui/core";
import AlertBox from "../../Extras/AlertBox/AlertBox";
import { useHistory } from "react-router-dom";
import useAlert from "./../../Extras/Alert/useAlert";
import Arrows from "../../../Icons/Arrows";
import Button2 from "../../Extras/Buttons/Button2/Button2";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import { useSelector } from "react-redux";

//MUI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { InputLabel, MenuItem, Select, FormControl } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export default function Report() {
	const { showAlert } = useAlert();
	const [type, setType] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [description, setDescription] = useState("");
	const [isFill, setIsFill] = useState(false);
	const [show, setShow] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const isMobile = window.screen.width < 768;

	const useStyles = makeStyles((theme) => ({
		formControl: {
			margin: "0 0 12px 0",
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		selectDk: {
			"& ul": {
				backgroundColor: "#0d0f22",
				color: "#7F89BE",
			},
		},
		selectLg: {
			"& ul": {
				backgroundColor: "#fff",
				color: "#0d0f22",
			},
		},
	}));

	const CustomSelect = withStyles({
		root: {
			"& label..Mui-focused": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
			},
			"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
				padding: "0 10px",
				zIndex: "5",
				background: theme === "dark" ? "#121528" : "#fff",
			},
			"& .MuiInputLabel-outlined": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
			},
			"& .MuiOutlinedInput-input": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
				border: "1px solid #7F89BE",
				borderRadius: "8px",
				padding: "20px",
			},
			"& .MuiSelect-icon": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
			},
		},
	})(FormControl);

	const classes = useStyles();

	let dropDown = document.querySelector("#dropdown");
	let textarea = document.querySelector("#textarea");

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (type === "" || description === "") {
			setIsLoading(false);
			setIsFill(true);
			return;
		}
		setIsFill(false);
		const form = new FormData();
		form.append("title", type);
		form.append("description", description);

		axiosConfig
			.post(reportIssueURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setType("");
				setDescription("");
				setIsLoading(false);

				// dropDown.value = "Choose your issue";
				// textarea.value = "";
				showAlert("Reported Successfully.", {
					header: "Reported Your Issue Successfully",
					buttonText: "Close",
				});
			})
			.catch((err) => {
				setIsLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	// const handleChange = () => {
	// if (description !== "" || type !== "") setShowCancel(true);
	// else setShowCancel(false);
	// };

	const handleCancel = (e) => {
		e.preventDefault();
		setType("");
		setDescription("");
	};

	return (
		<>
			{!isMobile && (
				<div className={styles.header}>
					<h2
						style={{
							color: theme === "dark" ? "#d9def4" : "#0D0F22",
						}}
					>
						Send Feedback
					</h2>
				</div>
			)}
			<div
				className={styles.rightWrapper}
				style={isMobile ? { marginTop: "30px" } : { marginTop: "0px" }}
			>
				<Form className={styles.form}>
					<CustomSelect
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel id="demo-simple-select-outlined-label">
							Choose your issue
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							MenuProps={{
								classes: {
									paper:
										theme === "dark"
											? classes.selectDk
											: classes.selectLg,
								},
							}}
							onChange={(e) => setType(e.target.value)}
							value={type}
						>
							<MenuItem value="Chat Issues">Chat Issues</MenuItem>
							<MenuItem value="Account Settings">
								Account Settings
							</MenuItem>
							<MenuItem value="Contacts">Contacts</MenuItem>
							<MenuItem value="Spam">Spam</MenuItem>
							<MenuItem value="Any other issues">
								Any other issues
							</MenuItem>
						</Select>
					</CustomSelect>
					<Form.Group controlId="exampleForm.ControlTextarea1">
						<Form.Control
							id="textarea"
							className={styles.red}
							as="textarea"
							rows="5"
							placeholder="Describe your issue"
							style={{ resize: "none" }}
							size="lg"
							onChange={(e) => setDescription(e.target.value)}
							value={description}
						/>
					</Form.Group>
				</Form>

				<div className="d-flex align-items-end justify-content-end">
					{(description || type) && (
						<button
							onClick={handleCancel}
							className={`${styles.btn2} mx-3`}
							type="submit"
						>
							<Button1>Cancel</Button1>
						</button>
					)}

					<button
						type="submit"
						className={styles.btn2}
						onClick={(e) => {
							handleSubmit(e);
						}}
						style={
							isMobile
								? { marginRight: "20px" }
								: { marginRight: "0px" }
						}
					>
						<Button2 disable={isLoading}>
							Submit{" "}
							{isLoading && (
								<CircularProgress
									size="20px"
									style={{ color: "white" }}
								/>
							)}
						</Button2>
					</button>
				</div>
			</div>
		</>
	);
}
