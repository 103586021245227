import { axiosBaseConfig } from "../../../Utils/axiosConfig";
import { foldersAPI } from "../../../Utils/Constants";
import {
	areFoldersLoading,
	isFoldersFetched,
} from "../../selectors/Bookmarks/folders";
import {
	ADD_FOLDER,
	ADD_STATUS_TO_FOLDER,
	EDIT_FOLDER,
	FETCH_FOLDERS,
	REMOVE_FOLDER,
	REMOVE_STATUS_FROM_FOLDER,
	SET_ERROR,
	SET_LOADING,
} from "./types";
const setFetchedFolders = (folders) => ({
	type: FETCH_FOLDERS,
	payload: folders,
});
const setLoading = (isLoading) => ({ type: SET_LOADING, isLoading });
const setError = (errorCode, message) => ({
	type: SET_ERROR,
	payload: { code: errorCode, message },
});
export const fetchFolders = (refresh) => async (dispatch, getState) => {
	const state = getState();
	var isFetched = isFoldersFetched(state);
	if (refresh) {
		isFetched = false;
	}

	const isLoading = areFoldersLoading(state);

	if (!isFetched && !isLoading) {
		try {
			dispatch(setLoading(true));
			const res = await axiosBaseConfig.get(foldersAPI.GET_FOLDERS);
			dispatch(setFetchedFolders(res.data.body || res.data.result));
			dispatch(setLoading(false));
		} catch (err) {
			const error = err.response;
			if (error) {
				dispatch(
					setError(
						error.status,
						error.data.message || "something went wrong"
					)
				);
			}
			dispatch(setLoading(false));
		}
	}
};

export const addNewFolder = (folder) => ({ type: ADD_FOLDER, payload: folder });

export const EditFolder = (folder) => ({ type: EDIT_FOLDER, payload: folder });

export const removeFolder = (id) => ({ type: REMOVE_FOLDER, payload: id });

export const incrementFolderStatusCount = (folderID) => ({
	type: ADD_STATUS_TO_FOLDER,
	payload: folderID,
});
export const decrementFolderStatusCount = (folderID) => ({
	type: REMOVE_STATUS_FROM_FOLDER,
	payload: folderID,
});
