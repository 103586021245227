export default function ShareIcon(props) {
	return (
		<svg
			style={{ margin: "auto 5px auto 5px" }}
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.06667 7.84444L11.9333 4.82222M6.06667 10.1556L11.9333 13.1778M6.33333 9C6.33333 10.4728 5.13943 11.6667 3.66667 11.6667C2.19391 11.6667 1 10.4728 1 9C1 7.52724 2.19391 6.33333 3.66667 6.33333C5.13943 6.33333 6.33333 7.52724 6.33333 9ZM17 3.66667C17 5.13943 15.8061 6.33333 14.3333 6.33333C12.8606 6.33333 11.6667 5.13943 11.6667 3.66667C11.6667 2.19391 12.8606 1 14.3333 1C15.8061 1 17 2.19391 17 3.66667ZM17 14.3333C17 15.8061 15.8061 17 14.3333 17C12.8606 17 11.6667 15.8061 11.6667 14.3333C11.6667 12.8606 12.8606 11.6667 14.3333 11.6667C15.8061 11.6667 17 12.8606 17 14.3333Z"
				stroke={props.color}
				strokeWidth="1.2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
