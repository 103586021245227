import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import axiosConfig from "../../../Utils/axiosConfig";
import { checkUsername, registerURL } from "../../../Utils/Constants";
import "./signUp.scss";
import InputField from "../../Extras/InputField";
import BackToLandingBtn from "../BackToLandingBtn";
import { useLocationPredictions } from "../../Extras/PlacesAutoComplete";
import { useReverseGeoCoder } from "../../Extras/Geocoder";
import useAlert from "../../Extras/Alert/useAlert";
// import { FormattedMessage } from "react-intl";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "../Login/login.scss";
import { socialKeys } from "../../../Utils/Constants";
import {
	googleCallbacks,
	facebookCallback,
	twitterCallback,
} from "../Login/socialLogins";
import TwitterLoginComponent from "../../../twitter/TwitterLogin";

const INITIAL_ERRORS = {
	username: null,
	password: null,
	full_name: null,
	email: null,
	country: null,
};

const fieldMapper = {
	username: "Username",
	password: "Password",
	full_name: "Full Name",
	email: "Email",
	country: "Country",
};

const Register = () => {
	const history = useHistory();
	const [state, setState] = useState({
		email: "",
		username: "",
		password: "",
		full_name: "",
		country: "",
		isSignUp: false,
		errors: INITIAL_ERRORS,
	});
	const [userLocation, setUserLocation] = useState({ lat: null, long: null });
	const [countrySearchQuery, setCountrySearchQuery] = useState("");

	const passwordRegex = new RegExp(
		"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
	);

	function usernameRegex(str) {
		return /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(str);
	}
	const emailRegex = new RegExp(
		"[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
	);

	useEffect(() => {
		if ("geolocation" in navigator) getLocation();
		sessionStorage.clear();
		localStorage.clear();
	}, []);

	const socialSignUp = () => {
		history.push("/create-profile");
	};

	function getLocation() {
		navigator.geolocation.getCurrentPosition(function (position) {
			setUserLocation({
				lat: position.coords.latitude,
				long: position.coords.longitude,
			});
		});
	}

	const {
		location: { country },
		loading: locationLoading,
	} = useReverseGeoCoder({ location: userLocation });
	useEffect(() => {
		setState((prev) => ({ ...prev, country }));
	}, [country]);
	const { showAlert } = useAlert();

	const validate = (data) => {
		if (
			data.password.length > 0 &&
			data.email.length > 0 &&
			data.username.length > 0 &&
			data.full_name.length > 0 &&
			data.country.length > 0
		) {
			let errors = {};
			if (data.password.length < 12) {
				errors.password = "Password should be minimum 12 characters";
			} else if (!passwordRegex.test(data.password)) {
				errors.password =
					"password should contain atleast one number, special character, Upper case and lower case";
			} else if (data.username.length < 4) {
				errors.username = "Username must be min 4 characters";
			} else if (!emailRegex.test(data.email)) {
				errors.email = "Invalid Email. Try with Valid Email Address ";
			} else if (Object.keys(errors).length > 0) {
				setState((prev) => ({ ...prev, errors }));
				return false;
			} else {
				return true;
			}
		} else {
			const errors = Object.keys(INITIAL_ERRORS).reduce(
				(acc, field) =>
					!state[field]
						? {
								...acc,
								[field]: `${fieldMapper[field]} is Required`,
						  }
						: acc,
				{}
			);
			setState((prev) => ({ ...prev, errors }));
			return false;
		}
	};

	const handleChange = (e) => {
		setState((prev) => ({ ...prev, errors: {} }));

		if (e.target.name === "username") {
			setState((prev) => ({
				...prev,
				errors: { username: "" },
			}));
			if (e.target.value.indexOf(" ") >= 0) {
				setState((prev) => ({
					...prev,
					errors: { username: "Username should not contain space." },
				}));
			} else if (usernameRegex(e.target.value)) {
				setState((prev) => ({
					...prev,
					errors: {
						username:
							"username should not contain any special character",
					},
				}));
			} else {
				setState((prev) => ({
					...prev,
					[e.target.name]: e.target.value,
				}));
				if (e.target.value.length > 0 && e.target.value.length < 4) {
					setState((prev) => ({
						...prev,
						errors: {
							username: "username must be min 4 characters",
						},
					}));
				} else if (e.target.value.length >= 4) {
					setState((prev) => ({
						...prev,
						errors: {
							username: "",
						},
					}));
					const URL = `${checkUsername}/?username=${e.target.value}`;
					const form = new FormData();
					form.append("username", e.target.value);
					axiosConfig
						.get(URL, form)
						.then((res) => {
							if (res.status !== 200) throw new Error();
							setState((prev) => ({
								...prev,
								errors: {
									username: "",
								},
							}));
						})
						.catch((err) => {
							setState((prev) => ({
								...prev,
								errors: {
									username: err.response.data.message,
								},
							}));
						});
				}
			}
		} else if (e.target.name === "password") {
			setState((prev) => ({
				...prev,
				errors: {
					password: "",
				},
			}));

			if (e.target.value.indexOf(" ") >= 0) {
				setState((prev) => ({
					...prev,
					errors: {
						password: "password should not contain spaces",
					},
				}));
			} else {
				setState((prev) => ({
					...prev,
					[e.target.name]: e.target.value,
				}));
				if (e.target.value.length > 0 && e.target.value.length < 5) {
					setState((prev) => ({
						...prev,
						errors: {
							password: "password should be of aleast 12",
						},
					}));
				} else if (
					!passwordRegex.test(e.target.value) &&
					e.target.value
				) {
					setState((prev) => ({
						...prev,
						errors: {
							password:
								"password should contain atleast one number, special character, Upper case and lower case.",
						},
					}));
				}
			}
		} else {
			setState((prev) => ({
				...prev,
				[e.target.name]: e.target.value,
			}));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = state;

		if (validate(data)) {
			setState((prev) => ({ ...prev, isSignUp: true }));
			const form = new FormData();
			form.append("email", data.email);
			form.append("username", data.username);
			form.append("password", data.password);
			form.append("password2", data.password);
			form.append("full_name", data.full_name);
			form.append("country", data.country);
			form.append("city", null);

			axiosConfig
				.post(registerURL, form)
				.then((res) => {
					if (res.status !== 200) throw new Error();
					sessionStorage.setItem(
						"temp_token",
						res.data.body[0].temp_token
					);
					setState((prev) => ({
						...prev,
						isSignUp: false,

						callback: () => history.push("/code-verification"),
					}));
					showAlert(res.data.message, {
						header: "You’re Almost There!",
						callback: () => history.push("/code-verification"),
						buttonText: "Verify your Account",
					});
				})
				.catch((err) => {
					if (err.response) {
						const { error } = err.response.data.message;
						const errors = {};

						if (
							typeof error === "string" &&
							error.includes("Username")
						) {
							errors.username =
								"Sorry this username already taken, please try another";
						}

						if (error?.email) {
							errors.email = (
								<>
									It looks like you already have an account.
									<Link to="/login"> Sign in</Link>
								</>
							);
						} else {
							showAlert(error, {
								header: "Sign Up Failed",
								buttonText: "Try Again",
							});
						}

						showAlert(" ", {
							header: err.response.data.message,
							buttonText: "Close",
						});

						setState((prev) => ({ ...prev, errors }));
					}

					setState((prev) => ({ ...prev, isSignUp: false }));
				});
		}
	};
	const sessionToken = useRef();
	const {
		libraryLoading,
		predictions: countryPredictions,
		loadingPredictions,
	} = useLocationPredictions(countrySearchQuery, {
		sessionToken,
		locationType: "(regions)",
		onlyCountry: true,
		minLength: 1,
	});

	const removecountry = () => {
		setState((prev) => ({ ...prev, country: "" }));
	};

	return (
		<div className="auth-page-Container">
			<BackToLandingBtn />
			<div className="pinkOval"></div>
			<div className="yellowDiv"></div>
			<div className="container">
				<div className="form-container register-form-container">
					<div className="header">
						<h1>
							{/* <FormattedMessage
								id="login.heading1"
								defaultMessage={`Sign Up`}
							/> */}
							Sign Up
						</h1>
						<p>
							{/* <FormattedMessage
								id="login.heading2"
								defaultMessage={`Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.`}
							/> */}
							Bditto is a powerful enterprise solution that will
							help you easily share, connect and explore thoughts
							in an expedited manner.
						</p>
					</div>

					<div className="form">
						<div className="left">
							<img className="logoM" src="/logoMain.svg" alt="" />
							<div className="heading">
								<h1>
									Where people{" "}
									<span className="gradienth1">connect</span>{" "}
									<span> & </span>{" "}
									<span className="gradienth1">share...</span>
								</h1>
							</div>
							<div className="launchImg">
								<img src="/icons/Launch.svg" alt="" />
							</div>
						</div>

						<div className="right">
							<div className="heading">
								<div className="login">
									<img src="/icons/login.svg" alt="" />
									<p>Sign Up</p>
								</div>
								<div className="underline"></div>
							</div>

							<div className="login-form">
								<div className="heading">
									<h5>Account details</h5>
								</div>
								<form noValidate onSubmit={handleSubmit}>
									<div className="inputDiv">
										<div className="input">
											<InputField
												className="email-field"
												placeholder="Email *"
												name="email"
												required
												errorText={state.errors.email}
												value={state.email}
												onChange={handleChange}
											/>
										</div>
										<div className="label">
											<p>
												Let's make the most of your work
												email
											</p>
										</div>
									</div>

									<div className="inputDiv">
										<div className="input">
											<InputField
												className="usernam-field"
												autoComplete="false"
												placeholder="Username *"
												name="username"
												required
												errorText={
													state.errors.username
												}
												value={state.username}
												onChange={handleChange}
											/>
										</div>
										{/* <div className="label">
											<p>{state.usernameMsg}</p>
										</div> */}
									</div>

									<div className="inputDiv">
										<div className="input">
											<InputField
												className="fullname-field"
												autoComplete="false"
												placeholder="Full Name *"
												name="full_name"
												required
												errorText={
													state.errors.full_name
												}
												value={state.full_name}
												onChange={handleChange}
												type="text"
											/>
										</div>
									</div>
									<div className="inputDiv">
										<div className="input">
											<InputField
												removecountry={removecountry}
												state={state}
												className="country-field"
												disabled={libraryLoading}
												optionsLoading={
													loadingPredictions
												}
												placeholder="Select Country*"
												name="country"
												options={countryPredictions.map(
													(opt) => opt.label.main_text
												)}
												required
												errorText={state.errors.country}
												tooltip={
													locationLoading ? (
														<div
															style={{
																fontSize:
																	"0.7rem",
																color: "#999",
															}}
														>
															<CircularProgress
																size={10}
															/>
															{"  "}fetching your
															location
														</div>
													) : libraryLoading ? (
														<div
															style={{
																fontSize:
																	"0.7rem",
																color: "#999",
															}}
														>
															<CircularProgress
																size={10}
															/>
															{"  "}Loading
															Suggestions
														</div>
													) : null
												}
												onSearchQueryChange={
													setCountrySearchQuery
												}
												autoComplete="anyrandomstring"
												type="select"
												value={state.country}
												onChange={handleChange}
											/>
										</div>
									</div>

									<div className="inputDiv d-flex">
										<div className="input">
											<InputField
												className="password-field"
												type="password"
												placeholder="Password *"
												name="password"
												required
												errorText={
													state.errors.password
												}
												value={state.password}
												onChange={handleChange}
											/>
										</div>
										{/* {state.passwordMsg && (
											<p className="password-msg">
												{state.passwordMsg}
											</p>
										)} */}
									</div>

									<p className="outLinks">
										{/* <FormattedMessage
											id="login.heading4"
											defaultMessage={`By continuing, you’re agreeing to our `}
										/> */}
										By continuing, you’re agreeing to our
										&nbsp;
										<Link to="/user-agreement">
											<span className="forget">
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`User Agreement `}
												/> */}
												User Agreement{" "}
											</span>
										</Link>
										<Link to="/privacy">
											<span className="forget">
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={` Privacy Policy `}
												/> */}
												Privacy Policy
											</span>
										</Link>
										{/* <FormattedMessage
											id="login.heading4"
											defaultMessage={`, and Cookie Policy.`}
										/> */}
										, and Cookie Policy.
									</p>

									<div className="inputDiv">
										<button
											className="login-btn"
											type="submit"
										>
											{/* <FormattedMessage
												id="signIn"
												defaultMessage={`Sign up`}
											/> */}
											Sign up
											{state.isLoggingIn && (
												<CircularProgress
													className="loader"
													color="primary"
													size={20}
												/>
											)}
										</button>
										<p className="link">
											{/* <FormattedMessage
												id="login.heading4"
												defaultMessage={`Go back to `}
											/> */}
											Go back to &nbsp;
											<Link to="/login">
												<span className="forget">
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Login`}
													/> */}
													Login
												</span>
											</Link>
										</p>
									</div>
								</form>
							</div>

							<hr className="orLine"></hr>

							<div className="social">
								<GoogleLogin
									clientId={socialKeys.GOOGLE}
									onSuccess={googleCallbacks.onSuccess({
										onRequireSignUp: () => socialSignUp(),
									})}
									onFailure={googleCallbacks.onFailure}
									buttonText="Login"
									render={(renderProps) => (
										<button
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
											className="button"
										>
											<img
												src="/icons/google.svg"
												alt="google"
											/>
											<p>
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`Login with Google`}
												/> */}
												Login with Google
											</p>
										</button>
									)}
								/>

								<TwitterLoginComponent
									authCallback={twitterCallback({
										onRequireSignUp: () => socialSignUp(),
									})}
								>
									<button elevation={2} className="button">
										<img
											src="/icons/twitter.svg"
											alt="twitter"
										/>
										<p>
											{/* <FormattedMessage
												id="login.heading3"
												defaultMessage={`Login with Twitter`}
											/> */}
											Login with Twitter
										</p>
									</button>
								</TwitterLoginComponent>

								<FacebookLogin
									appId={socialKeys.FACEBOOK}
									callback={facebookCallback({
										onRequireSignUp: () => socialSignUp(),
									})}
									render={(renderProps) => (
										<button
											onClick={renderProps.onClick}
											disabled={renderProps.isDisabled}
											elevation={2}
											className="button"
										>
											<img
												src="/icons/facebook.svg"
												alt="facebook"
											/>
											<p>
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`Login with Facebook`}
												/> */}
												Login with Facebook
											</p>
										</button>
									)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
