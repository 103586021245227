import React from "react";
import HoverPopover from "../HoverPopover";

import styles from "./ImagePopover.module.css";
const ImagePopover = (props) => {
	return (
		<HoverPopover
			trigger={props.children}
			className={styles.wrapper}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "center",
				horizontal: "left",
			}}
		>
			<div className={styles.popover}>
				{props.background_image && (
					<img
						src={props.background_image}
						style={{
							borderRadius: "4px",
							maxWidth: "300px",
							maxHeight: "200px",
						}}
					/>
				)}
			</div>
		</HoverPopover>
	);
};

export default ImagePopover;
