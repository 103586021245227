import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import IdleTimer from "./IdleTimer";
import { logout } from "../../Redux/Actions/actionAuth";
import config from "../../config";

const SessionTimeout = ({ children, isAuthenticated }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		let timer = null;
		if (isAuthenticated) {
			const timer = new IdleTimer({
				timeout: config.SESSION_TIMEOUT,
				onTimeout: () => {
					dispatch(logout());
				},
			});

			timer.startInterval();
		} else {
			timer && timer.cleanUp();
			timer = null;
		}
		return () => {
			timer && timer.cleanUp();
			timer = null;
		};
	}, [isAuthenticated, dispatch]);
	return children;
};

export default SessionTimeout;
