import React, { useEffect, useRef } from "react";
import { Dialog } from "@material-ui/core";
import {
	AspectRatio,
	CallEnd,
	VideocamOutlined,
	CallOutlined,
} from "@material-ui/icons";
import Username from "../../Extras/Username";

import {
	Avatar,
	Button,
	IconButton,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import styles from "./NewCallNotification.module.scss";
import { useHistory } from "react-router-dom";
import * as socket from "../../../Utils/socket";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../../Extras/Alert/useAlert";
import {
	closeCallNotification,
	toggleVideo,
} from "../../../Redux/Actions/videoCalls/actions";
import { audioVideoStateSelector } from "../../../Redux/selectors/videoCalls/configuration";
const useStyles = makeStyles((theme) => ({
	dialog: {
		position: "absolute",
		right: 0,
		bottom: 0,
		marginBottom: "6px",
		marginRight: "5px",
		borderRadius: "8px",
		background: "transparent",
		boxShadow: "none",
	},
	avatarBackground: {
		backgroundColor: "rgba(255, 255, 255, 0.15);",
		zIndex: 10,
	},
	avatar: {
		height: "8.2rem",
		width: "8.2rem",
		fontSize: "3.5rem",
		border: `4px solid #fdfdf5`,
		boxShadow: "0px 4px 30px 30px rgba(255, 255, 255, 0.07)",
	},
	statusIndicatorIcons: {
		border: "1px solid white",
		color: "white",
		margin: "0 5px",
	},
	dialogContent: {
		padding: "1rem 0",
		"&::-webkit-scrollbar": {
			width: "5px",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#ff5b5b",
		},
	},
	sideButtonsOff: {
		border: "2px solid black",
		padding: "0.5rem",
		color: "black",
	},

	sideButtonsOn: {
		backgroundColor: "#42DA93",
		border: "2px solid #42DA93",
		padding: "0.5rem",
		"&:hover": { backgroundColor: "#42DA93" },

		color: "white",
	},
	iconStyle: { fontSize: "1.1rem" },
	callEndButton: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		margin: " 0 1rem",
		"&:hover": { backgroundColor: theme.palette.primary.main },
	},
}));
function attachMediaStream(video, stream) {
	const videoTracks = stream.getVideoTracks();
	// console.log(`Using video device: ${videoTracks[0].label}`);

	video.srcObject = stream;
}
const NewCallNotification = (props) => {
	let stream = "";
	const classes = useStyles();
	const selfVideo = useRef();
	const history = useHistory();
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
	const camStream = useRef();
	// const selector = useSelector()
	const data = useSelector((state) => state.videoCalls.incommingCallData);

	const audioVideoParams = useSelector(audioVideoStateSelector);

	const videoRef = useRef(null);

	useEffect(() => {
		getVideo();
		// const audioEl = document.getElementsByClassName("audio-element")[0]

		var url = "https://bigsoundbank.com/UPLOAD/mp3/0063.mp3";
		stream = new Audio(url);
		stream.play();
		stream.loop = true;
		stream.preload = "none";
	}, [videoRef]);

	const getVideo = () => {
		let video1 = "";
		if (data.type === "VIDEO") {
			video1 = true;
		} else video1 = false;
		try {
			navigator.mediaDevices
				.getUserMedia({ video: video1, audio: false })
				.then((stream) => {
					let video = videoRef.current;
					video.srcObject = stream;
					video.play();
				})
				.catch((err) => {
					// console.error("error:", err);
				});
		} catch (err) {
			/* handle the error */
			// console.error("error:");
		}
	};

	const answerCall = () => {
		dispatch(closeCallNotification());
		history.push(
			`/call?callID=${data.callID}&answerCall=true&userID=${data.from.userID}&username=${data.from.username}&type=VIDEO`
		);
		socket.answerCall(data.callID, (err, res) => {
			if (res) {
				// console.log(res);
				stream.pause();
				stream.src = "";
			} else if (err) {
				showAlert(err.message, {
					callback: () => {
						// history.push("/chat");
					},
				});
			}
			// console.log("call answered");
		});
	};
	const answerCallAudio = () => {
		dispatch(closeCallNotification());
		history.push(
			`/call?callID=${data.callID}&answerCall=true&userID=${data.from.userID}&username=${data.from.username}&type=AUDIO`
		);
		socket.answerCall(data.callID, (err, res) => {
			if (res) {
				stream.pause();
				stream.src = "";
			} else if (err) {
				showAlert(err.message, {
					callback: () => {
						history.push("/chat");
					},
				});
			}
			// console.log("call answered");
		});
	};
	const rejectCall = () => {
		socket.rejectCall(data.callID);
		dispatch(closeCallNotification());
		stream.pause();
		stream.src = "";
	};
	return (
		<Dialog
			open={props.open}
			onClose={() => dispatch(closeCallNotification())}
			classes={{
				paper: classes.dialog,
			}}
			style={{
				backgroundColor: "transparent",
				boxShadow: "none",
			}}
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "none",
				},
			}}
		>
			<div className={styles.notificationContainer}>
				<div className={styles.videoContainer}>
					<div className={styles.info}>
						<div className={styles.username}>
							<Username value={data.from.username} />
						</div>
					</div>
					<div className={styles.avatarContainer}>
						<Avatar
							classes={{
								root: classes.avatar,
								colorDefault: classes.avatarBackground,
							}}
						>
							{data?.type === "VIDEO" ? (
								<img src="/icons\videoCallNotificationIcon.svg" />
							) : (
								<img src="/icons/audioCallNotification.svg" />
							)}
						</Avatar>
					</div>
					<video
						className={
							!audioVideoParams.video ? styles.videoOff : ""
						}
						ref={videoRef}
						id="selfView"
						autoPlay="true"
					></video>
					<div
						className={`${styles.videoContainer__footer} ${styles.videoContainer__footer__own}`}
					></div>
				</div>
				<div className={styles.bottomcontainer}>
					<div className={styles.callOptionsContainer}>
						<IconButton
							onClick={answerCallAudio}
							className={classes.sideButtonsOn}
							//   onClick={() => dispatch(toggleAudio())}
						>
							<CallOutlined className={classes.iconStyle} />
						</IconButton>
						<IconButton
							classes={{ colorPrimary: classes.callEndButton }}
							color="primary"
						>
							<CallEnd
								fontSize="large"
								className={classes.iconStyle}
								onClick={() => rejectCall()}
							/>
						</IconButton>
						<IconButton
							onClick={answerCall}
							className={classes.sideButtonsOn}
						>
							<VideocamOutlined className={classes.iconStyle} />
						</IconButton>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default NewCallNotification;
