import React from "react";

import UserSkeleton from "../../../Skeleton/UserSkeleton/UserSkeleton";
import styles from "./Mobile.module.css";

import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
export default function Mobile(props) {
	const {
		username,
		bio,
		country,
		isUserLoading,

		userLoaded,
	} = props;

	return (
		<div className={styles.resultBox}>
			<div className={styles.user}>
				<div className={styles.resultDiv}>
					{isUserLoading ? (
						<>
							<UserSkeleton width="80%" />
							<UserSkeleton width="80%" />
							<UserSkeleton width="80%" />
						</>
					) : !username ? (
						userLoaded && <p>No Result Found</p>
					) : (
						<>
							<div>
								<label className={styles.label}>
									Full Name
								</label>
								<h1 className={styles.details}>{username}</h1>
							</div>
							<div>
								<label className={styles.label}>Country</label>
								<h1 className={styles.details}>{country}</h1>
							</div>
							{bio && (
								<div>
									<label className={styles.label}>Bio</label>
									<h1 className={styles.detailsBio}>{bio}</h1>
								</div>
							)}

							{props.webUrls?.map((urls) => (
								<div>
									{urls.key === "facebook" && (
										<>
											<FacebookIcon
												className={styles.icon}
											/>
											<a
												href={urls.value}
												target="_blank"
												rel="noopener noreferrer"
											>
												<h1
													className={
														styles.detailsUrl
													}
												>
													{urls.value}
												</h1>
											</a>
										</>
									)}
									{urls.key === "instagram" && (
										<>
											<InstagramIcon
												className={styles.icon}
											/>
											<a
												href={urls.value}
												target="_blank"
												rel="noopener noreferrer"
											>
												<h1
													className={
														styles.detailsUrl
													}
												>
													{urls.value}
												</h1>
											</a>
										</>
									)}
									{urls.key === "linkedin" && (
										<>
											<LinkedInIcon
												className={styles.icon}
											/>
											<a
												href={urls.value}
												target="_blank"
												rel="noopener noreferrer"
											>
												<h1
													className={
														styles.detailsUrl
													}
												>
													{urls.value}
												</h1>
											</a>
										</>
									)}
									{urls.key === "github" && (
										<>
											<GitHubIcon
												className={styles.icon}
											/>
											<a
												href={urls.value}
												target="_blank"
												rel="noopener noreferrer"
											>
												<h1
													className={
														styles.detailsUrl
													}
												>
													{urls.value}
												</h1>
											</a>
										</>
									)}
									{urls.key === "twitter" && (
										<>
											<TwitterIcon
												className={styles.icon}
											/>
											<a
												href={urls.value}
												target="_blank"
												rel="noopener noreferrer"
											>
												<h1
													className={
														styles.detailsUrl
													}
												>
													{urls.value}
												</h1>
											</a>
										</>
									)}
									{urls.key === "others" && (
										<>
											<LanguageIcon
												className={styles.icon}
											/>
											<a
												href={urls.value}
												target="_blank"
												rel="noopener noreferrer"
											>
												<h1
													className={
														styles.detailsUrl
													}
												>
													{urls.value}
												</h1>
											</a>
										</>
									)}
								</div>
							))}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
