/* eslint-disable react-hooks/exhaustive-deps */
import { Chip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useRef, useState } from "react";
import axiosConfig from "../../../Utils/axiosConfig";
import useAlert from "./../Alert/useAlert";
import {
	createHashtagsURL,
	editStatusURL,
	searchHashtagsURL,
} from "../../../Utils/Constants";
import ImagePreview from "../ImagePreview/ImagePreview";
import Styles from "../AddStatusModal/AddStatusModal.module.css";
import { FormattedMessage } from "react-intl";
import DialogModal from "./../DialogModal";
import CameraIcon from "./../../../Icons/CameraIcon";
import AttachIcon from "./../../../Icons/AttachIcon";
import CloseIcon from "./../../../Icons/CloseIcon";
import { gradients } from "../../../Utils/gradients";
import Button1 from "../Buttons/Button1/Button1";
import { useSelector } from "react-redux";
import Arrows from "../../../Icons/Arrows";
import Tickicon from "../../../Icons/Tickicon";

const useStyles = makeStyles({
	cancel: {
		position: "absolute",
		right: "0",
		cursor: "pointer",
		color: "#ff5b5b",
	},
	attach: {
		position: "relative",
		cursor: "pointer",
		marginBottom: "10px",
		marginR: "2.5rem",
		"@media (max-width:780px)": {
			marginRight: "1.1rem",
		},
	},
	post: {
		float: "right",
		marginLeft: "auto !important",
		color: "#ffffff",
		marginTop: "1em",
		background: "linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%)",
		textTransform: "none",
		padding: "0.4rem 2rem",
		fontSize: "1rem",
		alignContent: "center",
		boxShadow: "0px 4px 15px rgba(189, 78, 156, 0.2)",
		borderRadius: "100px",
		fontFamily: "Poppins",
	},
	icon: {
		position: "absolute",
		right: "0",
		cursor: "pointer",
		marginTop: "10px",
		marginRight: "5rem",
		"@media (max-width:780px)": {
			marginRight: "3.5rem",
		},
	},
	"& .MuiPaper-rounded": {
		borderRadius: "16px",
	},
	paper: {
		borderRadius: "16px",
		// border: '3px solid '
	},
});

const ALLOWED_FILE_EXTENSIONS = [
	"pdf",
	"word",
	"jpeg",
	"jpg",
	"png",
	"jfif",
	"doc",
	"docx",
];

function EditStatusModal(props) {
	const { showAlert } = useAlert();
	const [statusText, setStatusText] = useState("");
	const [tagsSuggestion, setTagsSuggestion] = useState([]);
	const [displaySuggestion, setDisplaySuggestion] = useState([]);
	const [files, setFiles] = useState([]);
	const [image, setImage] = useState(null);
	const [imageURL, setImageURL] = useState(null);
	const [sending, setSending] = useState(false);
	const [palette, setPalette] = useState("RED");

	let isTag = useRef(false);
	let tagIndex = useRef(0);
	let lastTag = useRef("");
	let userTags = useRef([]);
	const imgRef = useRef(null);
	const fileRef = useRef(null);
	const statusRef = useRef(null);
	const classes = useStyles();
	const theme = useSelector((state) => state.theme.theme);

	useEffect(() => {
		if (props.status) {
			setStatusText(props.status.content);
			if (props.status.background_image) {
				setImage(true);
				setImageURL(props.status.background_image);
			}

			setPalette(props.status.background_color);
		}
		// userTags.push({ name: "#" + hashtag, id: res.data.body.Id });
	}, [props.status]);

	const handleChange = (event) => {
		let searchText = event.target.value;
		setStatusText(searchText);

		let lastWord = searchText.charAt(searchText.length - 1);

		if (lastWord === "#") {
			isTag.current = true;
			tagIndex.current = searchText.length;
			if (lastTag.current.length) lastTag.current = "";
		}

		if (lastWord === " ") {
			isTag.current = false;
			if (lastTag.current.length) checkIfPushed(lastTag.current);
			lastTag.current = "";
			return;
		}

		if (isTag.current && lastWord !== "#") {
			lastTag.current = searchText.substring(tagIndex.current);
		}

		if (lastTag === "") setTagsZero();

		if (isTag.current && lastTag.current.length === 2) {
			fetchSuggestion(lastTag.current);
			return;
		}

		if (isTag.current && lastTag.current.length > 2) {
			let suggestion = tagsSuggestion.filter(
				(tag, index) =>
					tag.name.startsWith(lastTag.current) && index < 5
			);
			setDisplaySuggestion(suggestion);
		}
	};

	const checkIfPushed = (Tag) => {
		userTags.current.map((data) => {
			if (data.name.toLowerCase() === "#" + Tag.toLowerCase()) {
				setTagsZero();
			}
			return 0;
		});
		tagsSuggestion.map((data) => {
			if (data.name.toLowerCase() === Tag.toLowerCase()) {
				userTags.current.push({ name: "#" + data.name, id: data.pk });
				setTagsZero();
			}
			return 0;
		});

		createHashtag(Tag);
		setTagsZero();
	};

	const setTagsZero = () => {
		if (tagsSuggestion.length) setTagsSuggestion([]);
		if (displaySuggestion.length) setDisplaySuggestion([]);
	};

	const fetchSuggestion = (search, findOldTags) => {
		const form = new FormData();
		form.append("text", search);

		axiosConfig
			.post(searchHashtagsURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (findOldTags) {
					res.data.body.forEach((tag) => {
						if (tag.name === search) {
							userTags.current.push({
								name: "#" + tag.name,
								id: tag.pk,
							});
						}
					});
				} else {
					setTagsSuggestion(res.data.body);
					setDisplaySuggestion(res.data.body);
				}
			})
			.catch((err) => {});
	};

	// eslint-disable-next-line no-useless-escape
	const regexp = /\B\#\w\w+\b/g;

	const findTags = () => {
		if (props.status) {
			if (props.status.content) {
				const results = props.status.content.match(regexp);
				if (results) {
					results.forEach((result) => {
						fetchSuggestion(result.slice(1, result.length), true);
					});
				}
			}
		}
	};

	props.status && findTags();

	const createHashtag = (hashtag, callback) => {
		const form = new FormData();
		form.append("hashtag", hashtag);
		axiosConfig
			.post(createHashtagsURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				userTags.current.push({
					name: "#" + hashtag,
					id: res.data.body.Id,
				});
				if (callback) callback();
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			
			});
	};

	const handleTagClick = (tagName, tagId) => {
		let tags = statusText.split("#");
		tags.pop();
		let newText = tags.join("#") + "#" + tagName + " ";
		statusRef.current.focus();
		userTags.current.push({ name: "#" + tagName, id: tagId });
		setStatusText(newText);
		isTag.current = false;
		lastTag.current = "";
		setTagsZero();
	};

	const changeImage = (event) => {
		setImage(event.target.files[0]);
		setImageURL(URL.createObjectURL(event.target.files[0]));
	};

	const removeImage = () => {
		setImage(null);
		setImageURL(null);
	};

	const setImgRef = () => {
		imgRef.current.click();
	};

	const setFileRef = () => {
		fileRef.current.click();
	};

	const handleFileChange = (event) => {
		const newFiles = [...event.target.files];
		const validNewFiles = newFiles.filter((file) => {
			const ext = file.name.split(".").pop().toLowerCase();
			return ALLOWED_FILE_EXTENSIONS.includes(ext);
		});
		if (validNewFiles.length < newFiles.length) {
			showAlert(
				"Some Files were ignored as those file types are not allowed"
			);
		}
		setFiles([...files, ...validNewFiles]);
	};

	const handleImageUpload = (event) => {
		setImage(event.target.files[0]);
		setImageURL(URL.createObjectURL(event.target.files[0]));
	};

	const handleFileRemove = (fileName) => {
		let newFiles = files.filter((file) => file.name !== fileName);
		setFiles(newFiles);
	};

	const handlePost = async () => {
        if (statusText.length > 140) {
            showAlert("Status exceeds word limit", {
							header: "Status exceeds word limit",
							buttonText: "Go Back",
						})
			
			return;
		}

        if (!statusText.length) {
                showAlert("Status field cannot be empty", {
							header: "Status field cannot be empty",
							buttonText: "Go Back",
						})
			return;
		}

		setSending(true);

		if (isTag.current) {
			createHashtag(lastTag.current, filterAndPost);
		} else filterAndPost();
	};

	const filterAndPost = () => {
		let background = palette;
		let form = new FormData();

		form.append("content", statusText);
		form.append("backgroundColor", background);
		form.append("statusId", props.status.pk);

		if (image && image !== true) form.append("backgroundImage", image);

		if (files.length) {
			files.forEach((file) => form.append("files", file));
		}

		let hashTags = statusText.match(/#[a-z0-9_]+/gi);

		if (hashTags) {
			hashTags = hashTags.map((tag) => tag.toLowerCase());

			hashTags = [...new Set(hashTags)];
			let newTags = [];
			hashTags.map((hash) => {
				for (let i = 0; i < userTags.current.length; ++i) {
					if (
						userTags.current[i].name.toLowerCase() ===
						hash.toLowerCase()
					) {
						newTags.push(userTags.current[i]);
						break;
					}
				}
				return 0;
			});
			newTags.map((tag) => form.append("hashtags", tag.id));
		}

		axiosConfig
			.post(editStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (props.liked) {
					props.fetchMyStatus("Refresh Liked Status");
				} else {
					props.fetchMyStatus();
				}

				setStatusText("");
				setTagsZero();
				isTag = false;
				tagIndex = 0;
				lastTag = "";
				userTags.current = [];
				setFiles([]);
				setImage(null);
				setImageURL(null);

				setSending(false);
				props.onHide();
			})
			.catch((err) => {
				setSending(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const handelClose = () => {
		props.onHide();
		isTag = false;
		tagIndex = 0;
		lastTag = "";
		userTags.current = [];
	};

	const wordCount = 140 - statusText.length;

	const singleColour = Object.keys(gradients).map(
		(colour) =>
			palette != colour && (
				<div
					style={{
						border: `1px solid ${gradients[colour]}`,
						borderLeft: "none",
						borderRadius: "0px 8px 8px 0px",
						zIndex: palette === colour ? 2 : 1,
					}}
					className={Styles.single_colour}
					onClick={() => setPalette(colour)}
					key={colour.first}
				></div>
			)
	);

	const isActive = props.status?.current_status === "active";
	const border = gradients[palette];
	return (
		<DialogModal
			{...props}
			width="sm"
			maxWidth="true"
			className={classes.paper}
		>
			<span
				className={classes.cancel}
				style={{ top: 12, right: 12 }}
				onClick={handelClose}
			>
				<CloseIcon />
			</span>

			<div
				className={`${Styles.modalBody} ${
					theme === "dark" ? Styles.darkBg : Styles.lgBg
				}`}
				style={{ borderRadius: "16px", border: `1px solid ${border}` }}
			>
				<h4 className={Styles.heading}>
					{/* <FormattedMessage
						id="editThoughts"
						defaultMessage={`Edit Thought`}
					/> */}
                    Edit Thought
				</h4>
				{!isActive && (
					<span className={Styles.notActive}>
						{/* <FormattedMessage
							id="activateThoughtToEdit"
							defaultMessage={`Please activate the thought to edit it.`}
						/> */}
                        Please activate the thought to edit it.
					</span>
				)}
				<div
					className={
						theme === "dark" ? Styles.textdarkBg : Styles.textlgBg
					}
					style={{
						borderRadius: "8px",
						margin: "1rem 0",
					}}
				>
					<textarea
						className={`${Styles.statusField} ${
							theme === "dark" ? Styles.darktext : Styles.lgtext
						}`}
						placeholder="What are you thinking right now?"
						onChange={handleChange}
						value={statusText}
						// style={{ background: "transparent" }}
						ref={statusRef}
					/>

					<div className={Styles.thoughtInfo}>
						<div className={Styles.icons}>
							{!image && (
								<>
									<span
										className={Styles.imageIcon}
										onClick={() => setImgRef()}
									>
										<img src="/Home/imageIcon.svg" alt="" />
									</span>
									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => handleImageUpload(e)}
										ref={imgRef}
									/>
								</>
							)}
							{image && (
								<ImagePreview
									image={imageURL}
									changeImage={changeImage}
									removeImage={removeImage}
								/>
							)}

							<span
								className={
									!image
										? Styles.fileIcon
										: Styles.imageSelected
								}
								onClick={() => setFileRef()}
							>
								<img src="/Home/fileIcon.svg" alt="" />
							</span>
							<input
								type="file"
								style={{ display: "none" }}
								onChange={(e) => handleFileChange(e)}
								ref={fileRef}
								multiple
							/>

							<div className={Styles.suggestion}>
								{files.length > 0 && (
									<>
										{files.map((file, key) => (
											<Tooltip title="Remove" key={key}>
												<div
													className={
														Styles.tagContainer
													}
												>
													<Chip
														key={key}
														// icon={<AttachFileIcon size="small" />}
														onDelete={() =>
															handleFileRemove(
																file.name
															)
														}
														style={{
															cursor: "pointer",
														}}
														label={`${file.name}`}
													/>
												</div>
											</Tooltip>
										))}
									</>
								)}
							</div>
						</div>

						{wordCount < 0 && (
							<div className={Styles.exceed}>
								Less is more. You’re trying to exceed the
								character limit!
							</div>
						)}
						<p className={Styles.inLimit}>
							{statusText.length} / 140 characters
						</p>
					</div>
				</div>

				<div className={Styles.icons}>
					<div className={Styles.colour_container}>
						<div
							style={{
								borderRadius: "8px",
								background: gradients[palette],
								width: "59px",
								height: "38px",
								zIndex: 2,
							}}
							className={Styles.single_colour}
						>
							<span
								style={{
									marginLeft: "17px",
									marginTop: "8px",
									display: "flex",
								}}
							>
								<Tickicon />
							</span>
						</div>
						{singleColour}
					</div>

					<Button1
						variant="contained"
						color="primary"
						className={classes.post}
						onClick={handlePost}
						disabled={sending}
					>
						{sending ? (
							<CircularProgress
								size="20px"
								style={{ color: "white" }}
							/>
						) : (
							<>
								{/* <FormattedMessage
									id="post"
									defaultMessage={`Post Thought`}
								/> */}
                                Post Thought
								<Arrows.Right
									style={{
										marginLeft: "0.4rem",
										marginTop: "0.75rem",
									}}
								/>
							</>
						)}
					</Button1>
				</div>

				<div className={Styles.suggestion}>
					{files.length > 0 && (
						<>
							{files.map((file, key) => (
								<Tooltip title="Remove">
									<div className={Styles.tagContainer}>
										<Chip
											key={key}
											icon={
												<AttachFileIcon size="small" />
											}
											onDelete={() =>
												handleFileRemove(file.name)
											}
											style={{ cursor: "pointer" }}
											label={`${file.name}`}
										/>
									</div>
								</Tooltip>
							))}
						</>
					)}
				</div>
			</div>
		</DialogModal>
	);
}

export default EditStatusModal;
