import React, { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import styles from "./Group.module.css";
import styled from "styled-components";

import formatDate from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import { IconButton } from "@material-ui/core";
import UsersIcon from "../../../../../Icons/users";
import { formatDistance } from "date-fns/esm";

const userRefRegex = /(<User pk=\{([0-9]+)\}>(@[a-zA-Z0-9]+)<\/User>)/g;

function sanitizeMessage(message) {
	if (message) {
		const userMentions = Array.from(message.matchAll(userRefRegex));
		let i = 0;
		return message.replace(userRefRegex, function (user) {
			const replaceText = userMentions[i][3];
			i++;
			return replaceText;
		});
	} else {
		return message;
	}
}

const Group = forwardRef((props, ref) => {
	const { data, chatID } = props;
	const [convId, setConvId] = useState(null);
	const [name, setName] = useState(null);
	const [lastMessage, setLastMessage] = useState({});

	const isChatsLoading = useSelector(
		(state) => state.messages.meta.isChatLoading
	);

	const { userData } = useSelector((state) => ({
		userData: state.auth.user_data,
	}));
	const count = useSelector(
		(state) => state.messages.unreadMessages[convId + "group"]
	);
	const theme = useSelector((state) => state.theme.theme);

	useEffect(() => {
		setLastMessage(data.lastMessage);
	}, [data]);

	useEffect(() => {
		setConvId(data.conversationID);

		setLastMessage(data.lastMessage);
		setName(data.name);
	}, []);

	function getMessagePreview() {
		let messagePreview;
		let username = "";
		if (lastMessage) {
			if (lastMessage.type === "INFO") {
				return lastMessage.message;
			}
			const mediaMessageLabel = lastMessage.type;
			username =
				lastMessage.from == userData?.userID
					? "You"
					: lastMessage.username;
			messagePreview = (
				<>
					<b>{username}</b>:
					{" " +
						(lastMessage.isDeleted
							? "This message is Deleted"
							: sanitizeMessage(lastMessage.message) ||
							  mediaMessageLabel)}
				</>
			);
			return messagePreview;
		} else {
			return (messagePreview = "Click Here to start chatting");
		}
	}

	const lastMessageDate = new Date(lastMessage?.timeStamp || Date.now());
	const timeStampDate = new Date(data.timestamp);
	const [hoverOnNewMessage, sethoverOnNewMessage] = useState(false);
	return (
		<Link
			style={
				isChatsLoading
					? { pointerEvents: "none", height: "fit-content" }
					: { height: "fit-content" }
			}
			ref={ref}
			to={`/chat/group/${convId}`}
		>
			<GroupContainer
				className={`${styles.container} ${
					props.isActive && styles.activeChat
				}`}
				isActive={props.isActive}
				theme={theme}
				onMouseEnter={() => sethoverOnNewMessage(true)}
				onMouseLeave={() => sethoverOnNewMessage(false)}
			>
				{props.isActive && (
					<div
						className={styles.firstLine}
						style={{
							color: theme === "dark" ? "#D9DEF4" : "#0D0F22",
						}}
					>
						{data.members && (
							<Avatar
								style={{
									background:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
									color:
										theme === "dark"
											? "#0d0f22"
											: "#d9def4",
								}}
								src={
									data.members[data.members.length - 1]
										.profileURL !== "" &&
									data.members[data.members.length - 1]
										.profileURL
								}
							>
								{data.members[
									data.members.length - 1
								].username[0].toUpperCase()}
							</Avatar>
						)}
						<h1
							className={styles.heading}
							style={{
								color: theme === "dark" ? "#D9DEF4" : "#0D0F22",
							}}
						>
							{data.members[data.members.length - 1].username}
						</h1>
					</div>
				)}

				<div className={styles.firstLine}>
					<h1
						className={styles.title}
						style={{
							color: theme === "dark" ? "#D9DEF4" : "#0D0F22",
						}}
					>
						{name}
					</h1>
				</div>

				<p className={styles.lastMessage}>{getMessagePreview()}</p>
				{count > 0 &&
					!props.isActive &&
					chatID !== data.conversationID && (
						<div>
							{/* {hoverOnNewMessage == true ? ( */}
							<div style={{ marginBottom: "2rem" }}>
								<div
									style={{
										position: "absolute",
										left: "60%",
										top: "40%",
										zIndex: "9999999",
										display: "flex",
									}}
								>
									<p
										style={{
											margin: "auto 0",
										}}
										className={`${styles.newMessage} ${
											theme === "dark"
												? styles.darkGText
												: styles.lgGText
										}`}
										// onMouseEnter={() =>
										// 	sethoverOnNewMessage(true)
										// }
										// onMouseLeave={() =>
										// 	sethoverOnNewMessage(false)
										// }
									>
										{count}
									</p>
									{hoverOnNewMessage && (
										<div
											style={{
												position: "relative",
												marginLeft: "-50%",
											}}
										>
											<div
												style={{
													width: "max-content",
													position: "absolute",
													left: "-70%",
													zIndex: "9999999",
													display: "flex",
													justifyContent:
														"flex-start",
													border: `1px solid #C4C4C4`,
													borderLeft: "0",
													top: "0%",
													borderRadius:
														"0 0.625rem 0.625rem 0",
													margin: "auto 0",
												}}
											>
												<p
													style={{
														color: "#7F89BE",
														marginBottom: "0rem",
														marginLeft: "1rem",
														marginRight: "1rem",
														fontSize: "0.625rem",
														lineHeight: "1.2rem",
														fontWeight: "700",
													}}
												>
													{formatDistance(
														lastMessageDate,
														new Date(),
														{ addSuffix: true }
													)}
												</p>
											</div>
										</div>
									)}
								</div>
							</div>
							{/* )
							 : ( */}
							{/* <p
									className={`${styles.newMessage} ${
										theme === "dark"
											? styles.darkGText
											: styles.lgGText
									}`}
									// onMouseEnter={() =>
									// 	sethoverOnNewMessage(true)
									// }
									// onMouseLeave={() =>
									// 	sethoverOnNewMessage(false)
									// }
								>
									{count}
								</p> */}
							{/* )} */}
						</div>
					)}

				<div className={styles.content}>
					{/* <div className={styles.avatars}>
            <AvatarGroup max={4} classes={{ avatar: classes.avatar }}>
              {data.members &&
                data.members.slice(0, 4).map((val, key) => {
                  return (
                    <Avatar src={val.profileURL} key={key}>
                      {val.username && val.username[0].toUpperCase()}
                    </Avatar>
                  );
                })}
            </AvatarGroup>
            {data.members.length > 4 && (
              <span className={styles.additionalmembers}>
                + {data.members.length === 5 ? data.members[4].username : data.members.length - 4 + " users"}{" "}
              </span>
            )}
          </div> */}
					{/* <CardContent className={classes.footer}>
            {status.profiles !== undefined && (
              <div className={`${Styles.users} users`}>
                <IconButton size="small">
                  <UsersIcon />
                </IconButton>
                <span className={Styles.countText}>+{additionalUsers} users</span>
              </div>
            )}

            <div className={`${Styles.footerRight} fRight`}> 
              <div className={Styles.likes}>
                <IconButton size="small" onClick={setStatusLike}>
                  {liked ? <FavFilledIcon width="12.5" height="12" /> : <FavIcon width="12.5" height="12" />}
                </IconButton>
                <span className={Styles.countText}>
                  {formatNumber(
                    !status.is_liked
                      ? liked
                        ? status.total_likes + 1
                        : status.total_likes
                      : liked
                      ? status.total_likes
                      : status.total_likes - 1
                  )}
                </span>
              </div>

              <div className={Styles.comments}>
                <IconButton size="small" onClick={viewChat}>
                  <MessageIcon width="12" height="12" />
                </IconButton>
                <span className={Styles.countText}>{formatNumber(status.message_count)}</span>
              </div>
              
              <div className={Styles.date}>
                <IconButton size="small" onClick={setStatusLike}>
                  <img src="/vectors/empty-states/Clock.svg" alt=""/>
                </IconButton>
                <span className={Styles.countText}>{timeConverter(lastUpdatedAt)}</span>
              </div>

            </div>
          </CardContent> */}

					<div className={styles.dFlex}>
						<IconButton size="small">
							<UsersIcon theme={theme} />
						</IconButton>
						<p className={styles.date}>
							+
							{data.memberCount === 1
								? "1 user"
								: data.memberCount + " users"}{" "}
						</p>
					</div>

					<div className={styles.dFlex}>
						<IconButton size="small">
							{theme === "dark" ? (
								<img
									src="/cards-img/Heart.svg"
									alt=""
									style={{ width: "14px" }}
								/>
							) : (
								<img src="/cards-img/heart-lg.png" alt="" />
							)}
						</IconButton>
						<p className={styles.date}>{data.totalike}</p>
					</div>

					<div className={styles.dFlex}>
						<IconButton size="small">
							{theme === "dark" ? (
								<img
									src="/cards-img/comment.svg"
									alt=""
									style={{ width: "16px" }}
								/>
							) : (
								<img src="/cards-img/chat-lg.png" alt="" />
							)}
						</IconButton>
						<p className={styles.date}>{data.totalMessages}</p>
					</div>

					<div className={styles.dFlex}>
						{lastMessage ? (
							<>
								<IconButton size="small">
									{theme === "dark" ? (
										<img
											src="/cards-img/Clock.svg"
											alt=""
											style={{ width: "16px" }}
										/>
									) : (
										<img
											src="/cards-img/clock-lg.png"
											alt=""
										/>
									)}
								</IconButton>
								<p className={styles.date}>
									{isToday(lastMessageDate)
										? formatDate(lastMessageDate, "hh:mm a")
										: isYesterday(lastMessageDate)
										? "Yesterday"
										: formatDate(
												lastMessageDate,
												"dd/MM/yyyy"
										  )}
								</p>
							</>
						) : (
							<>
								<IconButton size="small">
									{theme === "dark" ? (
										<img
											src="/vectors/empty-states/Clock.svg"
											alt=""
										/>
									) : (
										<img src="/Home/lg-clock.svg" alt="" />
									)}
								</IconButton>
								<p className={styles.date}>
									{formatDate(timeStampDate, "dd/MM/yyyy")}
								</p>
							</>
						)}
					</div>
				</div>
			</GroupContainer>
		</Link>
	);
});

const GroupContainer = styled.div`
	box-shadow: ${function ({ theme }) {
		return theme === "dark"
			? "0px 0px 10px rgba(255, 255, 255, 0.06), 0px 4px 20px rgba(0, 0, 0, 0.1)"
			: "0px 0px 10px rgba(0, 0, 0, 0.08)";
	}};

	background: ${function ({ theme, isActive }) {
		if (isActive && theme === "light") {
			return "#F5FAFF";
		}

		return "none";
	}};
`;

export default React.memo(Group);
