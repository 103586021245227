import React from "react";

const Buzzing = ({ width = "22", height = "12", color = "#D9DEF4" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 11.0013C10.3333 7.54797 8 2.83464 6.83333 1.66797C6.83333 5.2123 4.76483 7.19914 3.33333 8.66797C1.903 10.138 1 12.448 1 14.5013C1 16.3578 1.7375 18.1383 3.05025 19.451C4.36301 20.7638 6.14348 21.5013 8 21.5013C9.85652 21.5013 11.637 20.7638 12.9497 19.451C14.2625 18.1383 15 16.3578 15 14.5013C15 12.714 13.768 9.90464 12.6667 8.66797C10.583 12.168 9.4105 12.168 8 11.0013Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default Buzzing;
