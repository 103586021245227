import React from "react";
import { useSelector } from "react-redux";
import "./emptyState.scss";
const EmptyState = ({ heading, subHeading, imageUrl, ...props }) => {
	const theme = useSelector(state => state.theme.theme)
	return (
		<div id={props.id} className={`${props.className} emptyContainer `}>
			<div className="imgContainer">
				<img src={imageUrl} alt={props.alt} />
			</div>
			<div className="typography">
				<h1 className={`heading ${theme === "dark" ? "darkText" : "lgText"}`}>{heading}</h1>
				<p className={`subHeading ${theme === "dark" ? "darkText" : "lgText"}`}>{subHeading} </p>
			</div>
		</div>
	);
};
export default EmptyState;
