import {
	CLEAR_LOCATIONS,
	LOCATION_LOCAL_GLOBAL,
	LOCATION_SET,
	LOGIN_SUCCESS,
	LOGOUT_REQUEST,
	ONLINE_SET,
	REMOVE_LOCATION,
} from "../../Actions/actionTypes";

const initialState = {
	locations: {},
	online: false,
	isLocal: false,
};

const addNewLocation = (locations, newLocation) => {
	const country = newLocation.country.toLowerCase();
	const city = newLocation.city && newLocation.city.toLowerCase();

	const selectedCountry = locations[country];
	if (selectedCountry) {
		if (city) {
			return {
				...locations,
				[country]: !locations[country].includes(city)
					? [...locations[country], city]
					: [],
			};
		} else {
			return { ...locations, [country]: [] };
		}
	} else {
		return { ...locations, [country]: city ? [city] : [] };
	}
};

const removeLocation = (currentLocations, { city, country }) => {
	const { [country]: currCountry, ...otherCountries } = currentLocations;
	if (city) {
		const filteredCountry = currCountry.filter(
			(existingCity) => city !== existingCity
		);
		return {
			...otherCountries,
			[country]: filteredCountry,
		};
	} else {
		return otherCountries;
	}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case LOCATION_SET:
			return {
				...state,
				locations: addNewLocation(state.locations, action.payload),
			};

		case REMOVE_LOCATION:
			return {
				...state,
				locations: removeLocation(state.locations, action.payload),
			};

		case ONLINE_SET:
			return {
				...state,
				online: action.payload.value,
			};

		case LOGIN_SUCCESS:
			return { ...state, online: true };

		case LOGOUT_REQUEST:
			return { ...state, online: false };

		case CLEAR_LOCATIONS:
			return { ...state, locations: initialState.locations };

		default:
			return state;
	}
}
