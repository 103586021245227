import React from "react";

const MessengerIcon = ({ color }) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.81086 10.67C4.70419 10.67 4.59686 10.636 4.50619 10.5667C4.28752 10.398 4.24619 10.084 4.41486 9.86538L6.41019 7.27204C6.49152 7.16604 6.61219 7.09738 6.74419 7.08071C6.87886 7.06338 7.01086 7.10071 7.11553 7.18404L8.99552 8.66071L10.6402 6.53871C10.8095 6.31938 11.1229 6.27871 11.3415 6.44938C11.5602 6.61871 11.6002 6.93271 11.4309 7.15071L9.47752 9.67071C9.39619 9.77604 9.27619 9.84471 9.14419 9.86071C9.01086 9.87871 8.87886 9.84004 8.77353 9.75804L6.89486 8.28204L5.20752 10.4747C5.10886 10.6027 4.96086 10.67 4.81086 10.67Z"
				fill={color}
			/>
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="11"
				y="1"
				width="5"
				height="4"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.53 1.33398H15.0927V4.89725H11.53V1.33398Z"
					fill={color}
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.3114 2.33398C12.8807 2.33398 12.53 2.68398 12.53 3.11532C12.53 3.54598 12.8807 3.89732 13.3114 3.89732C13.742 3.89732 14.0927 3.54598 14.0927 3.11532C14.0927 2.68398 13.742 2.33398 13.3114 2.33398ZM13.3114 4.89732C12.3294 4.89732 11.53 4.09798 11.53 3.11532C11.53 2.13265 12.3294 1.33398 13.3114 1.33398C14.294 1.33398 15.0927 2.13265 15.0927 3.11532C15.0927 4.09798 14.294 4.89732 13.3114 4.89732Z"
					fill={color}
				/>
			</g>
			<mask
				id="mask1"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="1"
				y="1"
				width="14"
				height="15"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.33325 1.89453H14.5745V15.1352H1.33325V1.89453Z"
					fill={color}
				/>
			</mask>
			<g mask="url(#mask1)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.8219 15.1352H5.08592C2.84125 15.1352 1.33325 13.5585 1.33325 11.2119V5.82386C1.33325 3.47386 2.84125 1.89453 5.08592 1.89453H9.93125C10.2073 1.89453 10.4313 2.11853 10.4313 2.39453C10.4313 2.67053 10.2073 2.89453 9.93125 2.89453H5.08592C3.41392 2.89453 2.33325 4.04386 2.33325 5.82386V11.2119C2.33325 13.0152 3.38792 14.1352 5.08592 14.1352H10.8219C12.4939 14.1352 13.5746 12.9879 13.5746 11.2119V6.5192C13.5746 6.2432 13.7986 6.0192 14.0746 6.0192C14.3506 6.0192 14.5746 6.2432 14.5746 6.5192V11.2119C14.5746 13.5585 13.0666 15.1352 10.8219 15.1352Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default MessengerIcon;
