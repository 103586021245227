import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function (component, { style = {}, ...defaultProps } = {}) {
  return ({ style: newStyle = {}, ...props }) => {
    return (
      <SvgIcon
        component={component}
        {...(defaultProps ? defaultProps : {})}
        {...props}
        style={{ ...style, ...newStyle }}
      />
    );
  };
}
