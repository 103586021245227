import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import axiosConfig from "../../../Utils/axiosConfig";
import useAlert from "./../Alert/useAlert";
import {
	createHashtagsURL,
	createStatusURL,
	searchHashtagsURL,
} from "../../../Utils/Constants";
import Styles from "./AddStatusModal.module.css";
import ImagePreview from "../ImagePreview/ImagePreview";
import DialogModal from "../DialogModal";
import CloseIcon from "./../../../Icons/CloseIcon";
// import CameraIcon from "./../../../Icons/CameraIcon";
// import AttachIcon from "./../../../Icons/AttachIcon";
import { gradients } from "../../../Utils/gradients";
// import Image from "../../../Icons/Image";
// import File from "../../../Icons/File";
import Arrows from "../../../Icons/Arrows";
import Tickicon from "../../../Icons/Tickicon";

//MUI
import { Chip, Tooltip, CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles({
	cancel: {
		position: "absolute",
		right: "0",
		cursor: "pointer",
		color: "#ff5b5b",
	},
	attach: {
		position: "relative",
		cursor: "pointer",
		marginBottom: "10px",
		marginR: "2.5rem",
		"@media (max-width:780px)": {
			marginRight: "1.1rem",
		},
	},
	post: {
		float: "right",
		marginLeft: "auto",
		color: "#ffffff",
		marginTop: "1em",
		background: "linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%)",
		textTransform: "none",
		padding: "0.4rem 2rem",
		fontSize: "1rem",
		alignContent: "center",
		boxShadow: "0px 4px 15px rgba(189, 78, 156, 0.2)",
		borderRadius: "100px",
		fontFamily: "Poppins",
		"@media (max-width: 768px)": {
			marginLeft: "0px",
			borderRadius: "16px",
		},
	},
	icon: {
		position: "absolute",
		right: "0",
		cursor: "pointer",
		marginTop: "10px",
		marginRight: "5rem",
		"@media (max-width:780px)": {
			marginRight: "3.5rem",
		},
	},
	"& .MuiPaper-rounded": {
		borderRadius: "16px",
	},
	paper: {
		borderRadius: "16px",
		"@media (max-width: 768px)": {
			height: "100vh",
			width: "95%",
			margin: "0px",
		},
	},
});

const ALLOWED_FILE_EXTENSIONS = [
	"pdf",
	"word",
	"jpeg",
	"jpg",
	"png",
	"jfif",
	"doc",
	"docx",
];

function AddStatusModal(props) {
	const { showAlert } = useAlert();
	const [statusText, setStatusText] = useState("");
	const [tagsSuggestion, setTagsSuggestion] = useState([]);
	const [displaySuggestion, setDisplaySuggestion] = useState([]);
	const [files, setFiles] = useState([]);
	const [image, setImage] = useState(null);
	const [imageURL, setImageURL] = useState(null);
    const [palette, setPalette] = useState("RED");
    const [typingStop, setTypingStop] = useState(false)
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const theme = useSelector((state) => state.theme.theme);
	const user = useSelector((state) => state.auth.user_data);
	const [sending, setSending] = useState(false);
	let isTag = useRef(false);
	let tagIndex = useRef(0);
	let lastTag = useRef("");
	let userTags = useRef([]);

	useEffect(() => {
		if (props.show) {
			setTimeout(() => {
				statusRef.current.focus();
			}, 100);
		}
	}, [props.show]);

	const imgRef = useRef(null);
	const fileRef = useRef(null);
	const statusRef = useRef(null);

	const classes = useStyles();

	const handleChange = (event) => {
        if (event.target.value.length < 141) {
            setTypingStop(false)
            setStatusText(event.target.value);
            let searchText = event.target.value

            if (searchText !== "") {
                let words = searchText.split(" ");

                for (let i = 0; i < words.length; i++) {
                    if (words[i] === "#") {
                        isTag.current = true;
                        tagIndex.current = searchText.length;
                        if (lastTag.current.length) lastTag.current = "";
                    }

                    if (words[words.length - 1] === " ") {
                        isTag.current = false;
                        if (lastTag.current.length) checkIfPushed(lastTag.current);
                        lastTag.current = "";
                        return;
                    }

                    if (isTag.current && words[words.length - 1] !== "#") {
                        lastTag.current = searchText.substring(tagIndex.current);
                    }

                    if (lastTag === "") setTagsZero();

                    if (isTag.current && lastTag.current.length === 2) {
                        fetchSuggestion(lastTag.current);
                        return;
                    }

                    if (isTag.current && lastTag.current.length > 2) {
                        let suggestion = tagsSuggestion.filter(
                            (tag, index) =>
                                tag.name.startsWith(lastTag.current) && index < 5
                        );
                        setDisplaySuggestion(suggestion);
                    }

                    if (words[i][0] !== "#") {
                        setDisplaySuggestion([]);
                    }
                }
            } else {
                setDisplaySuggestion([]);
            }
        }
        else {
            setTypingStop(true)
        }

	};

	const checkIfPushed = (Tag) => {
		userTags.current.map((data) => {
			if (data.name.toLowerCase() === "#" + Tag.toLowerCase()) {
				setTagsZero();
				return true;
			}
			return false;
		});
		tagsSuggestion.map((data) => {
			if (data.name.toLowerCase() === Tag.toLowerCase()) {
				userTags.current.push({ name: "#" + data.name, id: data.pk });
				setTagsZero();
				return true;
			}
			return false;
		});

		createHashtag(Tag);
		setTagsZero();
	};

	const setTagsZero = () => {
		if (tagsSuggestion.length) setTagsSuggestion([]);
		if (displaySuggestion.length) setDisplaySuggestion([]);
	};

	const fetchSuggestion = (search) => {
		const form = new FormData();
		form.append("text", search);

		axiosConfig
			.post(searchHashtagsURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setTagsSuggestion(res.data.body);
				setDisplaySuggestion(res.data.body);
			})
			.catch((err) => {});
	};

	const createHashtag = (hashtag, callback) => {
		const form = new FormData();
		form.append("hashtag", hashtag);
		axiosConfig
			.post(createHashtagsURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				userTags.current.push({
					name: "#" + hashtag,
					id: res.data.body.Id,
				});
				if (callback) callback();
			})
            .catch((err) => {
                showAlert("Please Try again!", {
							header:"Hashtag can be off max 20 chars and It cannot have a space in between",
							buttonText: "Go Back",
						});
			});
		setSending(false);
	};

	const handleTagClick = (tagName, tagId) => {
		let tags = statusText.split("#");
		tags.pop();
		let newText = tags.join("#") + "#" + tagName + " ";
		statusRef.current.focus();
		userTags.current.push({ name: "#" + tagName, id: tagId });
		setStatusText(newText);
		isTag.current = false;
		lastTag.current = "";
		setTagsZero();
	};

	const changeImage = (event) => {
		setImage(event.target.files[0]);
		setImageURL(URL.createObjectURL(event.target.files[0]));
	};

	const removeImage = () => {
		setImage(null);
		setImageURL(null);
	};

	const setImgRef = () => {
		imgRef.current.click();
	};

	const setFileRef = () => {
		fileRef.current.click();
	};

	const handleFileChange = (event) => {
		const newFiles = [...event.target.files];
		const validNewFiles = newFiles.filter((file) => {
			const ext = file.name.split(".").pop().toLowerCase();
			return ALLOWED_FILE_EXTENSIONS.includes(ext);
		});

        if (validNewFiles.length < newFiles.length) {
             showAlert("Please Try again!", {
							header:"Some Files were ignored as those file types are not allowed",
							buttonText: "Go Back",
						});
		}

		setFiles([...files, ...validNewFiles]);
	};

	const handleImageUpload = (event) => {
		setImage(event.target.files[0]);
		setImageURL(URL.createObjectURL(event.target.files[0]));
	};

	const handleFileRemove = (fileName) => {
		let newFiles = files.filter((file) => file.name !== fileName);
		setFiles(newFiles);
	};

	const handlePost = async () => {
        if (statusText.length > 140) {
            showAlert("Please Try again!", {
							header:"Status exceeds word limit",
							buttonText: "Go Back",
						});
			return;
		}

        if (!statusText.length) {
            showAlert("Please Try again!", {
							header:"Status field cannot be empty",
							buttonText: "Go Back",
						});
			return;
		}

		setSending(true);

		if (isTag.current) {
			createHashtag(lastTag.current, filterAndPost);
		} else filterAndPost();
	};

	const filterAndPost = () => {
		let background = palette;

		let form = new FormData();

		form.append("content", statusText);
		form.append("groupId", groupDataFromStore.id);
		form.append("backgroundColor", background);
		// userTags.current.map(tag=>form.append("hashtags",tag.id ))
		if (image) form.append("backgroundImage", image);

		if (files.length) {
			files.forEach((file) => form.append("files", file));
		}

		let hashTags = statusText.match(/#[a-z0-9_]+/gi);

		if (hashTags) {
			hashTags = [...new Set(hashTags)];
			let newTags = [];
			hashTags.map((hash) => {
				for (let i = 0; i < userTags.current.length; ++i) {
					if (
						userTags.current[i].name.toLowerCase() ===
						hash.toLowerCase()
					) {
						newTags.push(userTags.current[i]);
						break;
					}
				}
				return 0;
			});
			newTags.map((tag) => form.append("hashtags", tag.id));
			hashTags = hashTags.map((tag) => tag.toLowerCase());
		}

		axiosConfig
			.post(createStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				props.fetchMyStatus && props.fetchMyStatus();
				setStatusText("");
				setTagsZero();
				isTag = false;
				tagIndex = 0;
				lastTag = "";
				userTags = [];
				setFiles([]);
				setImage(null);
				setImageURL(null);
				setPalette("RED");
				setSending(false);
				props.onHide();
                props.open = true;
                props.fetchHashtags()
			})
			.catch((err) => {
				setSending(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const singleColour = Object.keys(gradients).map(
		(colour) =>
			palette !== colour && (
				<div
					style={{
						border: `1px solid ${gradients[colour]}`,
						borderLeft: "none",
						borderRadius: "0px 8px 8px 0px",
						zIndex: palette === colour ? 2 : 1,
					}}
					className={Styles.single_colour}
					onClick={() => setPalette(colour)}
					key={colour.first}
				></div>
			)
	);
	const isMobile = window.screen.width < 768;
	const border = gradients[palette];

	return (
		<DialogModal
			{...props}
			width={isMobile ? "lg" : "sm"}
			maxWidth={"sm"}
			fullWidth={isMobile ? true : false}
			rounded={true}
			className={classes.paper}
		>
			<span
				className={classes.cancel + " " + Styles.crossIcon}
				onClick={props.onHide}
			>
				<CloseIcon />
			</span>
			<div
				className={`${Styles.modalBody} ${
					theme === "dark" ? Styles.darkBg : Styles.lgBg
				}`}
				style={{
					borderRadius: "16px",
					border: `1px solid ${border}`,
					height: isMobile ? "100vh" : "",
					margin: "0px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-evenly",
				}}
			>
				<h4 className={Styles.heading}>
					{/* <FormattedMessage
						id="addThoughts"
						defaultMessage={`Add a thought, ${user.full_name} 😃`}
					/> */}
                    {`Add a thought, ${user.full_name} 😃`}
				</h4>
				<div
					className={
						theme === "dark" ? Styles.textdarkBg : Styles.textlgBg
					}
					style={{
						borderRadius: "8px",
						marginTop: "1rem",
					}}
				>
					<textarea
						className={`${
							isMobile
								? Styles.mobstatusField
								: Styles.statusField
						} ${
							theme === "dark" ? Styles.darktext : Styles.lgtext
						}`}
						placeholder="What are you thinking right now?"
						onChange={handleChange}
						value={statusText}
						// style={{ background: "transparent" }}
						ref={statusRef}
					/>

					<div className={Styles.thoughtInfo}>
						<div className={Styles.icons}>
							{!image && (
								<>
									<span
										className={Styles.imageIcon}
										onClick={() => setImgRef()}
									>
										<img src="/Home/imageIcon.svg" alt="" />
									</span>
									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => handleImageUpload(e)}
										ref={imgRef}
									/>
								</>
							)}
							{image && (
								<ImagePreview
									image={imageURL}
									changeImage={changeImage}
									removeImage={removeImage}
								/>
							)}

							<span
								className={
									!image
										? Styles.fileIcon
										: Styles.imageSelected
								}
								onClick={() => setFileRef()}
							>
								<img src="/Home/fileIcon.svg" alt="" />
							</span>
							<input
								type="file"
								style={{ display: "none" }}
								onChange={(e) => handleFileChange(e)}
								ref={fileRef}
								multiple
							/>

							<div className={Styles.suggestion}>
								{files.length > 0 && (
									<>
										{files.map((file, key) => (
											<Tooltip title="Remove" key={key}>
												<div
													className={`${
														Styles.tagContainer
													} ${
														theme === "dark"
															? Styles.darktext
															: Styles.lgtext
													}`}
												>
													<Chip
														key={key}
														// icon={<AttachFileIcon size="small" />}
														onDelete={() =>
															handleFileRemove(
																file.name
															)
														}
														style={{
															cursor: "pointer",
															background:
																theme === "dark"
																	? "#121528"
																	: "#D1E7FE",
															color:
																theme === "dark"
																	? "#7F89BE"
																	: "#4A517E",
														}}
														label={`${file.name}`}
													/>
												</div>
											</Tooltip>
										))}
									</>
								)}
							</div>
						</div>
						{typingStop && (
							<div className={Styles.exceed}>
								You’re trying to exceed the
								character limit!
							</div>
						)}
						<p className={Styles.inLimit}>
							{statusText.length} / 140 characters
						</p>
					</div>
				</div>
				<div className={Styles.suggestion}>
					{displaySuggestion.length > 0 && (
						<>
							{displaySuggestion.map((tag, key) => (
								<div
									className={`${Styles.tagContainer} ${
										theme === "dark"
											? Styles.darktext
											: Styles.lgtext
									}`}
								>
									<Chip
										key={key}
										icon={
											<Add
												size="small"
												style={{
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
										}
										onClick={() =>
											handleTagClick(tag.name, tag.pk)
										}
										style={{
											cursor: "pointer",
											background:
												theme === "dark"
													? "#0d0f22"
													: "#D1E7FE",
											color:
												theme === "dark"
													? "#7F89BE"
													: "#4A517E",
										}}
										label={`#${tag.name}`}
									/>
								</div>
							))}
						</>
					)}
				</div>

				<div className={isMobile ? Styles.bottomActions : Styles.icons}>
					<div className={Styles.colour_container}>
						<div
							style={{
								borderRadius: "8px",
								background: gradients[palette],
								width: "62px",
								height: "38px",
								zIndex: 2,
							}}
							className={Styles.single_colour}
						>
							<span
								style={{
									marginLeft: "20px",
									marginTop: "8px",
									display: "flex",
								}}
							>
								<Tickicon />
							</span>
						</div>
						{singleColour}
					</div>
					<Button
						variant="contained"
						color="primary"
						className={classes.post}
						onClick={handlePost}
						disabled={sending}
					>
						{sending ? (
							<CircularProgress
								size="20px"
								style={{ color: "white" }}
							/>
						) : (
							<>
								{/* <FormattedMessage
									id="post"
									defaultMessage={`Post Thought`}
								/> */}
                                    Post Thought
								<Arrows.Right
									style={{
										marginLeft: "0.4rem",
										marginTop: "0.75rem",
									}}
								/>
							</>
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	);
}

export default AddStatusModal;
