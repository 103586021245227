export const AboutIcon = ({ color, style }) => {
	return (
		<svg
			style={style}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 8H7.01M13 8H13.01M7.5 13C7.82588 13.3326 8.21485 13.5968 8.64413 13.7772C9.07341 13.9576 9.53436 14.0505 10 14.0505C10.4656 14.0505 10.9266 13.9576 11.3559 13.7772C11.7852 13.5968 12.1741 13.3326 12.5 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
