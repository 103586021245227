export function Star({ color }) {
	return (
		<svg
			width="14"
			height="13"
			viewBox="0 0 14 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.83195 1C6.77129 1 6.62195 1.01667 6.54262 1.17533L5.32529 3.60933C5.13262 3.994 4.76129 4.26133 4.33195 4.32267L1.60662 4.71533C1.42662 4.74133 1.36529 4.87467 1.34662 4.93067C1.32995 4.98467 1.30329 5.122 1.42729 5.24067L3.39795 7.134C3.71195 7.436 3.85462 7.87133 3.77995 8.29733L3.31595 10.9707C3.28729 11.138 3.39195 11.2353 3.43862 11.2687C3.48795 11.306 3.61995 11.38 3.78329 11.2947L6.21995 10.0313C6.60395 9.83333 7.06129 9.83333 7.44395 10.0313L9.87995 11.294C10.044 11.3787 10.176 11.3047 10.226 11.2687C10.2726 11.2353 10.3773 11.138 10.3486 10.9707L9.88329 8.29733C9.80862 7.87133 9.95129 7.436 10.2653 7.134L12.236 5.24067C12.3606 5.122 12.334 4.984 12.3166 4.93067C12.2986 4.87467 12.2373 4.74133 12.0573 4.71533L9.33195 4.32267C8.90329 4.26133 8.53195 3.994 8.33929 3.60867L7.12062 1.17533C7.04195 1.01667 6.89262 1 6.83195 1ZM3.62998 12.3333C3.35464 12.3333 3.08131 12.2467 2.84731 12.076C2.44331 11.78 2.24531 11.2913 2.33131 10.7993L2.79531 8.126C2.81264 8.02667 2.77864 7.926 2.70531 7.85533L0.734642 5.962C0.371975 5.61467 0.241975 5.10133 0.395309 4.62467C0.549975 4.14267 0.959309 3.798 1.46398 3.726L4.18931 3.33333C4.29464 3.31867 4.38531 3.254 4.43064 3.162L5.64864 0.727333C5.87331 0.278667 6.32664 0 6.83198 0C7.33731 0 7.79064 0.278667 8.01531 0.727333L9.23398 3.16133C9.27997 3.254 9.36998 3.31867 9.47464 3.33333L12.2 3.726C12.7046 3.798 13.114 4.14267 13.2686 4.62467C13.422 5.10133 13.2913 5.61467 12.9286 5.962L10.958 7.85533C10.8846 7.926 10.8513 8.02667 10.8686 8.12533L11.3333 10.7993C11.4186 11.292 11.2206 11.7807 10.816 12.076C10.406 12.3767 9.87197 12.4173 9.41931 12.1813L6.98398 10.9193C6.88864 10.87 6.77464 10.87 6.67931 10.9193L4.24398 12.182C4.04931 12.2833 3.83931 12.3333 3.62998 12.3333Z"
				fill={color}
			/>
		</svg>
	);
}
