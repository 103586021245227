import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  PROFILE_RESET_REQUEST,
  PROFILE_URLS_RESET_REQUEST,
} from "../../Actions/actionTypes";

const initialState = {
  isAuthenticated: false,
  user_data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user_data: action.payload,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        user_data: {},
      };
    case PROFILE_RESET_REQUEST:
      return {
        ...state,
        user_data: action.payload,
      };
    case PROFILE_URLS_RESET_REQUEST:
      return {
        ...state,
        webUrls: action.payload,
      };

    default:
      return state;
  }
}
