import React from "react";

const SettingsIcon = () => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.875 18.8125L4.375 18.8125"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.625 18.8125L18.375 18.8125"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7.875 9.18757L4.375 9.1875"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M23.625 9.1875L11.375 9.18757"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.375 6.5625V11.8125"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M18.375 21.4375V16.1875"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default SettingsIcon;
