import { countsInContactUrl } from "../../Utils/Constants";
import axiosConfig from "../../Utils/axiosConfig";
import { GETCONTACTSCOUNT, GETCONTACTSCOUNTERROR } from "./actionTypes";
// import useAlert from './../../Components/Extras/Alert/useAlert';

export const getCountInContact = () => (dispatch) => {
	axiosConfig
		.get(countsInContactUrl)
		.then((res) => {
			if (res.status !== 200) throw new Error();

			dispatch({
				type: GETCONTACTSCOUNT,
				payload: res.data.body || res.data.result,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({
					type: GETCONTACTSCOUNTERROR,
					payload: err.response,
				});
			}
		});
};
