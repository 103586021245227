import { NEW_MESSAGE, READ_MESSAGES } from "../../Actions/actionTypes";

const initialState = {};
export default function (state = initialState, action) {
  switch (action.type) {
    case NEW_MESSAGE:
      const conversation = action.payload;
      const { conversationID } = conversation;
      if (
        conversation.isGroup &&
        Number(conversation.message.from) !== action.context.userID
      ) {
        return {
          ...state,
          [conversationID]: state[conversationID]
            ? [...state[conversationID], conversation.message]
            : [conversation.message],
        };
      } else return state;

    case READ_MESSAGES:
      const {
        [action.payload.conversationID]: currConversation,
        ...remainingConversations
      } = state;
      return remainingConversations;
    default:
      return state;
  }
}
