import React from "react";

const deletedUserRegex = /^deleted<[a-zA-Z0-9]{6,}>$/;
export const isDeleted = (username) =>
	username && username.match(deletedUserRegex);
const Username = ({ value }) => {
	return value ? (isDeleted(value) ? "Deleted User" : "@" + value) : null;
};

export default Username;
