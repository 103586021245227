import { Button, IconButton, makeStyles } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React from "react";
import styles from "./BookmarkFolder.module.scss";
import SidebarContextMenu from "../../Chats/Sidebar/SidebarContextMenu";
import useAlert from "../../../Extras/Alert/useAlert";
import axiosConfig from "../../../../Utils/axiosConfig";
import { foldersAPI } from "../../../../Utils/Constants";
import { useDispatch } from "react-redux";
import { removeFolder } from "../../../../Redux/Actions/bookmarks/folderActions";
import { generatePath, useHistory, useLocation } from "react-router";
const useStyles = makeStyles({
	menuButton: {
		fontWeight: 400,
		textTransform: "none",
		textAlign: "left",
		fontSize: "1rem",
		justifyContent: "flex-start",
	},
});

const BookmarkFolder = ({ name, folderID, selected, ...props }) => {
	const classes = useStyles();
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const deleteFolderConf = () => {
		showAlert(
			"Deleting the folder will remove all the status from bookmarks. Are You Sure you want to Continue?",
			{
				header: "Delete Folder",
				buttonText: "Delete Folder",
				callback: () => {
					deleteFolder();
				},
				linkText: "Not now",
			}
		);
	};
	const deleteFolder = async () => {
		try {
			const form = new FormData();
			form.append("ID", folderID);
			const res = await axiosConfig.post(foldersAPI.DELETE_FOLDER, form);
			if (res.status === 200) {
				dispatch(removeFolder(folderID));
				if (
					location.pathname ===
					generatePath("/bookmarks/folder/:id", { id: folderID })
				) {
					history.replace("/bookmarks");
				}
			}
		} catch (err) {
			if (err.response) {
				if (
					err.response.status === 403 &&
					err.response.statusText === "Forbidden"
				) {
					showAlert(
						("Your account has been blocked from update actions. Please contact Admin.",
						{
							header: "You Account is Blocked",
							buttonText: "Go Back",
						})
					);
				}
			}
		}
	};
	return (
		<div
			className={`${props.className} ${styles.container} ${
				selected ? styles.selected : ""
			}`}
			onClick={props.onClick}
		>
			<div className={styles.foldername}>{name}</div>
			{!props.noOps && (
				<SidebarContextMenu
					trigger={
						<IconButton size="small">
							<MoreVert color="disabled" />
						</IconButton>
					}
					listStyles={{ padding: 0 }}
					listItemStyle={{ padding: 0 }}
					anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
					transformOrigin={{ horizontal: "left", vertical: "top" }}
				>
					<Button
						fullWidth
						className={classes.menuButton}
						onClick={deleteFolderConf}
					>
						Delete Folder
					</Button>
					<Button
						onClick={props.onRename}
						fullWidth
						className={classes.menuButton}
					>
						Rename Folder
					</Button>
				</SidebarContextMenu>
			)}
		</div>
	);
};

export default BookmarkFolder;
