import React from "react";

const BlueLine = () => {
	return (
		<svg
			width="54"
			height="2"
			viewBox="0 0 54 2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 1H53"
				stroke="url(#paint0_linear)"
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="1"
					y1="0"
					x2="1.64288"
					y2="6.83234"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#C4C4C4" stopOpacity="0.17" />
					<stop offset="0.0001" stopColor="#00C6FF" />
					<stop offset="1" stop-color="#0072FF" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default BlueLine;
