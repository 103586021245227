import React from "react";

const PhoneCallIcon = ({ color }) => {
	return (
		<div>
			<svg
				width="22"
				height="22"
				viewBox="0 0 22 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3.35294 1H8.05882L10.4118 6.88235L7.47059 8.64706C8.73054 11.2018 10.7982 13.2695 13.3529 14.5294L15.1176 11.5882L21 13.9412V18.6471C21 19.2711 20.7521 19.8696 20.3108 20.3108C19.8696 20.7521 19.2711 21 18.6471 21C14.058 20.7211 9.72959 18.7724 6.47862 15.5214C3.22765 12.2704 1.27888 7.94204 1 3.35294C1 2.7289 1.2479 2.13042 1.68916 1.68916C2.13042 1.2479 2.7289 1 3.35294 1Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};

export default PhoneCallIcon;
