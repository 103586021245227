export default function ForwardMessage({ theme }) {
	const mainColor = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			width="28"
			height="26"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 5L12 9V1L16 5ZM16 5H5C3.93913 5 2.92172 5.42143 2.17157 6.17157C1.42143 6.92172 1 7.93913 1 9C1 10.0609 1.42143 11.0783 2.17157 11.8284C2.92172 12.5786 3.93913 13 5 13H6"
				stroke={mainColor}
				strokeWidth="1.2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
