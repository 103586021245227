import React, { useEffect, useRef, useState } from "react";
import axiosConfig from "../../../Utils/axiosConfig";
import {
	getFavouriteStatusURL,
	setStatusAsFavURL,
} from "../../../Utils/Constants";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";
import styles from "./Bookmark.module.css";
import EmptyState from "../../Extras/EmptyState";
import throttle from "lodash/throttle";
import { useHistory, useParams, useLocation } from "react-router";
import { Button, makeStyles } from "@material-ui/core";
import {
	Close,
	ChevronRight,
	Search,
	ArrowBackIos,
	MoreVert,
} from "@material-ui/icons";
import SidebarContextMenu from "../Chats/Sidebar/SidebarContextMenu";
import AddToBookmarksDialog from "./AddToBookmarks/AddToBookmarksDialog";
import { useDispatch, useSelector } from "react-redux";
import { selectFolders } from "../../../Redux/selectors/Bookmarks/folders";
import useAlert from "../../Extras/Alert/useAlert";
import {
	decrementFolderStatusCount,
	fetchFolders,
} from "../../../Redux/Actions/bookmarks/folderActions";
import useQuery from "./../../../Utils/useQuery";
import { FormattedMessage } from "react-intl";
import { BookmarksDialog } from "./BookmarksDropdown/BookmarksDropdown";
import NewFolderDialog from "./CreateNewFolder/NewFolderDialog";
import Button2 from "../../Extras/Buttons/Button2/Button2";
import emoji from "random-happy-emoji";
import { Dialog } from "@material-ui/core";

const FETCH_LIMIT = 18;

const Bookmark = (props) => {
	const useStyles = makeStyles({
		listButton: {
			width: "100%",
			fontSize: "1rem",
			textTransform: "none",
			textAlign: "left",
			justifyContent: "flex-start",
			fontWeight: "400",
		},
	});

	const params = useParams();
	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { showAlert } = useAlert();
	const searchInputRef = useRef();
	const { searchQuery } = useQuery();
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const folders = useSelector(selectFolders);
	const [searchText, setSearchText] = useState("");
	const [bookmarks, setBookmarks] = useState([]);
	const [collections, setCollections] = useState([]);
	const [showSearch, setShowSearch] = useState(false);
	const [mobActive, setMobActive] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [paginationLoading, setPaginationLoading] = useState(false);
	const [addToFolderState, setAddToFolderState] = useState({
		open: false,
		statusID: null,
	});
	const [title, setTitle] = useState("");
	const [showAddForm, setShowAddForm] = useState(false);
    const [deafultOpen, setdeafultOpen] = useState(false);
    const [folderId, setFolderId] = useState("")

	let offset = useRef(0);
	let end = useRef(false);

	const setLoading = (state) => {
		if (offset.current !== 0) {
			setPaginationLoading(state);
		} else {
			setIsLoading(state);
		}
	};

	const getFavourites = (reset, query = "") => {
		if (isLoading) return;
		if (!reset && end.current) return;
		if (props.folder ===13) return;

		if (reset) {
			offset.current = 0;
			end.current = false;
			setBookmarks([]);
		}
		setLoading(true);

		let folderid =
			!isMobile &&
			folders.find((folder, i) => i === props.folder - 14)?.id;

		if (folderid !== 13) {
			folderid = folderid;
        }
        
        setFolderId(folderid)

		let URL = `${getFavouriteStatusURL}`;
		const urlParams = {
			limit: FETCH_LIMIT,
			offset: reset ? 0 : offset.current,
			search_phrase: query,
			folderid: folderid ? folderid : "",
		};

		axiosConfig
			.get(URL, { params: urlParams })
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setBookmarks((prev) =>
					reset
						? [...(res.data.result || res.data.body)]
						: [...prev, ...(res.data.result || res.data.body)]
				);
				setLoading(false);

				offset.current += FETCH_LIMIT;
				if (!res.data.next) end.current = true;
			})
			.catch((err) => {
				if (err.response) {
					if (err.response.data.error === "invalid folder id") {
						history.push("/myprofile/bookmarks/folder/all");
					}
				}
				setLoading(false);
			});
	};

	const DisSkeleton = () => {
		return (
			<>
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
			</>
		);
	};

	const isMobile = window.screen.width < 768;
	const containerRef = useRef();

	const handleScroll = throttle((e) => {
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 200;
		if (bottom && !isLoading) {
			getFavourites();
		}
	}, 500);

	const getPageTitle = () => {
		if (props.folder === 12) {
			return "Default";
		} else if (props.folder === 13) {
			return "All Collections";
		} else {
			return folders.find((folder, i) => i === props.folder - 14)
				?.foldername;
		}
	};

	useEffect(() => {
		dispatch(fetchFolders());
	}, [dispatch, !isMobile]);

	useEffect(() => {
		const { current } = containerRef;

		if (searchQuery) getFavourites(true, searchQuery);
		else getFavourites(true);

		const handlerRef = current?.addEventListener("scroll", handleScroll);
		setTitle(getPageTitle());

		return () => {
			current?.removeEventListener("scroll", handlerRef);
		};
	}, [props.folder]);

	useEffect(() => {
		setTitle(getPageTitle());
	}, [folders]);

	useEffect(() => {
		if (props.folder === 13) setBookmarks([]);
	}, [props.folder]);

	const addToFolder = (statusID) => (e) => {
		e.stopPropagation();
		setAddToFolderState({ open: true, statusID });
	};

	const onAddToFolderAck = (statusID) => {
		setBookmarks((prev) => prev.filter((item) => item.pk !== statusID));
	};

	const removeBookmarkConfirmation = (statusID) => () => {
		showAlert(
			"Are you sure you want to remove this status from bookmarks?",
			{
				header: "Remove Bookmark",
				buttonText: "Remove",
				linkText: "Not now",
				callback: () => removeBookmark(statusID),
			}
		);
	};

	const removeBookmark = (statusID) => {
		const form = new FormData();
        form.append("statusId", statusID);
        form.append("folderID", folderId);


		axiosConfig
			.post(setStatusAsFavURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setBookmarks((prev) =>
					prev.filter((item) => item.status.pk !== statusID)
				);
				if (params.id !== "all") {
					dispatch(decrementFolderStatusCount(params.id));
				}
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							("Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							})
						);
					}
				}
			});
	};

	const handleCloseAddToFolderPopup = () => {
		setAddToFolderState({ open: false, statusID: null });
	};

	const searchHandler = (text) => {
		setSearchText(text);
		getFavourites(true, searchText);
		if (text === "") {
			setSearchText("");
			getFavourites(true, "");
		}
	};

	// const keyPress = (e) => {
	// 	const text = e.target.value.trim();

	// 	if (e.keyCode === 13) {
	// 		history.push({
	// 			pathname: `/myprofile/bookmarks/folder/${params.id}`,
	// 			search: `?searchQuery=${text}`,
	// 		});
	// 		getFavourites(true, searchText);
	// 	}
	// };

	const onFolderChange = (statusID) => () => {
		setBookmarks((prev) =>
			prev.filter((item) => item.status.pk !== statusID)
		);
		if (props.folder !== 13) {
			dispatch(decrementFolderStatusCount(props.folder));
		}
	};

	const folderHandler = (reset, query = "", id) => {
		history.push("/myprofile/bookmarks/folder/" + id);
		if (isLoading) return;
		if (!reset && end.current) return;

		if (reset) {
			offset.current = 0;
			end.current = false;
			setCollections([]);
		}
		setIsLoading(true);
		let URL = `${getFavouriteStatusURL}`;
		const urlParams = {
			limit: FETCH_LIMIT,
			offset: reset ? 0 : offset.current,
			search_phrase: query,
			folderid: id,
		};

		axiosConfig
			.get(URL, { params: urlParams })
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setCollections((prev) =>
					reset
						? [...(res.data.result || res.data.body)]
						: [...prev, ...(res.data.result || res.data.body)]
				);
				setIsLoading(false);
				offset.current += FETCH_LIMIT;
				if (!res.data.next) end.current = true;
			})
			.catch((err) => {
				if (err.response) {
					if (err.response.data.error === "invalid folder id") {
						history.push("/myprofile/bookmarks/folder/all");
					}
				}
				setLoading(false);
			});
	};

	return (
		<>
			{!isMobile ? (
				<div className={styles.bookmarks} ref={containerRef}>
					<div className={styles.header}>
						<h2
							style={{
								color: theme === "dark" ? "#d9def4" : "#0D0F22",
							}}
							className="text-capitalize"
						>
							Saved Thoughts ( {title} )
						</h2>
					</div>

					<div className={styles.searchContainer}>
						<img src="/Search.svg" className="ml-2 mr-1" alt="" />
						<input
							type="text"
							value={searchText}
							ref={searchInputRef}
							className={`${styles.input} text-capitalize`}
							onChange={(e) =>
								searchHandler(e.target.value.trim())
							}
							// onKeyDown={keyPress}
							placeholder={`Search in ${
								title === "All Collections" ? "" : title
							} Collection`}
						/>

						<div onClick={() => setShowAddForm(true)}>
							<Button2>
								<FormattedMessage
									id="newCollection"
									defaultMessage={`New Collection`}
								/>
							</Button2>
						</div>
					</div>

					<div className={styles.cards}>
						{bookmarks.length !== 0 && props.folder !== 0
							? bookmarks.map((item, i) => {
									return (
										<StatusCard
											status={item.status}
											key={item.status.pk}
											idx={i % FETCH_LIMIT}
											width={
												!isMobile ? "29.75%" : "100%"
											}
											height="13rem"
											extra={true}
											cta={
												<SidebarContextMenu
													icon={(props) => (
														<MoreVert
															style={{
																color:
																	theme ===
																	"dark"
																		? "#7F89BE"
																		: "#4A517E",
															}}
															{...props}
														/>
													)}
													listClass={styles.ctaList}
													anchorOrigin={{
														vertical: "center",
														horizontal: "right",
													}}
													transformOrigin={{
														vertical: "top",
														horizontal: "left",
													}}
													listItemStyle={{
														padding: 0,
													}}
												>
													{params.id === "all" && (
														<Button
															className={
																classes.listButton
															}
															style={{
																color:
																	theme ===
																	"dark"
																		? "#d9def4"
																		: "#0D0F22",
															}}
															onClick={() =>
																addToFolder(
																	item.pk
																)
															}
														>
															<FormattedMessage
																id="bookmarks.addToFolder"
																defaultMessage={`Add To Folder`}
															/>
														</Button>
													)}
													<Button
														className={
															classes.listButton
														}
														style={{
															color:
																theme === "dark"
																	? "#d9def4"
																	: "#0D0F22",
														}}
														onClick={removeBookmarkConfirmation(
															item.status.pk
														)}
													>
														<FormattedMessage
															id="bookmarks.removeBookmark"
															defaultMessage={`Remove bookmark`}
														/>
													</Button>
													{params.id !== "all" && (
														<BookmarksDialog
															isBookmarked={true}
															onBookmarkStateChange={onFolderChange(
																item.status.pk
															)}
															folderID={params.id}
															statusID={
																item.status.pk
															}
															variant="shift"
														>
															<Button
																className={
																	classes.listButton
																}
																style={{
																	color:
																		theme ===
																		"dark"
																			? "#d9def4"
																			: "#0D0F22",
																}}
															>
																<FormattedMessage
																	id="bookmarks.changeFolder"
																	defaultMessage={`Change Folder`}
																/>
															</Button>
														</BookmarksDialog>
													)}
												</SidebarContextMenu>
											}
										/>
									);
							  })
							: !isLoading &&
							  props.folder !== 13 && (
									<>
										<EmptyState
											heading={
												props.folder === "all"
													? "No bookmarks Yet!"
													: "Folder is Empty"
											}
											imageUrl="/vectors/empty-states/emptyFavourites.svg"
											subHeading={
												props.folder === "all"
													? "Looks like you haven’t added any statuses to your bookmarks yet. When you do so, this is where they’ll be."
													: "Looks like you haven’t added any statuses to this folder yet. When you do so, this is where they’ll be."
											}
											className={
												isMobile ? styles.mobempty : ""
											}
										/>{" "}
									</>
							  )}

						{(isLoading || paginationLoading) && DisSkeleton()}

						<div className="row w-100 px-2">
							{location.pathname !== "/myprofile/all" ? (
								collections.length !== 0 &&
								props.folder != 0 ? (
									collections.map((item, i) => {
										return (
											<StatusCard
												status={item.status}
												key={item.status.pk}
												idx={i % FETCH_LIMIT}
												width={
													!isMobile
														? "29.75%"
														: "100%"
												}
												height="13rem"
												extra={true}
												cta={
													<SidebarContextMenu
														icon={(props) => (
															<MoreVert
																style={{
																	color:
																		theme ===
																		"dark"
																			? "#7F89BE"
																			: "#4A517E",
																}}
																{...props}
															/>
														)}
														listClass={
															styles.ctaList
														}
														anchorOrigin={{
															vertical: "center",
															horizontal: "right",
														}}
														transformOrigin={{
															vertical: "top",
															horizontal: "left",
														}}
														listItemStyle={{
															padding: 0,
														}}
													>
														{params.id ===
															"all" && (
															<Button
																className={
																	classes.listButton
																}
																style={{
																	color:
																		theme ===
																		"dark"
																			? "#d9def4"
																			: "#0D0F22",
																}}
																onClick={() =>
																	addToFolder(
																		item.pk
																	)
																}
															>
																<FormattedMessage
																	id="bookmarks.addToFolder"
																	defaultMessage={`Add To Folder`}
																/>
															</Button>
														)}
														<Button
															className={
																classes.listButton
															}
															style={{
																color:
																	theme ===
																	"dark"
																		? "#d9def4"
																		: "#0D0F22",
															}}
															onClick={removeBookmarkConfirmation(
																item.status.pk
															)}
														>
															<FormattedMessage
																id="bookmarks.removeBookmark"
																defaultMessage={`Remove bookmark`}
															/>
														</Button>
														{params.id !==
															"all" && (
															<BookmarksDialog
																isBookmarked={
																	true
																}
																onBookmarkStateChange={onFolderChange(
																	item.status
																		.pk
																)}
																folderID={
																	params.id
																}
																statusID={
																	item.status
																		.pk
																}
																variant="shift"
															>
																<Button
																	className={
																		classes.listButton
																	}
																	style={{
																		color:
																			theme ===
																			"dark"
																				? "#d9def4"
																				: "#0D0F22",
																	}}
																>
																	<FormattedMessage
																		id="bookmarks.changeFolder"
																		defaultMessage={`Change Folder`}
																	/>
																</Button>
															</BookmarksDialog>
														)}
													</SidebarContextMenu>
												}
											/>
										);
									})
								) : (
									!isLoading &&
									props.folder === 13 && (
										<EmptyState
											heading={
												props.folder === "all"
													? "No bookmarks Yet!"
													: "Folder is Empty"
											}
											imageUrl="/vectors/empty-states/emptyFavourites.svg"
											subHeading={
												props.folder === "all"
													? "Looks like you haven’t added any statuses to your bookmarks yet. When you do so, this is where they’ll be."
													: "Looks like you haven’t added any statuses to this folder yet. When you do so, this is where they’ll be."
											}
											className={
												isMobile ? styles.mobempty : ""
											}
										/>
									)
								)
							) : folders.length !== 0 && props.folder === 13 ? (
								folders.map((folder) => {
									return (
										<div
											className=" col-md-4 col-6"
											onClick={() =>
												folderHandler(
													true,
													"",
													folder.id
												)
											}
										>
											<div
												className={styles.folderCard}
												style={{
													background:
														theme === "dark"
															? "#121528"
															: "#d9def4",
												}}
											>
												<div
													className={
														styles.folderHead +
														" text-capitalize"
													}
													style={{
														color:
															theme !== "dark"
																? "#0d0f22"
																: "#d9def4",
													}}
												>
													<h2>
														{folder?.foldername}
													</h2>
												</div>
												<div
													className={
														styles.thoughtCount
													}
													style={{
														color:
															theme === "dark"
																? "#7F89BE"
																: "#d9def4",
													}}
												>
													<p>
														{folder?.count} thoughts
													</p>
												</div>
												<div
													className={
														styles.folderTime
													}
													style={{
														color:
															theme === "dark"
																? "#4A517E"
																: "#d9def4",
													}}
												>
													<p>
														Created on{" "}
														{folder.created_at
															.substring(0, 10)
															.split("-")
															.reverse()
															.join("/")}
													</p>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<>
									{" "}
									<EmptyState
										heading="No bookmarks Yet!"
										imageUrl="/vectors/empty-states/emptyFavourites.svg"
										subHeading="Looks like you haven’t added any statuses to your bookmarks yet. When you do so, this is where they’ll be."
										className={
											isMobile ? styles.mobempty : ""
										}
									/>
								</>
							)}
						</div>
					</div>

					<NewFolderDialog
						show={showAddForm}
						onClose={() => setShowAddForm(false)}
					/>
					<AddToBookmarksDialog
						show={addToFolderState.open}
						statusText={
							bookmarks.find(
								(item) => item.pk === addToFolderState.statusID
							)?.status?.content
						}
						onClose={handleCloseAddToFolderPopup}
						statusID={addToFolderState.statusID}
						onAdd={onAddToFolderAck}
					/>
				</div>
			) : (
				<div style={{ height: "100%", overflow: "auto" }}>
					<div
						className="mt-3 px-3"
						onClick={() => setShowAddForm(true)}
					>
						<Button2>
							<FormattedMessage
								id="newCollection"
								defaultMessage={`New Collection`}
							/>
						</Button2>
					</div>
					<NewFolderDialog
						show={showAddForm}
						onClose={() => setShowAddForm(false)}
					/>
					<div className="mt-3">
						<div
							className={styles.myprofileTab}
							onClick={() => {
								// folderHandler(true, "", 1);
								setdeafultOpen(true);
								setMobActive(1);
							}}
						>
							<p className="mb-0">
								<span className="mr-3">
									<img
										src={
											theme === "dark"
												? "/default-dark.png"
												: "/default-lg.png"
										}
										alt=""
									/>
								</span>
								Default
							</p>
							<span>
								<ChevronRight style={{ color: "#7F89BE" }} />
							</span>
						</div>
						{folders?.map((folder) => (
							<>
								<div
									className={styles.myprofileTab}
									key={folder?.id}
									onClick={() => {
										folderHandler(true, "", folder?.id);
										setMobActive(folder.id);
									}}
								>
									<p className="mb-0">
										<span className="mr-3">{emoji()}</span>
										{folder?.foldername}
									</p>
									<div>
										<span
											style={{
												color: "#7F89BE",
												marginRight: "10px",
											}}
										>
											{folder?.count}
										</span>
										<span>
											<ChevronRight
												style={{ color: "#7F89BE" }}
											/>
										</span>
									</div>
								</div>
							</>
						))}
						{mobActive !== 1 &&
							location.pathname ===
								`/myprofile/bookmarks/folder/${mobActive}` && (
								<Dialog
									open={true}
									fullScreen={true}
									maxWidth={"100vw"}
									fullWidth={"100vw"}
									classes={{
										paper:
											theme === "dark"
												? styles.rootDark
												: styles.rootLg,
									}}
								>
									<div className={styles.mobNav}>
										<span className="btn p-1">
											{showSearch ? (
												<Close
													onClick={() =>
														setShowSearch(false)
													}
													style={{ color: "#D9DEF4" }}
												/>
											) : (
												<ArrowBackIos
													onClick={() => {
														setMobActive(0);
														history.push(
															"/myProfile"
														);
													}}
													style={{ color: "#D9DEF4" }}
												/>
											)}
										</span>
										{!showSearch && (
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mb-0">
													{/* <FormattedMessage
														id="editProfile"
														defaultMessage={`Collections`}
													/> */}
                                                    Collections
												</h2>
											</div>
										)}
										{!showSearch && (
											<div>
												<span
													onClick={() =>
														setShowSearch(true)
													}
													className="btn p-1"
												>
													<Search
														style={{
															color: "#D9DEF4",
														}}
													/>
												</span>
											</div>
										)}
										{showSearch && (
											<div
												className={
													styles.searchContainer
												}
											>
												<img
													src="/Search.svg"
													className="ml-2 mr-1"
													alt=""
												/>
												<input
													type="text"
													// value={searchText}
													className={styles.input}
													// onChange={(e) =>
													// 	setSearchText(
													// 		e.target.value.trim()
													// 	)
													// }
													placeholder={`Search thoughts`}
												/>
												{/* {searchText !== "" && (
													<Close
													onClick={() =>
														setSearchText("")
													}
													style={{
														margin: "auto 0",
														color:
													theme === "dark"
													? "#7F89BE"
													: "#4A517E",
													}}
													/>
												)} */}
											</div>
										)}
									</div>
									<div className={styles.cardsContainer}>
										{collections.length !== 0
											? collections.map((item, i) => {
													return (
														<StatusCard
															status={item.status}
															key={item.status.pk}
															idx={
																i % FETCH_LIMIT
															}
															width={"100%"}
															height="15rem"
															extra={true}
															cta={
																<SidebarContextMenu
																	icon={(
																		props
																	) => (
																		<MoreVert
																			style={{
																				color:
																					theme ===
																					"dark"
																						? "#7F89BE"
																						: "#4A517E",
																			}}
																			{...props}
																		/>
																	)}
																	listClass={
																		styles.ctaList
																	}
																	anchorOrigin={{
																		vertical:
																			"center",
																		horizontal:
																			"right",
																	}}
																	transformOrigin={{
																		vertical:
																			"top",
																		horizontal:
																			"left",
																	}}
																	listItemStyle={{
																		padding: 0,
																	}}
																>
																	{params.id ===
																		"all" && (
																		<Button
																			className={
																				classes.listButton
																			}
																			style={{
																				color:
																					theme ===
																					"dark"
																						? "#d9def4"
																						: "#0D0F22",
																			}}
																			onClick={() =>
																				addToFolder(
																					item.pk
																				)
																			}
																		>
																			<FormattedMessage
																				id="bookmarks.addToFolder"
																				defaultMessage={`Add To Folder`}
																			/>
																		</Button>
																	)}
																	<Button
																		className={
																			classes.listButton
																		}
																		style={{
																			color:
																				theme ===
																				"dark"
																					? "#d9def4"
																					: "#0D0F22",
																		}}
																		onClick={removeBookmarkConfirmation(
																			item
																				.status
																				.pk
																		)}
																	>
																		<FormattedMessage
																			id="bookmarks.removeBookmark"
																			defaultMessage={`Remove bookmark`}
																		/>
																	</Button>
																	{params.id !==
																		"all" && (
																		<BookmarksDialog
																			isBookmarked={
																				true
																			}
																			onBookmarkStateChange={onFolderChange(
																				item
																					.status
																					.pk
																			)}
																			folderID={
																				params.id
																			}
																			statusID={
																				item
																					.status
																					.pk
																			}
																			variant="shift"
																		>
																			<Button
																				className={
																					classes.listButton
																				}
																				style={{
																					color:
																						theme ===
																						"dark"
																							? "#d9def4"
																							: "#0D0F22",
																				}}
																			>
																				<FormattedMessage
																					id="bookmarks.changeFolder"
																					defaultMessage={`Change Folder`}
																				/>
																			</Button>
																		</BookmarksDialog>
																	)}
																</SidebarContextMenu>
															}
														/>
													);
											  })
											: !isLoading && (
													<EmptyState
														heading={
															props.folder ===
															"all"
																? "No bookmarks Yet!"
																: "Folder is Empty"
														}
														imageUrl="/vectors/empty-states/emptyFavourites.svg"
														subHeading={
															props.folder ===
															"all"
																? "Looks like you haven’t added any statuses to your bookmarks yet. When you do so, this is where they’ll be."
																: "Looks like you haven’t added any statuses to this folder yet. When you do so, this is where they’ll be."
														}
														className={
															isMobile
																? styles.mobempty
																: ""
														}
													/>
											  )}
									</div>
								</Dialog>
							)}
						{mobActive == 1 && (
							<>
								<Dialog
									open={deafultOpen}
									fullScreen={true}
									maxWidth={"100vw"}
									fullWidth={"100vw"}
									classes={{
										paper:
											theme === "dark"
												? styles.rootDark
												: styles.rootLg,
									}}
								>
									<div className={styles.mobNav}>
										<div className="row w-100">
											<div className="col-4 pr-0">
												<span className="btn p-1">
													<ArrowBackIos
														onClick={() =>
															setdeafultOpen(
																false
															)
														}
														style={{
															color: "#D9DEF4",
														}}
													/>
												</span>
											</div>
											<div className="col-8 pl-0">
												<div
													className={`${
														styles.headerText
													} ${
														theme === "dark"
															? styles.darkPText
															: styles.lgPText
													}`}
												>
													<h2 className="mt-2">
														{/* <FormattedMessage
															id="editProfile"
															defaultMessage={`Default collections`}
														/> */}
                                                        Default collections
													</h2>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className={styles.cards}>
											{bookmarks.length !== 0 &&
											props.folder !== 0
												? bookmarks.map((item, i) => {
														return (
															<StatusCard
																status={
																	item.status
																}
																key={
																	item.status
																		.pk
																}
																idx={
																	i %
																	FETCH_LIMIT
																}
																width={
																	!isMobile
																		? "29.75%"
																		: "100%"
																}
																height="13rem"
																extra={true}
																cta={
																	<SidebarContextMenu
																		icon={(
																			props
																		) => (
																			<MoreVert
																				style={{
																					color:
																						theme ===
																						"dark"
																							? "#7F89BE"
																							: "#4A517E",
																				}}
																				{...props}
																			/>
																		)}
																		listClass={
																			styles.ctaList
																		}
																		anchorOrigin={{
																			vertical:
																				"center",
																			horizontal:
																				"right",
																		}}
																		transformOrigin={{
																			vertical:
																				"top",
																			horizontal:
																				"left",
																		}}
																		listItemStyle={{
																			padding: 0,
																		}}
																	>
																		{params.id ===
																			"all" && (
																			<Button
																				className={
																					classes.listButton
																				}
																				style={{
																					color:
																						theme ===
																						"dark"
																							? "#d9def4"
																							: "#0D0F22",
																				}}
																				onClick={() =>
																					addToFolder(
																						item.pk
																					)
																				}
																			>
																				<FormattedMessage
																					id="bookmarks.addToFolder"
																					defaultMessage={`Add To Folder`}
																				/>
																			</Button>
																		)}
																		<Button
																			className={
																				classes.listButton
																			}
																			style={{
																				color:
																					theme ===
																					"dark"
																						? "#d9def4"
																						: "#0D0F22",
																			}}
																			onClick={removeBookmarkConfirmation(
																				item
																					.status
																					.pk
																			)}
																		>
																			<FormattedMessage
																				id="bookmarks.removeBookmark"
																				defaultMessage={`Remove bookmark`}
																			/>
																		</Button>
																		{params.id !==
																			"all" && (
																			<BookmarksDialog
																				isBookmarked={
																					true
																				}
																				onBookmarkStateChange={onFolderChange(
																					item
																						.status
																						.pk
																				)}
																				folderID={
																					params.id
																				}
																				statusID={
																					item
																						.status
																						.pk
																				}
																				variant="shift"
																			>
																				<Button
																					className={
																						classes.listButton
																					}
																					style={{
																						color:
																							theme ===
																							"dark"
																								? "#d9def4"
																								: "#0D0F22",
																					}}
																				>
																					<FormattedMessage
																						id="bookmarks.changeFolder"
																						defaultMessage={`Change Folder`}
																					/>
																				</Button>
																			</BookmarksDialog>
																		)}
																	</SidebarContextMenu>
																}
															/>
														);
												  })
												: !isLoading &&
												  props.folder !== 13 && (
														<>
															<EmptyState
																heading={
																	props.folder ===
																	"all"
																		? "No bookmarks Yet!"
																		: "Folder is Empty"
																}
																imageUrl="/vectors/empty-states/emptyFavourites.svg"
																subHeading={
																	props.folder ===
																	"all"
																		? "Looks like you haven’t added any statuses to your bookmarks yet. When you do so, this is where they’ll be."
																		: "Looks like you haven’t added any statuses to this folder yet. When you do so, this is where they’ll be."
																}
																className={
																	isMobile
																		? styles.mobempty
																		: ""
																}
															/>{" "}
														</>
												  )}

											{(isLoading || paginationLoading) &&
												DisSkeleton()}
										</div>
									</div>
								</Dialog>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Bookmark
