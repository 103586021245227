import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import OTPInput from "../../Extras/InputField/OTPInputField/index";
import { loginUser } from "../../../Redux/Actions/actionAuth";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackToLanding from "../BackToLandingBtn";
import "../Login/login.scss";
import SocialLoginsError from "../Login/SocialLoginsError";
import { socialLoginError } from "../../../Redux/Actions/actionSocialLogin";
// import { FormattedMessage } from "react-intl";
import { verifyOtpURL } from "../../../Utils/Constants";
import axiosConfig from "../../../Utils/axiosConfig";
import withAlert from "../../Extras/Alert/withAlert";
import OtpInput from "react-otp-input";

class CodeVerification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			otp: "",
			isLoggingIn: false,
		};
	}

	handleChange = (val) => {
		this.setState({ otp: val });
	};

	socialSignUp = () => {
		this.props.history.push("/create-profile");
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		if (!this.state.otp) {
			return 0;
		}

		if (this.state.otp.length !== 6) {
			this.props.showAlert("please enter the six digit number", {
				header: "OTP must have six digits",
				buttonText: "Try Again",
			});
			return 0;
		} else {
			const form = new FormData();
			form.append("otp_entered", this.state.otp);
			form.append("temp_token", sessionStorage.getItem("temp_token"));
			axiosConfig
				.post(verifyOtpURL, form)
				.then((res) => {
					if (res.status !== 200) throw new Error();
					this.setState((prev) => ({
						...prev,
						isLoggingIn: false,
						callback: () => this.props.history.push("/login"),
					}));
					this.props.showAlert(res.data.message, {
						header: "Go To Login",
						callback: () => this.props.history.push("/login"),
						buttonText: "Go To Login",
					});
				})
				.catch((err) => {
					this.setState({ otp: "" });
					if (err.response) {
						if (
							err.response.status === 403 &&
							err.response.statusText === "Forbidden"
						) {
							this.props.showAlert(
								"Your account has been blocked from update actions. Please contact Admin.",
								{
									header: "You Account is Blocked",
									buttonText: "Go Back",
								}
							);
						} else if (
							err.response.data.message.includes(
								"Maximum attempts over"
							)
						) {
							this.props.showAlert("Request Failed ", {
								header: err.response.data.message,
								callback: () =>
									this.props.history.push("/login"),
								buttonText: "Go To Login",
							});
						} else if (
							err.response.data.message.includes("Invalid OTP")
						) {
							this.props.showAlert("Request Failed ", {
								header: err.response.data.message,
								buttonText: "Try Again",
							});
						} else if (
							err.response.data.message.includes("OTP expired")
						) {
							this.props.showAlert("Request Failed ", {
								header: err.response.data.message,
								callback: () =>
									this.props.history.push("/login"),
								buttonText: "Go To Login",
							});
						} else {
							this.props.showAlert("Request Failed ", {
								header: err.response.data.message,
								callback: () =>
									this.props.history.push("/login"),
								buttonText: "Go To Login",
							});
						}
					} else {
						this.props.showAlert("Reqest Falied", {
							callback: () => this.props.history.push("/login"),
							buttonText: "Try Again",
						});
					}
				});
		}
	};

	render() {
		return (
			<div className="auth-page-Container">
				<BackToLanding />
				<div className="pinkOval"></div>
				<div className="yellowDiv"></div>
				<div className="container">
					<div className="form-container login-form-container">
						<div className="header">
							<h1>
								{/* <FormattedMessage
									id="login.heading1"
									defaultMessage={`Code Verification`}
								/> */}
								Code Verification
							</h1>
							<p>
								{/* <FormattedMessage
									id="login.heading2"
									defaultMessage={`Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.`}
								/> */}
								Bditto is a powerful enterprise solution that
								will help you easily share, connect and explore
								thoughts in an expedited manner.
							</p>
						</div>

						<form className="form" onSubmit={this.handleSubmit}>
							<div className="left">
								<img
									className="logoM"
									src="/logoMain.svg"
									alt=""
								/>
								<div className="heading">
									<h1>
										Where people{" "}
										<span className="gradienth1">
											connect
										</span>{" "}
										&{" "}
										<span className="gradienth1">
											share...
										</span>
									</h1>
								</div>
								<div className="launchImg">
									<img src="/icons/Launch.svg" alt="" />
								</div>
							</div>

							<div className="right">
								<div className="login">
									<img src="/icons/login.svg" alt="" />
									<p>Create Profile</p>
								</div>

								<div className="underline"></div>

								<div style={{ margin: "4rem 0" }}>
									<div className="heading">
										<h5>Verify Account</h5>
									</div>

									<div className="inputDiv">
										<div className="inputCode">
											<OtpInput
												isInputNum="number"
												value={this.state.otp}
												onChange={this.handleChange}
												numInputs={6}
												containerStyle="otpContainer"
												inputStyle="otpInput"
												style={{
													width: 40,
													height: 40,
												}}
											/>
											{/* <OTPInput
												autoFocus
												isNumberInput={true}
												length={6}
												type="password"
												className="otpContainer"
												inputClassName="otpInput"
												onChangeOTP={(otp) =>
													this.handleChange(otp)
												}
											/> */}
										</div>
										<div className="label">
											<p>
												Let's make the most of your work
												email
											</p>
										</div>
									</div>

									<p className="outLinks">
										{/* <FormattedMessage
											id="login.heading4"
											defaultMessage={`By continuing, you’re agreeing to our `}
										/> */}
										By continuing, you’re agreeing to our
										&nbsp;
										<Link to="/user-agreement">
											<span className="forget">
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`User Agreement `}
												/> */}
												User Agreement{" "}
											</span>
										</Link>
										<Link to="/privacy">
											<span className="forget">
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={` Privacy Policy `}
												/> */}
												Privacy Policy
											</span>
										</Link>
										{/* <FormattedMessage
											id="login.heading4"
											defaultMessage={`, and Cookie Policy.`}
										/> */}
										, and Cookie Policy.
									</p>

									<div className="inputDiv">
										<button
											className="login-btn"
											type="submit"
										>
											{/* <FormattedMessage
												id="signIn"
												defaultMessage={`Verify`}
											/> */}
											Verify
											{this.state.isLoggingIn && (
												<CircularProgress
													className="loader"
													color="primary"
													size={20}
												/>
											)}
										</button>
										<p className="link">
											{/* <FormattedMessage
												id="login.heading4"
												defaultMessage={` Don’t have an account?`}
											/> */}
											Don’t have an account? &nbsp;
											<Link to="/register">
												<span className="forget">
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Sign up here`}
													/> */}
													Sign up here
												</span>
											</Link>
										</p>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<SocialLoginsError />
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	loginUser: (payload) => dispatch(loginUser(payload)),
	loginError: (payload) => dispatch(socialLoginError(payload)),
});

export default connect(mapDispatchToProps)(
	withRouter(withAlert(CodeVerification))
);
