import {  CircularProgress } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchFolders,
	searchFolders,
} from "../../../../Redux/Actions/bookmarks/folderActions";
import {
	areFoldersLoading,
	selectFolders,
} from "../../../../Redux/selectors/Bookmarks/folders";
import axiosConfig from "../../../../Utils/axiosConfig";
import { setStatusAsFavURL } from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import Button2 from "../../../Extras/Buttons/Button2/Button2";
import ClickPopover from "../../../Extras/ClickPopover";
import DialogModal from "../../../Extras/DialogModal";
import NewFolderDialog from "../CreateNewFolder/NewFolderDialog";
import styles from "./BookmarksDropdown.module.scss";
import { IconButton } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

export const BookmarksDialog = React.memo(({ children, ...props }) => {
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const onClose = () => setShow(false);

	const openDialog = () => {
        if (props.onOpen) {
            props.onOpen();
        }
		setShow(true);
	};

	useEffect(() => {
		if (show) {
			dispatch(fetchFolders());
		}
	}, [show, dispatch]);

	return (
		<>
			<div className={styles.btn} onClick={openDialog}>
				{children}
			</div>
			<DialogModal
				width="xs"
				show={show}
				className={styles.dialog}
				onHide={onClose}
				maxWidth="false"
			>
				<AddToBookmarksContent
					isBookmarked={props.isBookmarked}
					folderID={props.folderID}
					variant={props.variant}
					statusID={props.statusID}
					onBookmarkStateChange={(state) => {
						props.onBookmarkStateChange(state);
						onClose();
					}}
				/>
			</DialogModal>
		</>
	);
});

export const BookmarksDropdown = ({ children, ...props }) => {
	const dispatch = useDispatch();
	const onOpen = () => {
		dispatch(fetchFolders());
	};
	return (
		<ClickPopover
			onOpen={onOpen}
			transformOrigin={{ top: 10, horizontal: "center" }}
			className={styles.popover}
			trigger={children}
		>
			<AddToBookmarksContent
				isBookmarked={props.isBookmarked}
				folderID={props.folderID}
				statusID={props.statusID}
				onBookmarkStateChange={props.onBookmarkStateChange}
			/>
		</ClickPopover>
	);
}

export const AddToBookmarksContent = ({
	statusID,
	isBookmarked,
	folderID,
	onBookmarkStateChange,
	variant = "add",
	onClose,
}) => {
	const [showAddFolder, setShowAddFolder] = useState(false);
	const [folderState, setFolder] = useState();
	const [loading, setLoading] = useState(false);
	const folders = useSelector(selectFolders);
	const theme = useSelector((state) => state.theme.theme);
	const isFolderLoading = useSelector(areFoldersLoading);
	const { showAlert } = useAlert();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const bookmarkThought = (folder) => {
		setLoading(true);
		const form = new FormData();
		form.append("statusId", statusID);
		if (folder.id !== "all") {
			form.append("folderID", folder.id);
		} else {
			form.append("folderID", "");
		}
		axiosConfig
			.post(setStatusAsFavURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				// if (folder.id === "all") {
				// 	onBookmarkStateChange(true, null);
				// } else {
				// 	onBookmarkStateChange(true, folder.id);
				// }
				dispatch(fetchFolders(true));

				showAlert(
					`You've successfully saved the thought in ${folder.foldername} folder`,
					{
						header: "You're thought is saved",
						buttonText: "Go Back",
					}
				);
                setLoading(false);
                onClose()
                history.goBack()
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							("Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							})
						);
					}
				}
				setLoading(false);
			});
	};
	const getOnBookmarkHandler = (folder) => () => {
		history.push({
			search: `folder=${folder.id}`,
		});
		setFolder(folder);
	};

	return (
		<>
			<div
				style={{ background: theme === "dark" ? "#111528" : "#FFF", padding:20 }}
			>
				<div className={styles.header}>
					<h3
						style={{
							color: theme === "dark" ? "#d9def4" : "#0d0f22",
						}}
					>
						{variant === "shift"
							? "Shift to another Folder"
							: "Add to collection"}
					</h3>
				</div>
				<div className={styles.searchBox}>
					<img src="/Search.svg" className="mr-2 ml-1" alt="" />
					<input
						type="text"
						// value={this.state.searchTerm}
						// onChange={this.handleSearchInput}
						// ref={this.searchInputRef}
						className={styles.searchField}
						placeholder="Search Collections"
						// onClick={() => {
						// 	this.setState((prev) => ({
						// 		searchOpen: !prev.searchOpen,
						// 	}));
						// 	this.searchInputRef.current.focus();
						// }}
					/>
				</div>

				<div
					className={
						theme === "dark"
							? styles.folderContainerDk
							: styles.folderContainerLg
					}
				>
					{!isFolderLoading ? (
						<>
							<Folder
								theme={theme}
								onSelect={getOnBookmarkHandler({
									foldername: "Default",
									id: "all",
								})}
								folder={{
									foldername: "Default",
									id: "all",
								}}
								selected={
									"?folder=all" === location.search
									// "" === location.search
								}
							/>
							{folders.map((folder, i) => {
								return (
									<>
										<Folder
											theme={theme}
											onSelect={getOnBookmarkHandler(
												folder
											)}
											folder={folder}
											selected={
												"?folder=" + folder.id ===
												location.search
											}
										/>
									</>
								);
							})}
						</>
					) : (
						<div className={styles.loaderContainer}>
							<CircularProgress color="primary" size="0.8rem" />
						</div>
					)}
				</div>

				<div className={styles.addFolderContainer}>
					<Button1
						color="primary"
						style={{
							textTransform: "capitalize",
							fontWeight: "400",
						}}
						variant="outlined"
						startIcon={<Add />}
						onClick={() => setShowAddFolder(true)}
					>
						New Collection
					</Button1>
					<Button2
						disable={loading}
						onClick={() => bookmarkThought(folderState)}
					>
						Save
					</Button2>
				</div>
				<NewFolderDialog
					show={showAddFolder}
					onClose={() => setShowAddFolder(false)}
				/>
			</div>
		</>
	);
};

export const Folder = ({ folder, selected, onSelect, theme }) => (
	<div
		onClick={onSelect}
		className={`${styles.folder} ${selected && styles.selectedFolder}`}
		style={{
			border: selected && "1px solid #0072FF",
		}}
	>
		<div
			className={styles.folderName}
			style={{ color: theme === "dark" ? "#d9def4" : "#0d0f22" }}
		>
			<p className="m-0">{folder.foldername}</p>
			<small className={styles.thoughtsCount}>
				{folder.count} thoughts
			</small>
		</div>
		<IconButton size="small">
			{theme === "dark" ? (
				<img src="/cards-img/more-dark.png" alt="" />
			) : (
				<img src="/cards-img/more-lg.png" alt="" />
			)}
		</IconButton>
	</div>
);

export default BookmarksDropdown;
