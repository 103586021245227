import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

import styles from "./Works.module.css";
import carouselData from "./CarouselData";
import { useMediaQuery } from "react-responsive";
import { whyData } from "./whyData";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const CarouselItem = ({ data }) => {
	const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });

	return (
		<div className={styles.carouselItem}>
			<div className={styles.carouselItemImg}>
				<img
					height="100%"
					width="100%"
					src={data.imgUri}
					alt={data.imgUri}
				/>
			</div>
		</div>
	);
};

const Carousel = (props) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const prev = () => {
		activeIndex > 0 && setActiveIndex((prev) => prev - 1);
	};
	const next = () => {
		activeIndex < carouselData.length - 1 &&
			setActiveIndex((prev) => prev + 1);
	};
	useEffect(() => {
		const timer = setInterval(() => {
			setActiveIndex((prev) =>
				prev < carouselData.length - 1 ? prev + 1 : 0
			);
		}, 5000);
		return () => clearInterval(timer);
	}, []);
	const isMobile = useMediaQuery({ maxWidth: 768 });
	return (
		<div>
			<div className={styles.carouselContainer}>
				<button onClick={prev} className={styles.beforeBtn}>
					{"<"}
				</button>
				<button onClick={next} className={styles.afterBtn}>
					{">"}
				</button>

				<div
					className={styles.carouselItems}
					style={{
						marginLeft: `${
							-activeIndex * (isMobile ? 23 : 52.5)
						}rem`,
					}}
				>
					{carouselData.map((item) => (
						<CarouselItem data={item} />
					))}
				</div>
			</div>
			<div className={styles.carouselLabel}>
				<h4>{carouselData[activeIndex]?.heading}</h4>
				<p>{carouselData[activeIndex]?.subHeading}</p>
			</div>
			<div className={styles.dotsContainer}>
				{carouselData.map((item, idx) => (
					<div
						onClick={() => setActiveIndex(idx)}
						className={`${styles.dot} ${
							activeIndex === idx && styles.activeDot
						}`}
					></div>
				))}
			</div>
		</div>
	);
};

export default function Works() {
	const [carouselActiveIdx, setActiveIdx] = useState(0);
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Navbar />
				<div className={styles.cont}>
					<h1 className={styles.heading}>
						<FormattedMessage
							id="landingPage.works.heading"
							defaultMessage={` What makes Bditto`}
						/>

						<br />
						<FormattedMessage
							id="landingPage.works.heading1"
							defaultMessage={`unique?`}
						/>
					</h1>
					<div className={styles.valueCont}>
						{whyData.map((data) => (
							<div className={styles.useCards}>
								<h3 className={styles.value}>{data.heading}</h3>
								<h4 className={styles.content}>
									{data.subHeading}
								</h4>
							</div>
						))}
					</div>
					<div className={styles.yellowBox}></div>
					<h1
						className={styles.heading}
						style={{ marginBottom: "20px" }}
					>
						<FormattedMessage
							id="landingPage.works.heading2"
							defaultMessage={`How does it work?`}
						/>
					</h1>
					<Carousel />

					<div className={styles.contact}>
						<p>
							{" "}
							<FormattedMessage
								id="landingPage.works.heading3"
								defaultMessage={`So, what are you waiting for?`}
							/>
						</p>
						<Link to="/register">
							<button className={styles.contactBtn}>
								<FormattedMessage
									id="landingPage.works.button"
									defaultMessage={`Get Started`}
								/>
							</button>
						</Link>
					</div>
				</div>
				<div className={styles.blueBox}></div>
				<Footer />
			</div>
		</div>
	);
}
