import React, { Component, Fragment } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import MobileNavbar from "../Components/Navbar/Mobile/MobileNavbar";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";
import ProfileSidebar from "../Components/Profile/ProfileSidebar/ProfileSidebar";
import CodeVerification from "../Components/auth/CodeVerification/CodeVerification";
import CallScreen from "../Components/calls/CallScreen/callScreeen";
import GroupInfoModal from "../Components/Home/Groups/GroupModals/GroupInfoModal";
import Explore from "../Components/Home/Explore/Explore";
import Chat from "../Components/Home/Chats/Chat";
import SettingSidebar from "../Components/Settings/SettingsSidebar";
import InterestSidebar from "../Components/Home/Interests/InterestSidebar";
import ShareTheApp from "../Components/Info/ShareApp";
import Policies from "../Components/Info/Policies";
import InterestsPage from "../Components/Home/Interests/InterestsPage";
import Friends from "./../Components/Home/Friends/Friends";
import GroupInfo from "../Components/Home/Groups/GroupInfo";
import Login from "../Components/auth/Login/Login";
import Register from "../Components/auth/Register/Register";
import FrogetPassword from "../Components/auth/ForgetPassword/FrogetPassword";
import VerifyAccount from "../Components/auth/VerifyAccount/VerifyAccount";
import ChangePassword from "../Components/auth/ChangePassword/ChangePassword";
import UnProtectedAbout from "../Components/LandingPage/About/About";
import UnProtectedFAQ from "../Components/LandingPage/FAQs/FAQs";
import UnProtectedPrivacy from "../Components/LandingPage/Privacy/Privacy";
import UserAgreement from "../Components/LandingPage/userAgreement/userAggrement";
import UnProtectedWorks from "../Components/LandingPage/Works/Works";
import Home from "../Components/Home/Thoughts/Home";
import UserProfile from "../Components/Profile/UserProfile/UserProfile";
import Search from "../Components/Search/Search";
import Info from "../Components/Info/Info";
import Bookmark from "../Components/Home/Bookmark";
import Notification from "../Components/Navbar/Notification/Notification";
import TagsPage from "../Components/Home/Tags/TagsPage";
import Error404Page from "../Components/404";
import ContentPolicy from "../Components/LandingPage/ContentPolicy/ContentPolicy";
import CreateProfile from "../Components/auth/createProfile/CreateProfile";
import Error400Page from "../Components/errorScreen/Some";
import DeleteAccountRedirect from "./../Components/Profile/DeleteAccount/DeleteAccountRedirect";
import { BlockedAccountError } from "./../Components/404/BlockedAccountError";

// const SettingSidebar = React.lazy(() =>
// 	import("../Components/Settings/SettingsSidebar")
// );
// const InterestSidebar = React.lazy(() =>
// 	import("../Components/Home/Interests/InterestSidebar")
// );
// const ShareTheApp = React.lazy(() => import("../Components/Info/ShareApp"));
// const Policies = React.lazy(() => import("../Components/Info/Policies"));
// const InterestsPage = React.lazy(() =>
// 	import("../Components/Home/Interests/InterestsPage")
// );
// const Login = React.lazy(() => import("../Components/auth/Login/Login"));
// const Register = React.lazy(() =>
// 	import("../Components/auth/Register/Register")
// );
// const FrogetPassword = React.lazy(() =>
// 	import("../Components/auth/ForgetPassword/FrogetPassword")
// );
// const VerifyAccount = React.lazy(() =>
// 	import("../Components/auth/VerifyAccount/VerifyAccount")
// );
// const ChangePassword = React.lazy(() =>
// 	import("../Components/auth/ChangePassword/ChangePassword")
// );
// const LandingPage = React.lazy(() =>
// 	import("../Components/LandingPage/LandingPage")
// );
// const UnProtectedAbout = React.lazy(() =>
// 	import("../Components/LandingPage/About/About")
// );
// const UnProtectedFAQ = React.lazy(() =>
// 	import("../Components/LandingPage/FAQs/FAQs")
// );
// const UnProtectedPrivacy = React.lazy(() =>
// 	import("../Components/LandingPage/Privacy/Privacy")
// );
// const UserAgreement = React.lazy(() =>
// 	import("../Components/LandingPage/userAgreement/userAggrement")
// );
// const UnProtectedWorks = React.lazy(() =>
// 	import("../Components/LandingPage/Works/Works")
// );
// const Home = React.lazy(() => import("../Components/Home/Thoughts/Home"));
// const UserProfile = React.lazy(() =>
// 	import("../Components/Profile/UserProfile/UserProfile")
// );
// const Search = React.lazy(() => import("../Components/Search/Search"));
// const Info = React.lazy(() => import("../Components/Info/Info"));
// // const Explore = React.lazy(() => import("../Components/Home/Explore/Explore"));
// const Bookmark = React.lazy(() => import("../Components/Home/Bookmark"));
// // const Chat = React.lazy(() => import("../Components/Home/Chats/Chat"));
// const Notification = React.lazy(() =>
// 	import("../Components/Navbar/Notification/Notification")
// );
// const TagsPage = React.lazy(() => import("../Components/Home/Tags/TagsPage"));
// const Error404Page = React.lazy(() => import("../Components/404"));
// const ContentPolicy = React.lazy(() =>
// 	import("../Components/LandingPage/ContentPolicy/ContentPolicy")
// );
// const CreateProfile = React.lazy(() =>
// 	import("../Components/auth/createProfile/CreateProfile")
// );
// const Error400Page = React.lazy(() => import("../Components/errorScreen/Some"));
// const DeleteAccountRedirect = React.lazy(() =>
// 	import("./../Components/Profile/DeleteAccount/DeleteAccountRedirect")
// );
// const BlockedAccountError = React.lazy(() =>
// 	import("./../Components/404/BlockedAccountError")
// );
// const Friends = React.lazy(() =>
// 	import("./../Components/Home/Friends/Friends")
// );
// const GroupInfo = React.lazy(() =>
// 	import("../Components/Home/Groups/GroupInfo")
// );
class Routes extends Component {
	componentWillMount() {
		this.setState({
			previousLocation: this.props.location,
		});
	}

	componentWillUpdate() {
		window.addEventListener(this.props.location, function () {
			if (
				!(this.props.location.state && this.props.location.state.modal)
			) {
				this.setState({
					previousLocation: this.props.location,
				});
			}
		});
	}

	isChat = () => {
		return (
			this.props.location?.pathname?.includes("group") ||
			this.props.location?.pathname?.includes("private") ||
			this.props.location?.pathname?.includes("call")
		);
	};

	render() {
		const { isAuthenticated } = this.props;
		const isMobile = window.screen.width < 768;
		return (
			<>
				<Helmet>
					<title>Bditto - Where Thoughts unfold</title>
				</Helmet>
				<Switch
					location={
						this.props.location.state &&
						this.props.location.state?.modal &&
						this.state.previousLocation !== this.props.location
							? this.state.previousLocation
							: this.props.location
					}
				>
					<UnprotectedRoute
						exact
						path="/about"
						component={UnProtectedAbout}
					/>
					<UnprotectedRoute
						exact
						path="/privacy"
						component={UnProtectedPrivacy}
					/>
					<UnprotectedRoute
						exact
						path="/user-agreement"
						component={UserAgreement}
					/>

					<UnprotectedRoute
						exact
						path="/guidelines"
						component={ContentPolicy}
					/>
					<UnprotectedRoute
						exact
						path="/faqs"
						component={UnProtectedFAQ}
					/>
					<UnprotectedRoute
						exact
						path="/how-it-works"
						component={UnProtectedWorks}
					/>
					<UnprotectedRoute
						exact
						path="/login"
						isAuthenticated={isAuthenticated}
						component={Login}
					/>

					<UnprotectedRoute
						path="/register"
						isAuthenticated={isAuthenticated}
						component={Register}
					/>

					<UnprotectedRoute
						exact
						path="/create-profile"
						isAuthenticated={isAuthenticated}
						component={CreateProfile}
					/>
					<UnprotectedRoute
						exact
						path="/forget-password"
						isAuthenticated={isAuthenticated}
						component={FrogetPassword}
					/>
					<UnprotectedRoute
						path="/forget-password-reset/:tag/:token"
						isAuthenticated={isAuthenticated}
						component={ChangePassword}
					/>
					<UnprotectedRoute
						exact
						path="/delete-your-account/:tag/:token"
						// isAuthenticated={isAuthenticated}
						component={DeleteAccountRedirect}
					/>
					<UnprotectedRoute
						exact
						path="/account-blocked-error"
						// isAuthenticated={isAuthenticated}
						component={BlockedAccountError}
					/>

					<UnprotectedRoute
						path="/code-verification"
						isAuthenticated={isAuthenticated}
						component={CodeVerification}
					/>
					<UnprotectedRoute
						path="/account-activation/:tag/:token"
						isAuthenticated={isAuthenticated}
						component={VerifyAccount}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						exact
						path={["/", "/dashboard", "/group-info/:id"]}
						isAuthenticated={isAuthenticated}
						component={Home}
					/>

					<ProtectedRoute
						theme={this.props.theme}
						exact
						path="/profile/:userID"
						prevPath={this.props.location.pathname}
						isAuthenticated={isAuthenticated}
						component={UserProfile}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/myprofile"
						isAuthenticated={isAuthenticated}
						prevPath={this.props.location.pathname}
						component={ProfileSidebar}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/bookmarks"
						isAuthenticated={isAuthenticated}
						component={Bookmark}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/search"
						isAuthenticated={isAuthenticated}
						component={Search}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/info"
						isAuthenticated={isAuthenticated}
						component={Info}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/share-the-app"
						isAuthenticated={isAuthenticated}
						component={ShareTheApp}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/policies"
						isAuthenticated={isAuthenticated}
						component={Policies}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						path="/settings"
						isAuthenticated={isAuthenticated}
						component={SettingSidebar}
					/>

					{isMobile ? (
						<ProtectedRoute
							theme={this.props.theme}
							path="/explore"
							isAuthenticated={isAuthenticated}
							component={Explore}
							noBottomNavigation={true}
						/>
					) : (
						<ProtectedRoute
							theme={this.props.theme}
							path="/explore"
							isAuthenticated={isAuthenticated}
							component={Explore}
						/>
					)}
					<ProtectedRoute
						theme={this.props.theme}
						path="/group/:groupId"
						isAuthenticated={isAuthenticated}
						component={GroupInfo}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						exact
						path="/chat"
						isAuthenticated={isAuthenticated}
						component={Chat}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						exact
						path="/chat/:chatType/:chatID"
						isAuthenticated={isAuthenticated}
						component={Chat}
						noBottomNavigation
					/>

					{/* <ProtectedRoute theme={this.props.theme} path="/bookmarks" isAuthenticated={isAuthenticated} component={Bookmark} /> */}
					<ProtectedRoute
						theme={this.props.theme}
						path="/interests/:keyword"
						isAuthenticated={isAuthenticated}
						component={InterestsPage}
					/>

					{isMobile && (
						<ProtectedRoute
							theme={this.props.theme}
							exact
							path="/tags"
							isAuthenticated={isAuthenticated}
							component={TagsPage}
						/>
					)}
					{isMobile && (
						<ProtectedRoute
							theme={this.props.theme}
							exact
							path="/intrests"
							isAuthenticated={isAuthenticated}
							component={InterestSidebar}
						/>
					)}

					<ProtectedRoute
						theme={this.props.theme}
						exact
						path="/notification"
						isAuthenticated={isAuthenticated}
						component={Notification}
					/>
					<ProtectedRoute
						theme={this.props.theme}
						exact
						path="/myfeed"
						isAuthenticated={isAuthenticated}
						component={Friends}
					/>
					{isMobile && (
						<ProtectedRoute
							theme={this.props.theme}
							exact
							path="/myfeed"
							isAuthenticated={isAuthenticated}
							component={Friends}
							noBottomNavigation={true}
						/>
					)}
					<ProtectedRoute
						theme={this.props.theme}
						exact
						path="/call"
						isAuthenticated={isAuthenticated}
						component={CallScreen}
					/>

					{/* <Route
						exact
						path="/group-info/:id"
						isAuthenticated={isAuthenticated}
						component={GroupInfoModal}
					/> */}
					<Route path="/error/api" component={Error400Page} />
					<Route render={() => <Error404Page />} />
				</Switch>
				{this.props.location.state &&
				this.props.location.state?.modal &&
				this.state.previousLocation !== this.props.location ? (
					<Route
						exact
						path="/group-info/:id"
						isAuthenticated={isAuthenticated}
						component={() => <GroupInfoModal show={true} />}
					/>
				) : (
					<Fragment />
				)}
				{isAuthenticated && !this.isChat() && isMobile && (
					<MobileNavbar />
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(withRouter(Routes));
