import React, { useState } from "react";
import { connect } from "react-redux";
import "./ThemeSelector.scss";

export const ThemeContext = React.createContext();

export const ThemeSelector = ({ children, theme }) => {
  return (
    <ThemeContext.Provider>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeSelector;
