import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { dismissNotification } from "../../../Redux/Actions/notifications";
let displayed = [];

function usePrevious(value) {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	return ref.current;
}
const Notifier = (props) => {
	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter((key) => id !== key)];
	};
	const notifications = useSelector(
		(state) => state.notifications.newestData
	);
	const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
	const prevIsLoggedIn = usePrevious(isLoggedIn);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isLoggedIn && prevIsLoggedIn) {
			closeSnackbar();
		}
	}, [prevIsLoggedIn, isLoggedIn, closeSnackbar]);
	useEffect(() => {
		notifications.forEach(({ key, dismissed = false, ...message }) => {
			if (dismissed) {
				closeSnackbar(key);
				return;
			}
			if (displayed.includes(key)) return;
			enqueueSnackbar(message, {
				key,

				onExited: (event, myKey) => {
					dispatch(dismissNotification(myKey));
					removeDisplayed(myKey);
				},
			});
			storeDisplayed(key);
		});
	}, [notifications, dispatch, enqueueSnackbar, closeSnackbar]);
	return null;
};

export default Notifier;
