import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./Navbar.module.css";
import { useLocation } from "react-router";

import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import { FormattedMessage } from "react-intl";

export default function Navbar() {
	const location = useLocation();
	const [popup, setPopup] = useState(false);
	const closePopup = () => {
		setPopup(false);
	};
	const isMobile = useMediaQuery({ query: "(max-device-width: 600px)" });
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.mainCont}>
					<Link to="/landingpage">
						<div className={styles.brand}>
							<img src="/icons/bditto_logo.png" alt="logo" />
						</div>
					</Link>
					<div className={styles.mobNavBtn}>
						{!popup ? (
							<MenuIcon
								onClick={() => setPopup(true)}
								style={{ fontSize: "2rem", color: "#333" }}
							/>
						) : (
							<CloseIcon
								onClick={() => setPopup(false)}
								style={{ fontSize: "2rem", color: "#333" }}
							/>
						)}
					</div>
				</div>

				<div className={popup ? styles.popup : styles.close}>
					<ul id="options" className={styles.options}>
						<li>
							{location.pathname.includes("landing") ? (
								<ScrollLink
									spy={true}
									smooth={true}
									to="home"
									offset={-10}
									activeClass={styles.active}
									onClick={closePopup}
									className={`${styles.optionBtn} `}
								>
									<FormattedMessage
										id="landingPage.navbar.home"
										defaultMessage={`Home`}
									/>
								</ScrollLink>
							) : (
								<Link
									className={`${styles.optionBtn}`}
									to={{
										pathname: "/landingPage",
										state: { block: "home" },
									}}
								>
									<FormattedMessage
										id="landingPage.navbar.home"
										defaultMessage={`Home`}
									/>
								</Link>
							)}
						</li>
						<li>
							{location.pathname.includes("landing") ? (
								<ScrollLink
									spy={true}
									smooth={true}
									to="features"
									activeClass={styles.active}
									onClick={closePopup}
									offset={isMobile ? -80 : -20}
									className={`${styles.optionBtn} `}
								>
									<FormattedMessage
										id="landingPage.navbar.features"
										defaultMessage={`Features`}
									/>
								</ScrollLink>
							) : (
								<Link
									className={`${styles.optionBtn}`}
									to={{
										pathname: "/landingPage",
										state: { block: "features" },
									}}
								>
									{" "}
									<FormattedMessage
										id="landingPage.navbar.features"
										defaultMessage={`Features`}
									/>
								</Link>
							)}
						</li>
						<li>
							{location.pathname.includes("landing") ? (
								<ScrollLink
									spy={true}
									smooth={true}
									to="why"
									activeClass={styles.active}
									onClick={closePopup}
									className={`${styles.optionBtn} `}
								>
									<FormattedMessage
										id="landingPage.navbar.whybditto"
										defaultMessage={`Why Bditto`}
									/>
								</ScrollLink>
							) : (
								<Link
									className={`${styles.optionBtn}`}
									to={{
										pathname: "/landingPage",
										state: { block: "why" },
									}}
								>
									<FormattedMessage
										id="landingPage.navbar.whybditto"
										defaultMessage={`Why Bditto`}
									/>
								</Link>
							)}
						</li>
						<li>
							<Link
								to="/how-it-works"
								onClick={closePopup}
								className={`${styles.optionBtn} ${
									location.pathname.includes("how-it") &&
									styles.active
								}`}
							>
								<FormattedMessage
									id="landingPage.navbar.howItWorks"
									defaultMessage={`How it works`}
								/>
							</Link>
						</li>
					</ul>

					<div className={styles.btns}>
						<Link to="/login">
							<button className={styles.signInBtn}>
								<FormattedMessage
									id="landingPage.navbar.signIn"
									defaultMessage={`Sign In`}
								/>
							</button>
						</Link>
						<Link to="/register">
							<button className={styles.signUpBtn}>
								<FormattedMessage
									id="landingPage.navbar.signUp"
									defaultMessage={`Sign Up`}
								/>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
