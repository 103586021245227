import React, { useState, useEffect } from "react";
import styles from "./BlockDialog.module.css";
import { FormattedMessage } from "react-intl";
import { Dialog, Box } from "@material-ui/core";
import CloseIcon from "../../../Icons/CloseIcon";
import Button1 from "../Buttons/Button1/Button1";
import Button2 from "../Buttons/Button2/Button2";

const BlockDialog = ({ blockStatus, call, data, onClose, font, user }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		onClose && onClose();
	};

	const handleShow = () => {
		if (data === "Interest") {
			call(data);
		} else {
			setShow(true);
		}
	};
	useEffect(() => {
		onClose && setShow(false);
	}, [onClose]);

	return (
		<>
			<h1
				className={font === 0.9 ? styles.popoverBtn2 : styles.popoverBtn}
				onClick={(e) => {
					e.stopPropagation();
					handleShow();
				}}
			>
				{blockStatus === "Withdraw" ? (
					<span
						style={{
							border: "1px solid #fd5d5f",
							borderRadius: "4px",
							padding: "0.2rem 0.4rem",
							color: " #fd5d5f",
						}}
					>
						{blockStatus}
					</span>
				) : (
					<FormattedMessage
						id={blockStatus}
						defaultMessage={blockStatus}
					/>
				)}
			</h1>

			<Dialog
				maxWidth="sm"
				style={{
					zIndex: "9999",
					background: "rgba(0, 0, 0, 0.06)",
					backdropFilter: "blur(4px)",
				}}
				// classes={{ paper: classes.dialog }}
				open={show}
				// onClose={handleClose}
				isCentered
			>
				<span
					className={styles.crossIcon}
					onClick={(e) => {
						e.stopPropagation();
						handleClose(0);
					}}
				>
					<CloseIcon />
				</span>
				<div className={styles.container}>
					{blockStatus === "Withdraw" ? (
						<span>
							<h1 className={styles.title}>
								{blockStatus} Request
							</h1>
							<p className={styles.body}>
								Are you sure you want to
								<span style={{ fontWeight: "600" }}>
									{blockStatus}
								</span>
								your request.
							</p>
						</span>
					) : (
						<>
							<h1 className={styles.title}>{blockStatus} User</h1>
							<p className={styles.body}>
								Are you sure you want to
								<span style={{ textTransform: "lowercase" }}>
									{blockStatus}
								</span>
								{user}.
								{blockStatus !== "Unblock" && (
									<>
										You will no longer see messages from
										them.
									</>
								)}
							</p>
						</>
					)}
					<div className={styles.buttonBox}>
                        <Box sx={{marginRight:14}}>
                            <Button1
							className={styles.disagree}
							onClick={(e) => {
								e.stopPropagation();
								handleClose();
							}}
						>
							No
						</Button1>
                        </Box>
						<Button2
							className={styles.agree}
							onClick={(e) => {
								e.stopPropagation();
								call(data);
							}}
						>
							Yes
						</Button2>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default BlockDialog;
