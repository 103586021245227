import { Avatar } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { pure } from "recompose";
import axiosConfig from "../../../../Utils/axiosConfig";
import { blockUnblock, getBlockedUsers } from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import BlockDialog from "../../../Extras/BlockDialogBox/BlockDialog";
import EmptyState from "../../../Extras/EmptyState";
import SmallCardSkeleton from "../../../Skeleton/SmallCardSkeleton/SmallCardSkeleton";
import styles from "../Contacts.module.css";
import { useDispatch } from "react-redux";
import { getCountInContact } from "./../../../../Redux/Actions/contactAction";
import Username from "../../../Extras/Username";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import { useSelector } from "react-redux";

function BlockUsers(props) {
	const [isloading, setIsLoading] = useState(true);
	const [blockedUsersList, setBlockedUsersList] = useState([]);
	const [isblockedUsersLoaded, setBlockedUsersLoaded] = useState(false);
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useSelector((state) => state.theme.theme);

	useEffect(() => {
		if (isblockedUsersLoaded) {
			return;
		}
		setIsLoading(true);
		const request = new FormData();
		axiosConfig
			.get(getBlockedUsers, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setBlockedUsersLoaded(true);
				setBlockedUsersList(res.data.body || res.data.result);
				dispatch(getCountInContact());
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert("Please Try again later", {
					header: "Something went wrong",
					buttonText: "Go Back",
				});
			});
	}, [isblockedUsersLoaded, dispatch, showAlert]);

	const blockFriend = (user) => {
		let id = user.userID;
		const request = new FormData();
		request.append("userId", id);

		axiosConfig
			.post(blockUnblock, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setBlockedUsersList(
					blockedUsersList.filter(
						(friend) => friend.user.userID !== id
					)
				);
				dispatch(getCountInContact());

				showAlert(
					`${user.username} can view your profile and contact you again`,
					{
						header: "User UnBlocked",
						buttonText: `go to @${user.username}'s profile`,
						callback: () => history.push(`/profile/${id}`),
						linkText: "Not Now",
					}
				);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
	const filterBySearchTerm = (searchText) =>
		blockedUsersList.filter((user) =>
			user.user.username.toLowerCase().includes(searchText.toLowerCase())
		);
	if (isloading) {
		return (
			<div className={styles.cards}>
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
			</div>
		);
	} else {
		const filteredUsers = filterBySearchTerm(props.searchText);
		return (
			<div className={styles.cards}>
				{filteredUsers.length > 0 ? (
					filteredUsers.map((user, i) => (
						<div className={styles.card} key={i}>
							<Link
								to={`/profile/${user.user.userID}`}
								style={{ flex: 1 }}
							>
								<Avatar
									style={{
										marginRight: ".55rem",
										color:
											theme === "dark"
												? "#0D0F22"
												: "#d9def4",
										background:
											theme === "dark"
												? "#d9def4"
												: "#0D0F22",
									}}
									src={user.user.avatar}
									alt={user.user.full_name}
								>
									{user.user.username &&
										user.user.username[0].toUpperCase()}
								</Avatar>

								<p
									className={
										user.user.profession
											? styles.cardText1
											: styles.cardText
									}
								>
									<span
										style={{
											fontSize: "1rem",
											color:
												theme === "dark"
													? "#d9def4"
													: "#0D0F22",
										}}
									>
										<Username
											value={
												user.user.full_name ||
												user.user.username
											}
										/>{" "}
									</span>
									{user.user.profession && (
										<p
											style={{
												color: "#b7b7b7",
												padding:
													"0rem 0rem 0rem 0.2rem",
												fontSize: "0.85rem",
												margin: "0.5rem 0rem 0rem 0",
											}}
										>
											{user.user.profession &&
												user.user.profession}
										</p>
									)}{" "}
								</p>
							</Link>

							<div onClick={() => blockFriend(user.user)}>
								<Button1 padding={"5px 18px"}>Unblock</Button1>
							</div>
						</div>
					))
				) : (
					<EmptyState
						heading="No Blocked Users"
						className={styles.emptyState}
						imageUrl="/vectors/empty-states/emptyBlockedUsers.svg"
						subHeading="Looks like you haven’t blocked any users yet. When you do, this is where you’ll find them."
					/>
				)}
			</div>
		);
	}
}

export default pure(BlockUsers);
