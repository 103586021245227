import React from 'react'

const ShareAppIcon = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M37.0657 21.2458L42.9886 16.8242V39.8474C42.9886 42.1198 41.148 43.9605 38.8755 43.9605H6.12483C3.85234 43.9605 2.01172 42.1198 2.01172 39.8474V16.8242L7.80092 21.143L20.0374 30.2741C21.4976 31.3641 23.5027 31.3641 24.9526 30.2741L37.0657 21.2458Z" fill="#FF5B5B"/>
        <path d="M42.9986 16.8249L37.0654 21.2465V12.0742L42.9986 16.8249Z" fill="#FF5B5B"/>
        <path d="M16.3653 4.86719H28.6429H37.0748V12.0754V21.2477L24.972 30.2759C23.5118 31.3659 21.5067 31.3659 20.0568 30.2759L7.82031 21.1448L7.85116 21.1037V12.0034V4.86719H16.3653Z" fill="#DFDFDF"/>
        <path d="M25.1271 1.94673L28.6336 4.85676H16.3662L19.8726 1.94673C21.4048 0.692235 23.6053 0.692235 25.1271 1.94673Z" fill="#ED664C"/>
        <path d="M7.84205 12.0039V21.1042L7.81121 21.1453L2.01172 16.8265L7.83177 12.0039H7.84205Z" fill="#ED664C"/>
        <path d="M43.6569 16.0231L38.1351 11.581V4.86634C38.1351 4.30078 37.6415 3.86891 37.0657 3.86891H29.004L25.7752 1.17482C23.8729 -0.398445 21.1171 -0.388162 19.2148 1.1851L15.9963 3.86891H7.83173C7.26618 3.86891 6.76232 4.2905 6.76232 4.86634V11.5193L1.33301 16.0334C1.09651 16.2288 0.983398 16.5167 0.983398 16.8252H1.01425V39.8483C1.01425 42.6864 3.29702 45 6.12479 45H38.8754C41.7135 45 43.986 42.6761 43.986 39.8483V16.8252H44.0168C44.0271 16.5167 43.9037 16.2185 43.6569 16.0231ZM41.3536 16.7944L38.1351 19.1902V14.2134L41.3536 16.7944ZM20.5412 2.75837C21.6826 1.81235 23.3382 1.82263 24.4795 2.76865L25.7855 3.86891H19.2148L20.5412 2.75837ZM36.0785 5.92546V20.7224L24.3664 29.4422C23.2765 30.2545 21.734 30.2545 20.6544 29.4422L8.82916 20.6401V5.92546H36.0785ZM6.7726 14.1825V19.108L3.64664 16.7738L6.7726 14.1825ZM38.8857 42.9435H6.12479C4.42813 42.9435 3.0708 41.545 3.0708 39.8483V18.8715L7.20448 21.9666L19.4307 31.0977C20.3356 31.7764 21.4153 32.1157 22.5052 32.1157C23.5849 32.1157 24.6646 31.7764 25.5695 31.0977L41.9397 18.8715V39.8381C41.9397 41.545 40.5824 42.9435 38.8857 42.9435Z" fill="black"/>
        <path d="M16.1289 16.2089H21.6816C22.2471 16.2089 22.7098 15.7462 22.7098 15.1806C22.7098 14.6151 22.2471 14.1523 21.6816 14.1523H16.1289C15.5633 14.1523 15.1006 14.6151 15.1006 15.1806C15.1006 15.7462 15.5633 16.2089 16.1289 16.2089Z" fill="black"/>
        <path d="M16.1289 21.3495H28.8795C29.4451 21.3495 29.9078 20.8868 29.9078 20.3212C29.9078 19.7557 29.4451 19.293 28.8795 19.293H16.1289C15.5633 19.293 15.1006 19.7557 15.1006 20.3212C15.1006 20.8868 15.5633 21.3495 16.1289 21.3495Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="45" height="45" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        

    )
}

export default ShareAppIcon
