import React from "react";

const VideoCallIcon = ({ color }) => {
	return (
		<div>
			<svg
				width="26"
				height="18"
				viewBox="0 0 26 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17 6.33333L23.0707 3.29867C23.2739 3.19711 23.4997 3.14917 23.7267 3.1594C23.9536 3.16962 24.1742 3.23766 24.3675 3.35707C24.5608 3.47649 24.7203 3.6433 24.831 3.8417C24.9417 4.04009 24.9999 4.26348 25 4.49067V13.5093C24.9999 13.7365 24.9417 13.9599 24.831 14.1583C24.7203 14.3567 24.5608 14.5235 24.3675 14.6429C24.1742 14.7623 23.9536 14.8304 23.7267 14.8406C23.4997 14.8508 23.2739 14.8029 23.0707 14.7013L17 11.6667V6.33333Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V14.3333C1 15.8061 2.19391 17 3.66667 17H14.3333C15.8061 17 17 15.8061 17 14.3333V3.66667C17 2.19391 15.8061 1 14.3333 1Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};

export default VideoCallIcon;
