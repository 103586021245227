import { List, ListItem,  Popover } from "@material-ui/core";
import React from "react";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components";

const SidebarContextMenu = ({ children, ...props }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const paper = {
		boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
		background: theme === "dark" ? "#121528" : "#fff",
		boxSizing: "border-box",
		border: "1px solid #0D0F22",
		borderRadius: "8px",
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const transformOrigin = props.transformOrigin || {
		vertical: "center",
		horizontal: "left",
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const Icon = props.icon || MoreVertRoundedIcon;
	const TriggerComponent = props.trigger;

	return (
		<>
			{TriggerComponent ? (
				<div className={props.className} onClick={handleClick}>
					{TriggerComponent}
				</div>
			) : (
				<IconButton onClick={handleClick} size="small">
					<Icon fontSize="small" color="disabled" />
				</IconButton>
			)}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				classes={{ paper: paper }}
				anchorOrigin={
					props.anchorOrigin || {
						vertical: "bottom",
						horizontal: "right",
					}
				}
				transformOrigin={transformOrigin}
			>
				<StyledList
					className={props.listClass}
					theme={theme}
					applyChatOptionsColor={props.applyChatOptionsColor}
					style={props.listStyles}
				>
					{typeof children !== "function"
						? React.Children.map(children, (child) => {
								return (
									child && (
										<ListItem style={props.listItemStyle}>
											{React.cloneElement(child, {
												onClick: (e) => {
													e.stopPropagation();
													handleClose();
													child.props.onClick &&
														child.props.onClick(e);
												},
											})}
										</ListItem>
									)
								);
						  })
						: children({ onClose: handleClose }).map(
								(child) =>
									child && (
										<ListItem style={props.listItemStyle}>
											{child}
										</ListItem>
									)
						  )}
				</StyledList>
			</Popover>
		</>
	);
};

const StyledList = styled(List)`
	background: ${function ({ theme, applyChatOptionsColor }) {
		if (applyChatOptionsColor) {
			return theme === "dark" ? "#121528" : "#F5FAFF";
		}

		return theme === "dark" ? "#121528" : "white";
	}};
`;

export default React.memo(SidebarContextMenu);
