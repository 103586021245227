import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axiosConfig from "../../../Utils/axiosConfig";
import { RequestDeleteaccountLink } from "../../../Utils/Constants";
import styles from "./DeleteAccount.module.css";
import {
    Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import useAlert from "../../Extras/Alert/useAlert";
import { FormattedMessage } from "react-intl";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import Button2 from "../../Extras/Buttons/Button2/Button2";

const useStyles = makeStyles((theme) => ({
	save: {
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "45%",
		fontWeight: "400",
		margin: "1rem 0.8rem",
	},
	save2: {
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "100%",
		fontWeight: "400",
		margin: "1rem 0.8rem",
	},
	save1: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "45%",
		fontWeight: "400",
		margin: "1rem 0.8rem",
	},
	dialog: {
		padding: 0,
		width: "100%",
		maxHeight: "70vh",

		"&::-webkit-scrollbar": {
			display: "none",
		},
		[theme.breakpoints.up("md")]: {
			width: "400px",
		},
	},
}));

export default function DeleteAccount(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);

	const { showAlert } = useAlert();
	const classes = useStyles();

	const onOpen = () => {
		setIsOpen(true);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const onClose2 = () => {
		setIsOpen2(false);
	};

	const showErrorAlert = (message) => {
		showAlert(message, {
			buttonText: "Go back",
		});
	};

	const handleDelete = () => {
		axiosConfig
			.post(RequestDeleteaccountLink)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setIsOpen(false);
				setIsOpen2(true);
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;
					showErrorAlert(catchError.error);
				} else {
					showErrorAlert("network issue");
				}
			});
	};

	return (
		<div className={styles.container}>
			<Button1 onClick={onOpen}>
				<FormattedMessage
					id="deleteAccount"
					defaultMessage={`Delete Account`}
				/>
			</Button1>
                <Dialog
				open={isOpen}
				onClose={onClose}
				maxWidth="md"
				classes={{ paper: classes.dialog }}
			>
            <Box sx={{margin:14}}>
				<DialogTitle>
					Delete Account?
				</DialogTitle>
				<DialogContent>
					Are you sure you want to delete your account? Your data will
					be permanently deleted.
				</DialogContent>
                <DialogActions>
                    <Button1
						variant="outlined"
						color="primary"
						className={classes.save1}
						onClick={onClose}
					>
						No
					</Button1>
					<Button2
						variant="contained"
						color="primary"
						className={classes.save}
						onClick={handleDelete}
					>
						Yes, delete
					</Button2>
				
				</DialogActions>
            </Box>
			</Dialog>

			<Dialog
				open={isOpen2}
				onClose={onClose2}
				maxWidth="md"
				classes={{ paper: classes.dialog }}
			>
				<Box sx={{margin:14}}><DialogTitle style={{ marginTop: "1em", marginLeft: "1em" }}>
					The Link to Delete The Account Is Sent to Your Mail
				</DialogTitle>
				<DialogActions>
					<Button2
						variant="contained"
						color="primary"
						className={classes.save2}
						onClick={onClose2}
					>
						Close
					</Button2>
				</DialogActions></Box>
			</Dialog>
		</div>
	);
}
