import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import genIcons from "./genIcons";

const Search = {
	Outline: genIcons(SearchIcon, { style: { width: "22px", height: "15px" } }),
	LargeOutline: genIcons(SearchIcon, {
		style: { width: "30px", height: "30px" },
	}),
};

export default Search;
