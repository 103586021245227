import { NOTIFICATION_FETCH_LIMIT } from "../Actions/notifications";
import { createSelector } from "./helper";

const getNotificationsBlock = (state) => state.notifications;

export const isServerPolled = createSelector(
	getNotificationsBlock,
	(data) => data.polledFromServer
);

export const getFetchedNotificationCount = createSelector(
	getNotificationsBlock,
	(data) => data.fetchOffset
);

export const getNotifications = createSelector(
	getNotificationsBlock,
	(notifications) => notifications.data
);
export const getTop5Notifications = createSelector(
	getNotificationsBlock,
	(notifications) => notifications.data.slice(0, 5)
);

export const getUnreadCount = createSelector(
	getNotificationsBlock,
	(notifications) => notifications.unreadCount
);

export const isNotificationLoading = createSelector(
	getNotificationsBlock,
	(notifications) => notifications.loading
);

export const isNotificationFetchError = createSelector(
	getNotificationsBlock,
	(notifications) => notifications.fetchError
);

export const isNewNotificationPresent = createSelector(
	getNotificationsBlock,
	(notifications) => notifications.newNotifications
);

export const shouldFetchNotifications = (state) => {
	const loading = isNotificationLoading(state);
	const offset = getFetchedNotificationCount(state);
	const isPolled = isServerPolled(state);
	const isFinished = getNotificationsBlock(state).isFinished;
	return !loading && !isFinished && (!isPolled || offset !== 0);
};
