import Styles from "./AddGroup.module.css";
import { useState, useEffect } from "react";
import { createGroupURL } from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import axiosConfig from "../../../../Utils/axiosConfig";
import React from "react";
import { useSelector } from "react-redux";
import {
	Avatar,
	Switch,
	withStyles,
} from "@material-ui/core";

const AddGroup = (props) => {
	const { showAlert } = useAlert();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [isPrivate, setIsPrivate] = useState(false);
	const [uploadImage, setuploadImage] = useState("");
	const [UploadUrl, setUploadUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const imgRef = React.createRef();
	const [isValid, setIsValid] = useState(false);
	const theme = useSelector((state) => state.theme.theme);

	const StyledSwitch = withStyles((theme) => ({
		root: {
			height: "3.3rem",
			width: "5rem",
		},
		checked: {
			transform: "translateX(28px) !important",
			"& + $track": {
				backgroundColor: "#4A517E !important",
				opacity: "1 !important",
			},
		},
		switchBase: {
			left: 4,
			top: 3,
		},
		track: {
			background: "#4A517E",
			borderRadius: 27,
			opacity: 1,
		},
		thumb: {
			width: "27px",
			height: "27px",
			background: "#fff",
		},
	}))(Switch);

	const createGroup = () => {
		setIsLoading(true);
		const form = new FormData();
		form.append("name", name);
		form.append("type", isPrivate ? "private" : "public");
		form.append("bio", description);
		form.append("avatar", uploadImage);
		axiosConfig
			.post(createGroupURL, form)
			.then((res) => {
				showAlert("Successfull", {
					header: "Group Created Successfully",
					buttonText: "Close",
				});
				props.onSuccess();
				setName("");
				setuploadImage("");
				setDescription("");
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert(JSON.stringify(err.response.data?.error), {
					header: err.response.data?.message,
					buttonText: "Try Again",
				});
			});
	};
	const handleUploadImage = (event) => {
		setuploadImage(event.target.files[0]);
		setUploadUrl(URL.createObjectURL(event.target.files[0]));
	};
	const handleCamClick = () => {
		imgRef.current.click();
	};

	useEffect(() => {
		if (name.length > 0 && description.length > 0) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}, [name, description]);
	return (
		<div className={Styles.mainContainer}>
			<div className={Styles.navbar}>
				<div className={Styles.icon}>
					<img
						src={"/icons/close.svg"}
						onClick={() => {
							props.onClose();
                        }}
                        alt=""
					/>
				</div>
				<p className={Styles.header}>Create a Group</p>
			</div>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					createGroup();
				}}
			>
				<div className={Styles.topBlock}>
					<p className={Styles.head}>Basic Details</p>
					<div className={Styles.iconBox}>
						<Avatar
							className={Styles.ava}
							style={{
								width: "72px",
								height: "72px",

								background:
									theme === "dark" ? "#002a55" : "#00C6FF",
							}}
							src={uploadImage ? UploadUrl : ""}
						></Avatar>
						<input
							type="file"
							style={{ display: "none" }}
							ref={imgRef}
							onChange={handleUploadImage}
							accept="image/gif,image/jpeg,image/jpg,image/png"
						/>
						<img
							src={"/sample/camera.svg"}
							className={Styles.camera}
                            onClick={handleCamClick}
                            alt=""
						/>
					</div>
				</div>
				<div className={Styles.block}>
					<span className={Styles.blockHead}>NAME</span>
				</div>

				<input
					className={Styles.contentBlock}
					type="text"
					placeholder="Name for your Group*"
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				<div className={Styles.block}>
					<span className={Styles.blockHead}>DESCRIPTION</span>
				</div>

				<textarea
					onChange={(e) => {
						setDescription(e.target.value);
					}}
					className={Styles.contentBlock}
					type="text"
					placeholder="Describe your group here*"
				/>
				<div className={Styles.block}>
					<span className={Styles.blockHead}>VISIBILITY</span>
				</div>
				<div className={Styles.privateBlock}>
					<img
						src={"/sample/LOCK.svg"}
                        style={{ margin: "auto 0" }}
                        alt=""
					/>
					<span className={Styles.username}>Private Group</span>
					<div>
						<StyledSwitch
							onChange={() =>
								isPrivate
									? setIsPrivate(false)
									: setIsPrivate(true)
							}
							checked={isPrivate}
						/>
					</div>
				</div>
				<p className={Styles.note}>
					Text description here to make the user understand about the
					permission.
				</p>

				<div style={{ padding: "1rem" }}>
					<button
						type="submit"
						style={{
							background: isValid
								? "linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%)"
								: "#4A517E",
						}}
						className={Styles.button}
					>
						<div
							style={{
								display: "inline-block",
							}}
						>
							<span>Create</span>
							{/* <img
								style={{
									marginLeft: "0.75rem",
								}}
								src={"/sample/right.svg"}
							/> */}
						</div>
					</button>
				</div>
			</form>
		</div>
	);
};

export default AddGroup;
