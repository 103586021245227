export default function HelpIcon(props) {
	return (
		<svg
			style={{ margin: "auto 10px auto 0", width: 24, height: 24 }}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 15V15.01M10 11.5C9.98159 11.1754 10.0692 10.8536 10.2495 10.583C10.4299 10.3125 10.6933 10.1079 11 10C11.3759 9.85626 11.7132 9.62724 11.9856 9.33095C12.2579 9.03467 12.4577 8.67922 12.5693 8.29259C12.6809 7.90595 12.7013 7.49869 12.6287 7.10287C12.5562 6.70704 12.3928 6.33345 12.1513 6.01151C11.9099 5.68958 11.597 5.42808 11.2373 5.24762C10.8776 5.06715 10.4809 4.97264 10.0785 4.97152C9.67611 4.97041 9.27892 5.06272 8.91824 5.24119C8.55756 5.41965 8.24323 5.67941 8 6M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
				stroke={props.color}
				strokeWidth="1.4"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
