import { Avatar, Button, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./GroupInfo.module.css";
import AddIcon from "@material-ui/icons/Add";
import axiosConfig from "../../../Utils/axiosConfig";
import { getGroupDetails, joinGroupsURL } from "../../../Utils/Constants";
import OnlineBadge from "../../Extras/StyledBadge";
import Username from "../../Extras/Username";
import GroupsSidebar from "./GroupsSidebar";
import GroupInfoModal from "./GroupModals/GroupInfoModal";
import InterestSidebar from "../Interests/InterestSidebar";
import Info from "../../../Icons/Info";
import { useHistory } from "react-router-dom";
import useAlert from "../../Extras/Alert/useAlert";
import { useSelector } from "react-redux";
import { Close } from "@material-ui/icons";

const GroupInfo = (props) => {
	const { showAlert } = useAlert();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [group, setGroup] = useState({});
	const [public1, setPublic] = useState(true);
	const [groupsDisplay, setGroupDisplay] = useState(false);
	const [joinLoading, setJoinLoading] = useState(false);
	const [joined, setjoined] = useState(false);
	const [groupInfoModal, setgroupInfoModal] = useState(false);
	const [openModalGrpId, setopenModalGrpId] = useState("");

	useEffect(() => {
		getGroupInfo();
	}, [props.rootParams.params.groupId]);

	const loggedinUserID = useSelector((state) => state.auth.user_data?.userID);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);

	const getGroupInfo = () => {
		setIsLoading(true);
		const form = new FormData();
		form.append("groupId", props.rootParams.params.groupId);
		axiosConfig
			.post(getGroupDetails, form)
			.then((res) => {
				setGroup(res.data.body || res.data.results);
				checkMemberGroup(res.data.body.member_list);
				if (res.data.body.group_details.type === "public")
					setPublic(true);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};

	const checkMemberGroup = (members) => {
		members.forEach((mem) => {
			if (mem.user?.userID === loggedinUserID) {
				setjoined(true);
			}
		});
	};
	const joinGroup = () => {
		if (joined) {
			return 0;
		}
		setJoinLoading(true);
		const form = new FormData();
		form.append("groupId", props.rootParams.params.groupId);
		axiosConfig
			.post(joinGroupsURL, form)
			.then((res) => {
				getGroupInfo();
				setJoinLoading(false);
			})
			.catch((err) => {
				setJoinLoading(false);
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};

	const setGroupsInfoModal = () => {
		setgroupInfoModal(false);
	};

	const openInfoModal = (id) => {
		setgroupInfoModal(true);
		setopenModalGrpId(id);
	};

	const setGroupsDisplay = () => {
		setGroupDisplay(!groupsDisplay);
	};

	const isMobile = window.screen.width < 600;
	return (
		<div className={`home ${styles.homeContainer}`}>
			<div className={styles.center}>
				{!isMobile && (
					<div className={styles.sidebarContainer}>
						<div className={styles.grpContainer}>
							{!groupsDisplay && (
								<span
									style={{ marginLeft: "-0.5rem" }}
									className="mr-2"
									onClick={() => history.push("/")}
								>
									<Close
										style={{
											width: "1em",
											height: "1em",
											cursor: "pointer",
										}}
									/>
								</span>
							)}
							{!groupsDisplay && (
								<>
									<Avatar
										style={{
											width: "1.7em",
											height: "1.7em",
										}}
										className="ml-2"
										src={groupDataFromStore.avatar}
									>
										{groupDataFromStore.name[0].toUpperCase()}
									</Avatar>
									<span className={styles.grpname}>
										{groupDataFromStore.name}
									</span>
									<span
										onClick={() =>
											openInfoModal(groupDataFromStore.id)
										}
									>
										<Info.Outline
											viewBox="0 0 37 37"
											fontSize="1rem"
											style={{
												width: "1.15em",
												height: "1.15em",
											}}
										/>
									</span>
								</>
							)}
						</div>

						<GroupsSidebar
							setGroupsDisplay={setGroupsDisplay}
                            top="false"
                            groupId={groupDataFromStore.id}

						/>
						<GroupInfoModal
							show={groupInfoModal}
							onHide={setGroupsInfoModal}
							groupId={openModalGrpId}
						/>
					</div>
				)}
				<div className={styles.container}>
					{isLoading ? (
						<div className={styles.loader}>
							<CircularProgress
								className={styles.loaderSpinner}
							/>
						</div>
					) : (
						<div className={styles.infoWrapper}>
							<div className={styles.top}>
								<h3
									style={{ margin: "0.5rem auto" }}
									className={styles.head}
								>
									{group.group_details?.name}
								</h3>
								<Avatar
									style={{
										width: "6rem",
										height: "6rem",
										margin: "0.5rem auto",
										fontSize: "2rem",
									}}
									src={group.group_details?.avatar}
								>
									{group.group_details?.name[0]?.toUpperCase()}
								</Avatar>

								{/* <span className={styles.editPhoto}> <CameraEditIcon style={{width:'25px', height:'25px'}} /></span>  */}

								<div className="ml-2 mt-3">
									{group.group_details?.bio}
								</div>
								<Button
									variant="contained"
									className={styles.createBtn}
									onClick={joinGroup}
								>
									{joined ? (
										"Already Joined"
									) : (
										<>
											<AddIcon
												style={{
													fontSize: "0.99rem",
													color: "#fffff",
												}}
											/>
											{/* <FormattedMessage
												id="createGroup"
												defaultMessage={`Join Group`}
											/> */}
                                                    Join Group
										</>
									)}
								</Button>
							</div>
							<div>
								<h4 className={styles.head}>Members</h4>
								{group.member_list?.map((grp) => (
									<div className={styles.item} key={grp.id}>
										<OnlineBadge
											invisible={false}
											last_active={grp.user?.last_active}
											online={grp.user?.online}
											ripple={true}
										>
											<Avatar src={grp.user?.avatar}>
												{grp.user?.username[0]?.toUpperCase()}
											</Avatar>
										</OnlineBadge>
										<span className="ml-2">
											<Username
												value={grp.user?.username}
											/>
										</span>
										<span
											style={{
												fontSize: "0.6rem",
												color: "#ff5b5b",
											}}
											className="ml-2"
										>
											{group.group_details.admin === grp.pk
												? "ADMIN"
												: ""}
										</span>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
				{!isMobile && <InterestSidebar />}
			</div>
		</div>
	);
};

export default GroupInfo;
