export const FETCH_FOLDERS = "FETCH_FOLDERS";

export const ADD_FOLDER = "ADD_FOLDER";
export const EDIT_FOLDER = "EDIT_FOLDER";
export const REMOVE_FOLDER = "REMOVE_FOLDER";

export const ADD_STATUS_TO_FOLDER = "ADD_STATUS_TO_FOLDER";
export const REMOVE_STATUS_FROM_FOLDER = "REMOVE_STATUS_FROM_FOLDER";

export const SET_LOADING = "SET_LOADING_FOLDERS";
export const SET_ERROR = "SET_ERROR_FOLDERS";
export const IS_FOLDER_FETCHED = "IS_FOLDER_FETCHED";
