import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Button1 from "../Buttons/Button1/Button1";

import styles from "./ImageDisplay.module.css";

const ImageDisplay = (props) => {
	const theme = useSelector(state => state.theme.theme);
	return (
		<>
			<Dialog
				open={props.show}
				onHide={props.handleClose}
				size="lg"
				centered
			>
				<DialogContent style={{ background: theme === "dark" ? "#0d0f22" : "#D9DEF4" }}>
					<img className={styles.img} src={props.img} alt="preview" />
				</DialogContent>

				<DialogActions 
					style={{ 
						display: "flex", 
						justifyContent: "space-between", 
						padding: "1rem 2rem", 
						background: theme === "dark" ? "#0d0f22" : "#D9DEF4" 
					}}
				>
					<span style={{ color: theme === "dark" ? "#D9DEF4" : "#0d0f22"}}>
						{props.data && props.data.message}
					</span>
					<Button1
						onClick={props.handleClose}
						variant="contained"
						color="primary"
					>
						Close
					</Button1>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ImageDisplay;
