import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import styles from "./ReportDialog.module.css";
import { FormattedMessage } from "react-intl";
import { Dialog,Box } from "@material-ui/core";
import CloseIcon from "../../../Icons/CloseIcon";
import Button1 from "../Buttons/Button1/Button1";
import Button2 from "../Buttons/Button2/Button2";

const ReportDialog = (props) => {
	const [show, setShow] = useState(false);

	const { blockStatus } = props;

	const handleClose = (arg) => {
		if (!arg) {
			setShow(false);
			return;
		}

		props.call(props.data);
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
	};

	return (
		<>
			<h1
				className={styles.popoverBtn}
				onClick={(e) => {
					e.stopPropagation();
					handleShow();
				}}
			>
				<FormattedMessage
					id={blockStatus}
					defaultMessage={blockStatus}
				/>
			</h1>
			{/* <Modal
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        style={{ zIndex: "9999", background: "rgba(0, 0, 0, 0.06)" , backdropFilter: 'blur(4px)' }}
        centered
      > */}
			<Dialog
				maxWidth="sm"
				style={{
					zIndex: "9999",
					background: "rgba(0, 0, 0, 0.06)",
					backdropFilter: "blur(4px)",
				}}
				// classes={{ paper: classes.dialog }}
				open={show}
				// onClose={handleClose}
				isCentered
			>
				<span
					className={styles.crossIcon}
					onClick={(e) => {
						e.stopPropagation();
						handleClose(0);
					}}
				>
					<CloseIcon />
				</span>
				<div className={styles.container}>
					<h1 className={styles.title}>{props.blockStatus} User</h1>
					<p className={styles.body}>
						Are you sure you want to report{" "}
						<span className={styles.name}>{props.user}</span>. A
						report will be sent to the Bditto admin team.
					</p>

					<div className={styles.buttonBox}>
                        <Box sx={{ marginRight: 14 }}>
                            <Button1
                                className={styles.disagree}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose(0);
                                }}
                            >
							    No
                            </Button1>
                        </Box>
						<Button2
							className={styles.agree}
							onClick={(e) => {
								e.stopPropagation();
								handleClose(1);
							}}
						>
							Yes
						</Button2>
					</div>
				</div>
			</Dialog>
			{/* </Modal> */}
		</>
	);
};

export default ReportDialog;
