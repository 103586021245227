import React from "react";

const Inactive = ({ color }) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.5 3.5L24.5 24.5"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5025 5.83203H19.8358C20.7641 5.83203 21.6543 6.20078 22.3107 6.85716C22.9671 7.51354 23.3358 8.40377 23.3358 9.33203V16.332C23.3358 17.0029 23.1468 17.6294 22.8201 18.1614M19.8346 19.832H9.33464L4.66797 24.4987V9.33203C4.66797 8.06503 5.34114 6.9567 6.34797 6.34186L19.8346 19.832Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
export default Inactive;
