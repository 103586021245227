import React from "react";
import styles from "./NotificationScreenCard.module.css";
import { Link } from "react-router-dom";
import { Avatar, makeStyles } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	userIcon: {
		width: "3rem",
		height: "3rem",

		alignItems: "center",
		"@media (max-width:780px)": {
			width: "3rem",
			height: "3rem",
		},
		"@media (max-width:480px)": {
			width: "2.5rem",
			height: "2.5rem",
			marginLeft: "0px",
		},
	},
}));

const NotificationScreenCard = ({
	seen,
	profileLink,
	avatar,
	username,
	notificationLink,
	content,
	date,
}) => {
	const classes = useStyles();
	const theme = useSelector((state) => state.theme.theme);

	return (
		<div
			className={
				username
					? `${styles.subCont} ${seen && styles.seen}`
					: styles.hide
			}
		>
			<Link className={styles.avatar} to={profileLink}>
				<Avatar
					src={avatar}
					style={{
						background: theme === "dark" ? "#d9def4" : "#0d0f22",
						color: theme === "dark" ? "#0d0f22" : "#d9def4",
					}}
					className={classes.userIcon}
				>
					{username && username[0].toUpperCase()}
				</Avatar>
			</Link>

			<Link className={styles.content} to={notificationLink}>
				<div className={seen ? styles.seen : styles.content}>
					{content}
				</div>
			</Link>
			<div className={styles.date}>
				<span>
					<AccessTime
						color={"var(--heading-color-2)"}
						fontSize="small"
					/>
					{date}
				</span>
			</div>
		</div>
	);
};

export default NotificationScreenCard;
