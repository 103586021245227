import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styles from "./FriendsSidebar.module.scss";

// Utils
import { getRecentUploadsByFriendsURL } from "../../../../Utils/Constants";
import axiosConfig from "../../../../Utils/axiosConfig";
import useQuery from "../../../../Utils/useQuery";

// Material UI
import { Avatar, CircularProgress } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Components
import Username from "../../../Extras/Username";
import GroupsSidebar from "../../Groups/GroupsSidebar";
import GroupInfoModal from "../../Groups/GroupModals/GroupInfoModal";
import Users from "../../../../assets/icons/Users.svg";
import ChatUserReview from "../../../Extras/ChatUserReview/ChatUserReview";

const FriendsSidebar = () => {
	const isMobile = window.screen.width < 768;
	const theme = useSelector((state) => state.theme.theme);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);

	const { search } = useLocation();
	const completeFriendsList = useRef();
	const { friend: activeFriendID } = useQuery(search);

	const [friends, setFriends] = useState([]);
	const [sideBar, setSideBar] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [groupInfo, setGroupInfo] = useState(false);
	const [groupDisplay, setGroupDisplay] = useState(false);

	const fetchFriends = useCallback(() => {
		setIsLoading(true);
		axiosConfig
			.get(
				`${getRecentUploadsByFriendsURL}?group_id=${groupDataFromStore.id}`
			)
			.then((res) => {
				setFriends(res.data.result);
				completeFriendsList.current = res.data.result;
				setIsLoading(false);
			})
			.catch((err) => {});
		setIsLoading(false);
	}, []);

	useEffect(() => {
		fetchFriends();
	}, [fetchFriends]);

	useEffect(() => {
		if (searchText.length > 0) {
			setFriends(
				completeFriendsList.current.filter(
					({ friend }) =>
						friend.username
							.toLowerCase()
							.includes(searchText.toLowerCase()) ||
						friend.full_name
							.toLowerCase()
							.includes(searchText.toLowerCase())
				)
			);
		} else {
			setFriends(completeFriendsList.current);
		}
	}, [searchText]);

	const displayFriends = () => {
		return (
			friends &&
			friends.map(({ friend, count }) => {
				const isActive = Number(activeFriendID) === Number(friend.pk);
				let profileLink = "/profile/" + friend.pk;
				return (
					<Link
						className={`${styles.card} ${
							isActive ? styles.card__active : ""
						}`}
						to={{
							pathname: `/myfeed`,
							search: `?friend=${friend.pk}`,
						}}
						key={friend.pk}
					>
						<div className={styles.cardHeader}>
							<ChatUserReview
								id={friend.pk}
								data={{}}
								data1={{
									...friend,
									profileURL: friend.avatar,
									from: friend.pk,
								}}
								profileLink={profileLink}
								user={false}
								userLoading={isLoading}
								userProfile={friend}
							>
								<Avatar
									src={friend.avatar}
									style={{ width: "2rem", height: "2rem" }}
								>
									{friend.username &&
										friend.username[0].toUpperCase()}
								</Avatar>
							</ChatUserReview>
							<p
								className={`${styles.username} ${
									theme === "dark"
										? styles.darkrow
										: styles.lgrow
								}`}
							>
								<Username value={friend.username} />
							</p>
						</div>
						{count > 0 && <p className={styles.count}>{count}</p>}
					</Link>
				);
			})
		);
	};

	const updateGroupInfo = () => {
		setGroupInfo(!groupInfo);
	};

	const setGroupsDisplay = () => {
		setSideBar(!sideBar);
	};

	return (
		<div className={styles.container} style={ isMobile ? {width: "100%"} : {width: "28%"} }>
			{!isMobile && <div className={styles.grpContainer}>
				<div
					className={styles.groupName}
					onClick={() => setGroupInfo(!groupInfo)}
				>
					<Avatar
						style={{
							background:
							theme === "dark" ? "#d9def4" : "#0d0f22",
							color: theme === "dark" ? "#0d0f22" : "#d9def4",
						}}
						className="ml-2"
						src={groupDataFromStore.avatar}
					>
						{groupDataFromStore.name &&
							groupDataFromStore.name[0].toUpperCase()}
					</Avatar>
					<span
						className={`${styles.grpname} ${
							theme === "dark" ? styles.darkrow : styles.lgrow
						}`}
					>
						{groupDataFromStore.name}
					</span>
				</div>
				<div onClick={() => setGroupDisplay(!groupDisplay)}>
					{groupDisplay ? (
						<ExpandMoreIcon
							style={{
								fontSize: "1.5rem",
								color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
								transform: "rotate(180deg)",
								cursor:"pointer"
							}}
						/>
					) : (
						<ExpandMoreIcon
							style={{
								fontSize: "1.5rem",
								color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
								cursor:"pointer"
							}}
						/>
					)}
				</div>
			</div>}
			{!groupDisplay ? (
				<>
					{!isMobile ? (<div className={styles.backgroundText}>
						<img src={Users} alt="" />
						<div
							className={`${styles.header}  ${
								theme === "dark" ? styles.darkrow : styles.lgrow
							}`}
						>
							{theme === "dark" ? (
								<img
									className={styles.icon}
									src="/Home/twoUserdark.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.icon}
									src="/Home/twoUserlg.svg"
									alt=""
								/>
							)}
							{/* <FormattedMessage
								id="myFeed"
								defaultMessage={`My Connections`}
							/> */}
                            My Connections
						</div>
					</div>) : (
						<div className={styles.para}>
							<h5>View thoughts from network</h5>
							<p >
								Select or search connection from below to view recent thoughts posted.
							</p>
						</div>
					)}
					<div className={styles.searchContainer}>
						<img src="/Search.svg" className="mr-2 ml-1" alt="" />
						<input
							type="text"
							value={searchText}
							className={styles.inputField}
							placeholder="Search your connections"
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
						/>
					</div>
					<div className={styles.content}>
						{isLoading ? (
							<div className={styles.loader}>
								<CircularProgress
									className={styles.loaderSpinner}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
									}}
								/>
							</div>
						) : (
							<>
								<Link
									className={`${styles.card} ${
										!activeFriendID
											? styles.card__active
											: ""
									}`}
									to={{ pathname: `/myfeed` }}
									key={"all"}
								>
									<AvatarGroup>
										{completeFriendsList.current &&
											completeFriendsList.current
											.slice(0, 3)
											.map(({ friend }) => (
												<Avatar
													src={friend.avatar}
													style={{
															width: "2rem",
															height: "2rem",
															border: "none",
													}}
												>
													{friend.username &&
														friend.username[0].toUpperCase()}
												</Avatar>
											))}
									</AvatarGroup>
									<span
										className={`${styles.username} ${
											theme === "dark"
												? styles.darkrow
												: styles.lgrow
										}`}
									>
										{/* <FormattedMessage
											id="allConnections"
											defaultMessage={`All Connections`}
										/> */}
                                            All Connections
									</span>
								</Link>
								{displayFriends()}
							</>
						)}
					</div>
				</>
			) : (
                    <GroupsSidebar
                        groupId={groupDataFromStore.id}
                        setGroupsDisplay={setGroupsDisplay}
                        top="true"
                    />
			)}

			<GroupInfoModal
				show={groupInfo}
				onHide={updateGroupInfo}
				groupId={groupDataFromStore.id}
			/>
		</div>
	);
};

export default FriendsSidebar;
