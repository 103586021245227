import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import {
	FileMessageBox,
	ImageMessageBox,
	ReplyMessage,
	TextMessageBox,
	AudioMessageBox,
	MetaTags,
} from "./MessageTypes";

//Utils
import axiosConfig from "../../../../../Utils/axiosConfig";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import {
	getShortUserProfileUrl,
	likeMessageURL,
	MESSAGE_STATUS,
} from "../../../../../Utils/Constants";

//Material UI
import { IconButton, Tooltip, Avatar } from "@material-ui/core";
import { useSnackbar } from "notistack";

//Components
import { Like, Star, ArrowBendUpLeft } from "../../../../../Icons";
import useAlert from "../../../../Extras/Alert/useAlert";
import Username from "../../../../Extras/Username";
import ChatUserReview from "../../../../Extras/ChatUserReview/ChatUserReview";
import ReplyOptions from "../../../../Extras/ReplyOptions/ReplyOptions";

import styles from "./MessageBox.module.css";

function MessageBox(props) {
	const { setReply, showMessageInfo, forwardMessage } = props;
	const { showAlert } = useAlert();
	const { enqueueSnackbar } = useSnackbar();

	const [user, setUser] = useState({});
	const [sending, setSending] = useState(false);
	const [showLiked, setShowLiked] = useState(false);
	const [reaction, setReaction] = useState("");
	const [userLoading, setUserLoading] = useState(false);
	const [isLiked, setIsLiked] = useState(props.data.isLiked);
	const [likeShow, setLikeShow] = useState(true);

	const theme = useSelector((state) => state.theme.theme);
	const { userData } = useSelector((state) => ({
		userData: state.auth.user_data,
	}));

	const isSender =
		userData.user && props.data.username === userData.user.username;

	const scrollToMessage = () => {
		props.highlightReply(props.data.replyMessageID);
	};

	const likeMessage = (id, groupId, reaction) => {
		setSending(true);
		const data = {
			groupID: groupId,
			messageID: id,
			reaction: reaction,
		};
		axiosChatConfig
			.post(likeMessageURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.status === 200) {
					if (res.data.message.includes("removed")) {
						setShowLiked(false);
						setLikeShow(false);
					} else {
						setShowLiked(true);
						setLikeShow(true);
					}
					enqueueSnackbar(res.data.message);
					// isLiked
					// 	? enqueueSnackbar("Message Updated")
					// 	: enqueueSnackbar("Message Liked");
					setIsLiked((prev) => !prev);
				}
				setSending(false);

				// if (isLiked) {
				// 	setShowLiked(false);
				// 	setLikeShow(!likeShow);
				// } else {
				// 	setShowLiked(!showLiked);
				// 	setLikeShow(!likeShow);
				// }

				setReaction(reaction);
			})
			.catch((err) => {
				if (err.response) {
					// console.log("ada", err.response);
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const getShortUSerProfile = () => {
		let newUserid = userData.userID;
		if (newUserid === user.userID) {
			return;
		}
		if (Object.keys(user).length > 0) {
			return;
		}
		const request = new FormData();
		request.append("userId", props.data.from);
		setUserLoading(true);
		axiosConfig
			.post(getShortUserProfileUrl, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUserLoading(false);
				setUser(res.data.body);
			})
			.catch((err) => {
				setUserLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
			});
	};
	// console.log("ada", props);

	if (props.data.isDeleted)
		return (
			<div
				className={`${isSender ? styles.myChat : styles.chat} ${
					props.showAvatar && styles.lastinGroup
				}`}
				style={{
					color: theme === "dark" ? "rgb(190, 200, 240)" : "#000",
					backgroundColor: theme === "dark" ? "#5a6298" : "#D1E7FE",
				}}
			>
				<div
					className={`${
						isSender ? styles.myChatContent : styles.chatContent
					} `}
				>
					{!isSender && !props.noActions && (
						<div className={styles.nameavatar}>
							<ChatUserReview
								id={props.data.from}
								data={props.data}
								user={isSender}
								userProfile={user}
								userLoading={userLoading}
							>
								<Avatar
									style={{
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
									src={props.data.profileURL}
									onMouseOver={
										!isSender && getShortUSerProfile
									}
								>
									{props.data.username[0].toUpperCase()}
								</Avatar>
							</ChatUserReview>
							<h2
								className={`${
									isSender
										? styles.myUsername
										: styles.username
								}`}
							>
								<Username value={props.data.username} />
							</h2>
						</div>
					)}
					<div
						className={
							isSender ? styles.revMidCont : styles.midCont
						}
					>
						<div className={styles.messageCont}>
							<p className={styles.deletedText}>
								This message is Deleted
							</p>
						</div>
					</div>
				</div>
			</div>
		);

	return props.data.type === "INFO" ? (
		<MemberStatus data={props.data} theme={theme} />
	) : (
		<ChatboxOuterContainer
			className={`${isSender ? styles.myChat : styles.chat} ${
				props.showAvatar && styles.lastinGroup
			}`}
			theme={theme}
			isSender={isSender}
		>
			<ChatboxInnerContainer
				className={`${
					isSender ? styles.myChatContent : styles.chatContent
				} ${props.data.meta && styles.maxWidthContent}`}
				theme={theme}
				isSender={isSender}
			>
				<div className={styles.nameavatar}>
					{!isSender && (
						<>
							<div>
								<ChatUserReview
									id={props.data.from}
									data={props.data}
									user={isSender}
									userProfile={user}
								>
									<Avatar
										style={{
											background:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											color:
												theme === "dark"
													? "#0d0f22"
													: "#d9def4",
										}}
										src={props.data.profileURL}
										onMouseOver={
											!isSender && getShortUSerProfile
										}
									>
										{props.data.username[0].toUpperCase()}
									</Avatar>
								</ChatUserReview>
							</div>
							<div>
								<h2
									className={`${
										isSender
											? styles.myUsername
											: styles.username
									}`}
								>
									<Username value={props.data.username} />
								</h2>
							</div>
						</>
					)}

					{isSender ? (
						!props.noActions &&
						props.isMember && (
							<ReplyOptions
								className={styles.replyOptionsMy}
								data={{
									...props.data,
									conversationID: props.chatID,
								}}
								setReply={setReply}
								isGroup={props.isGroup}
								listenDeletes={props.listenMessageDeletes}
								showMessageInfo={showMessageInfo}
								forwardMessage={forwardMessage}
							/>
						)
					) : (
						<div className={styles.replyOptions}>
							{/*
								removed 'props.isGroup &&' it was showing undefined.
								Removing it solved the issue.
								Plzz check if its creating any issue.
							*/}
							{props.isGroup &&
								props.data.status !==
									MESSAGE_STATUS.PENDING && (
									<>
										<Tooltip
											title={
												<FormattedMessage
													id="unLike"
													defaultMessage={`Like`}
												/>
											}
											// title={
											// 	isLiked ? (
											// 		<FormattedMessage
											// 			id="unLike"
											// 			defaultMessage={`Dislike`}
											// 		/>
											// 	) : (
											// 		<FormattedMessage
											// 			id="like"
											// 			defaultMessage={`Like`}
											// 		/>
											// 	)
											// }
										>
											<IconButton
												onClick={() => {
													likeMessage(
														props.data._id,
														props.data.groupID,
														"heart"
													);
												}}
												className={styles.option}
												size="small"
												disabled={sending}
												style={{ paddingTop: "5px" }}
											>
												<Like.Filled
													style={{
														width: 20,
														height: 20,
													}}
												/>
											</IconButton>
										</Tooltip>
										<Tooltip
											title={
												<FormattedMessage
													id="unLike"
													defaultMessage={`Laugh`}
												/>
											}
											// title={
											// 	isLiked ? (
											// 		<FormattedMessage
											// 			id="unLike"
											// 			defaultMessage={`Dislike`}
											// 		/>
											// 	) : (
											// 		<FormattedMessage
											// 			id="like"
											// 			defaultMessage={`Laugh`}
											// 		/>
											// 	)
											// }
										>
											<IconButton
												onClick={() => {
													likeMessage(
														props.data._id,
														props.data.groupID,
														"laugh"
													);
												}}
												className={styles.option}
												size="small"
												style={{ paddingTop: "5px" }}
											>
												{isLiked ? (
													<img
														src="/chat/laugh.svg"
														alt=""
													/>
												) : (
													<img
														src="/chat/laugh.svg"
														alt=""
													/>
												)}
											</IconButton>
										</Tooltip>
										<Tooltip
											title={
												<FormattedMessage
													id="unLike"
													defaultMessage={`Innocent`}
												/>
											}
											// title={
											// 	isLiked ? (
											// 		<FormattedMessage
											// 			id="unLike"
											// 			defaultMessage={`Dislike`}
											// 		/>
											// 	) : (
											// 		<FormattedMessage
											// 			id="like"
											// 			defaultMessage={`Innocent`}
											// 		/>
											// 	)
											// }
										>
											<IconButton
												onClick={() => {
													likeMessage(
														props.data._id,
														props.data.groupID,
														"inno"
													);
												}}
												className={styles.option}
												size="small"
												style={{ paddingTop: "5px" }}
											>
												{isLiked ? (
													<img
														src="/chat/inno.svg"
														alt=""
													/>
												) : (
													<img
														src="/chat/inno.svg"
														alt=""
													/>
												)}
											</IconButton>
										</Tooltip>
										<Tooltip
											title={
												<FormattedMessage
													id="unLike"
													defaultMessage={`Cry`}
												/>
											}
											// title={
											// 	isLiked ? (
											// 		<FormattedMessage
											// 			id="unLike"
											// 			defaultMessage={`Dislike`}
											// 		/>
											// 	) : (
											// 		<FormattedMessage
											// 			id="like"
											// 			defaultMessage={`Sad`}
											// 		/>
											// 	)
											// }
										>
											<IconButton
												onClick={() => {
													likeMessage(
														props.data._id,
														props.data.groupID,
														"cry"
													);
												}}
												className={styles.option}
												size="small"
												style={{ paddingTop: "5px" }}
											>
												{isLiked ? (
													<img
														src="/chat/cry.svg"
														alt=""
													/>
												) : (
													<img
														src="/chat/cry.svg"
														alt=""
													/>
												)}
											</IconButton>
										</Tooltip>
									</>
								)}
							{!props.noActions && props.isMember && (
								<ReplyOptions
									className={styles.replyOptions}
									data={{
										...props.data,
										conversationID: props.chatID,
									}}
									setReply={setReply}
									isGroup={props.isGroup}
									listenDeletes={props.listenMessageDeletes}
									showMessageInfo={showMessageInfo}
									forwardMessage={forwardMessage}
								/>
							)}
						</div>
					)}
				</div>
				<div className={isSender ? styles.revMidCont : styles.midCont}>
					<div
						className={`${
							props.isHighlighted ? styles.highlighted : ""
						} ${styles.messageCont}`}
					>
						{props.data.isForwarded && (
							<p className={styles.forwardedTag}>Forwarded</p>
						)}
						{props.data.meta && <MetaTags meta={props.data.meta} />}
						{!props.noActions && (
							<ReplyMessage
								theme={theme}
								onClick={scrollToMessage}
								replyMessage={props.data.replyMessage}
								replyMessageID={props.data.replyMessageID}
								currentUserID={userData.userID}
							/>
						)}
						<BoxContent
							data={props.data}
							setReply={props.setReply}
							restrictHeight={props.restrictHeight}
							showLiked={showLiked}
							reaction={reaction}
							likeShow={likeShow}
						/>
					</div>
				</div>
			</ChatboxInnerContainer>

			{/* Its a chat replying arrow */}
			{props.data.status !== MESSAGE_STATUS.PENDING && !isSender && (
				<span
					className={styles.show_reply_icon}
					onClick={() => {
						setReply(props.data);
					}}
				>
					<div>
						<IconButton
							size="small"
							className={styles.replyoption}
							disabled={sending}
						>
							<ArrowBendUpLeft.Solid />
						</IconButton>
					</div>
				</span>
			)}

			{/* Its a liked and starred stats, its currently hardcoded */}
			{/* {statsOpen && (
				<div
					className={`${styles.stats} ${isSender && styles.myStats}`}
				>
					<div className={styles.statsItem}>
				<Like.Filled style={{ width: 27, marginTop: 3 }} />
				<span>3</span>
					</div>
					<div className={styles.statsItem}>
				<Star.Outline />
						<span>3</span>
					</div>
				</div>
			)} */}
		</ChatboxOuterContainer>
	);
}

const MemberStatus = (props) => {
	return (
		<div className="text-center">
			<p
				className="small"
				style={{
					color: props.theme === "dark" ? "#D1E7FE" : "#4A517E",
				}}
			>
				{props.data.message}
			</p>
		</div>
	);
};

const BoxContent = (props) => {
	const { userData } = useSelector((state) => ({
		userData: state.auth.user_data,
	}));
	const userID = userData.userID;
	if (props.data.type === "IMAGE" || props.data.type === "GIF") {
		return (
			<ImageMessageBox
				message={props.data}
				restrictHeight={props.restrictHeight}
				showLiked={props.showLiked}
				reaction={props.reaction}
				likeShow={props.likeShow}
			/>
		);
	} else if (props.data.type === "TEXT") {
		return (
			<TextMessageBox
				message={props.data}
				restrictHeight={props.restrictHeight}
				currentUserID={userID}
				showLiked={props.showLiked}
				reaction={props.reaction}
				likeShow={props.likeShow}
			/>
		);
	} else if (props.data.type === "AUDIO") {
		return (
			<AudioMessageBox
				message={props.data}
				showLiked={props.showLiked}
				reaction={props.reaction}
				likeShow={props.likeShow}
			/>
		);
	} else {
		return (
			<FileMessageBox
				message={props.data}
				restrictHeight={props.restrictHeight}
				showLiked={props.showLiked}
				reaction={props.reaction}
				likeShow={props.likeShow}
			/>
		);
	}
};

const ChatboxOuterContainer = styled.div`
	background: ${function ({ theme, isSender }) {
		if (isSender) {
			return theme === "dark" ? "#4A517E" : "#D1E7FE";
		}
	}} !important;
`;

const ChatboxInnerContainer = styled.div`
	background: ${function ({ theme, isSender }) {
		if (!isSender) {
			return theme === "dark" ? "#0D0F22" : "white";
		}
	}} !important;

	box-shadow: ${function ({ theme, isSender }) {
		if (!isSender && theme === "light") {
			return "0px 4px 16px rgba(0, 0, 0, 0.05)";
		}
	}};
`;

export default MessageBox;
