import { LOGOUT_REQUEST } from "../../Actions/actionTypes";

import {
  CLOSE_SINGLE_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  NEW_NOTIFICATION,
  NOTIFICATION_FETCH_ERROR,
  NOTIFICATION_LOADING,
  REMOVE_SINGLE_NOTIFICATION,
  SET_NEW_NOTIFICATIONS_PRESENT,
  SET_UNREAD_NOTIFICATION_COUNT,
  INCREMENT_NOTIFICATION_COUNT,
} from "../../Actions/notifications/types";
import createReducer from "../utils/createReducer";
import {
  closeNotification,
  incrementNotificationCount,
  recieveNewNotification,
  removeNotification,
  setNewNotificationsPreset,
  setNotificationCount,
} from "./liveNotifications";
import { fetchedNotifications, onFetchError, setLoading } from "./staticNotifications";

const INITIAL_STATE = {
  data: [],
  newestData: [],
  fetchOffset: 0,
  polledFromServer: false,
  fetchError: false,
  loading: false,
  isFinished: false,
  newNotifications: false,
  unreadCount: 0,
};

const onLogout = (state) => INITIAL_STATE;

const notificationsReducer = createReducer(INITIAL_STATE, {
  [NEW_NOTIFICATION]: recieveNewNotification,
  [CLOSE_SINGLE_NOTIFICATION]: closeNotification,
  [REMOVE_SINGLE_NOTIFICATION]: removeNotification,
  [FETCH_NOTIFICATIONS]: fetchedNotifications,
  [NOTIFICATION_LOADING]: setLoading,
  [NOTIFICATION_FETCH_ERROR]: onFetchError,
  [LOGOUT_REQUEST]: onLogout,
  [SET_NEW_NOTIFICATIONS_PRESENT]: setNewNotificationsPreset,
  [SET_UNREAD_NOTIFICATION_COUNT]: setNotificationCount,
  [INCREMENT_NOTIFICATION_COUNT]: incrementNotificationCount,
});

export default notificationsReducer;
