import React from "react";

const FavFilledIcon = ({ width, height }) => {
	return (
		<svg
			width={width ? width : "14"}
			height={height ? height : "14"}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="1"
				y="1"
				width="13"
				height="13"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.16797 1.75H13.1103V13.1256H1.16797V1.75Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.23194 7.07182C3.04978 9.61632 6.28086 11.6737 7.13953 12.183C8.00111 11.6685 11.2555 9.58832 12.0471 7.07415C12.5669 5.44899 12.0844 3.3904 10.1676 2.77265C9.23894 2.47457 8.15569 2.65599 7.40786 3.23465C7.25153 3.35482 7.03453 3.35715 6.87703 3.23815C6.08486 2.64257 5.05003 2.46815 4.10678 2.77265C2.19286 3.38982 1.71219 5.4484 2.23194 7.07182ZM7.14011 13.1257C7.06778 13.1257 6.99603 13.1082 6.93069 13.0726C6.74811 12.9728 2.44719 10.6022 1.39894 7.33899C1.39836 7.33899 1.39836 7.3384 1.39836 7.3384C0.74036 5.2839 1.47303 2.70207 3.83844 1.93965C4.94911 1.58032 6.15953 1.7384 7.13836 2.35615C8.08686 1.75649 9.34686 1.59082 10.4354 1.93965C12.8031 2.70324 13.5381 5.28449 12.8807 7.3384C11.8663 10.5642 7.53386 12.9705 7.35011 13.0714C7.28478 13.1076 7.21244 13.1257 7.14011 13.1257Z"
					fill="#FF5B5B"
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5905 6.1975C10.3648 6.1975 10.1735 6.02425 10.1548 5.79558C10.1163 5.31608 9.79546 4.91125 9.33871 4.76367C9.1083 4.689 8.9823 4.44225 9.05638 4.213C9.13163 3.98317 9.37605 3.85833 9.60705 3.93067C10.4021 4.18792 10.9592 4.892 11.0275 5.72442C11.0467 5.96533 10.8676 6.1765 10.6267 6.19575C10.6145 6.19692 10.6028 6.1975 10.5905 6.1975Z"
				fill="#FF5B5B"
			/>
		</svg>
	);
};

export default FavFilledIcon;
