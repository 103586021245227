import React, { useEffect, useRef, useState, Fragment } from "react";
import Interweave, { Markup } from "interweave";
// import { Interweave } from "interweave";
import { EmojiMatcher } from "interweave-emoji";
import { useSelector } from "react-redux";

import { MESSAGE_STATUS } from "../../../../../Utils/Constants";

import ImageDisplay from "../../../../Extras/ImageDisplay/ImageDisplay";
import { TextFormatter, MessageTimeStamp } from "./Helpers";

import {
	CircularProgress,
	Fab,
	Fade,
	IconButton,
	LinearProgress,
} from "@material-ui/core";
import {
	AccessTime,
	Description,
	Done,
	DoneAll,
	GetApp,
	Pause,
	PlayArrow,
	Avatar,
} from "@material-ui/icons";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { withStyles } from "@material-ui/core/styles";

import styles from "./MessageStyles.module.css";
import "./animation.scss";

const StyledLinearProgress = withStyles({
	colorPrimary: {
		backgroundColor: "#fff",
		height: "5rem",
	},
	barColorPrimary: {
		backgroundColor: "#7F89BE",
	},
})(LinearProgress);

const MessageTextFormatter = TextFormatter({
	classes: { link: styles.link_colour },
});

const MessageStatus = ({ status, seen, messageSender }) => {
	const { userData } = useSelector((state) => ({
		userData: state.auth.user_data,
	}));

	const isSender = userData.user && messageSender === userData.user.username;
	if (!isSender) {
		return null;
	}
	switch (status) {
		case MESSAGE_STATUS.SENT:
			return <Done color={"disabled"} fontSize="small" />;
		case MESSAGE_STATUS.PENDING:
		case MESSAGE_STATUS.sending:
			return <AccessTime color={"disabled"} fontSize="small" />;
		default:
			return (
				<DoneAll
					style={{ color: seen ? "#00C6FF" : "#393939" }}
					color={seen ? "blue" : "disabled"}
					fontSize="small"
				/>
			);
	}
};

export const ReplyMessage = React.memo(
	({ onClick, replyMessage, replyMessageID, currentUserID }) =>
		replyMessageID ? (
			<div className={styles.replyCont} onClick={onClick}>
				{replyMessage.message ? (
					<div className="d-flex">
						{/* <Avatar
							src={data.profileURL}
							className={styles.avatar}
							style={{
								width: "2rem",
								height: "2rem",
								marginRight: ".1rem",
								background:
									theme === "dark" ? "#d9def4" : "#0d0f22",
								color: theme === "dark" ? "#0d0f22" : "#d9def4",
							}}
						>
							{data.username && data.username[0].toUpperCase()}
						</Avatar> */}
						<Markup
							content={MessageTextFormatter({
								text: replyMessage.message,
								currentUserID,
							})}
						/>
					</div>
				) : (
					replyMessage.type
				)}
			</div>
		) : null
);

export const MetaTags = React.memo(({ meta }) =>
	meta ? (
		<a
			href={meta.url}
			target="_blank"
			rel="noopener noreferrer"
			className={styles.meta}
		>
			{meta.image && (
				<div className={styles.metaMedia}>
					<img alt="ogImage" src={meta.image.url} />
				</div>
			)}
			<div className={styles.metaContent}>
				<h2>{meta.title}</h2>
				<p>{meta.description}</p>
			</div>
		</a>
	) : null
);

export const TextMessageBox = ({
	message,
	restrictHeight,
	currentUserID,
	showLiked,
	reaction,
	likeShow,
}) => {
	const { userData, theme } = useSelector((state) => ({
		userData: state.auth.user_data,
		theme: state.theme.theme,
	}));

	// useEffect(() => {
	// 	let a = message;

	// 	debugger;
	// }, []);

	const messageMarkup = (message) => {
		try {
			var co = decodeURIComponent(
				MessageTextFormatter({
					text: message,
					// text: message.message,
					currentUserID,
				})
			);
			return (
				<Markup
					content={decodeURIComponent(
						escape(
							MessageTextFormatter({
								text: message,
								// text: message.message,
								currentUserID,
							})
						)
					)}
				/>
			);
		} catch (err) {
			return (
				<Markup
					content={decodeURIComponent(
						MessageTextFormatter({
							text: message,
							// text: message.message,
							currentUserID,
						})
					)}
				/>
			);
		}
	};

	const isSender =
		userData.user && message.username === userData.user.username;
	return (
		<Fragment>
			<div className={styles.dateCont}>
				<p
					className={`${styles.message} ${
						restrictHeight ? styles.restrictHeight : ""
					}`}
					style={{ color: theme === "dark" ? "#d9def4" : "#0D0F22" }}
					role="img"
				>
					{messageMarkup(message.message)}
					{/* <Markup
						content={decodeURIComponent(
							MessageTextFormatter({
								text: message.message,
								// text: message.message,
								currentUserID,
							})
						)}
					/> */}
				</p>
			</div>
			<div>
				<p className={`${isSender ? styles.myDate : styles.date}`}>
					<span
						style={{
							color: theme === "dark" ? "#D9DEF4" : "#0D0F22",
						}}
					>
						<MessageTimeStamp timestamp={message.timeStamp} />
					</span>
					<MessageStatus
						status={message.status}
						seen={message.seen}
						messageSender={message.username}
					/>
					<div>
						{(showLiked && (
							<>
								{reaction === "heart" ? (
									<FavoriteIcon
										style={{ color: "#E42A5B" }}
									/>
								) : reaction === "laugh" ? (
									<img src="/chat/laugh.svg" />
								) : reaction === "cry" ? (
									<img src="/chat/cry.svg" />
								) : (
									reaction === "inno" && (
										<img src="/chat/inno.svg" />
									)
								)}
							</>
						)) ||
							(likeShow &&
								message.reaction_user &&
								message.reaction_user.map((reaction) => (
									<>
										{reaction.reaction === "heart" ? (
											<FavoriteIcon
												style={{ color: "#E42A5B" }}
											/>
										) : reaction.reaction === "laugh" ? (
											<img src="/chat/laugh.svg" />
										) : reaction.reaction === "cry" ? (
											<img src="/chat/cry.svg" />
										) : (
											reaction.reaction === "inno" && (
												<img src="/chat/inno.svg" />
											)
										)}
									</>
								)))}
					</div>
				</p>
			</div>
		</Fragment>
	);
};

export const ImageMessageBox = React.memo(
	({ message, restrictHeight, showLiked, reaction, likeShow }) => {
		const [show, setShow] = useState(false);
		const handleClose = () => setShow(false);
		const handleShow = () => setShow(true);
		return (
			<>
				<div className={styles.imageCont}>
					<img
						onClick={() => handleShow()}
						className={styles.img}
						src={message.media}
						alt={message.username}
					/>
					<Fade
						in={message.status === MESSAGE_STATUS.sending}
						mountOnEnter
						unmountOnExit
					>
						<div className={styles.imgOverlay}>
							<CircularProgress
								size="2.5rem"
								style={{ color: "white" }}
							/>
						</div>
					</Fade>
				</div>
				<ImageDisplay
					show={show}
					data={message}
					img={message.media}
					handleClose={handleClose}
				/>
				<TextMessageBox
					message={message}
					showLiked={showLiked}
					reaction={reaction}
					restrictHeight={restrictHeight}
					likeShow={likeShow}
				/>
			</>
		);
	}
);

export const FileMessageBox = ({
	message,
	restrictHeight,
	showLiked,
	reaction,
	likeShow,
}) => {
	const theme = useSelector((state) => state.theme.theme);
	const isDownloadable =
		message.status === MESSAGE_STATUS.DELIVERED ||
		message.status === undefined;
	return (
		<>
			<div
				className={styles.downloadSegment}
				style={{
					boxShadow: `0px 0px 8px ${
						theme === "dark" ? "#121528" : "#fff"
					}`,
				}}
			>
				<Description
					style={{
						fontSize: "2rem",
						color: theme === "dark" ? "#d9def4" : "#0d0f22",
					}}
				/>
				<p
					className={styles.fileName}
					style={{
						color: `${theme === "dark" ? "#d9def4" : "#0d0f22"}`,
					}}
				>
					{message.filename}
				</p>
				<a href={message.media} download={message.filename}>
					<IconButton disabled={!isDownloadable}>
						{isDownloadable ? (
							<GetApp
								style={{
									color: `${
										theme === "dark" ? "#d9def4" : "#0d0f22"
									}`,
								}}
							/>
						) : (
							<CircularProgress size="1.5rem" />
						)}
					</IconButton>
				</a>
			</div>
			<TextMessageBox
				message={message}
				restrictHeight={restrictHeight}
				showLiked={showLiked}
				reaction={reaction}
				likeShow={likeShow}
			/>
		</>
	);
};

export const AudioMessageBox = ({
	message,
	restrictHeight,
	showLiked,
	reaction,
	likeShow,
}) => {
	const [elapsedTime, setElapsedTime] = useState(0);
	const [isPlaying, setPlaying] = useState(false);
	const theme = useSelector((state) => state.theme.theme);
	const audioRef = useRef();
	const play = () => {
		setPlaying(true);
		audioRef.current.play();
	};
	const pause = () => {
		setPlaying(false);
		audioRef.current.pause();
	};
	useEffect(() => {
		const audio = audioRef.current;
		const timeUpdateHandler = audio.addEventListener("timeupdate", () => {
			setElapsedTime((audio.currentTime / audio.duration) * 100);
		});

		const endedHandler = audioRef.current.addEventListener("ended", () => {
			setPlaying(false);
			setElapsedTime(0);
		});

		return () => {
			audio.removeEventListener("timeupdate", timeUpdateHandler);
			audio.removeEventListener("ended", endedHandler);
		};
	}, []);
	return (
		<>
			<audio ref={audioRef} hidden src={message.media} controls></audio>
			<div className={styles.audioMessage}>
				{!isPlaying ? (
					<PlayArrow
						onClick={play}
						style={{
							fontSize: "2rem",
							color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
						}}
					/>
				) : (
					<Pause
						onClick={pause}
						style={{
							fontSize: "2rem",
							color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
						}}
					/>
				)}

				<div style={{ margin: "0 1rem" }}>
					<ul class="ul">
						<li
							class={`li ${isPlaying ? "li-1" : "li-stop-1"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-2" : "li-stop-2"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-3" : "li-stop-3"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-4" : "li-stop-4"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-5" : "li-stop-5"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-6" : "li-stop-6"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-7" : "li-stop-7"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-8" : "li-stop-8"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-9" : "li-stop-9"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-10" : "li-stop-10"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-11" : "li-stop-11"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-12" : "li-stop-12"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-13" : "li-stop-13"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-14" : "li-stop-14"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-15" : "li-stop-15"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-16" : "li-stop-16"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-17" : "li-stop-17"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-18" : "li-stop-18"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-19" : "li-stop-19"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-20" : "li-stop-20"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-21" : "li-stop-21"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-22" : "li-stop-22"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-23" : "li-stop-23"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-24" : "li-stop-24"}`}
						></li>
						<li
							class={`li ${isPlaying ? "li-25" : "li-stop-25"}`}
						></li>
					</ul>
				</div>

				{/* <StyledLinearProgress
					style={{ flex: 1, marginLeft: "0.6rem" }}
					variant="determinate"
					value={elapsedTime}
				/> */}
			</div>
			<TextMessageBox
				message={message}
				restrictHeight={restrictHeight}
				showLiked={showLiked}
				reaction={reaction}
				likeShow={likeShow}
			/>
		</>
	);
};
