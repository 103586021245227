import React, { useRef, useEffect, useState } from "react";
import styles from "./callScreen.module.scss";
import {
	Avatar,
	Button,
	IconButton,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import * as Socket from "../../../Utils/socket";
import BottomBar from "./bottomBar/BottomBar";
import {
	AspectRatio,
	ChevronLeft,
	MicNoneOutlined,
	MicOffOutlined,
	VideocamOffOutlined,
	VideocamOutlined,
} from "@material-ui/icons";
import Username from "../../Extras/Username";
import useQuery from "../../../Utils/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { audioVideoStateSelector } from "../../../Redux/selectors/videoCalls/configuration";
import useAlert from "../../Extras/Alert/useAlert";
import { useHistory } from "react-router";
import NewCallNotification from "../NewCallNotification/NewCallNotification";
import { getUserProfileURL } from "../../../Utils/Constants";
import axiosConfig from "../../../Utils/axiosConfig";
import { toggleVideo } from "../../../Redux/Actions/videoCalls/actions";
const useStyles = makeStyles((theme) => ({
	avatarBackground: {
		backgroundColor: "rgba(255, 255, 255, 0.15);",
	},
	avatar: {
		height: "8.2rem",
		width: "8.2rem",
		fontSize: "3.5rem",
		border: `4px solid ${theme.palette.primary.main}`,
		boxShadow: "0px 4px 30px 30px rgba(255, 255, 255, 0.07)",
	},
	statusIndicatorIcons: {
		border: "1px solid white",
		color: "white",
		margin: "0 5px",
	},
	dialogContent: {
		padding: "1rem 0",
		"&::-webkit-scrollbar": {
			width: "5px",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#ff5b5b",
		},
	},
}));

function attachMediaStream(video, stream) {
	const videoTracks = stream.getVideoTracks();
	// console.log(`Using video device: ${videoTracks[0].label}`);

	video.srcObject = stream;
}

const CallScreen = ({ callID }) => {
	const socketConnected = useSelector(
		(state) => state.deviceParameters.chatSocket
	);
	const audioVideoParams = useSelector(audioVideoStateSelector);
	const user_data = useSelector((state) => state.auth.user_data);
	const [otherUSer, setOtherUser] = useState([]);
	const [remoteDeviceStats, setRemoteDeviceStats] = useState({
		audio: true,
		video: false,
	});
	const theme = useTheme();
	const classes = useStyles(theme);
	const { showAlert } = useAlert();
	const history = useHistory();
	const selfVideo = useRef();
	const [fullScreen, SetFullScreen] = useState(false);
	const incomingVideo = useRef();
	const peerConnection = useRef();
	const dispatch = useDispatch();
	const camStream = useRef();
	const params = useQuery();
	useEffect(() => {
		if (socketConnected) startRTC();
	}, [socketConnected]);

	useEffect(() => {
		if (camStream.current && camStream.current.getVideoTracks().length > 0)
			camStream.current.getVideoTracks()[0].enabled =
				audioVideoParams.video;
		Socket.sendDeviceStats(params.callID, audioVideoParams);
	}, [audioVideoParams.video]);

	useEffect(() => {
		if (camStream.current && camStream.current.getAudioTracks().length > 0)
			camStream.current.getAudioTracks()[0].enabled =
				audioVideoParams.audio;
		Socket.sendDeviceStats(params.callID, audioVideoParams);
	}, [audioVideoParams.audio]);

	const answerCall = () => {
		const { callID } = params;
		Socket.answerCall(callID, (err, res) => {
			if (res) {
			} else if (err) {
				showAlert(err.message, {
					callback: () => {
						history.push("/chat");
					},
				});
			}
			// console.log("call answered");
		});
	};

	useEffect(() => {
		const form = new FormData();
		form.append("userId", params.userID);
		axiosConfig
			.post(getUserProfileURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setOtherUser(res.data.body);
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;

					showAlert(catchError.error, {
						buttonText: "Go Back",
					});
				}
			});
	}, [params.userID]);

	useEffect(() => {
		setRemoteDeviceStats({
			video: params.type === "AUDIO" ? false : true,
			audio: true,
		});
		if (params.type === "AUDIO") dispatch(toggleVideo());
	}, [params.type]);
	const startRTC = async () => {
		const { callID } = params;

		const rtcConn = new RTCPeerConnection({
			iceServers: [
				{ urls: "stun:stun.l.google.com:19302" },
				{ url: "stun:stun1.l.google.com:19302" },
				{ url: "stun:stun2.l.google.com:19302" },
				{ url: "stun:stun3.l.google.com:19302" },
				{ url: "stun:stun4.l.google.com:19302" },
				{ url: "stun:stunserver.org" },
				{ url: "stun:stun.softjoys.com" },
				{ url: "stun:stun.voiparound.com" },
				{ url: "stun:stun.voipbuster.com" },
				{ url: "stun:stun.voipstunt.com" },
				{ url: "stun:stun.voxgratia.org" },
				{ url: "stun:stun.xten.com" },
				{
					url: "turn:numb.viagenie.ca",
					credential: "muazkh",
					username: "webrtc@live.com",
				},
			],
		});
		try {
			camStream.current = await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: true,
			});
			attachMediaStream(selfVideo.current, camStream.current);

			rtcConn.addStream(camStream.current);
		} catch (err) {
			// console.log(err);
			// console.log(
			// 	"##ERROR:There was an error getting the requested media#"
			// );
		}

		rtcConn.onaddstream = function (e) {
			// console.log("stream added");
			attachMediaStream(incomingVideo.current, e.stream);
		};

		rtcConn.onicecandidate = (evt) => {
			// console.log("candidate info", evt.candidate);
			if (evt.candidate)
				Socket.sendPrivateCandidate(callID, evt.candidate);
		};
		Socket.ListenForIceCandidates(callID, (candidate) => {
			// console.log("candidate received", candidate);
			rtcConn.addIceCandidate(new RTCIceCandidate(candidate));
		});

		Socket.ListenForSdpRemote(callID, (desc) => {
			// console.log("remote sdp");
			rtcConn.setRemoteDescription(desc);
			// console.log(rtcConn);
		});

		Socket.ListenForDeviceStats(callID, setRemoteDeviceStats);

		Socket.ListenForSdp(callID, (sdp) => {
			// console.log("sdp received", sdp);
			rtcConn.setRemoteDescription(sdp);
			rtcConn.createAnswer(
				(desc) => {
					rtcConn.setLocalDescription(desc);
					Socket.sendPrivateSDPRemote(callID, desc);
				},
				() => {
					// console.log(
					// 	"##ERROR:There was an error with the description#"
					// )
				}
			);
		});

		Socket.listenCallDisconnect(callID, () => {
			try {
				camStream.current.getTracks().forEach(function (track) {
					if (track.enabled) {
						track.stop();
						track.enabled = false;
					}
				});
				history.replace(`/chat/private/${params.userID}`);
				peerConnection.current.close();
			} catch {
				history.replace(`/chat/private/${params.userID}`);
			}
		});

		Socket.listenCallRejected(callID, () => {
			camStream.current.getTracks().forEach(function (track) {
				if (track.enabled) {
					track.stop();
					track.enabled = false;
				}
			});
			history.replace(`/chat/private/${params.userID}`);
			peerConnection.current.close();
		});

		if (params.startCall === "true") {
			Socket.ListenforAnswers(callID, (data) => {
				// console.log("answered", data);
				rtcConn.createOffer().then(
					(desc) => {
						// console.log("description created");
						// console.log(rtcConn, desc);
						rtcConn.setLocalDescription(desc);
						Socket.sendPrivateSDP(callID, desc);
						// console.log("sdp sent");
					},
					(err) => { 
						// console.log("error creating offer") 
					}
				);
			});
		}

		if (params.answerCall === "true") {
			// answerCall();
		}

		peerConnection.current = rtcConn;
	};

	const disconnectCall = () => {
		Socket.endCall(params.callID, (err, res) => {
			if (err) {
				showAlert(err.message);
				return;
			} else {
				history.replace(`/chat/private/${params.userID}`);
				try {
					camStream.current.getTracks().forEach(function (track) {
						if (track.enabled) {
							track.stop();
							track.enabled = false;
						}
					});
				} catch (err) {
					/* handle the error */
					// console.error("error:");
				}

				peerConnection.current.close();
			}
		});
	};

	return (
		<div className={styles.homeContainer}>
			<div className={`${styles.center}`}>
				{/* <Button onClick={answerCall}>Answer</Button> */}
				<div className={styles.callArea}>
					<div
						className={
							fullScreen
								? styles.fullScreenContainer
								: styles.videoContainer
						}
					>
						<div className={styles.header}>
							<Button
								className={styles.back}
								onClick={() =>
									history.replace(
										`/chat/private/${params.userID}`
									)
								}
							>
								<ChevronLeft />
								<span> Conversation</span>
							</Button>
							<IconButton className={styles.expandButton}>
								<AspectRatio
									onClick={() => SetFullScreen(!fullScreen)}
								/>
							</IconButton>
						</div>
						<div className={styles.avatarContainer}>
							<Avatar
								classes={{
									root: classes.avatar,
									colorDefault: classes.avatarBackground,
								}}
								src={otherUSer?.avatar}
							>
								{params.username[0]?.toUpperCase()}
							</Avatar>
						</div>
						<video
							className={
								!remoteDeviceStats.video ? styles.videoOff : ""
							}
							ref={incomingVideo}
							id="incomingVideo"
							playsInline
							autoPlay
						></video>
						<div className={styles.footer}>
							<div className={styles.info}>
								<div className={styles.username}>
									<Username value={params.username} />
								</div>
								<IconButton
									disabled
									size="small"
									className={classes.statusIndicatorIcons}
								>
									{remoteDeviceStats.audio ? (
										<MicNoneOutlined htmlColor="white" />
									) : (
										<MicOffOutlined htmlColor="white" />
									)}
								</IconButton>
								<IconButton
									disabled
									size="small"
									className={classes.statusIndicatorIcons}
								>
									{remoteDeviceStats.video ? (
										<VideocamOutlined htmlColor="white" />
									) : (
										<VideocamOffOutlined htmlColor="white" />
									)}
								</IconButton>
							</div>
							{/* <div className={styles.status}>Connecting...</div> */}
						</div>
					</div>
					<div
						className={
							fullScreen
								? styles.smallContainer
								: styles.videoContainer
						}
					>
						<div className={styles.avatarContainer}>
							<Avatar
								classes={{
									root: classes.avatar,
									colorDefault: classes.avatarBackground,
								}}
								src={user_data?.avatar}
							>
								{user_data?.full_name[0]?.toUpperCase()}
							</Avatar>
						</div>
						<video
							className={
								!audioVideoParams.video ? styles.videoOff : ""
							}
							ref={selfVideo}
							id="selfView"
							playsInline
							autoPlay
						></video>
						<div
							className={`${styles.footer} ${styles.footer__own}`}
						>
							<div className={styles.info}>
								<div className={styles.username}>
									You
									{/* <Username value={"manish12"} /> */}
								</div>
								<IconButton
									disabled
									size="small"
									className={classes.statusIndicatorIcons}
								>
									{audioVideoParams.audio ? (
										<MicNoneOutlined htmlColor="white" />
									) : (
										<MicOffOutlined htmlColor="white" />
									)}
								</IconButton>
								<IconButton
									disabled
									size="small"
									className={classes.statusIndicatorIcons}
								>
									{audioVideoParams.video ? (
										<VideocamOutlined htmlColor="white" />
									) : (
										<VideocamOffOutlined htmlColor="white" />
									)}
								</IconButton>
							</div>
						</div>
					</div>
				</div>
				<BottomBar
					onDisconnect={disconnectCall}
					setFullScreen={SetFullScreen}
					fullScreen={fullScreen}
				/>
				{/* <NewCallNotification /> */}
			</div>
		</div>
	);
};

export default CallScreen;
