import React, { createContext, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import Alert from "./Alert";
const initialAlertProps = {
	message: "",
	callback: null,
	header: "",
	buttonText: "",
	linkText: "",
	linkClick: null,
};
export const AlertContext = createContext({
	alertProps: null,
	showAlert: () => {},
	closeAlert: () => {},
});

export const AlertProvider = ({ children }) => {
	const [alertProps, setAlertProps] = useState(initialAlertProps);
	const showAlert = useCallback(
		(
			message,
			{ callback, header, buttonText, linkText, onLinkClick } = {}
		) => {
			setAlertProps({
				message,
				callback,
				header,
				buttonText,
				linkText,
				linkClick: onLinkClick,
			});
		},
		[]
	);

	const closeAlert = useCallback(() => {
		setAlertProps(initialAlertProps);
	}, []);
	const contextValue = { alertProps, showAlert, closeAlert };

	return (
		<AlertContext.Provider value={contextValue}>
			{children}
			{createPortal(
				<Alert
					{...{
						...alertProps,
						show: !!alertProps.message,
						hideAlert: closeAlert,
					}}
				/>,
				document.body
			)}
		</AlertContext.Provider>
	);
};

export default AlertProvider;
