import { Avatar, Popover } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import BlockDialog from "../../../Extras/BlockDialogBox/BlockDialog";
import styles from "./friendCard.module.css";
import OnlineBadge from "./../../../Extras/StyledBadge";
import ReportDialog from "../../../Extras/ReportDialogBox/ReportDialog";
import Username from "../../../Extras/Username";
import OptionsIcon from "../../../../Icons/Options";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import { useSelector } from "react-redux";

const FriendCard = ({
	friend,
	onUnfriend,
	onBlock,
	onReport,
	closePopup,
	isMobile,
	...props
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (closePopup) {
			handleClose();
		}
	}, [closePopup]);

	const open = Boolean(anchorEl);
	const popid = open ? "simple-popover" : undefined;
	const last_active = friend.friend.last_active;

	return (
		<>
			{!isMobile ? (
				<div className={styles.card}>
					<Link
						className={styles.profileLink}
						to={`/profile/${friend.friend.pk}`}
					>
						<OnlineBadge
							invisible={false}
							last_active={last_active}
							online={friend.friend.online}
							ripple={false}
						>
							<Avatar
								src={friend.friend.avatar}
								className={styles.avatar}
								style={{
									background:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
									color:
										theme === "dark"
											? "#0d0f22"
											: "#d9def4",
									border:
										theme === "dark"
											? "2px solid #d9def4"
											: "",
								}}
							>
								{friend.friend.username &&
									friend.friend.username[0].toUpperCase()}
							</Avatar>
						</OnlineBadge>
						{/* <p className={styles.cardText}>{friend.friend.full_name || friend.friend.username} </p> */}
						<p
							className={
								friend.friend.profession
									? styles.cardText1
									: styles.cardText
							}
						>
							<span
								style={{
									fontSize: "1rem",
									color:
										theme === "dark" ? "#d9def4" : "black",
								}}
							>
								<Username
									value={
										friend.friend.full_name ||
										friend.friend.username
									}
								/>{" "}
							</span>
							{friend.friend.profession && (
								<p
									style={{
										color: "var(--secondary)",
										padding: "0rem 0rem 0rem 0.2rem",
										fontSize: "0.8rem",
										margin: "0.5rem 0rem 0rem 0",
									}}
								>
									{friend.friend.profession &&
										friend.friend.profession}
								</p>
							)}{" "}
						</p>
					</Link>

					<div
						className={styles.deleteButton}
						onClick={() => onUnfriend(friend)}
					>
						<Button1>Remove</Button1>
					</div>

					<button onClick={handleClick} className={styles.popoverBtn}>
						<OptionsIcon />
					</button>
					<Popover
						id={popid}
						className={`${styles.pop} ${props.className}`}
						open={open}
						onClose={handleClose}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{ horizontal: "center" }}
						style={{ outline: "none" }}
					>
						<div className={styles.popover}>
							<BlockDialog
								blockStatus={"Unfriend"}
								data={friend}
								call={onUnfriend}
								onClose={handleClose}
								user={friend.friend.full_name}
							/>
							<BlockDialog
								blockStatus={"Block"}
								style={{
									fontSize: "0.88rem",
									fontWeight: "normal",
								}}
								data={friend}
								call={onBlock}
								onClose={handleClose}
								user={friend.friend.full_name}
							/>
							<ReportDialog
								blockStatus={"Report"}
								data={friend}
								call={onReport}
								onClose={() => handleClose()}
								user={friend.friend.full_name}
							/>
						</div>
					</Popover>
				</div>
			) : (
				<>
					<div className={styles.card}>
						<Link
							className={styles.profileLink}
							to={`/profile/${friend.friend.pk}`}
						>
							<OnlineBadge
								invisible={false}
								last_active={last_active}
								online={friend.friend.online}
								ripple={false}
							>
								<Avatar
									src={friend.friend.avatar}
									className={styles.avatar}
									style={{
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
										border:
											theme === "dark"
												? "2px solid #d9def4"
												: "",
									}}
								>
									{friend.friend.username &&
										friend.friend.username[0].toUpperCase()}
								</Avatar>
							</OnlineBadge>
							{/* <p className={styles.cardText}>{friend.friend.full_name || friend.friend.username} </p> */}
							<p
								className={
									friend.friend.profession
										? styles.cardText1
										: styles.cardText
								}
							>
								<span
									style={{
										fontSize: "1rem",
										color:
											theme === "dark"
												? "#d9def4"
												: "black",
									}}
								>
									<Username
										value={
											friend.friend.full_name ||
											friend.friend.username
										}
									/>{" "}
								</span>
								{friend.friend.profession && (
									<p
										style={{
											color: "var(--secondary)",
											padding: "0rem 0rem 0rem 0.2rem",
											fontSize: "0.8rem",
											margin: "0.5rem 0rem 0rem 0",
										}}
									>
										{friend.friend.profession &&
											friend.friend.profession}
									</p>
								)}{" "}
							</p>
						</Link>

						{/* <div
							className={styles.deleteButton}
							onClick={() => onUnfriend(friend)}
						>
							<img src="/Mobile/reject.svg" alt="reject" />
						</div> */}

						<button
							onClick={handleClick}
							className={styles.popoverBtn}
						>
							<MoreVert
								style={{
									color:
										theme === "dark"
											? "#7F89BE"
											: "#4A517E",
								}}
							/>
						</button>
						<Popover
							id={popid}
							className={`${styles.pop} ${props.className}`}
							open={open}
							onClose={handleClose}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{ horizontal: "center" }}
							style={{ outline: "none" }}
						>
							<div className={styles.popover}>
								<BlockDialog
									blockStatus={"Unfriend"}
									data={friend}
									call={onUnfriend}
									onClose={handleClose}
									user={friend.friend.full_name}
								/>
								<BlockDialog
									blockStatus={"Block"}
									style={{
										fontSize: "0.88rem",
										fontWeight: "normal",
									}}
									data={friend}
									call={onBlock}
									onClose={handleClose}
									user={friend.friend.full_name}
								/>
								<ReportDialog
									blockStatus={"Report"}
									data={friend}
									call={onReport}
									onClose={() => handleClose()}
									user={friend.friend.full_name}
								/>
							</div>
						</Popover>
					</div>
				</>
			)}
		</>
	);
};

export default FriendCard;
