export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";

export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_FETCH_ERROR = "NOTIFICATION_FETCH_ERROR";

export const PREVIEW_DISPLAYED = "NOTIFICATION_PREVIEW_DISPLAYED";

export const REMOVE_SINGLE_NOTIFICATION = "REMOVE_SINGLE_NOTIFICATION";
export const CLOSE_SINGLE_NOTIFICATION = "CLOSE_SINGLE_NOTIFICATION";
export const CLOSE_ALL_ACTIVE_NOTIFICATION = "CLOSE_ALL_ACTIVE_NOTIFICATION";

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const READ_NOTIFICATION = "READ_NOTIFICATION";

export const SET_NEW_NOTIFICATIONS_PRESENT = "SET_NEW_NOTIFICATIONS_PRESENT";

export const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT";

export const INCREMENT_NOTIFICATION_COUNT = "INCREMENT_NOTIFICATION_COUNT";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export const FETCH_CONTACT_REQUESTS = "FETCH_CONTACT_REQUESTS";
export const LOADING_CONTACT_REQUESTS = "LOADING_CONTACT_REQUESTS";
export const ERROR_CONTACT_REQUESTS = "ERROR_CONTACT_REQUESTS";
export const REMOVE_CONTACT_REQUESTS = "REMOVE_CONTACT_REQUESTS";
