import React from "react";

const GithubIcon = () => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 21.15C16.3125 21.15 14.85 19.8 14.85 18C14.85 16.3125 16.2 14.85 18 14.85C19.6875 14.85 21.15 16.2 21.15 18C21.15 19.6875 19.6875 21.15 18 21.15Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.825 10.35H14.175C13.275 10.4625 12.825 10.575 12.4875 10.6875C12.0375 10.8 11.7 11.025 11.3625 11.3625C11.0954 11.6296 10.9693 11.8966 10.8167 12.2194C10.7766 12.3045 10.7344 12.3937 10.6875 12.4875C10.6701 12.5397 10.65 12.5946 10.6285 12.6535C10.5108 12.975 10.35 13.4142 10.35 14.175V21.825C10.4625 22.725 10.575 23.175 10.6875 23.5125C10.8 23.9625 11.025 24.3 11.3625 24.6375C11.6296 24.9046 11.8966 25.0307 12.2194 25.1833C12.3046 25.2235 12.3936 25.2655 12.4875 25.3125C12.5397 25.3299 12.5946 25.35 12.6535 25.3715C12.975 25.4892 13.4142 25.65 14.175 25.65H21.825C22.725 25.5375 23.175 25.425 23.5125 25.3125C23.9625 25.2 24.3 24.975 24.6375 24.6375C24.9046 24.3704 25.0307 24.1034 25.1833 23.7806C25.2235 23.6955 25.2656 23.6064 25.3125 23.5125C25.3299 23.4603 25.35 23.4054 25.3715 23.3465C25.4892 23.025 25.65 22.5858 25.65 21.825V14.175C25.5375 13.275 25.425 12.825 25.3125 12.4875C25.2 12.0375 24.975 11.7 24.6375 11.3625C24.3704 11.0954 24.1034 10.9693 23.7806 10.8167C23.6956 10.7766 23.6063 10.7344 23.5125 10.6875C23.4603 10.6701 23.4054 10.65 23.3465 10.6285C23.025 10.5108 22.5858 10.35 21.825 10.35ZM18 13.1625C15.3 13.1625 13.1625 15.3 13.1625 18C13.1625 20.7 15.3 22.8375 18 22.8375C20.7 22.8375 22.8375 20.7 22.8375 18C22.8375 15.3 20.7 13.1625 18 13.1625ZM24.075 13.05C24.075 13.6713 23.5713 14.175 22.95 14.175C22.3287 14.175 21.825 13.6713 21.825 13.05C21.825 12.4287 22.3287 11.925 22.95 11.925C23.5713 11.925 24.075 12.4287 24.075 13.05Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 5.625C0 2.5184 2.5184 0 5.625 0H30.375C33.4816 0 36 2.5184 36 5.625V30.375C36 33.4816 33.4816 36 30.375 36H5.625C2.5184 36 0 33.4816 0 30.375V5.625ZM14.175 8.6625H21.825C22.8375 8.775 23.5125 8.8875 24.075 9.1125C24.75 9.45 25.2 9.675 25.7625 10.2375C26.325 10.8 26.6625 11.3625 26.8875 11.925C27.1125 12.4875 27.3375 13.1625 27.3375 14.175V21.825C27.225 22.8375 27.1125 23.5125 26.8875 24.075C26.55 24.75 26.325 25.2 25.7625 25.7625C25.2 26.325 24.6375 26.6625 24.075 26.8875C23.5125 27.1125 22.8375 27.3375 21.825 27.3375H14.175C13.1625 27.225 12.4875 27.1125 11.925 26.8875C11.25 26.55 10.8 26.325 10.2375 25.7625C9.675 25.2 9.3375 24.6375 9.1125 24.075C8.8875 23.5125 8.6625 22.8375 8.6625 21.825V14.175C8.775 13.1625 8.8875 12.4875 9.1125 11.925C9.45 11.25 9.675 10.8 10.2375 10.2375C10.8 9.675 11.3625 9.3375 11.925 9.1125C12.4875 8.8875 13.1625 8.6625 14.175 8.6625Z"
				fill="white"
			/>
			<rect width="36" height="36" rx="4" fill="#D9DEF4" />
			<path
				d="M21 26.9223V23.4223C21 22.4223 21.1 22.0223 20.5 21.4223C23.3 21.1223 26 20.0223 26 15.4223C25.9988 14.2273 25.5325 13.0796 24.7 12.2223C25.0905 11.1843 25.0545 10.0339 24.6 9.02231C24.6 9.02231 23.5 8.72231 21.1 10.3223C19.0672 9.7929 16.9328 9.7929 14.9 10.3223C12.5 8.72231 11.4 9.02231 11.4 9.02231C10.9455 10.0339 10.9095 11.1843 11.3 12.2223C10.4675 13.0796 10.0012 14.2273 10 15.4223C10 20.0223 12.7 21.1223 15.5 21.4223C14.9 22.0223 14.9 22.6223 15 23.4223V26.9223M15 24.9223C10.7 26.3223 10.7 22.4223 9 21.9223L15 24.9223Z"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default GithubIcon;
