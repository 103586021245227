import React from "react";

const LinkedinIcon = () => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 5.625C0 2.5184 2.5184 0 5.625 0H30.375C33.4816 0 36 2.5184 36 5.625V30.375C36 33.4816 33.4816 36 30.375 36H5.625C2.5184 36 0 33.4816 0 30.375V5.625ZM9.225 14.9625V27H13.05V14.9625H9.225ZM9 11.1375C9 12.375 9.9 13.275 11.1375 13.275C12.375 13.275 13.275 12.375 13.275 11.1375C13.275 9.9 12.375 9 11.1375 9C10.0125 9 9 9.9 9 11.1375ZM23.175 27H26.775V19.575C26.775 15.8625 24.525 14.625 22.3875 14.625C20.475 14.625 19.125 15.8625 18.7875 16.65V14.9625H15.1875V27H19.0125V20.5875C19.0125 18.9 20.1375 18 21.2625 18C22.3875 18 23.175 18.5625 23.175 20.475V27Z"
				fill="#D9DEF4"
			/>
		</svg>
	);
};

export default LinkedinIcon;
