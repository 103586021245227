import React, { useEffect, useState, useRef } from "react";
import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	makeStyles,
	Switch,
	Typography,
	withStyles,
	DialogTitle,
} from "@material-ui/core";
import DialogModal from "../../../Extras/DialogModal";
import CloseIcon from "../../../../Icons/CloseIcon";
import SidebarContextMenu from "../../Chats/Sidebar/SidebarContextMenu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Check, Close } from "@material-ui/icons";
import axiosConfig from "../../../../Utils/axiosConfig";
import {
	actionGroupRequest,
	editGroupURL,
	getGroupDetails,
	leaveTheGroupsURL,
	makeUserAdminURL,
	removeUserGroupURL,
	deleteGroupURL,
	joinGroupsURL,
} from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import Username from "../../../Extras/Username";
import OnlineBadge from "../../../Extras/StyledBadge";
import { useDispatch, useSelector } from "react-redux";
import { initalizeGroup } from "../../../../Redux/Actions/actionAuth";
import styles from "./GroupInfo.module.css";
import Button2 from "../../../Extras/Buttons/Button2/Button2";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import ChatUserReview from "../../../Extras/ChatUserReview/ChatUserReview";
import { ShareSocial } from "../../Chats/Extra/ShareURL/Share";
import LoveFace from "../../../../Icons/Emogi/LoveFace";
import { useHistory, useParams } from "react-router-dom";
import ClickPopover from "../../../Extras/ClickPopover";
import EditGroupModal from "./EditGroupModal";
import { setGroup as setGroupForDispatch } from "../../../../Redux/Actions/groupsAction";
import { updateMyGroups, updateMySingleGroup } from "../../../../Redux/Actions/myGroupsAction";

const StyledSwitch = withStyles((theme) => ({
	root: {
		width: 70,
		height: 44,
	},
	checked: {
		transform: "translateX(24px) !important",
		"& + $track": {
			backgroundColor: "transparent !important",
			opacity: "1 !important",
		},
	},
	switchBase: {
		left: 6,
		top: 6,
	},
	track: {
		backgroundColor: "transparent",
		border: "2px solid #ff5b5b",
		borderRadius: 22,
		opacity: 1,
	},
	thumb: {
		width: 14,
		height: 14,
		background: "linear-gradient(45deg, #FF5B5B 0%, #EA9898 100%)",
	},
}))(Switch);

const useStyles = makeStyles({
	cancel: {
		marginRight: "1rem",
		fontSize: "1rem",
		fontFamily: "Roboto",
		fontWeight: "400",
		color: "#fff",
		textTransform: "none",
		background: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		borderRadius: "1rem",
		border: "none",
	},
	share: {
		fontSize: "1rem",
		fontFamily: "Roboto",
		fontWeight: "400",
		textTransform: "none",
		background: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		backgroundImage: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		backgroundOrigin: "borderBox",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		backgroundClip: "border-box text",
		border: "1px solid transparent",
		borderImageSlice: "1",
		borderImage: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		mozborderimage: "-moz-linear-gradient(90deg, #FC466B, #3F5EFB)",
		WebKitBorderImage: "-webkit-linear-gradient(90deg, #FC466B, #3F5EFB)",
	},
	save: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		fontWeight: "400",
		marginLeft: "1rem",
	},
	shareIcon: {
		backgroundImage: "-webkit-linear-gradient(90deg, #FC466B, #3F5EFB)",

		/* Set the background size and repeat properties. */
		backgroundSize: "100%",
		backgroundRepeat: "repeat",

		/* Use the text as a mask for the background. */
		/* This will show the gradient as a text color rather than element bg. */
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		MozBackgroundClip: "text",
		MozTextFillColor: "transparent",
	},
});

const GroupInfoModal = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { showAlert } = useAlert();
	const [bio, setBio] = useState("");
	const [admin, setAdmin] = useState("");
	const [group, setGroup] = useState({});
	const [edit, setEdit] = useState(false);
	const [avatar, setAvatar] = useState("");
	const [grpName, setGrpname] = useState("");
	const [groupType, setGroupType] = useState("");
	const [member, setMembers] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const [UploadUrl, setUploadUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadImage, setuploadImage] = useState("");
	const [joined, setjoined] = useState(false);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [loading, setLoading] = useState(false);
	const isMobile = window.screen.width < 724;
	const theme = useSelector((state) => state.theme.theme);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const loggedinUserID = useSelector((state) => state.auth.user_data?.userID);
	const { id } = useParams();
	const groupID = id;

	useEffect(() => {
		if (props.show) {
			setIsLoading(true);
			getGroupInfo();
		}
	}, [props.show]);

	function onHide() {
		history.push("/");
	}

	const imgRef = useRef();

	const handleUploadImage = (event) => {
		setuploadImage(event.target.files[0]);
		setUploadUrl(window.URL.createObjectURL(event.target.files[0]));
	};

	const setImgRef = () => {
		if (!edit) return;
		imgRef.current.click();
	};

	const checkMemberGroup = (members) => {
		members.forEach((mem) => {
			if (mem.user?.userID === loggedinUserID) {
				setjoined(true);
			}
		});
	};

	const getGroupInfo = () => {
		const form = new FormData();
		form.append("groupId", groupID ? groupID : 1);
		axiosConfig
			.post(getGroupDetails, form)
			.then((res) => {
               
                setGroup(res.data.body || res.data.result);
				checkMemberGroup(res.data.body.member_list);
				setGroupType(res.data.body.group_details.type);
				if (res.data.body.member_list.length > 0) {
                    setAdmin(res.data.body.member_list[0].user.userID);
				}
				setGrpname(res.data.body.group_details?.name);
				setAvatar(res.data.body.group_details?.avatar);
				setBio(res.data.body.group_details?.bio);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert(err.response?.data?.body, {
					header: err.response?.data?.error,
					callback: () => onHide(),
					buttonText: "Close",
				});
				setGroup([]);
				setAdmin(false);
				setGrpname("");
				setAvatar("");
				setBio("");
			});
	};

	const EditGroup = () => {
		const form = new FormData();
		form.append("groupId", groupID);
		form.append("name", grpName);
		form.append("type", groupType === "private" ? "public" : "private");
		form.append("bio", bio);
		form.append("avatar", uploadImage);
		axiosConfig
			.patch(editGroupURL, form)
			.then((res) => {
				showAlert("Edit Group Successfully", {
					header: "Group Edited",
					buttonText: "Close",
				});
				setEdit(false);
				setVisible(false);
				setGroupType(groupType === "private" ? "public" : "private");
			})
			.catch((err) => {
				showAlert(JSON.stringify(err.response.data?.error), {
					header: err.response.data?.message,
					buttonText: "Close",
				});
			});
	};

	const leaveGroup = () => {
		const form = new FormData();
		form.append("groupId", groupID);
		axiosConfig
			.post(leaveTheGroupsURL, form)
			.then((res) => {
				if (res.data.status_code !== 200) return;
				dispatch(updateMyGroups(Number(groupID)));
				showAlert("Group left Successfully", {
					header: "Left Successfully",
					buttonText: "Close",
				});
				dispatch(initalizeGroup());
				onHide();
			})
			.catch((err) => {
				showAlert("Bad Request", {
					header: err.response?.data?.message[0],
					buttonText: "Close",
				});
			});
	};

	const joinGroup = () => {
		if (joined) {
			return 0;
		}

		const form = new FormData();
		form.append("groupId", groupID);
		axiosConfig
			.post(joinGroupsURL, form)
			.then((res) => {
				getGroupInfo();
				if (
					res.data.message ===
					"Request forwarded to admin or subadmin."
				) {
					showAlert("Request Sent", {
						header: res.data.message,
						buttonText: "Close",
					});
				} else {
					showAlert("Joined Group", {
						header: "Joined Group Successfully",
						buttonText: "Close",
					});
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					if (
						err.response.data.message &&
						err.response.data.message.length > 0 &&
						err.response.data.message[0] ===
							"Request already pending."
					) {
						showAlert(err.response.data.message[0], {
							header: "Request Pedning",
							buttonText: "Close",
						});
					}
				} else {
					showAlert("Bad Request", {
						header: err.response.data?.error,
						buttonText: "Close",
					});
				}
			});
	};

	const deleteGroup = () => {
		setLoading(true);
		axiosConfig
			.delete(deleteGroupURL + `${groupID}`)
			.then((res) => {
				dispatch(updateMyGroups(Number(groupID)));
				dispatch(
					setGroupForDispatch({ id: 1, name: "Global", avatar: "" })
				);
				setLoading(false);
				showAlert("Group Deleted", {
					header: "Deleted Successfully",
					buttonText: "Close",
				});

				if (groupDataFromStore.id === groupID) {
					dispatch(initalizeGroup());
				}
				onHide();
			})
			.catch((err) => {
				setLoading(false);
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};

	const acceptReject = (id, type) => {
		const form = new FormData();
		form.append("requestid", id);
		form.append("action", type);
		axiosConfig
			.post(actionGroupRequest, form)
			.then((res) => {
				showAlert("", {
					header: "Successfull",
					buttonText: "Close",
				});
				getGroupInfo();
			})
			.catch((err) => {
				showAlert(err.response?.data?.body, {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};
	const removeUser = (pk) => {
		const form = new FormData();
		form.append("groupmemId", pk);
        		form.append("groupmemId", pk);

		axiosConfig
			.post(removeUserGroupURL, form)
			.then((res) => {
                let members = group.member_list.filter(member => member.pk !==pk)
                setGroup({...group,member_list: members})
				showAlert("", {
					header: "User Removed Successfully",
					buttonText: "Close",
				});
			})
			.catch((err) => {
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};

	const makeUserAdmin = (pk) => {
		const form = new FormData();
		form.append("groupmemId", pk);
		axiosConfig
			.post(makeUserAdminURL, form)
			.then((res) => {
				getGroupInfo();
				showAlert("", {
					header: "User is made Admin",
					buttonText: "Close",
				});
			})
			.catch((err) => {
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};

	return (
		<DialogModal
			{...props}
			width={isMobile ? "lg" : "xs"}
			maxWidth="true"
			fullWidth={isMobile ? "true" : "false"}
			className={`${styles.root} ${
				theme === "dark" ? styles.rootDark : styles.rootLg
			}`}
			onHide={() => onHide()}
		>
			{edit ? (
				<>
					<div
						className={`${styles.head} ${
							theme === "dark"
								? styles.headingdark
								: styles.headinglg
						}`}
					>
						<h4>Edit Group</h4>
						<div className={styles.crossIcon} onClick={onHide}>
							<CloseIcon />
						</div>
					</div>
					<EditGroupModal
						data={group}
						cancel={() => setEdit(false)}
						getGroupInfo={getGroupInfo}
					/>
				</>
			) : (
				<>
					<div
						className={`${styles.head} ${
							theme === "dark"
								? styles.headingdark
								: styles.headinglg
						}`}
					>
						<h4 className="mb-0">Group Info</h4>
						<div className={styles.crossIcon} onClick={onHide}>
							<CloseIcon
								color={theme === "dark" ? "#7F89BE" : "#4A517E"}
							/>
						</div>
					</div>

					<div className={styles.container}>
						{isLoading ? (
							<div className={styles.loader}>
								<CircularProgress
									style={{
										color:
											theme === "dark"
												? "#7F89BE"
												: "#4A517E",
									}}
									className={styles.loaderSpinner}
								/>
							</div>
						) : (
							<div className={styles.infoWrapper}>
								<div className={styles.top}>
									{admin === loggedinUserID && (
										<span
											className={
												edit
													? styles.hide
													: styles.editText
											}
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "#0D0F22",
												cursor: "pointer",
											}}
											onClick={() => setEdit(true)}
										>
											Edit Group
										</span>
									)}

									<div className={styles.heading}>
										<Avatar
											style={{
												width: "5rem",
												height: "5rem",
												margin: "1rem 1.5rem 1rem 0",
												fontSize: "2rem",
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											src={
												uploadImage ? UploadUrl : avatar
											}
											onClick={setImgRef}
										>
											{grpName[0]?.toUpperCase()}
										</Avatar>

										{edit && (
											<input
												type="file"
												ref={imgRef}
												style={{ display: "none" }}
												onChange={(e) =>
													handleUploadImage(e)
												}
											/>
										)}

										<div className="d-flex justify-content-between w-50">
											<div>
												<Typography
													className={`${
														styles.grpname
													} ${
														theme === "dark"
															? styles.textdark
															: styles.textlg
													}`}
													style={{
														fontFamily: "Poppins",
														fontSize: "1.25rem",
													}}
												>
													{grpName}
												</Typography>

												<Typography
													className={`${
														styles.headMembers
													} ${
														theme === "dark"
															? styles.textdarkP
															: styles.textlgP
													}`}
													style={{
														fontFamily: "Poppins",
														fontSize: ".75rem",
													}}
												>
													{group.member_list?.length >
														0 &&
														`${
															group.member_list
																?.filter(member=>{if(member.status ==="active") return member}).length
														} ${
															group.member_list
																?.filter(member=>{if(member.status ==="active") return member}).length < 2
																? "Member"
																: "Members"
														}`}
												</Typography>
											</div>

											{group.group_details &&
												groupType === "private" && (
													<span
														className={
															styles.editPhoto
														}
													>
														<img
															style={{
																marginLeft:
																	".75rem",
															}}
															src="/images/Lock.png"
															alt=""
														/>
													</span>
												)}

											{admin === loggedinUserID && (
												<ClickPopover
													className={styles.popover}
													trigger={
														<img
															style={{
																marginLeft:
																	"1rem",
															}}
															src="/icons/options-dark.png"
															alt=""
														/>
													}
												>
													<div
														className={styles.btns}
														style={{
															background:
																theme === "dark"
																	? "#0d0f22"
																	: "#fff",
														}}
													>
														{visible ? (
															<>
																<p
																	className={
																		styles.popoverbtn
																	}
																	style={{
																		color:
																			theme ===
																			"dark"
																				? "#D9DEF4"
																				: "#4A517E",
																	}}
																>
																	Do you want
																	to make the
																	group{" "}
																	{group
																		.group_details
																		.type ===
																	"private"
																		? "public"
																		: "private"}
																	?
																</p>
																<div
																	className={
																		styles.popcheck
																	}
																>
																	<button
																		className={`${styles.popoverCap} ${styles.popoverbtn}`}
																		onClick={() => {
																			setVisible(
																				false
																			);
																		}}
																	>
																		No
																	</button>
																	<Button1
																		onClick={() => {
																			setVisible(
																				false
																			);

																			EditGroup();
																		}}
																	>
																		Yes,
																		make it{" "}
																		{groupType ===
																		"private"
																			? "public"
																			: "private"}
																	</Button1>
																</div>
															</>
														) : (
															<>
																<button
																	className={
																		styles.popoverbtn
																	}
																	style={{
																		color:
																			theme ===
																			"dark"
																				? "#D9DEF4"
																				: "#4A517E",
																	}}
																	onClick={() => {
																		setVisible(
																			true
																		);
																	}}
																>
																	<div className="d-flex">
																		<IconButton className="pl-0 pt-0">
																			<img
																				src="/images/eye-dark.png"
																				alt=""
																			/>
																		</IconButton>
																		<div className="d-flex flex-column align-items-start">
																			Change
																			visibility
																			<p
																				className={
																					styles.popoverCap
																				}
																			>
																				Make
																				the
																				groups
																				{group
																					.group_details
																					.type ===
																				"private"
																					? "public"
																					: "private"}
																			</p>
																		</div>
																	</div>
																</button>
																<button
																	className={
																		styles.popoverbtn
																	}
																	style={{
																		color:
																			theme ===
																			"dark"
																				? "#D9DEF4"
																				: "#4A517E",
																	}}
																	onClick={() => {
																		leaveGroup();
																	}}
																>
																	<IconButton className="pl-0">
																		<img
																			src="/exit.svg"
																			alt=""
																		/>
																	</IconButton>
																	<div>
																		Exit
																		Group
																	</div>
																</button>
															</>
														)}
													</div>
												</ClickPopover>
											)}
										</div>
									</div>

									<Typography
										className={`${styles.bio} ${
											theme === "dark"
												? styles.textdark
												: styles.textlg
										}`}
										style={{
											margin: "1rem 0",
											fontFamily: "Poppins",
										}}
										onClick={() => {
											if (groupType !== "global") {
												setEdit(true);
											}
										}}
									>
										{bio}
									</Typography>
								</div>

								<div className={styles.buttons}>
									{groupType !== "global" && (
										<>
											{joined === true ? (
												<>
													{admin ===
													loggedinUserID ? (
														<Button2
															color="primary"
															variant="outlined"
															onClick={() =>
																setDeleteConfirm(
																	true
																)
															}
															className={
																classes.cancel
															}
														>
															Delete Group
														</Button2>
													) : (
														<Button2
															color="primary"
															variant="outlined"
															onClick={leaveGroup}
															className={
																classes.cancel
															}
														>
															Leave Group
														</Button2>
													)}
												</>
											) : (
												<Button2
													color="primary"
													variant="outlined"
													onClick={joinGroup}
													className={classes.cancel}
												>
													Join Group
												</Button2>
											)}
										</>
									)}

									<div className={styles.button}>
										<Button1
											onClick={() => setIsOpen(true)}
										>
											<img
												style={{
													margin: "0 .5rem .35rem 0",
												}}
												src="/Home/shareIcon.png"
												alt="Share"
											/>
											<span>Share</span>
										</Button1>
									</div>
								</div>
								{groupType !== "global" && (
									<>
										{group?.member_list && (
											<>
												<span
													style={{
														display: "flex",
														flexDirection: "row",
														margin: "1rem 0 0 0",
													}}
													className={
														styles.membersHead
													}
												>
													<h6
														className={`${
															member
																? styles.selected
																: styles.switchText
														} ${
															theme === "dark"
																? styles.textdark
																: styles.textlg
														}`}
														onClick={() =>
															setMembers(true)
														}
													>
														{group?.member_list &&
														group?.member_list
															.length > 1
															? "Members"
															: "Member"}
														{member && (
															<div
																className={
																	styles.underline
																}
															></div>
														)}
													</h6>
													{admin ===
														loggedinUserID && (
														<h6
															className={`${
																!member
																	? styles.selected
																	: styles.switchText
															} ${
																theme === "dark"
																	? styles.textdark
																	: styles.textlg
															}`}
															onClick={() =>
																setMembers(
																	false
																)
															}
														>
															Requests
															{!member && (
																<div
																	className={
																		styles.underline
																	}
																></div>
															)}
														</h6>
													)}
												</span>

												<div
													className={
														theme === "dark"
															? styles.membersDk
															: styles.membersLg
													}
												>
													{member ? (
														<>
															{group?.member_list?.map(
																(grp) => {
																	return (
																		<div key={grp.pk}>
                                                                            {grp.status ==="active"&&<div
																			className={
																				styles.item
																			}
																			key={
																				grp.id
																			}
																		>
																			<ChatUserReview
																				id={
																					grp
																						.user
																						?.userID
																				}
																				data={{
																					...grp.user,
																					profileURL:
																						grp
																							.user
																							?.avatar,
																					from: grp
																						.user
																						?.userID,
																				}}
																				profileLink={`/profile/${grp.user?.userID}`}
																				user={
																					false
																				}
																				userProfile={
																					null
																				}
																				userLoading={
																					false
																				}
																			>
																				<OnlineBadge
																					invisible={
																						false
																					}
																					last_active={
																						grp
																							.user
																							?.last_active
																					}
																					online={
																						grp
																							.user
																							?.online
																					}
																					ripple={
																						true
																					}
																				>
																					<Avatar
																						className="mr-1"
																						src={
																							grp
																								.user
																								?.avatar
																						}
																						style={{
																							background:
																								theme ===
																								"dark"
																									? "#d9def4"
																									: "#0d0f22",
																							color:
																								theme ===
																								"dark"
																									? "#0d0f22"
																									: "#d9def4",
																						}}
																					>
																						{grp.user?.username[0]?.toUpperCase()}
																					</Avatar>
																				</OnlineBadge>
																			</ChatUserReview>

																			<span
																				className={`${
																					styles.grpname
																				} ${
																					theme ===
																					"dark"
																						? styles.textdark
																						: styles.textlg
																				}`}
																			>
																				<Username
																					value={
																						grp
																							.user
																							?.username
																					}
																				/>
																			</span>

																			{(group
																				.group_details
																				.admin ===
																				grp.pk || grp.is_subadmin) && (
																				<span
																					className={`${
																						styles.adminTag
																					} ${
																						theme ===
																						"dark"
																							? styles.tagdark
																							: styles.taglg
																					}`}
																				>
                                                                                    {grp.is_subadmin ? "SUB-ADMIN": "ADMIN"}
																					
																				</span>
																			)}
																			<span>
																				{(group.group_details.admin !==grp.pk && grp.status ==="active") &&  (
																					<SidebarContextMenu
																						className={
																							styles.hoverBtn
																						}
																						transformOrigin={{
																							vertical:
																								"top",
																							horizontal:
																								"right",
																						}}
																						icon={(
																							props
																						) => (
																							<MoreVertIcon
                                            													style={{ color :theme === "dark"? "#e5e7eb" : "#151515"}}

																							/>
																						)}
																					>
																						<Button
																							className={
																								styles.deleteBtn
																							}
																							style={{
																								fontSize:
																									"0.9rem",
																								textTransform:
																									"none",
                                                                                                color :theme === "dark"? "#e5e7eb" : "#151515"
																							}}
																							onClick={() =>
																								removeUser(
																									grp.pk
																								)
																							}
																						>
																							Remove
																						</Button>
																						<Button
																							className={
																								styles.deleteBtn
																							}
																							style={{
																								fontSize:
																									"0.9rem",
																								textTransform:
																									"none",
                                                                                                color :theme === "dark"? "#e5e7eb" : "#151515"
																							}}
																							onClick={() =>
																								makeUserAdmin(
																									grp.pk
																								)
																							}
																						>
																							 {grp.is_subadmin ?  "remove sub-admin" : "make admin"}
																						</Button>
																					</SidebarContextMenu>
																				)}
																			</span>
																		</div>}
                                                                        </div>
																	);
																}
															)}
														</>
													) : (
														admin ===
															loggedinUserID && (
															<>
																{group?.requests
																	?.length >
																0 ? (
																	group.requests?.map(
																		(
																			grp
																		) => {
																			return (
																				<div
																					className={
																						styles.item
																					}
																					key={
																						grp.pk
																					}
																				>
																					<ChatUserReview
																						id={
																							grp
																								.sender
																								?.userID
																						}
																						data={{
																							...grp.sender,
																							profileURL:
																								grp
																									.sender
																									?.avatar,
																							from: grp
																								.sender
																								?.userID,
																						}}
																						profileLink={`/profile/${grp.sender?.userID}`}
																						user={
																							false
																						}
																						userProfile={
																							null
																						}
																						userLoading={
																							false
																						}
																					>
																						<OnlineBadge
																							invisible={
																								false
																							}
																							last_active={
																								grp
																									.sender
																									?.last_active
																							}
																							online={
																								grp
																									.sender
																									?.online
																							}
																							ripple={
																								true
																							}
																						>
																							<Avatar
																								className="mr-1"
																								src={
																									grp
																										.sender
																										?.avatar
																								}
																								style={{
																									background:
																										theme ===
																										"dark"
																											? "#d9def4"
																											: "#0d0f22",
																									color:
																										theme ===
																										"dark"
																											? "#0d0f22"
																											: "#d9def4",
																								}}
																							>
																								{grp.sender?.username[0]?.toUpperCase()}
																							</Avatar>
																						</OnlineBadge>
																					</ChatUserReview>
																					<span
																						className={
																							styles.grpname
																						}
																						style={{
																							color:
																								theme ===
																								"dark"
																									? "#d9def4"
																									: "#0d0f22",
																						}}
																					>
																						<Username
																							value={
																								grp
																									.sender
																									?.username
																							}
																						/>
																					</span>
																					<span className="ml-auto">
																						<div
																							className={
																								styles.actions
																							}
																						>
																							<Button2
																								onClick={() =>
																									acceptReject(
																										grp.pk,
																										"accepted"
																									)
																								}
																							>
																								<Check />
																							</Button2>
																							&nbsp;
																							<Button1
																								onClick={() =>
																									acceptReject(
																										grp.pk,
																										"blocked"
																									)
																								}
																								style={{
																									marginLeft:
																										"0.4rem",
																								}}
																							>
																								<Close
																									style={{
																										color:
																											theme ===
																											"dark"
																												? "#d9def4"
																												: "#0d0f22",
																									}}
																								/>
																							</Button1>
																						</div>
																					</span>
																				</div>
																			);
																		}
																	)
																) : (
																	<p
																		style={{
																			margin: "0 auto 1rem auto",
																			fontSize:
																				".75rem",
																			color:
																				theme ===
																				"dark"
																					? "#7F89BE"
																					: "#4A517E",
																		}}
																	>
																		{" "}
																		No
																		Requests
																		Pending
																	</p>
																)}
															</>
														)
													)}
												</div>
											</>
										)}
									</>
								)}
							</div>
						)}
					</div>
				</>
			)}

			<Dialog
				open={deleteConfirm}
				onClose={() => setDeleteConfirm(false)}
				classes={{
					paper: `${styles.dialog} ${
						theme === "dark" ? styles.rootDark : styles.rootLg
					}`,
				}}
			>
				<Box sx={{ p: 4 }}>
					<div className={styles.header}>
						<h2
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							Delete Conformation!
						</h2>
						<IconButton
							onClick={() => setDeleteConfirm(false)}
							size="small"
							className={styles.shareClose}
						>
							<CloseIcon
								color={theme === "dark" ? "#7F89BE" : "#4A517E"}
							/>
						</IconButton>
					</div>
					<DialogTitle
						className={`${
							theme === "dark" ? styles.textdark : styles.textlg
						}`}
					>
						Are you sure you want to delete?
					</DialogTitle>
					<div className="d-flex items-center">
						<Box sx={{ mx: 1 }}>
							<div className={styles.button}>
								<Button1
									onClick={() => setDeleteConfirm(false)}
								>
									<span>Cancel</span>
								</Button1>
							</div>
						</Box>

						<Box sx={{ mx: 1 }}>
							<Button2
								color="primary"
								variant="outlined"
								onClick={() => deleteGroup()}
								className={classes.cancel}
							>
								{loading ? "loading..." : "Delete Group"}
							</Button2>
						</Box>
					</div>
				</Box>
			</Dialog>

			<Dialog
				open={isOpen}
				aria-labelledby="share-dialog"
				onClose={() => setIsOpen(!isOpen)}
				classes={{
					paper: `${styles.dialog} ${
						theme === "dark" ? styles.rootDark : styles.rootLg
					}`,
				}}
			>
				<div className={styles.header}>
					<h2
						className={`${
							theme === "dark" ? styles.textdark : styles.textlg
						}`}
					>
						Share Bditto
					</h2>
					<IconButton
						onClick={() => setIsOpen(!isOpen)}
						size="small"
						className={styles.shareClose}
					>
						<CloseIcon
							color={theme === "dark" ? "#7F89BE" : "#4A517E"}
						/>
					</IconButton>
				</div>

				<div>
					<div className={styles.textBox}>
						<LoveFace />
						<p
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							Recommendation: <br />
							We are competing with leading companies & to stand
							out, we need to focus on fantastic design with an
							engaging copy.
						</p>
					</div>

					<div className={styles.textBox}>
						<LoveFace />
						<p
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							You can invite anyone to participate in Bditto and
							have fun sharing thoughts & ideas.
						</p>
					</div>

					<div className={styles.textBox}>
						<LoveFace />
						<p
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							Finding people who you know or finding people with
							similar interests is a lot easier on bditto.
						</p>
					</div>
				</div>

				<div>
					<ShareSocial
						className={styles.ShareSocial}
						url={window.location.href}
						iconSize={40}
					/>
				</div>
			</Dialog>
		</DialogModal>
	);
};

export default GroupInfoModal;
