import React, { useState } from "react";
import { connect, useSelector } from "react-redux";

//Utils
import { emitDeleteMessage } from "../../../Utils/socket";
import {
	MESSAGE_STATUS,
	reportMessageURL,
	starMessageURL,
} from "../../../Utils/Constants";
import axiosChatConfig from "../../../Utils/axiosChatConfig";

//Material UI
import { IconButton, makeStyles, Divider } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useSnackbar } from "notistack";

//Components
import SidebarContextMenu from "../../Home/Chats/Sidebar/SidebarContextMenu";
import useAlert from "./../Alert/useAlert";
import { Trash, Save, Report } from "../../../Icons";
import ClipBoardtext from "../../../Icons/ClipBoardtext";
import ForwardMessage from "../../../Icons/forwardMessage";

import styles from "./ReplyOptions.module.css";

const useStyles = makeStyles({
	list: { flexDirection: "column", display: "column", padding: "16px" },
});

function ReplyOptions(props) {
	const { showAlert } = useAlert();
	const { enqueueSnackbar } = useSnackbar();

	const [sending, setSending] = useState(false);

	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const classes = useStyles();

	const reportMessage = (id, groupId) => {
		setSending(true);
		const data = {
			conversationID: groupId,
			isGroup: props.isGroup,
			messageID: id,
		};
		axiosChatConfig
			.post(reportMessageURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setSending(false);
				enqueueSnackbar("Message Reported", { variant: "success" });
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const deleteMessage = (messageID) => {
		const { isGroup } = props;
		const conversationID = props.data.conversationID;
		// console.log("ada1", props);
		// console.log(
		// 	"ada",
		// 	messageID,
		// 	conversationID,
		// 	isGroup
		// 	// props.listenDeletes
		// );
		emitDeleteMessage(
			messageID,
			conversationID,
			isGroup,
			props.listenDeletes
		);
	};

	const starMessage = (id, groupId) => {
		setSending(true);
		const data = {
			itemID: groupId && groupId,
			messageID: id,
			isPrivate: !props.isGroup,
		};
		axiosChatConfig
			.post(starMessageURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setSending(false);
				enqueueSnackbar("Message Starred", {
					variant: "success",
				});
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const handleCopyCommand = () => {
		navigator.clipboard.writeText(props.data.message);
	};

	return (
		<>
			<SidebarContextMenu
				anchorOrigin={
					props.userID === Number(props.data.from)
						? { vertical: "top", horizontal: "left" }
						: { vertical: "bottom", horizontal: "right" }
				}
				listClass={classes.list}
				transformOrigin={
					props.userID !== Number(props.data.from)
						? { horizontal: "left", vertical: "top" }
						: { horizontal: "right", vertical: "top" }
				}
				className={props.className}
				trigger={
					<IconButton size="small">
						<MoreVert
							fontSize="medium"
							style={{
								margin: "0 2px",
								color: theme === "dark" ? "#D9DEF4" : "#0D0F22",
							}}
						/>
					</IconButton>
				}
				applyChatOptionsColor
				listItemStyle={{ padding: "0 0.3rem" }}
			>
				{({ onClose }) => {
					return [
						props.data.status !== MESSAGE_STATUS.PENDING &&
							props.userID !== Number(props.data.from) && (
								<div
									className={styles.show_save_list}
									onClick={() => {
										onClose();
										starMessage(
											props.data._id,
											props.isGroup
												? props.data.groupID
												: props.data.conversationID
										);
									}}
								>
									<div>
										<IconButton
											className={styles.option}
											size="small"
											disabled={sending}
										>
											<Save theme={theme} />
										</IconButton>
									</div>
									<div
										className={styles.save_label_container}
									>
										<div
											className={styles.save_label}
											style={{
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#0D0F22",
											}}
										>
											Save
										</div>
										<div
											className={styles.add_label}
											style={{
												color:
													theme === "dark"
														? "#7F89BE"
														: "#4A517E",
											}}
										>
											Add to saved messages
										</div>
									</div>
								</div>
							),
						props.data.status !== MESSAGE_STATUS.PENDING &&
							props.userID !== Number(props.data.from) && (
								<>
									<div
										className={styles.show_report_list}
										onClick={() => {
											onClose();
											reportMessage(
												props.data._id,
												props.data.conversationID
											);
										}}
									>
										<div>
											<IconButton
												className={styles.option}
												disabled={sending}
												size="small"
											>
												<Report
													style={{
														fontSize: "1.2rem",
													}}
													theme={theme}
												/>
											</IconButton>
										</div>
										<div
											className={
												styles.report_label_container
											}
										>
											<div
												className={styles.report_label}
												style={{
													color:
														theme === "dark"
															? "#D9DEF4"
															: "#0D0F22",
												}}
											>
												Report
											</div>
											<div
												className={styles.mark_label}
												style={{
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											>
												Mark this message as invalid
											</div>
										</div>
									</div>
								</>
							),

						props.data.status !== MESSAGE_STATUS.PENDING &&
							props.userID !== Number(props.data.from) && (
								<>
									<Divider
										light={true}
										className={styles.divHr}
										style={{
											backgroundColor:
												theme === "dark"
													? "#7F89BE"
													: "#4A517E",
										}}
									/>
								</>
							),

						props.data.status !== MESSAGE_STATUS.PENDING && (
							<div
								className={styles.show_user_list}
								onClick={() => {
									onClose();
									props.setReply(props.data);
								}}
							>
								<div>
									<IconButton
										size="small"
										className={styles.option}
										disabled={sending}
									>
										<svg
											width="25"
											height="25"
											viewBox="0 0 27 27"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1 5L5 9V1L1 5ZM1 5H12C13.0609 5 14.0783 5.42143 14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13H11"
												stroke={
													theme === "dark"
														? "#D9DEF4"
														: "#0D0F22"
												}
												strokeWidth="1.2"
												strokeLinecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</IconButton>
								</div>
								<div
									className={styles.report_label}
									style={{
										marginBottom: ".2rem",
										color:
											theme === "dark"
												? "#D9DEF4"
												: "#0D0F22",
									}}
								>
									Reply
								</div>
							</div>
						),
						props.data.status !== MESSAGE_STATUS.PENDING && (
							<div
								className={styles.show_user_list}
								onClick={() => {
									onClose();
									handleCopyCommand();
								}}
							>
								<div>
									<IconButton
										className={styles.option}
										disabled={sending}
										size="small"
									>
										<ClipBoardtext theme={theme} />
									</IconButton>
								</div>
								<div
									className={styles.report_label}
									style={{
										marginBottom: ".2rem",
										color:
											theme === "dark"
												? "#D9DEF4"
												: "#0D0F22",
									}}
								>
									Copy
								</div>
							</div>
						),

						props.data.status !== MESSAGE_STATUS.PENDING && (
							<div
								className={styles.show_user_list}
								onClick={() => {
									onClose();
									props.forwardMessage();
								}}
							>
								<div>
									<IconButton
										size="small"
										className={styles.option}
										disabled={sending}
									>
										<ForwardMessage theme={theme} />
									</IconButton>
								</div>
								<div
									className={styles.report_label}
									style={{
										marginBottom: ".2rem",
										color:
											theme === "dark"
												? "#D9DEF4"
												: "#0D0F22",
									}}
								>
									Forward
								</div>
							</div>
						),
						props.userID === Number(props.data.from) &&
							props.data.status !== MESSAGE_STATUS.PENDING && (
								<div
									className={styles.show_user_list}
									onClick={() => {
										onClose();
										deleteMessage(props.data._id);
									}}
								>
									<div>
										<IconButton
											className={styles.option}
											disabled={sending}
											size="small"
										>
											<Trash theme={theme} />
										</IconButton>
									</div>
									<div
										className={styles.report_label}
										style={{
											marginBottom: ".2rem",
											color:
												theme === "dark"
													? "#D9DEF4"
													: "#0D0F22",
										}}
									>
										Delete
									</div>
								</div>
							),
					];
				}}
			</SidebarContextMenu>
		</>
	);
}

const mapStateToProps = ({ auth }) => ({ userID: auth?.user_data?.userID });

export default connect(mapStateToProps, null)(ReplyOptions);
