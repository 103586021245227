import { format } from "@flasd/whatsapp-formatting";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import isThisYear from "date-fns/isThisYear";
import formatDate from "date-fns/format";

const urlRegex =
	/(http(s)?:\/\/)?([\w-]+\.)+[A-Za-z-]+([/:][\w- ;,./?%&=+]*)?/gi;
const userRefRegex = /(<User pk=\{([0-9]+)\}>(@[a-zA-Z0-9]+)<\/User>)/g;

export const TextFormatter =
	({ classes = {} }) =>
	({ classes: propClasses = {}, text, currentUserID }) => {
		const match = text.matchAll(urlRegex);
		const userMentions = Array.from(text.matchAll(userRefRegex));
		if (match) {
			const groups = Array.from(match)[0];
			const httpsPresent = groups && !!groups[1];
			let i = 0;
			const message = text.replace(userRefRegex, function (user) {
				const redirectLink =
					Number(currentUserID) !== Number(userMentions[i][2])
						? `/profile/${userMentions[i][2]}`
						: "/myprofile";
				const replaceText = format(
					`<a class=${classes.link}  href='${redirectLink}' > ${userMentions[i][3]}</a>`
				);
				i++;
				return replaceText;
			});
			return message.replace(urlRegex, function (url) {
				return format(
					`<a class=${classes.link} target='_blank' href= ${
						(!httpsPresent ? "https://" : "") + url
					} > ${url}</a>`
				);
			});
		}
		return format(text);
	};

export const urlExtractor = (text) => (text ? text.match(urlRegex) : null);

export const MessageTimeStamp = ({ timestamp }) => {
	let date = new Date(timestamp);
	if (isToday(date)) {
		date = formatDate(date, "'Today' hh:mm  aaa");
	} else if (isYesterday(date)) {
		date = formatDate(date, "'Yesterday' hh:mm aaa");
	} else if (isThisYear(date)) {
		date = formatDate(date, "do MMM hh:mm aaa");
	} else {
		date = formatDate(date, "do MMM yy hh:mm aaa");
	}
	return date + " ";
};

export const MsgTime = ({ timestamp }) => {
	let date = new Date(timestamp);
	if (isToday(date)) {
		date = formatDate(date, "hh:mm  aaa");
	} else if (isYesterday(date)) {
		date = formatDate(date, "hh:mm aaa");
	} else if (isThisYear(date)) {
		date = formatDate(date, "do MMM hh:mm aaa");
	} else {
		date = formatDate(date, "Do MMM'yy hh:mm aaa");
	}
	return date + " ";
};
