import React from "react";
import EmptyState from "../Extras/EmptyState";

const Error400Page = () => (
	<div
		style={{
			height: "100vh",
		}}
	>
		<EmptyState
			heading="Something went wrong!!"
			className="errorPage"
			imageUrl="/vectors/empty-states/somethingWrong.svg"
			subHeading="We’re trying to fix the problem, it might take a few seconds..."
			alt
		/>
	</div>
);
export default Error400Page;
