import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Fab,
	Grow,
	IconButton,
	Slide,
} from "@material-ui/core";
import { Mic, Pause, PlayArrow, Stop } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Close } from "../../../../../Icons";
import Button1 from "../../../../Extras/Buttons/Button1/Button1";
import DialogModal from "../../../../Extras/DialogModal";
import styles from "./voiceDialog.module.scss";

const AUDIO_PERMISSIONS = { audio: true };
let mediaRecorder;
let dataArray = [];

const VoiceDialog = ({ open, onClose, onSend, ...props }) => {
	const audioRecRef = useRef();
	const audioPlayRef = useRef();
	const mediaStream = useRef(null);
	const [isRecording, setIsRecording] = useState(false);
	const [isLoaded, setisLoaded] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [time, setTime] = useState(0);
	const intervalHandler = useRef();
	const [recordedFile, setRecordedFile] = useState(null);
	const theme = useSelector(state => state.theme.theme);

	const setUpAudioRecorder = async () => {
		try {
			const mediaStreamObj = await navigator.mediaDevices.getUserMedia(
				AUDIO_PERMISSIONS
			);
			mediaStream.current = mediaStreamObj;
			mediaRecorder = new MediaRecorder(mediaStreamObj);
			mediaRecorder.ondataavailable = function (ev) {
				dataArray.push(ev.data);
			};

			audioPlayRef.current.addEventListener("ended", () => {
				setIsPlaying(false);
			});

			mediaRecorder.onstop = function (ev) {
				let audioData = new Blob(dataArray, { type: "audio/mp3;" });
				dataArray = [];
				let audioSrc = window.URL.createObjectURL(audioData);
				setRecordedFile(audioData);
				audioPlayRef.current.src = audioSrc;
			};

			if ("srcObject" in audioRecRef.current) {
				audioRecRef.current.srcObject = mediaStreamObj;
			} else {
				audioRecRef.current.src =
					window.URL.createObjectURL(mediaStreamObj);
			}
			setisLoaded(true);
		} catch (err) {
			console.log(err)
		}
	};

	useEffect(() => {
		if (open) {
			setUpAudioRecorder();
			setRecordedFile(null);
		} else {
			mediaRecorder = null;
			if (mediaStream.current) {
				mediaStream.current
					.getTracks()
					.forEach((track) => track.stop());
			}
		}
	}, [open]);
	const startRecording = () => {
		if (isLoaded) {
			setTime(0);
			intervalHandler.current = setInterval(() => {
				setTime((time) => time + 1);
			}, 1000);
			setRecordedFile(null);
			setIsRecording(true);
			mediaRecorder.start();
		}
	};
	const stopRecording = () => {
		setIsRecording(false);
		clearInterval(intervalHandler.current);
		mediaRecorder.stop();
	};

	const playAudio = () => {
		setIsPlaying(true);
		audioPlayRef.current.play();
	};
	const pauseAudio = () => {
		setIsPlaying(false);
		audioPlayRef.current.pause();
	};
	const stopAudio = () => {
		setIsPlaying(false);
		audioPlayRef.current.pause();
		audioPlayRef.current.currentTime = 0;
	};

	const sendAudio = () => {
		onSend(recordedFile);
		onClose();
	};

	const closeModal = () => {
		onClose();
		if (isRecording) {
			stopRecording();
		}
		if (recordedFile) {
			setRecordedFile(null);
		}
	};

	return (
		<>
			{/* <IconButton className={styles.closeButton} onClick={closeModal}>
				<Close.Outline />
			</IconButton>
			<p className={styles.title}>
				Send Voice Message
			</p> */}

			<audio hidden ref={audioRecRef}></audio>
			<audio hidden ref={audioPlayRef} controls></audio>
			<div className={styles.content}>
				<div className={styles.recorder}>
					
					<div className={styles.recorderBtn}>
						{recordedFile === null && (
							 !isRecording ? (
								<div
									disabled={!isLoaded || isRecording}
									onClick={startRecording}
								>
									<Mic 
										style={{
											cursor: "pointer",
											fontSize: "2rem",
											color: theme === "dark" ? "#D9DEF4": "#0D0F22",
										}}
									/>
								</div>
							) : (
								<div onClick={stopRecording}>
									<Stop 
										style={{
											cursor: "pointer",
											fontSize: "2rem",
											color: theme === "dark" ? "#D9DEF4": "#0D0F22",
										}}
									/>
								</div>
							)
						)}
					</div>
					<div className={styles.actions}></div>
				</div>

				{recordedFile !== null && (
					<>
						<div className={styles.player}>
							{!isPlaying ? (
								<IconButton onClick={playAudio}>
									<PlayArrow
										style={{
											cursor: "pointer",
											fontSize: "2rem",
											color: theme === "dark" ? "#D9DEF4": "#0D0F22",
										}} 
									/>
								</IconButton>
							) : (
								<IconButton onClick={stopAudio}>
									<Stop
										style={{
											cursor: "pointer",
											fontSize: "2rem",
											color: theme === "dark" ? "#D9DEF4": "#0D0F22",
										}}
									/>
								</IconButton>
							)}
						</div>

						<div style={{ margin: "0 auto" }}>
							<ul class="ul">
								<li class={`li ${isPlaying ? "li-1" : "li-stop-1"}`}></li>
								<li class={`li ${isPlaying ? "li-2" : "li-stop-2"}`}></li>
								<li class={`li ${isPlaying ? "li-3" : "li-stop-3"}`}></li>
								<li class={`li ${isPlaying ? "li-4" : "li-stop-4"}`}></li>
								<li class={`li ${isPlaying ? "li-5" : "li-stop-5"}`}></li>
								<li class={`li ${isPlaying ? "li-6" : "li-stop-6"}`}></li>
								<li class={`li ${isPlaying ? "li-7" : "li-stop-7"}`}></li>
								<li class={`li ${isPlaying ? "li-8" : "li-stop-8"}`}></li>
								<li class={`li ${isPlaying ? "li-9" : "li-stop-9"}`}></li>
								<li class={`li ${isPlaying ? "li-10" : "li-stop-10"}`}></li>
								<li class={`li ${isPlaying ? "li-11" : "li-stop-11"}`}></li>
								<li class={`li ${isPlaying ? "li-12" : "li-stop-12"}`}></li>
								<li class={`li ${isPlaying ? "li-13" : "li-stop-13"}`}></li>
								<li class={`li ${isPlaying ? "li-14" : "li-stop-14"}`}></li>
								<li class={`li ${isPlaying ? "li-15" : "li-stop-15"}`}></li>
								<li class={`li ${isPlaying ? "li-16" : "li-stop-16"}`}></li>
								<li class={`li ${isPlaying ? "li-17" : "li-stop-17"}`}></li>
								<li class={`li ${isPlaying ? "li-18" : "li-stop-18"}`}></li>
								<li class={`li ${isPlaying ? "li-19" : "li-stop-19"}`}></li>
								<li class={`li ${isPlaying ? "li-20" : "li-stop-20"}`}></li>
								<li class={`li ${isPlaying ? "li-21" : "li-stop-21"}`}></li>
								<li class={`li ${isPlaying ? "li-22" : "li-stop-22"}`}></li>
								<li class={`li ${isPlaying ? "li-23" : "li-stop-23"}`}></li>
								<li class={`li ${isPlaying ? "li-24" : "li-stop-24"}`}></li>
								<li class={`li ${isPlaying ? "li-25" : "li-stop-25"}`}></li>
								<li class={`li ${isPlaying ? "li-26" : "li-stop-26"}`}></li>
								<li class={`li ${isPlaying ? "li-27" : "li-stop-27"}`}></li>
								<li class={`li ${isPlaying ? "li-28" : "li-stop-28"}`}></li>
								<li class={`li ${isPlaying ? "li-29" : "li-stop-29"}`}></li>
								<li class={`li ${isPlaying ? "li-30" : "li-stop-30"}`}></li>
								<li class={`li ${isPlaying ? "li-31" : "li-stop-31"}`}></li>
								<li class={`li ${isPlaying ? "li-32" : "li-stop-32"}`}></li>
								<li class={`li ${isPlaying ? "li-33" : "li-stop-33"}`}></li>
								<li class={`li ${isPlaying ? "li-34" : "li-stop-34"}`}></li>
								<li class={`li ${isPlaying ? "li-35" : "li-stop-35"}`}></li>
								<li class={`li ${isPlaying ? "li-36" : "li-stop-36"}`}></li>
								<li class={`li ${isPlaying ? "li-37" : "li-stop-37"}`}></li>
								<li class={`li ${isPlaying ? "li-38" : "li-stop-38"}`}></li>
								<li class={`li ${isPlaying ? "li-39" : "li-stop-39"}`}></li>
								<li class={`li ${isPlaying ? "li-40" : "li-stop-40"}`}></li>
								<li class={`li ${isPlaying ? "li-41" : "li-stop-41"}`}></li>
								<li class={`li ${isPlaying ? "li-42" : "li-stop-42"}`}></li>
								<li class={`li ${isPlaying ? "li-43" : "li-stop-43"}`}></li>
								<li class={`li ${isPlaying ? "li-44" : "li-stop-44"}`}></li>
								<li class={`li ${isPlaying ? "li-45" : "li-stop-45"}`}></li>
								<li class={`li ${isPlaying ? "li-46" : "li-stop-46"}`}></li>
								<li class={`li ${isPlaying ? "li-47" : "li-stop-47"}`}></li>
								<li class={`li ${isPlaying ? "li-48" : "li-stop-48"}`}></li>
								<li class={`li ${isPlaying ? "li-49" : "li-stop-49"}`}></li>
								<li class={`li ${isPlaying ? "li-50" : "li-stop-50"}`}></li>

							</ul>
						</div>
					</>
				)}
				
				{isRecording && (
					<div className={styles.timer}>
						<span style={{ color: theme === "dark" ? "#d9def4" : "#0d0f222" }}>
							{new Date(time * 1000)
								.toISOString()
								.substr(14, 5)}
						</span>
					</div>
				)}
				{recordedFile === null && (
					isRecording ? (
						<div style={{ marginRight: "2rem"}}>
							<ul class="ul">
								<li class={`li li-1`}></li><li class={`li li-2`}></li>
								<li class={`li li-3`}></li><li class={`li li-4`}></li><li class={`li li-5`}></li><li class={`li li-6`}></li>
								<li class={`li li-7`}></li><li class={`li li-8`}></li><li class={`li li-9`}></li><li class={`li li-10`}></li>
								<li class={`li li-11`}></li><li class={`li li-12`}></li><li class={`li li-13`}></li><li class={`li li-14`}></li>
								<li class={`li li-15`}></li><li class={`li li-16`}></li><li class={`li li-17`}></li><li class={`li li-18`}></li>
								<li class={`li li-19`}></li><li class={`li li-20`}></li><li class={`li li-21`}></li><li class={`li li-22`}></li>
								<li class={`li li-23`}></li><li class={`li li-24`}></li><li class={`li li-25`}></li><li class={`li li-26`}></li>
								<li class={`li li-27`}></li><li class={`li li-28`}></li><li class={`li li-29`}></li><li class={`li li-30`}></li>
								<li class={`li li-31`}></li><li class={`li li-32`}></li><li class={`li li-33`}></li><li class={`li li-34`}></li>
								<li class={`li li-35`}></li><li class={`li li-36`}></li><li class={`li li-37`}></li><li class={`li li-38`}></li>
								<li class={`li li-39`}></li><li class={`li li-40`}></li><li class={`li li-41`}></li><li class={`li li-42`}></li>
								<li class={`li li-43`}></li><li class={`li li-44`}></li><li class={`li li-45`}></li><li class={`li li-46`}></li>
								<li class={`li li-47`}></li><li class={`li li-48`}></li><li class={`li li-49`}></li><li class={`li li-50`}></li>
							</ul>
						</div>
					) : (
						<div style={{ margin: "0 auto"}}>
							<ul class="ul">
								<li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
								<li class={`li`}></li><li class={`li`}></li><li class={`li`}></li><li class={`li`}></li>
							</ul>
						</div>
					)
				)}

				<div className={styles.buttonContainer}>
					<Button1
						disabled={!recordedFile}
						onClick={sendAudio}
						fullWidth={true}
						className={styles.button}
						color="primary"
						variant="contained"
					>
						Send
					</Button1>
				</div>
			</div>
		</>
	);
};

export default VoiceDialog;
