import {
	INITGROUP,
	LOGIN_SUCCESS,
	LOGOUT_REQUEST,
	PROFILE_RESET_REQUEST,
	PROFILE_URLS_RESET_REQUEST,
} from "./actionTypes";

import * as NotificationSocket from "../../notificationsSocket";

import axiosConfig from "../../Utils/axiosConfig";
import { loginURL, getMyProfileURL } from "../../Utils/Constants";

import * as Encrypt from "../../Utils/Encryption";
import Log from "../../Utils/logger";

const receiveLogin = (payload) => {
	return {
		type: LOGIN_SUCCESS,
		payload,
	};
};
export const initalizeGroup = () => {
	return {
		type: INITGROUP,
	};
};
export const resetUserData = (payload) => {
	return {
		type: PROFILE_RESET_REQUEST,
		payload,
	};
};
export const resetWebUrlData = (payload) => {
	return {
		type: PROFILE_URLS_RESET_REQUEST,
		payload,
	};
};

const logoutRequest = () => {
	return {
		type: LOGOUT_REQUEST,
	};
};

export const logout = () => (dispatch) => {
	localStorage.removeItem("authToken");
	sessionStorage.removeItem("isOnline");
	localStorage.clear();
	NotificationSocket.disconnect();
	dispatch(logoutRequest());
};

export const loginUser = (payload) => (dispatch) => {
	const form = new FormData();
	form.append("email", payload.email);
	form.append("password", payload.password);

	return axiosConfig
		.post(loginURL, form)
		.then((res) => {
			if (res.status !== 200) throw new Error();

			if (res.data.body.temp_token) {
				sessionStorage.setItem("temp_token", res.data.body.temp_token);
				return { status: true, data: res.data };
			} else {
				let userData = res.data.body;
				setToken(userData.token);
				delete userData["token"];
				dispatch(receiveLogin(userData));
				dispatch(initalizeGroup());
				return { status: true, data: res.data };
			}
		})
		.catch((err) => {
			if (err.response) {
				// if (err.response.data.body) {
				// 	localStorage.setItem(
				// 		"temp_token",
				// 		err.response.data.body[0].temp_token
				// 	);
				// }
				const catchError = err.response.data;
				return { status: false, error: catchError.message };
			} else {
				return { status: false, error: "Network Problem" };
			}
		});
};

export const socialLogin = (userData) => (dispatch) => {
	const { token, ...profile } = userData;
	if (token) setToken(token);
	dispatch(receiveLogin(profile));
	dispatch(initalizeGroup());
};

export const fetchProfile = () => (dispatch) => {
	axiosConfig
		.get(getMyProfileURL)
		.then((res) => {
			if (res.status !== 200) throw new Error();
			let userData = res.data.body;
			dispatch(receiveLogin(userData));
			return { status: true };
		})
		.catch((err) => {
			Log.error(err);
		});
};

export const setToken = (token) => {
	const newToken = Encrypt.encryptToken(token);
	localStorage.setItem("authToken", newToken);
};
