export const timeConverter = (inputDate) => {
  const types = ["second", "minute", "hour", "day", "month", "year"];
  const conversion = ["s", "m", "h", "d", "mo", "yr"];
  let newDate = "";
  let removedSpace = "";
  types.forEach((date) => {
    if (inputDate.includes(date) && inputDate[inputDate.length - 1] === "s") {
      newDate = inputDate.replace(date, conversion[types.indexOf(date)]).slice(0, -1);
      if (newDate[1] === " ") removedSpace = removeSpace(newDate, 1);
      else if (newDate[2] === " ") removedSpace = removeSpace(newDate, 2);
    } else if (inputDate.includes(date)) {
      newDate = inputDate.replace(date, conversion[types.indexOf(date)]);
      removedSpace = removeSpace(newDate, 1);
    }
  });
  return removedSpace;
};

const removeSpace = (date, i) => {
  return date.slice(0, i) + date.slice(i + 1);
};
