import React from "react";

const DarkAddIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 1V13M1 7H13"
				stroke="url(#paint0_linear)"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="1"
					y1="1"
					x2="13.4268"
					y2="1.45957"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FC466B" />
					<stop offset="1" stop-color="#3F5EFB" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default DarkAddIcon;
