import { axiosBaseConfig } from "../../Utils/axiosConfig";
import { socialLoginURLs } from "../../Utils/Constants";

export const obtainOauthRequestToken = async ({ callbackUrl }) => {
  const data = new FormData();
  data.append("callback_url", callbackUrl);
  try {
    const res = await axiosBaseConfig.post(
      socialLoginURLs.twitter.GET_TOKEN,
      data
    );

    if (res.status !== 200) {
      throw new Error("Something went Wrong. Try again later");
    }
    const requestToken = res.data.data;
    return requestToken;
  } catch (err) {
    let error = new Error(err.response.data);
    error = { ...error, data: err.response.data };
    throw error;
  }
};

export const obtainOauthAccessToken = async ({ oauthToken, oauthVerifier }) => {
  const data = new FormData();
  data.append("oauth_token", oauthToken);
  data.append("oauth_verifier", oauthVerifier);
  try {
    const res = await axiosBaseConfig.post(socialLoginURLs.twitter.LOGIN, data);

    if (res.status !== 200) {
      throw new Error("Something went Wrong. Try again later");
    }

    const requestToken = res.data;
    return requestToken;
  } catch (err) {
    let error = new Error(err.response.data);
    error = { ...error, data: err.response.data };
    throw error;
  }
};
