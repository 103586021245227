import io from "socket.io-client";
import {
	SocketConnected,
	SocketdisConnected,
} from "../Redux/Actions/deviceParamsActions";
import {
	mediaUploadSuccess,
	messageReceived,
	readConversation,
} from "../Redux/Actions/messages/actions";
import { incomingCallNotifier } from "../Redux/Actions/videoCalls/actions";
import store from "../Redux/store";
import axiosFileConfig from "./axiosFileConfig";
import { chatEndpoint, uploadMediaURL } from "./Constants";
import ReduxStore from "../Redux/store";
import { getDecryptedToken } from "./Encryption";
import Log from "./logger";

export let socket = io(chatEndpoint, {
	query: `token=${getDecryptedToken()}`,
});

export const connectToSocket = (isAuthenticated) => {
	if (isAuthenticated) {
		socket.disconnect();
		socket = io(chatEndpoint, {
			query: `token=${getDecryptedToken()}`,
		});
		store.dispatch(SocketConnected());
	}
	console.log("connected");
	socket.on("connect", (res) => {
		console.log("connected to socket");
		store.dispatch(SocketConnected());
	});

	socket.on("disconnect", (res) => {
		store.dispatch(SocketdisConnected());
	});

	socket.on("connect_failed", function () {
		Log.debug("here");
		store.dispatch(SocketConnected());
		store.dispatch(SocketdisConnected());
	});

	socket.on("new message", (message) => {
		Log.debug(message);
		store.dispatch(messageReceived(message));
	});

	socket.on("read_message_response", (res) => {
		store.dispatch(readConversation(res));
	});
};

export const connect = (setMessages, setOnline, setNewMember) => {
	socket.on("member joined", (message) => {
		let data = {
			isOnline: true,
			userID: message.userID,
		};

		setOnline(data);
	});

	socket.on("member left", (message) => {
		let data = {
			isOnline: false,
			userID: message.userID,
		};

		setOnline(data);
	});

	socket.on("new message", (res) => {
		Log.debug("message from socket");
		setMessages(res);
	});

	socket.on("error", (message) => {});
};

export const listenNewMembers = (callback, chatID) => {
	socket.on("new_member", (res) => {
		if (res.conversationID == chatID) {
			callback(res);
		}
	});
};

export const listenLeaveGroup = (callback, chatID) => {
	socket.on("leave_group_response", (res) => {
		if (res.conversationID == chatID) {
			callback(res);
		}
	});
};

export const ListenMessages = (callback) => {
	socket.on("new message", (res) => {
		callback(res);
	});
};

export const ListenRemovalFromGroup = (
	callback,
	{ conversationID, userID }
) => {
	socket.on("member_removed", function (res) {
		if (res.userID == userID && res.conversationID == conversationID) {
			callback(res);
		}
	});
};

export const listenMessageDeletes = (callback, chatID) => {
	Log.debug("listening to deletes");
	socket.on("delete_response", (res) => {
		if (res.conversationID == chatID) {
			callback(res);
		}
	});
};

export const listenTyping = (setMemberTyping) => {
	socket.on("member typing", (res) => {
		Log.debug("here");
		setMemberTyping(res);
	});
};

export const listenReads = (callback) => {
	socket.on("read_message_response", (res) => {
		callback(res);
	});
};

export const emitMessage = ({
	message,
	ID,
	isGroup,
	type,
	reply,
	resetReply,
	hash,
	meta,
	mentions,
}) => {
	let data = {
		conversationID: `${ID}`,
		message,
		isGroup,
		type,
		hash,
		meta,
		mentions,
	};

	if (reply) data.replyMessageID = reply._id;

	socket.emit("send message", data, (res) => {
		resetReply && resetReply();
	});
};

export const emitMediaMessage = ({
	message,
	ID,
	isGroup,
	type,
	files,
	hash,
	fileURL,
}) => {
	let data = {
		conversationID: `${ID}`,
		message,
		isGroup,
		type,
		hash,
	};

	const form = new FormData();

	form.append("message", message);
	form.append("conversationID", ID);
	form.append("isGroup", isGroup);
	if (files) {
		form.append("media", files);
	} else {
		form.append("mediaURL", fileURL);
		form.append("type", type);
	}

	axiosFileConfig
		.post(uploadMediaURL, form)
		.then((res) => {
			if (res.status !== 200) throw new Error();
			data.messageID = res.data.messageID;
			store.dispatch(mediaUploadSuccess(data.hash));
			socket.emit("send message", data, (res) => {});
		})
		.catch((err) => {
			Log.error(err);
		});
};

export const emitTyping = (ID, isGroup) => {
	const data = { conversationID: `${ID}`, isGroup };
	socket.emit("is typing", data, (err) => {});
};

export const emitJoinGroup = (ID, callback) => {
	const data = { conversationID: ID };
	socket.emit("join group", data, (res) => {
		if (callback) callback(res.response);
	});
};

export const emitDeleteMessage = (
	messageID,
	conversationID,
	isGroup,
	callback
) => {
	const data = { conversationID, messageID, isGroup };
	socket.emit("delete_request", data, (res) => {
		if (res.status == 200 && callback) {
			callback(res.data);
		}
	});
};

export const emitRead = (conversationID, messageID, isGroup, callback) => {
	const data = { conversationID, messageID, isGroup };
	socket.emit("read_message_request", data, (res) => {
		if (res.status == 200 && callback) {
			callback(res.data);
		}
	});
};

export const emitLeaveGroup = (conversationID) => {
	const data = { conversationID };
	Log.debug("leaving");
	socket.emit("leave_group", data, (res) => {});
};
export const emitRemoveUser = (conversationID, userID) => {
	const data = { conversationID, userID };
	socket.emit("remove_request", data, (res) => {
		Log.debug(res);
	});
};

const prepareCallback = (res, callback) => {
	if (res.status === 200) {
		callback(null, res.data);
	} else {
		callback(res, res.message);
	}
};
export const privateVideoCall = (conversationID, type, callback) => {
	socket.emit("webrtc:private:call", { conversationID, type }, (res) =>
		prepareCallback(res, callback)
	);
};

export const listenForCalls = (callback) => {
	socket.on("webrtc:private:incomingCall", (data) => {
		ReduxStore.dispatch(incomingCallNotifier(data));
		console.log(data);
		// callback(data);
	});
};

export const ListenforAnswers = (callID, callback) => {
	socket.on("webrtc:private:callAnswered", (data) => {
		if (data.callID === callID) {
			incomingCallNotifier(data);
			callback(data);
			console.log(data);
		}
	});
};

export const ListenForIceCandidates = (callID, callback) => {
	socket.on("webrtc:private:getCandidates", (data) => {
		if (data.callID === callID) {
			callback(data.candidate);
		}
	});
};
export const ListenForSdp = (callID, callback) => {
	socket.on("webrtc:private:getSDP", (data) => {
		console.log(data);
		console.log("recieved sdp");
		if (data.callID === callID) {
			callback(data.sdp);
		}
	});
};
export const ListenForSdpRemote = (callID, callback) => {
	socket.on("webrtc:private:getSDPRemote", (data) => {
		if (data.callID === callID) {
			callback(data.sdp);
		}
	});
};

export const ListenForDeviceStats = (callID, callback) => {
	socket.on("webrtc:private:deviceStatsChanged", (data) => {
		if (data.callID === callID) {
			console.log(data);
			callback(data.deviceStats);
		}
	});
};

export const listenCallDisconnect = (callID, callback) => {
	socket.on("webrtc:private:callEnded", (data) => {
		if (data.callID === callID) {
			callback(data);
		}
	});
};

export const sendPrivateCandidate = (callID, candidate) => {
	socket.emit("webrtc:private:candidate", { callID, candidate }, (res) => {});
};

export const sendPrivateSDP = (callID, sdp) => {
	console.log("sending sdp Req");
	socket.emit("webrtc:private:sdp", { callID, sdp }, (res) => {});
	console.log(Date.now());
};

export const sendPrivateSDPRemote = (callID, sdp) => {
	socket.emit("webrtc:private:sdpRemote", { callID, sdp }, (res) => {});
};

export const answerCall = (callID, callback) => {
	socket.emit("webrtc:private:answer", { callID }, (res) =>
		prepareCallback(res, callback)
	);
};

export const endCall = (callID, callback) => {
	socket.emit("webrtc:private:end", { callID }, (res) =>
		prepareCallback(res, callback)
	);
};

export const sendDeviceStats = (callID, deviceStats, callback) => {
	socket.emit(
		"webrtc:private:deviceStats",
		{ callID, deviceStats },
		(res) => {
			if (res.status == 200) {
				callback(res);
			}
		}
	);
};

export const rejectCall = (callID) => {
	socket.emit("webrtc:private:reject", { callID }, (res) => {
		if (res.status === 200) {
			// callback(res);
		}
		// else callback(res.message)
	});
};
export const listenCallRejected = (callID, callback) => {
	socket.on("webrtc:private:callRejected", (data) => {
		if (data.callID === callID) {
			callback(data);
		} else callback(data.message);
	});
};
