import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		margin: "1rem .5rem",
		padding: "1rem",
		width: (props) => props.width,
		display: "flex",
		flexDirection: "row",
		background: "var(--backgroud-color)",
		borderRadius: "1rem",
		"@media (max-width:780px)": {
			marginBottom: "1%",
			width: "80% !important",
		},
	},
	img: {
		marginRight: "1rem",
		background: "var(--dark-background)",
	},
	text: {
		background: "var(--dark-background)",
	},
});

export default function SmallCardSkeleton(props) {
	const classes = useStyles(props);
	return (
		<div className={classes.root}>
			<Skeleton
				variant="circle"
				width={40}
				height={40}
				className={classes.img}
			/>
			<Skeleton variant="text" width="100%" className={classes.text} />
		</div>
	);
}
