import { Button, makeStyles, useMediaQuery } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import BookmarkFolder from "../BookmarkFolder/BookmarkFolder";
import styles from "./styles.module.css";
import { Skeleton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
	areFoldersLoading,
	selectFolders,
} from "../../../../Redux/selectors/Bookmarks/folders";
import { fetchFolders } from "../../../../Redux/Actions/bookmarks/folderActions";
import { generatePath, useHistory, useLocation } from "react-router";
import NewFolderDialog from "../CreateNewFolder/NewFolderDialog";
import EditFolderDialog from "../CreateNewFolder/EditFolderDialog";

const useStyles = makeStyles({
	newFolderBtn: {
		fontWeight: 400,
		fontSize: "1rem",
		textTransform: "none",
	},
});

const Folders = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const isDesktop = useMediaQuery("(min-device-width:768px)");

	const folders = useSelector(selectFolders);
	const isLoading = useSelector(areFoldersLoading);

	const [showAddForm, setShowAddForm] = useState(false);
	const [editFolderID, setEditFolderId] = useState(null);

	useEffect(() => {
		dispatch(fetchFolders());
	}, []);

	const getFolderPath = (id) => generatePath("/bookmarks/folder/:id", { id });

	const onFolderRename = (id) => () => {
		setEditFolderId(id);
	};

	const onFolderClick = (id, name) => () => {
		const folderPath = getFolderPath(id);
		history.push({ pathname: folderPath });
	};

	return (
		<div className={styles.foldersContainer}>
			<div className={styles.header}>
				<Button
					className={classes.newFolderBtn}
					variant="outlined"
					color="primary"
					startIcon={<Add fontSize="large" />}
					onClick={() => setShowAddForm(true)}
				>
					New Bookmark Folder
				</Button>
			</div>

			{!isLoading ? (
				folders.length > 0 ? (
					<div className={styles.folders}>
						<BookmarkFolder
							className={styles.folder}
							selected={
								getFolderPath("all") === location.pathname
							}
							name="General Folder"
							noOps
							preventRename
							key="all"
							onClick={onFolderClick("all")}
						/>
						{folders.map((folder) => (
							<BookmarkFolder
								className={styles.folder}
								name={folder.foldername}
								folderID={folder.id}
								key={folder.id}
								selected={
									getFolderPath(folder.id) ===
									location.pathname
								}
								onClick={onFolderClick(folder.id)}
								onRename={onFolderRename(folder.id)}
							/>
						))}
					</div>
				) : isDesktop ? (
					<div className={styles.noFolders}>
						<p>No folders Available.</p>
					</div>
				) : (
					<div className={styles.folders}>
						<BookmarkFolder
							className={styles.folder}
							name="General Folder"
							preventRename
							key="all"
							onClick={onFolderClick("all")}
						/>
					</div>
				)
			) : (
				<div className={styles.folders}>
					{[1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5].map((i) => (
						<Skeleton
							variant="rect"
							height={50}
							animation="wave"
							className={styles.foldersPlaceHolder}
						/>
					))}
				</div>
			)}

			<NewFolderDialog
				show={showAddForm}
				onClose={() => setShowAddForm(false)}
			/>
			<EditFolderDialog
				folderID={editFolderID}
				show={!!editFolderID}
				onClose={() => setEditFolderId(null)}
			/>
		</div>
	);
};

export default Folders;
