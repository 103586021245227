import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useHistory } from "react-router-dom";
import axiosConfig from "../../../Utils/axiosConfig";
import { socialLoginURLs } from "../../../Utils/Constants";

import "./CreateProfile.scss";
import "../Login/login.scss";
import InputField from "../../Extras/InputField";
import BackToLandingBtn from "../BackToLandingBtn";
import { socialLogin } from "../../../Redux/Actions/actionAuth";
import { connect } from "react-redux";
import { useLocationPredictions } from "../../Extras/PlacesAutoComplete";
// import { FormattedMessage } from "react-intl";

const INITIAL_ERRORS = {
	full_name: null,
};
const fieldMapper = {
	full_name: "Full Name",
};
const CreateProfile = (props) => {
	const [state, setState] = useState({
		full_name: "",
		country: "",
		isSignUp: false,
		errors: INITIAL_ERRORS,
	});

	const history = useHistory();

	useEffect(() => {
		if (!localStorage.getItem("authToken")) history.replace("/login");
	}, []);

	const validate = (data) => {
		if (data.full_name.length > 0 && data.country.length > 0) {
			return true;
		} else {
			const errors = Object.keys(INITIAL_ERRORS).reduce(
				(acc, field) =>
					state[field].length === 0
						? {
								...acc,
								[field]: `${fieldMapper[field]} is Required`,
						  }
						: acc,
				{}
			);
			setState((prev) => ({ ...prev, errors }));

			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = state;

		if (validate(data)) {
			setState((prev) => ({ ...prev, isSignUp: true }));
			const form = new FormData();
			form.append("full_name", data.full_name);
			form.append("country", data.country);
			form.append("country", data.country);
			form.append("city", null);
			form.append("gender", null);

			axiosConfig
				.post(socialLoginURLs.createProfile, form)
				.then((res) => {
					if (res.status !== 200) throw new Error();
					setState((prev) => ({
						...prev,
						isSignUp: false,
					}));
					props.login(res.data.body);
				})
				.catch((err) => {
					console.log(err.response.data.message.detail);
					setState((prev) => ({ ...prev, isSignUp: false }));
				});
		}
	};

	const [countrySearchQuery, setCountrySearchQuery] = useState("");

	const sessionToken = useRef();
	const {
		libraryLoading,
		searched,
		predictions: countryPredictions,
		loadingPredictions,
	} = useLocationPredictions(countrySearchQuery, {
		sessionToken,
		locationType: "(regions)",
		onlyCountry: true,
		minLength: 1,
	});

	return (
		<div className="auth-page-Container">
			<BackToLandingBtn />
			<div className="pinkOval"></div>
			<div className="yellowDiv"></div>
			<div className="container">
				<div className="form-container register-form-container">
					<div className="header">
						<h1>
							{/* <FormattedMessage
								id="login.heading1"
								defaultMessage={`Create Profile`}
							/> */}
							Create Profile
						</h1>
						<p>
							{/* <FormattedMessage
								id="login.heading2"
								defaultMessage={`Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.`}
							/> */}
							Bditto is a powerful enterprise solution that will
							help you easily share, connect and explore thoughts
							in an expedited manner.
						</p>
					</div>

					<form className="form" onSubmit={handleSubmit}>
						<div className="left">
							<img className="logoM" src="/logoMain.svg" alt="" />
							<div className="heading">
								<h1>
									Where people{" "}
									<span className="gradienth1">connect</span>{" "}
									&{" "}
									<span className="gradienth1">share...</span>
								</h1>
							</div>
							<div className="launchImg">
								<img src="/icons/Launch.svg" alt="" />
							</div>
						</div>

						<div className="right">
							<div className="login">
								<img src="/icons/login.svg" alt="" />
								<p>Create Profile</p>
							</div>

							<div className="underline"></div>

							<div style={{ margin: "4rem 0" }}>
								{/* <div className="heading">
									<h5>Change Password</h5>
								</div> */}

								<div className="inputDiv">
									<div className="input">
										<InputField
											className="fullname-field"
											placeholder="Full name *"
											name="full_name"
											required
											errorText={state?.errors?.full_name}
											value={state.full_name}
											onChange={(e) => {
												setState((prev) => ({
													...prev,
													full_name: e.target.value,
												}));
											}}
										/>
									</div>
									{/* <div className="label">
										<p>
											Let's make the most of your work
											email
										</p>
									</div> */}
								</div>

								<div className="inputDiv">
									<div className="input">
										<InputField
											className="country-field"
											disabled={libraryLoading}
											optionsLoading={loadingPredictions}
											placeholder="Select Country"
											name="country"
											options={countryPredictions.map(
												(opt) => opt.label.main_text
											)}
											required
											onSearchQueryChange={
												setCountrySearchQuery
											}
											autocomplete="anyrandomstring"
											type="select"
											value={state.country}
											tooltip={
												libraryLoading ? (
													<div
														style={{
															fontSize: "0.7rem",
															color: "#999",
														}}
													>
														<CircularProgress
															size={10}
														/>
														{"  "}Loading
														Suggestions
													</div>
												) : null
											}
											autoComplete="anyrandomstring"
											onChange={(e) =>
												setState((prev) => ({
													...prev,
													country: e.target.value,
												}))
											}
										/>
									</div>
									<div className="label">
										{/* <p>
											Let's make the most of your work
											email
										</p> */}
									</div>
								</div>

								<p className="outLinks">
									{/* <FormattedMessage
										id="login.heading4"
										defaultMessage={`By continuing, you’re agreeing to our `}
									/> */}
									By continuing, you’re agreeing to our &nbsp;
									<Link to="/user-agreement">
										<span className="forget">
											{/* <FormattedMessage
												id="login.heading3"
												defaultMessage={`User Agreement `}
											/> */}
											User Agreement
										</span>
									</Link>
									<Link to="/privacy">
										<span className="forget">
											{/* <FormattedMessage
												id="login.heading3"
												defaultMessage={` Privacy Policy `}
											/> */}
											Privacy Policy
										</span>
									</Link>
									{/* <FormattedMessage
										id="login.heading4"
										defaultMessage={`, and Cookie Policy.`}
									/> */}
									, and Cookie Policy.
								</p>

								<div className="inputDiv">
									<button className="login-btn" type="submit">
										{/* <FormattedMessage
											id="signIn"
											defaultMessage={`Create Profile`}
										/> */}
										Create Profile
										{state.isSignUp && (
											<CircularProgress
												className="loader"
												color="primary"
												size={20}
											/>
										)}
									</button>
									<p className="link">
										{/* <FormattedMessage
											id="login.heading4"
											defaultMessage={`Go back to `}
										/> */}
										Go back to &nbsp;
										<Link to="/login">
											<span className="forget">
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`Login`}
												/> */}
												Login
											</span>
										</Link>
									</p>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	login: (userData) => dispatch(socialLogin(userData)),
});
export default connect(null, mapDispatchToProps)(CreateProfile);
