export function LockIcon({ color }) {
	return (
		<svg
			width="12"
			height="14"
			viewBox="0 0 12 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.28379 5.79854C9.00779 5.79854 8.78379 5.57454 8.78379 5.29854V3.86721C8.78379 2.46988 7.64713 1.33321 6.24979 1.33321H6.23913C5.56379 1.33321 4.93113 1.59321 4.45313 2.06721C3.97179 2.54321 3.70579 3.17854 3.70313 3.85588V5.29854C3.70313 5.57454 3.47913 5.79854 3.20313 5.79854C2.92713 5.79854 2.70312 5.57454 2.70312 5.29854V3.86721C2.70713 2.90721 3.07846 2.02121 3.74846 1.35721C4.41913 0.692542 5.30446 0.307876 6.25179 0.333209C8.19846 0.333209 9.78379 1.91854 9.78379 3.86721V5.29854C9.78379 5.57454 9.55979 5.79854 9.28379 5.79854Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.69597 5.75C2.5773 5.75 1.66797 6.65933 1.66797 7.778V10.6373C1.66797 11.756 2.5773 12.6653 3.69597 12.6653H8.78997C9.90797 12.6653 10.818 11.756 10.818 10.6373V7.778C10.818 6.65933 9.90797 5.75 8.78997 5.75H3.69597ZM8.78997 13.6653H3.69597C2.02597 13.6653 0.667969 12.3073 0.667969 10.6373V7.778C0.667969 6.108 2.02597 4.75 3.69597 4.75H8.78997C10.46 4.75 11.818 6.108 11.818 7.778V10.6373C11.818 12.3073 10.46 13.6653 8.78997 13.6653Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.24219 10.4494C5.96619 10.4494 5.74219 10.2254 5.74219 9.94942V8.46875C5.74219 8.19275 5.96619 7.96875 6.24219 7.96875C6.51819 7.96875 6.74219 8.19275 6.74219 8.46875V9.94942C6.74219 10.2254 6.51819 10.4494 6.24219 10.4494Z"
				fill={color}
			/>
		</svg>
	);
}
