import React from "react";

const CameraEditIcon = ({ isMobile }) => {
	return (
		<svg
			width={!isMobile ? "48" : "30"}
			height={!isMobile ? "48" : "30"}
			viewBox="0 0 49 49"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="24.5" cy="24.5" r="24.5" fill="#FF5B5B" />
			<path
				d="M33.9383 33.9384H15.8645C15.3852 33.9384 14.9254 33.748 14.5865 33.409C14.2475 33.0701 14.0571 32.6104 14.0571 32.131V19.4794C14.0571 19 14.2475 18.5403 14.5865 18.2014C14.9254 17.8624 15.3852 17.672 15.8645 17.672H19.4786L21.286 14.9609H28.5155L30.3229 17.672H33.9383C34.4176 17.672 34.8773 17.8624 35.2163 18.2014C35.5552 18.5403 35.7457 19 35.7457 19.4794V32.131C35.7457 32.6104 35.5552 33.0701 35.2163 33.409C34.8773 33.748 34.4176 33.9384 33.9383 33.9384Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M24.9013 29.4203C27.1472 29.4203 28.9679 27.5996 28.9679 25.3537C28.9679 23.1078 27.1472 21.2871 24.9013 21.2871C22.6554 21.2871 20.8347 23.1078 20.8347 25.3537C20.8347 27.5996 22.6554 29.4203 24.9013 29.4203Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default CameraEditIcon;
