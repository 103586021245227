import React from "react";
import { IntlProvider } from "react-intl";
import English from "./Languages/English.json";
import French from "./Languages/French.json";
import German from "./Languages/German.json";

var local = navigator.language || navigator.userLanguage;
let lang;

if (local.includes("en")) {
	lang = English;
} else if (local.includes("fr")) {
	lang = French;
} else if (local.includes("de")) {
	lang = German;
} else {
	lang = English;
}

const InternationalizationWrapper = (props) => {
	return (
		<IntlProvider
		// messages={English || lang}
		// locale={local}
		// defaultLocale="en"
		>
			{props.children}
		</IntlProvider>
	);
};

export default InternationalizationWrapper;
