import { Avatar, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosConfig from "../../../../Utils/axiosConfig";
import { TurnOffURL } from "../../../../Utils/Constants";
import styles from "../Contacts.module.css";

import useAlert from "./../../../Extras/Alert/useAlert";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { getCountInContact } from "../../../../Redux/Actions/contactAction";
import Username from "../../../Extras/Username";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import { useSelector } from "react-redux";

export const TurnedOffCard = ({ user, setRequestsLoaded }) => {
	const { showAlert } = useAlert();
	const [loading, setLoading] = useState(false);

	const theme = useSelector((state) => state.theme.theme);

	const turnOnUser = (user_id) => {
		setLoading(true);
		setRequestsLoaded(false);

		const request = new FormData();
		request.append("user_id", user_id);

		axiosConfig
			.post(TurnOffURL, request)
			.then((res) => {
				if (res.data.status_code !== 200) throw new Error();
				setLoading(false);
				setRequestsLoaded(true);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert(err.response.data.message, {
							header: err.response.data.error,
							buttonText: "Try Again",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
				setLoading(false);
			});
	};

	return (
		<div className={styles.pendingCard}>
			<Link
				to={`/profile/${user.pk}`}
				className={styles.pendingRequestProfileLink}
			>
				<Avatar
					src={user.avatar}
					classname={styles.avatarSent}
					style={{
						marginRight: ".55rem",
						background: theme === "dark" ? "#d9def4" : "#0d0f22",
						color: theme === "dark" ? "#0d0f22" : "#d9def4",
						border: theme === "dark" ? "2px solid #d9def4" : "",
						"@media screen and (min-width: 768px) ": {
							marginTop: "0.2rem",
						},
					}}
				>
					{user.full_name && user.full_name[0].toUpperCase()}
				</Avatar>

				<p
					className={
						user.profession ? styles.cardText1 : styles.cardText
					}
				>
					<span
						style={{
							fontSize: "1rem",
							color: theme === "dark" ? "#d9def4" : "#0D0F22",
						}}
					>
						<Username value={user.full_name || user.username} />{" "}
					</span>
					{user.profession && (
						<p
							s
							style={{
								color: theme === "dark" ? "#7F89BE" : "#4A517E",
								padding: "0rem 0rem 0rem 0.2rem",
								fontSize: "0.85rem",
								margin: "0.5rem 0rem 0rem 0",
							}}
						>
							{user.profession && user.profession}
						</p>
					)}
				</p>
			</Link>

			<div className={styles.btnCont}>
				{loading ? (
					<CircularProgress
						size="20px"
						style={{ color: "#00C6FF" }}
					/>
				) : (
					<>
						<div>
							<Button1
								padding={"5px 18px"}
								onClick={() => turnOnUser(user.pk)}
							>
								<FormattedMessage
									id="turn-on"
									defaultMessage={`Turn On`}
								/>
							</Button1>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
