/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosConfig from "../../Utils/axiosConfig";
import {
	getAllStatusURL,
	changeStatusURL,
	deleteStatusURL,
} from "../../Utils/Constants";
import CardSkeleton from "../Skeleton/CardSkeleton/CardSkeleton";
import styles from "./MyThoughts.module.css";
import StatusCard from "../Extras/StatusCard/StatusCard";
import EmptyState from "../Extras/EmptyState";
import { FormattedMessage } from "react-intl";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import formatDate from "date-fns/format";
import LikersDialog from "./LikersDialog";
import CancelIcon from "@material-ui/icons/Cancel";
import useAlert from "../Extras/Alert/useAlert";
import EditStatusModal from "../Extras/EditStatusModal/EditStatusModal";


export default function MyThoughts(props) {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [activeTab, setActiveTab] = useState(1);
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const [popEditStatus, setPopEditStatus] = useState(false);
    const [editStatusContent, setEditStatusContent] = useState(null);
	const { showAlert } = useAlert();
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);

    useEffect(() => {
		if (props.com === "Active") {
			getLikedStatus("Active");
		} else if (props.com === "Deactive") {
			getLikedStatus("Deactive");
		} else {
			getLikedStatus("Deleted");
		}
    }, [props.com]);
    


    useEffect(() => {
        getLikedStatus(props.com)
    },[searchText])

	const onEdit = (status) => {
		setPopEditStatus(true);
		setEditStatusContent(status);
	};

	const setCloseEditStatus = () => {
		setPopEditStatus(false);
	};

	const onDeactivate = (id) => {
		const form = new FormData();
		form.append("statusId", id);

		axiosConfig
			.post(changeStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("Thought status has been changes", {
					header: "Status changed",
					buttonText: "Go Back",
				});
				if (props.com === "Active") {
					getLikedStatus("Active");
				} else if (props.com === "Deactive") {
					getLikedStatus("Deactive");
				} else {
					getLikedStatus("Deleted");
				}
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

    const onDelete = (id) => {
		const form = new FormData();
		form.append("statusId", id);

		axiosConfig
			.post(deleteStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("Your status has been deleted", {
					header: "Status Deleted",
					buttonText: "Go Back",
				});
				if (props.com === "Active") {
					getLikedStatus("Active");
				} else if (props.com === "Deactive") {
					getLikedStatus("Deactive");
				} else if (props.com === "Deleted") {
					getLikedStatus("Deleted");
				}
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
							}
						);
					} else {
						showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};



	

	const getLikedStatus = (status) => {
		setIsLoading(true);
		if (!groupDataFromStore.id) return;
		const form = new FormData();
		if (status) {
			form.append("status", status);
		} else {
			form.append("status", props.com);
		}
		form.append("search_phrase", searchText);
		axiosConfig
			.post(getAllStatusURL + "?groupId=" + groupDataFromStore.id, form)
			.then((res) => {
				if (res.status !== 200) throw Error;
                setData(res.data.result || res.data.body);
                setAllData(res.data.result || res.data.body)
				setIsLoading(false);
			})
			.catch((err) => {});
	};

	const isMobile = window.screen.width < 768;

	useEffect(() => {
		isMobile && getLikedStatus("Active");
	}, []);

	const placeholderTag = props.com?.toLowerCase();

	const tabs = [
		{
			id: 1,
			label: "Active",
		},
		{
			id: 2,
			label: "Deactive",
		},
		{
			id: 3,
			label: "Deleted",
		},
	];

	return (
		<>
			{!isMobile && (
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<div
							className={`${styles.left} ${
								theme === "dark" ? styles.darkBg : styles.lgBg
							}`}
						>
							<div className={styles.heading}>
								{!isMobile &&
									(props.com === "Active" ? (
										<div
											className={`${styles.headItem} ${
												theme === "dark"
													? styles.darkPtext
													: styles.lgPtext
											}`}
											style={{ padding: "0 0.5rem" }}
										>
											<h1
												style={{
													color:
														theme === "dark"
															? "#d9def4"
															: "#0D0F22",
												}}
											>
												<FormattedMessage
													id="Active"
													defaultMessage={`My Thoughts (Active)`}
												/>
												&nbsp;
											</h1>
										</div>
									) : props.com === "Deactive" ? (
										<div
											className={`${styles.headItem} ${
												theme === "dark"
													? styles.darkPtext
													: styles.lgPtext
											}`}
											style={{ padding: "0 0.5rem" }}
										>
											<h1
												style={{
													color:
														theme === "dark"
															? "#d9def4"
															: "#0D0F22",
												}}
											>
												<FormattedMessage
													id="Deactivated"
													defaultMessage={`My Thoughts (Deactivated)`}
												/>{" "}
												&nbsp;
											</h1>
										</div>
									) : (
										props.com === "Deleted" && (
											<div
												className={`${
													styles.headItem
												} ${
													theme === "dark"
														? styles.darkPtext
														: styles.lgPtext
												}`}
												style={{ padding: "0 0.5rem" }}
											>
												<h1
													style={{
														color:
															theme === "dark"
																? "#d9def4"
																: "#0D0F22",
													}}
												>
													<FormattedMessage
														id="Deleted"
														defaultMessage={`My Thoughts (Deleted)`}
													/>
													&nbsp;
												</h1>
											</div>
										)
									))}
							</div>
							<div className={styles.searchContainer}>
								<img
									src="/Search.svg"
									className="ml-2 mr-1"
									alt=""
								/>
								<input
									type="text"
									value={searchText}
									className={styles.input}
									onChange={(e) =>
										setSearchText(e.target.value.trim())
									}
									placeholder={`Search ${placeholderTag} thoughts`}
								/>
								{searchText !== "" && (
									<CancelIcon
										onClick={() => setSearchText("")}
										style={{
											margin: "auto 0",
											color:
												theme === "dark"
													? "#7F89BE"
													: "#4A517E",
										}}
									/>
								)}
							</div>
						</div>

						<div className={styles.cds}>
							<Content
								items={data}
								isLoading={isLoading}
								onEdit={onEdit}
								onDelete={onDelete}
                                onDeactivate={onDeactivate}
							/>
						</div>
						<EditStatusModal
							show={popEditStatus}
							onHide={setCloseEditStatus}
							status={editStatusContent}
							fetchMyStatus={getLikedStatus}
						/>
					</div>
				</div>
			)}
			{isMobile && (
				<>
					<div className={styles.thoughtsTab}>
						{tabs.map((tab, i) =>
							tab.id === activeTab ? (
								<div className={styles.activeTab}>
									{tab.label}
								</div>
							) : (
								<div
									onClick={() => {
										setActiveTab(tab.id);
                                        getLikedStatus(tab.label);
									}}
									className={styles.tab}
								>
									{tab.label}
								</div>
							)
						)}
					</div>
					<div className={styles.cds}>
						<Content items={data}  isLoading={isLoading} />
					</div>
				</>
			)}
		</>
	);
}

const Content = ({
	items,
	isLoading,
	onEdit,
	onDelete,
    onDeactivate,
    ...props
}) => {
	// const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
    const isMobile = window.screen.width < 768;
	const [likesStatusID, setLikesStatusID] = useState(null);
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	// const viewLikers = (statusID) => () => {
	// 	setLikesStatusID(statusID);
	// };

	const closeLikesDialog = () => {
		setLikesStatusID(null);
    };

    
	if (isLoading)
		return (
			<>
				<div className={styles.cards}>
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
					<CardSkeleton width={!isMobile ? "30.7%" : "100%"} />
				</div>
			</>
		);
	else {
		const groups = items.reduce((acc, item) => {
			const createdDate = new Date(
				item.last_updated_at || item.created_at
			);

			let tag = "";
			if (isToday(createdDate)) {
				tag = "Today";
			} else if (isYesterday(createdDate)) {
				tag = "Yesterday";
			} else {
				tag = formatDate(createdDate, "do MMMM");
			}

			if (acc[tag]) {
				return { ...acc, [tag]: [...acc[tag], item] };
			} else {
				return { ...acc, [tag]: [item] };
			}
		}, {});
		const tags = Object.keys(groups);
		return (
			<>
				{tags?.length > 0 ? (
					tags.map((tag, i) => (
						<React.Fragment key={i}>
							<div style={{ padding: "0 1rem" }}>
								<div className={styles.section}>
									<h6
										className={styles.time}
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0D0F22",
										}}
									>
										{tag}
									</h6>
								</div>
								<div className={styles.cards}>
									{groups[tag]
										.reverse()
										.map((status, idx) => (
                                            <StatusCard
												status={status}
												key={status.pk}
												idx={
													idx %
													(groups[tag].length < 16
														? groups[tag].length
														: 16)
												}
												width={
													!isMobile ? "30.7%" : "100%"
												}
												height="15rem"
												// cta={
												// 	<button
												// 		className={
												// 			styles.listButton
												// 		}
												// 		onClick={viewLikers(
												// 			status.pk
												// 		)}
												// 		style={{
												// 			color:
												// 				theme === "dark"
												// 					? "#D9DEF4"
												// 					: "#0D0F22",
												// 		}}
												// 	>
												// 		<FormattedMessage
												// 			id="myThoughts.viewLikers"
												// 			defaultMessage={`Show Likers`}
												// 		/>
												// 	</button>
												// }
												onEdit={onEdit}
												onDelete={onDelete}
                                                onDeactivate={onDeactivate}
											/>
										))}
								</div>
							</div>
						</React.Fragment>
					))
				) : (
					<EmptyState
						style={{ marginTop: "1rem" }}
						id="suggestions-box"
						heading="Hey there! What are you upto?"
						imageUrl="/vectors/empty-states/noThoughts.svg"
						subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
					/>
				)}
				<LikersDialog
					show={!!likesStatusID}
					statusID={likesStatusID}
					onClose={closeLikesDialog}
				/>
			</>
		);
	}
};
