import { ReactComponent as OutlineIcon } from "../assets/icons/chats/saveOutline.svg";
import genIcons from "./genIcons";

export default function Save({ theme }) {
	const mainColor = theme === "dark" ? "white" : "#D9DEF4";

	return (
		<svg
			width="26"
			height="27"
			viewBox="0 0 27 27"
			fill={mainColor}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.95167 1.91797C2.8275 1.91797 1.75 2.7363 1.75 4.35213V16.6221C1.75 16.7005 1.795 16.743 1.8325 16.7646C1.87 16.788 1.92917 16.8046 1.9975 16.7663L7.31583 13.783C7.50583 13.6771 7.73833 13.6763 7.92917 13.7838L13.2017 16.7621C13.2708 16.8021 13.33 16.7838 13.3675 16.7613C13.405 16.7388 13.4492 16.6963 13.4492 16.618V4.50463C13.4492 3.86547 13.4492 1.91797 10.2508 1.91797H4.95167ZM1.91583 18.0405C1.67 18.0405 1.42417 17.9746 1.20083 17.843C0.761667 17.5871 0.5 17.1296 0.5 16.6221V4.35214C0.5 2.01047 2.1225 0.667969 4.95167 0.667969H10.2508C13.0775 0.667969 14.6992 2.06714 14.6992 4.50464V16.618C14.6992 17.1263 14.4375 17.5838 13.9975 17.8396C13.5592 18.0963 13.0308 18.1013 12.5875 17.8513L7.62083 15.0455L2.60833 17.8571C2.39167 17.9788 2.15417 18.0405 1.91583 18.0405Z"
				fill={mainColor}
			/>
		</svg>
	);
}
