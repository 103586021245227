import React from 'react'

const LoveFace = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.0008 18C36.0008 27.9412 27.9412 36 18 36C8.05877 36 0 27.9412 0 18C0 8.05878 8.05958 0 18 0C27.9404 0 36.0008 8.05959 36.0008 18Z" fill="#FFCA28"/>
        <path d="M18.0014 28.5135C15.8966 28.507 13.837 27.9014 12.0637 26.7675C10.2903 25.6336 8.8764 24.0183 7.9873 22.1104C7.94374 22.0166 7.9191 21.9151 7.91476 21.8117C7.91042 21.7084 7.92647 21.6052 7.96201 21.508C7.99755 21.4109 8.05188 21.3217 8.12189 21.2456C8.19189 21.1694 8.27622 21.1078 8.37004 21.0643C8.46386 21.0207 8.56533 20.996 8.66868 20.9917C8.77203 20.9874 8.87522 21.0034 8.97236 21.039C9.06951 21.0745 9.1587 21.1288 9.23485 21.1988C9.31099 21.2688 9.3726 21.3532 9.41616 21.447C10.1733 23.087 11.3842 24.4759 12.9058 25.4493C14.4274 26.4228 16.1959 26.9401 18.0022 26.9401C19.8086 26.9401 21.5771 26.4228 23.0987 25.4493C24.6203 24.4759 25.8312 23.087 26.5883 21.447C26.6313 21.3521 26.6926 21.2667 26.7688 21.1958C26.8449 21.1248 26.9344 21.0696 27.032 21.0334C27.1297 20.9972 27.2335 20.9808 27.3375 20.985C27.4416 20.9893 27.5437 21.0141 27.6381 21.058C27.7325 21.102 27.8172 21.1643 27.8874 21.2412C27.9575 21.3181 28.0117 21.4082 28.0468 21.5062C28.0819 21.6043 28.0972 21.7083 28.0919 21.8123C28.0865 21.9162 28.0606 22.0181 28.0156 22.112C27.1258 24.0188 25.7119 25.633 23.9389 26.7661C22.166 27.8992 20.1072 28.5044 18.0031 28.5111L18.0014 28.5135Z" fill="#263238"/>
        <path d="M11.5816 10.3503C11.5796 9.87369 11.6965 9.40415 11.9217 8.98415C12.1469 8.56415 12.4733 8.20696 12.8714 7.94488C13.2694 7.68281 13.7266 7.52414 14.2014 7.48323C14.6762 7.44233 15.1537 7.52048 15.5907 7.71061C16.0277 7.90075 16.4104 8.19686 16.7041 8.57215C16.9978 8.94744 17.1933 9.39006 17.2729 9.85994C17.3525 10.3298 17.3136 10.8121 17.1599 11.2632C17.0061 11.7143 16.7422 12.1199 16.3922 12.4433H16.4011L11.5816 17.2588L6.76363 12.4433C6.41575 12.1194 6.15399 11.7139 6.00196 11.2636C5.84993 10.8132 5.81242 10.3321 5.8928 9.86357C5.97318 9.39508 6.16893 8.95396 6.46239 8.58002C6.75585 8.20608 7.13778 7.91108 7.57374 7.72163C8.00969 7.53218 8.48596 7.45425 8.95956 7.49486C9.43316 7.53547 9.88921 7.69334 10.2866 7.95424C10.6839 8.21514 11.01 8.57084 11.2356 8.98928C11.4611 9.40772 11.5789 9.87572 11.5783 10.3511L11.5816 10.3503Z" fill="#E53935"/>
        <path d="M24.4204 10.3503C24.4185 9.87369 24.5354 9.40415 24.7606 8.98415C24.9858 8.56415 25.3122 8.20696 25.7103 7.94488C26.1083 7.68281 26.5654 7.52414 27.0403 7.48323C27.5151 7.44233 27.9926 7.52048 28.4296 7.71061C28.8666 7.90075 29.2493 8.19686 29.543 8.57215C29.8367 8.94744 30.0322 9.39006 30.1118 9.85994C30.1914 10.3298 30.1525 10.8121 29.9987 11.2632C29.845 11.7143 29.5811 12.1199 29.2311 12.4433H29.2384L24.4204 17.2612L19.6025 12.4449C19.6025 12.4449 19.6025 12.4449 19.6066 12.4449C19.2589 12.1211 18.9974 11.7157 18.8455 11.2655C18.6936 10.8153 18.6562 10.3344 18.7366 9.86614C18.817 9.39786 19.0127 8.95697 19.306 8.5832C19.5993 8.20943 19.9811 7.91456 20.4168 7.72517C20.8526 7.53578 21.3286 7.45783 21.802 7.49835C22.2754 7.53887 22.7313 7.69659 23.1285 7.95728C23.5257 8.21798 23.8518 8.57344 24.0774 8.99163C24.3029 9.40981 24.4208 9.87755 24.4204 10.3527V10.3503Z" fill="#E53935"/>
        </svg>

        
    )
}

export default LoveFace
