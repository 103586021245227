import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import styles from "./userAgreement.module.css";

export default function UserAgreement() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Navbar />
				<div className={styles.cont}>
					<div className={styles.yellowBox}></div>
					<h1 className={styles.heading}>BDITTO USER AGREEMENT</h1>

					<p className={styles.content}>
						Last updated and effective: January 29, 2021
					</p>

					<p className={styles.content}>
						This Bditto User Agreement (“Terms”) applies to your
						access to and use of the website, widgets, APIs, emails,
						and other online products and services (collectively,
						the “Services”) provided by Bditto, which is part of
						Bharg Technologies GmbH (“Bditto,” “we,” “us,” or
						“our”).
						<br />
						<br />
						Remember Bditto is for fun and is intended to be a place
						for your entertainment or getting things done together,
						but we still need some basic rules. By accessing or
						using our Services, you agree to be bound by these
						Terms. If you do not agree to these Terms, you may not
						access or use our Services.
						<br />
						<br />
						Please take a look at Bditto’s Privacy Policy too—it
						explains how and why we collect, use, and share
						information about you when you access or use our
						Services.
					</p>
					<h2 className={styles.subHeading}>
						1. YOUR ACCESS TO THE SERVICES
					</h2>
					<p className={styles.content}>
						Children under the age of 13 are not allowed to create
						an Account or otherwise use the Services. Additionally,
						you must be over the age required by the laws of your
						country to create an account or otherwise use the
						Services, or we need to have received verifiable consent
						from your parent or legal guardian.
						<br />
						<br />
						In addition, certain of our Services or portions of our
						Services require you to be 18 years of age or older, so
						please read all notices and any Additional Terms
						carefully when you access the Services.
						<br />
						<br />
						If you are accepting these Terms on behalf of another
						legal entity, including a business or government entity,
						you represent that you have full legal authority to bind
						such entity to these Terms.
					</p>
					<h2 className={styles.subHeading}>
						2. YOUR USE OF THE SERVICES
					</h2>
					<p className={styles.content}>
						Bditto grants you a personal, non-transferable,
						non-exclusive, revocable, limited license to use and
						access the Services solely as permitted by these Terms.
						We reserve all rights not expressly granted to you by
						these Terms. Except as permitted through the Services or
						as otherwise permitted by us in writing, your license
						does not include the right to:
						<ol>
							<li>
								license, sell, transfer, assign, distribute,
								host, or otherwise commercially exploit the
								Services or Content;
							</li>
							<li>
								modify, prepare derivative works of,
								disassemble, decompile, or reverse engineer any
								part of the Services or Content; or
							</li>
							<li>
								access the Services or Content in order to build
								a similar or competitive website, product, or
								service
							</li>
						</ol>
						We reserve the right to modify, suspend, or discontinue
						the Services (in whole or in part) at any time, with or
						without notice to you. Any future release, update, or
						other addition to functionality of the Services will be
						subject to these Terms, which may be updated from time
						to time. You agree that we will not be liable to you or
						to any third party for any modification, suspension, or
						discontinuation of the Services or any part thereof.
					</p>
					<h2 className={styles.subHeading}>
						3. YOUR BDITTO ACCOUNT AND ACCOUNT SECURITY
					</h2>
					<p className={styles.content}>
						To use our Services, you may be required to create a
						Bditto account (an “Account”) and provide us with a
						username, password, and certain other information about
						yourself as set forth in the Privacy Policy.
						<br />
						<br />
						You are solely responsible for the information
						associated with your Account and anything that happens
						related to your Account. You must maintain the security
						of your Account and immediately notify Bditto
						(admin@bditto.com) if you discover or suspect that
						someone has accessed your Account without your
						permission. We recommend that you use a strong password
						that is used only with your Account.
						<br />
						<br />
						You will not license, sell, or transfer your Account
						without our prior written approval.
						<br />
					</p>

					<h2 className={styles.subHeading}>4. YOUR CONTENT</h2>
					<p className={styles.content}>
						The Services may contain information, text, links,
						graphics, photos, videos, or other materials
						(“Content”), including Content created or submitted to
						the Services by you or through your Account (“Your
						Content”). We take no responsibility for and we do not
						expressly or implicitly endorse, support, or guarantee
						the completeness, truthfulness, accuracy, or reliability
						of any of Your Content.
						<br />
						<br />
						By submitting Your Content to the Services, you
						represent and warrant that you have all rights, power,
						and authority necessary to grant the rights to Your
						Content contained within these Terms. Because you alone
						are responsible for Your Content, you may expose
						yourself to liability if you post or share Content
						without all necessary rights.
						<br />
						<br />
						You retain any ownership rights you have in Your
						Content, but you grant Bditto the following license to
						use that Content:
						<br />
						<br />
						When Your Content is created with or submitted to the
						Services, you grant us a worldwide, royalty-free,
						perpetual, irrevocable, non-exclusive, transferable, and
						sublicensable license to use, copy, modify, adapt,
						prepare derivative works of, distribute, store, perform,
						and display Your Content and any name, username, voice,
						or likeness provided in connection with Your Content in
						all media formats and channels now known or later
						developed anywhere in the world. This license includes
						the right for us to make Your Content available for
						syndication, broadcast, distribution, or publication by
						other companies, organizations, or individuals who
						partner with Bditto. You also agree that we may remove
						metadata associated with Your Content, and you
						irrevocably waive any claims and assertions of moral
						rights or attribution with respect to Your Content.
						<br />
						<br />
						Any ideas, suggestions, and feedback about Bditto or our
						Services that you provide to us are entirely voluntary,
						and you agree that Bditto may use such ideas,
						suggestions, and feedback without compensation or
						obligation to you.
						<br />
						<br />
						Although we have no obligation to screen, edit, or
						monitor Your Content, we may, in our sole discretion,
						delete or remove Your Content at any time and for any
						reason, including for violating these Terms, violating
						our Content Policy, or if you otherwise create or are
						likely to create liability for us.
					</p>

					<h2 className={styles.subHeading}>
						5. THIRD-PARTY CONTENT, ADVERTISEMENTS, AND PROMOTIONS
					</h2>
					<p className={styles.content}>
						The Services may contain links to third-party websites,
						products, or services, which may be posted by
						advertisers, our affiliates, our partners, or other
						users (“Third-Party Content”). Third-Party Content is
						not under our control, and we are not responsible for
						any third party’s websites, products, or services. Your
						use of Third-Party Content is at your own risk and you
						should make any investigation you feel necessary before
						proceeding with any transaction in connection with such
						Third-Party Content.
						<br />
						<br />
						The Services may also contain sponsored Third-Party
						Content or advertisements. The type, degree, and
						targeting of advertisements are subject to change, and
						you acknowledge and agree that we may place
						advertisements in connection with the display of any
						Content or information on the Services, including Your
						Content.
						<br />
						<br />
						If you choose to use the Services to conduct a
						promotion, including a contest or sweepstakes
						(“Promotion”), you alone are responsible for conducting
						the Promotion in compliance with all applicable laws and
						regulations at your own risk. Your Promotion must state
						that the Promotion is not sponsored by, endorsed by, or
						associated with Bditto, and the rules for your Promotion
						must require each entrant or participant to release
						Bditto from any liability related to the Promotion.
					</p>

					<h2 className={styles.subHeading}>
						6. THINGS YOU CANNOT DO
					</h2>
					<p className={styles.content}>
						When using or accessing Bditto, you must comply with
						these Terms and all applicable laws, rules, and
						regulations. Please review the Content Policy, which are
						part of these Terms and contain Bditto’s rules about
						prohibited content and conduct. In addition to what is
						prohibited in the Content Policy, you may not do any of
						the following:
						<br />
						<br />
						Use the Services in any manner that could interfere
						with, disable, disrupt, overburden, or otherwise impair
						the Services.
						<br />
						<br />
						Gain access to (or attempt to gain access to) another
						user’s Account or any non-public portions of the
						Services, including the computer systems or networks
						connected to or used together with the Services.
						<br />
						<br />
						Upload, transmit, or distribute to or through the
						Services any viruses, worms, malicious code, or other
						software intended to interfere with the Services,
						including its security-related features.
						<br />
						<br />
						Use the Services to violate applicable law or infringe
						any person’s or entity's intellectual property rights or
						any other proprietary rights.
						<br />
						<br />
						Access, search, or collect data from the Services by any
						means (automated or otherwise) except as permitted in
						these Terms or in a separate agreement with Bditto. We
						conditionally grant permission to crawl the Services in
						accordance with the parameters set forth in our
						robots.txt file, but scraping the Services without
						Bditto’s prior consent is prohibited.
						<br />
						<br />
						Use the Services in any manner that we reasonably
						believe to be an abuse of or fraud on Bditto or any
						payment system.
						<br />
						<br />
						We encourage you to report content or conduct that you
						believe violates these Terms or our Content Policy. We
						also support the responsible reporting of security
						vulnerabilities. To report a security issue, please
						email admin@bditto.com.
					</p>
					<h2 className={styles.subHeading}>
						7. COPYRIGHT, TRADEMARK, THE DMCA, AND TAKEDOWNS
					</h2>
					<p className={styles.content}>
						Bditto respects the intellectual property of others and
						requires that users of our Services do the same. We have
						a policy that includes the removal of any infringing
						material from the Services and for the termination, in
						appropriate circumstances, of users of our Services who
						are repeat infringers. If you believe that anything on
						our Services infringes a copyright or a trademark that
						you own or control, you may notify Bditto Designated
						Agent by emailing to admin@bditto.com
						<br />
						<br />
						Also, please note that if you knowingly misrepresent
						that any activity or material on our Service is
						infringing, you may be liable to Bditto for certain
						costs and damages.
						<br />
						<br />
						If we remove Your Content in response to a copyright or
						trademark notice, we will notify you via email. If you
						believe Your Content was wrongly removed due to a
						mistake or misidentification in a copyright notice, you
						can send a counter notification via admin@bditto.com.
						Please see 17 U.S.C. § 512(g)(3) for the requirements of
						a proper counter notification.
					</p>
					<h2 className={styles.subHeading}>
						8. PAID SERVICES AND PAYMENT INFORMATION
					</h2>
					<p className={styles.content}>
						There are no fees for the use of many aspects of the
						Services.
					</p>
					<h2 className={styles.subHeading}>9. INDEMNITY</h2>
					<p className={styles.content}>
						Except to the extent prohibited by law, you agree to
						defend, indemnify, and hold us, our directors, officers,
						employees, affiliates, agents, contractors, third-party
						service providers, and licensors (the “Bditto Entities”)
						harmless from any claim or demand, including costs and
						attorneys’ fees, made by any third party due to or
						arising out of (a) your use of the Services, (b) your
						violation of these Terms, (c) your violation of
						applicable laws or regulations, or (d) Your Content. We
						reserve the right to control the defense of any matter
						for which you are required to indemnify us, and you
						agree to cooperate with our defense of these claims.
					</p>
					<h2 className={styles.subHeading}>10. DISCLAIMERS</h2>
					<p className={styles.content}>
						THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”
						WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
						IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
						WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
						PARTICULAR PURPOSE, AND NON-INFRINGEMENT. BDITTO
						ENTITIES DO NOT WARRANT THAT THE SERVICES ARE ACCURATE,
						COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. BDITTO DOES
						NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY
						CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE
						ACTIONS OF ANY THIRD PARTY OR USER. WHILE BDITTO
						ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES
						SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR SERVICES
						OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
						COMPONENTS.
					</p>
					<h2 className={styles.subHeading}>
						11. LIMITATION OF LIABILITY
					</h2>
					<p className={styles.content}>
						IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING
						CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY,
						OR OTHERWISE, WILL THE BDITTO ENTITIES BE LIABLE TO YOU
						FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
						SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING
						FROM OR RELATING TO THESE TERMS OR THE SERVICES,
						INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE
						AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE
						DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE
						OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND
						YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
						DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING
						THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF
						THE BDITTO ENTITIES EXCEED THE GREATER OF ONE HUNDRED
						U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID BDITTO IN THE
						PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO THE
						CLAIM. THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY
						THEORY OF LIABILITY, INCLUDING THOSE BASED ON WARRANTY,
						CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR
						OTHERWISE, AND EVEN IF THE BDITTO ENTITIES HAVE BEEN
						ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN
						IF ANY REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED
						ITS ESSENTIAL PURPOSE. THE FOREGOING LIMITATION OF
						LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY
						LAW IN THE APPLICABLE JURISDICTION.
					</p>
					<h2 className={styles.subHeading}>
						12. GOVERNING LAW AND VENUE
					</h2>
					<p className={styles.content}>
						We want you to enjoy Bditto, so if you have an issue or
						dispute, you agree to raise it and try to resolve it
						with us informally. You can contact us with feedback and
						concerns at admin@bditto.com
						<br />
						<br />
						Any claims arising out of or relating to these Terms or
						the Services will be governed by the laws of Canton
						Schwyz, without regard to its conflict of laws rules.
						All disputes related to these Terms or the Services will
						be brought solely in the federal or state courts located
						in Canton Schwyz, Switzerland and you consent to
						personal jurisdiction in these courts.
					</p>
					<h2 className={styles.subHeading}>
						13. CHANGES TO THESE TERMS
					</h2>
					<p className={styles.content}>
						We may make changes to these Terms from time to time. If
						we make changes, we will post the revised Terms and
						update the Effective Date above. If the changes, in our
						sole discretion, are material, we may also notify you by
						sending an email to the address associated with your
						Account (if you have chosen to provide an email address)
						or by otherwise providing notice through our Services.
						By continuing to access or use the Services on or after
						the Effective Date of the revised Terms, you agree to be
						bound by the revised Terms. If you do not agree to the
						revised Terms, you must stop accessing and using our
						Services before the changes become effective.
					</p>
					<h2 className={styles.subHeading}>14. ADDITIONAL TERMS</h2>
					<p className={styles.content}>
						Because we offer a variety of Services, you may be asked
						to agree to additional terms before using a specific
						product or service offered by Bditto (“Additional
						Terms”). To the extent any Additional Terms conflict
						with these Terms, the Additional Terms govern with
						respect to your use of the corresponding Service.
					</p>
					<h2 className={styles.subHeading}>15. TERMINATION</h2>
					<p className={styles.content}>
						You may terminate these Terms at any time and for any
						reason by deleting your Account and discontinuing use of
						all Services. If you stop using the Services without
						deactivating your Account, your Account may be
						deactivated due to prolonged inactivity.
						<br />
						We may suspend or terminate your Account, moderator
						status, or ability to access or use the Services at any
						time for any or no reason, including for violating these
						Terms or our Content Policy.
						<br />
						The following sections will survive any termination of
						these Terms or of your Account: 4 (Your Content), 6
						(Things You Cannot Do), 9 (Indemnity), 10 (Disclaimers),
						11 (Limitation of Liability), 12 (Governing Law and
						Venue), 15 (Termination), and 16 (Miscellaneous).
					</p>
					<h2 className={styles.subHeading}>16. MISCELLANEOUS</h2>
					<p className={styles.content}>
						These Terms constitute the entire agreement between you
						and us regarding your access to and use of the Services.
						Our failure to exercise or enforce any right or
						provision of these Terms will not operate as a waiver of
						such right or provision. If any provision of these Terms
						is, for any reason, held to be illegal, invalid, or
						unenforceable, the rest of the Terms will remain in
						effect. You may not assign or transfer any of your
						rights or obligations under these Terms without our
						consent. We may freely assign any of our rights and
						obligations under these Terms.
						<br />
						Contact Information:
						<br />
						Bharg Technologies GmbH.
						<br />
						Pfaeffikonerstrasse 27
						<br />
						8834 Schindellegi
						<br />
						Switzerland
					</p>
				</div>
				<div className={styles.blueBox}></div>
				<Footer />
			</div>
		</div>
	);
}
