import { history } from "../Router/history";
import Log from "./logger";

export const errorInterceptor = (err) => {
  const { status } = err.response;
  if (status === 404 || status === 500) {
    history.push("/error/api");
  } else if (status === 401 && err.response.data.detail === "Invalid token.") {
    history.push("/account-blocked-error");
  } else {
    return Promise.reject(err);
  }
};
