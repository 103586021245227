import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import { SnackbarContent, useSnackbar } from "notistack";
import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { closeNotification } from "../../../Redux/Actions/notifications";
import styles from "./newNotification.module.scss";
import { Chat, FavoriteOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: "344px !important",
		["@media (max-width:768px)"]: {
			marginBottom: "3rem",
		},
	},
	card: {
		width: "100%",
	},
	header: {
		padding: "10px",
		color: "white",
	},
	content: {
		padding: 10,
	},
	avatar: {
		marginRight: 10,
	},
	iconAvatar: {
		backgroundColor: "#0d3d60",
	},
	cardTitle: {
		fontSize: "1rem",
	},
	link: {
		textDecoration: "none",
		color: "black",
	},
}));

const NewNotification = forwardRef((props, ref) => {
	let heading = "";
	let avatar = null;
	let notificationLink = "";
	const classes = useStyles();

	const theme = useSelector((state) => state.theme.theme);

	const { closeSnackbar } = useSnackbar();

	const notificationType = props.message && props.message.notification_type;

	if (notificationType === "like") {
		const user = props.message?.metas?.liked_by;
		notificationLink = `/chat/group/${props.message.metas.status.pk}`;
		avatar = (
			<Link to={`/profile/${props.message.metas.liked_by.pk}`}>
				<Avatar
					src={user.avatar}
					className={`${classes.avatar} ${
						!user.avatar ? classes.iconAvatar : ""
					}`}
				>
					{user.username ? user.username[0].toUpperCase() : null}
				</Avatar>
			</Link>
		);
	} else if (notificationType === "group_joined") {
		const user = props.message.metas.user;
		notificationLink = `/chat/group/${props.message.metas.status.pk}`;
		heading = `${user.username || user.full_name} joined your group.`;
		avatar = (
			<Link to={`/profile/${props.message.metas.user.userID}`}>
				<Avatar
					src={user.avatar}
					className={`${classes.avatar} ${
						!user.avatar ? classes.iconAvatar : ""
					}`}
				>
					{user.username ? user.username[0].toUpperCase() : null}
				</Avatar>
			</Link>
		);
	} else if (notificationType === "liked_message") {
		const user = props.message.metas.sender;
		notificationLink = `/chat/group/${props.message.metas.message.conversationID}?highlight=${props.message.metas.message.messageID}`;
		avatar = (
			<Link to={`/profile/${user.senderID}`}>
				<Avatar
					src={user.avatar}
					className={`${classes.avatar} ${classes.iconAvatar}`}
				>
					<FavoriteOutlined />
				</Avatar>
			</Link>
		);
	} else if (notificationType === "friend_request_accepted") {
		const user = props.message.metas.receiver;
		notificationLink = `/profile/${props.message.metas.receiver.userID}`;
		avatar = (
			<Link to={`/profile/${props.message.metas.receiver.userID}`}>
				<Avatar
					src={user.avatar}
					className={`${classes.avatar} ${
						!user.avatar ? classes.iconAvatar : ""
					}`}
				>
					{user.username ? user.username[0].toUpperCase() : null}
				</Avatar>
			</Link>
		);
	} else if (notificationType === "friend_request_sent") {
		const user = props.message.metas.sender;
		notificationLink = "/myprofile/contacts/pending";
		avatar = (
			<Link to={`/profile/${props.message.metas.sender.userID}`}>
				<Avatar
					src={user?.avatar}
					className={`${classes.avatar} ${
						!user?.avatar ? classes.iconAvatar : ""
					}`}
				>
					{user?.username ? user?.username[0].toUpperCase() : null}
				</Avatar>
			</Link>
		);
	} else if (notificationType === "new_message") {
		const user = props.message.metas.user;
		notificationLink = `/chat/private/${user.userID}`;
		avatar = (
			<Avatar
				src={user.avatar}
				className={`${classes.avatar} ${classes.iconAvatar}`}
			>
				<Chat />
			</Avatar>
		);
	} else if (notificationType === "create_status") {
		const user = props.message.metas.user;
		notificationLink = `/chat/group/${props.message.metas.status.pk}`;
		avatar = (
			<Link to={`/profile/${props.message.metas.user.userID}`}>
				<Avatar
					src={user.avatar}
					className={`${classes.avatar} ${
						!user.avatar ? classes.iconAvatar : ""
					}`}
				>
					{user.username ? user.username[0].toUpperCase() : null}
				</Avatar>
			</Link>
		);
	}

	const dispatch = useDispatch();

	const handleClose = () => {
		if (props.message.isUncontrolled) {
			closeSnackbar(props.id);
		} else {
			dispatch(closeNotification(props.id));
		}
	};

	return (
		<SnackbarContent ref={ref} className={classes.root}>
			{props.message.content && (
				<Card
					className={classes.card}
					style={{
						background: theme === "dark" ? "#0d0f22" : "#D1E7FE",
					}}
					onClick={handleClose}
				>
					<CardHeader
						classes={{ title: classes.cardTitle }}
						title={
							notificationType !== "new_message"
								? "New Notification"
								: "New Message"
						}
						className={classes.header}
						style={{
							background:
								theme === "dark" ? "#7F89BE" : "#4A517E",
						}}
						action={
							<IconButton
								aria-label="close"
								size="small"
								onClick={handleClose}
							>
								<CloseIcon
									fontSize="small"
									style={{
										color:
											theme === "dark"
												? "#fff"
												: "#0d0f22",
									}}
								/>
							</IconButton>
						}
					></CardHeader>
					<CardContent className={classes.content}>
						<div className={styles.content}>
							{avatar}
							<Link
								to={notificationLink}
								className={classes.link}
							>
								<div
									className={styles.notfication}
									style={{
										color:
											theme === "dark"
												? "#fff"
												: "#0d0f22",
									}}
								>
									{props.message.content}
								</div>
							</Link>
						</div>
					</CardContent>
				</Card>
			)}
		</SnackbarContent>
	);
});
export default NewNotification;
