import React from "react";
const generalFaqs = [
	{
		head: "What is Bditto.com?",
		content:
			"Have you ever been wondering what other people are thinking, Bditto is the place to be. It's the first and only platform to share your thoughts in real-time and instantly get connected to like minded people.",
	},
	{
		head: "Why should I use Bditto?",
		content:
			"It is the easiest and fastest way to connect, help, collaborate and have fun with people around the globe by simply matching their thoughts.",
	},
	{
		head: "What does the name Bditto stand for?",
		content: "Bditto stands for Better Do It Together",
	},
	{
		head: "What do you do with my data?",
		content:
			"Please refer to our Privacy Policy and Terms of Use for more details about how we treat your data.",
	},
	{
		head: "How to start with Bditto?",
		content: (
			<ol>
				<li>Navigate to www.bditto.com in your web browser.</li>{" "}
				<li>
					Under Sign Up, enter your personal information and desired
					password, then click Sign Up.
				</li>
				<li>
					You can then follow the directions on the screen and get
					started.
				</li>
			</ol>
		),
	},
	{
		head: "Is posting personal information ok?",
		content:
			"NO. Bditto is a pretty open and free speech place, but it is not ok to post someone's personal information, or post links to personal information. This includes links to public pages and screenshots of pages with the names still legible. We all get outraged by the ignorant things people say and do online, but witch hunts and vigilantism hurt innocent people and certain individual information, including personal info found online is often false. Posting personal information will get you banned. Posting professional links to contact a congressman or the CEO of some company is probably fine, but don't post anything inviting harassment, don't harass, and don't cheer on or vote up obvious vigilantism.",
	},
	{
		head: "Is it okay to create multiple accounts?",
		content:
			"You can create multiple accounts as long as you have a unique email id for each account and are not doing any false self-promotions or creating a fake identity.",
	},
	{
		head: "Why should I create a status?",
		content:
			"It helps to get discovered by other users across the globe and doubles your chance of getting in touch with each other.",
	},
	{
		head: "Can I maintain unlimited statuses?",
		content: (
			<>
				To ensure that the discussions are active and up to date, Bditto
				allows you to engage in a maximum of 200 status conversations
				(including own and other statuses). Once you reach the limit you
				can either delete statuses or even deactivate if you don’t want
				to lose content. The limit does not apply to individual private
				chats. <br />
				Our recommendation, thoughts get outdated pretty quick, so it`s
				more about posting new statuses than keeping the old ones.
			</>
		),
	},
	{
		head: "What’s the difference between a private and group chat?",
		content:
			"Group chat is an open and public discussion channel where any user can join based on their matched statuses or by exploring others. One can also have private chats in Bditto, but only with accepted friends, and these conversations are one to one and are private.",
	},
	{
		head: "How do I start a private chat? ",
		content:
			"Click on someone’s profile and send a friend request. if the friend request has been approved, then both are able to start a private chat.      ",
	},
	{
		head: "How do I remove someone from a group?",
		content:
			"If you feel like someone is not following the Bditto conversation rules, browse the list of users by selecting the status bar and click on the relevant user profile pic and hit the remove button.",
	},
	{
		head: "Will my status in a Bditto group be deleted after leaving the Group?",
		content:
			"If you delete your own status, it still remains on Bditto for all other users in the group. However, if the user wants to delete their chats, they can do so by deleting one by one within the group conversation.",
	},
	{
		head: "What happens if I delete my account?",
		content: (
			<>
				When you delete your Bditto account, a few things happen:
				<ul>
					<li>
						Your profile is permanently removed from Bditto. That
						includes your profile information, bookmarks, contacts
						you may have set up. Even if someone has a direct link
						to your profile, they’ll get a message that says the
						account has been deleted.
					</li>
					<li>
						Your username is permanently removed from Bditto. You
						won’t be able to log in to Bditto with your old username
						and password. Also, nobody (including you) will be able
						to create a new account with your old username.
					</li>
					<li>
						Your posts and comments become unattributed. Any posts
						or comments you made from your deleted account stay on
						Bditto, but people can’t see who they came from. (If you
						have posts or comments you’d like to remove, you need to
						delete them before you delete your account.)
					</li>
					<li>
						Your messages and chats become unattributed. Any
						messages you sent or chats you took part in from your
						deleted account stay on Bditto, but people can’t see who
						they came from.
					</li>
				</ul>
			</>
		),
	},
	{
		head: "Any other questions?",
		content:
			"This is the fun part, directly post your question or problem on Bditto with: #Bditto #support",
	},
];

const privacyFaqs = [
	{
		head: "What is the privacy policy of Bditto.com?",
		content:
			"Have you ever been wondering what other people are thinking, Bditto is the place to be. It's the first and only platform to share your thoughts in real-time and instantly get connected to like minded people.",
	},
	{
		head: "Why should I use Bditto?",
		content:
			"It is the easiest and fastest way to connect, help, collaborate and have fun with people around the globe by simply matching their thoughts.",
	},
	{
		head: "What does the name Bditto stand for?",
		content: "Bditto stands for Better Do It Together",
	},
	{
		head: "What do you do with my data?",
		content:
			"Please refer to our Privacy Policy and Terms of Use for more details about how we treat your data.",
	},
	{
		head: "How to start with Bditto?",
		content: (
			<ol>
				<li>Navigate to www.bditto.com in your web browser.</li>{" "}
				<li>
					Under Sign Up, enter your personal information and desired
					password, then click Sign Up.
				</li>
				<li>
					You can then follow the directions on the screen and get
					started.
				</li>
			</ol>
		),
	},
	{
		head: "Is posting personal information ok?",
		content:
			"NO. Bditto is a pretty open and free speech place, but it is not ok to post someone's personal information, or post links to personal information. This includes links to public pages and screenshots of pages with the names still legible. We all get outraged by the ignorant things people say and do online, but witch hunts and vigilantism hurt innocent people and certain individual information, including personal info found online is often false. Posting personal information will get you banned. Posting professional links to contact a congressman or the CEO of some company is probably fine, but don't post anything inviting harassment, don't harass, and don't cheer on or vote up obvious vigilantism.",
	},
	{
		head: "Is it okay to create multiple accounts?",
		content:
			"You can create multiple accounts as long as you have a unique email id for each account and are not doing any false self-promotions or creating a fake identity.",
	},
	{
		head: "Why should I create a status?",
		content:
			"It helps to get discovered by other users across the globe and doubles your chance of getting in touch with each other.",
	},
	{
		head: "Can I maintain unlimited statuses?",
		content: (
			<>
				To ensure that the discussions are active and up to date, Bditto
				allows you to engage in a maximum of 200 status conversations
				(including own and other statuses). Once you reach the limit you
				can either delete statuses or even deactivate if you don’t want
				to lose content. The limit does not apply to individual private
				chats. <br />
				Our recommendation, thoughts get outdated pretty quick, so it`s
				more about posting new statuses than keeping the old ones.
			</>
		),
	},
	{
		head: "What’s the difference between a private and group chat?",
		content:
			"Group chat is an open and public discussion channel where any user can join based on their matched statuses or by exploring others. One can also have private chats in Bditto, but only with accepted friends, and these conversations are one to one and are private.",
	},
	{
		head: "How do I start a private chat? ",
		content:
			"Click on someone’s profile and send a friend request. if the friend request has been approved, then both are able to start a private chat.      ",
	},
	{
		head: "How do I remove someone from a group?",
		content:
			"If you feel like someone is not following the Bditto conversation rules, browse the list of users by selecting the status bar and click on the relevant user profile pic and hit the remove button.",
	},
	{
		head: "Will my status in a Bditto group be deleted after leaving the Group?",
		content:
			"If you delete your own status, it still remains on Bditto for all other users in the group. However, if the user wants to delete their chats, they can do so by deleting one by one within the group conversation.",
	},
	{
		head: "What happens if I delete my account?",
		content: (
			<>
				When you delete your Bditto account, a few things happen:
				<ul>
					<li>
						Your profile is permanently removed from Bditto. That
						includes your profile information, bookmarks, contacts
						you may have set up. Even if someone has a direct link
						to your profile, they’ll get a message that says the
						account has been deleted.
					</li>
					<li>
						Your username is permanently removed from Bditto. You
						won’t be able to log in to Bditto with your old username
						and password. Also, nobody (including you) will be able
						to create a new account with your old username.
					</li>
					<li>
						Your posts and comments become unattributed. Any posts
						or comments you made from your deleted account stay on
						Bditto, but people can’t see who they came from. (If you
						have posts or comments you’d like to remove, you need to
						delete them before you delete your account.)
					</li>
					<li>
						Your messages and chats become unattributed. Any
						messages you sent or chats you took part in from your
						deleted account stay on Bditto, but people can’t see who
						they came from.
					</li>
				</ul>
			</>
		),
	},
	{
		head: "Any other questions?",
		content:
			"This is the fun part, directly post your question or problem on Bditto with: #Bditto #support",
	},
];

export default generalFaqs;
