import Styles from "./GroupInfo.module.css";
import React from "react";
import { Avatar, Switch, withStyles } from "@material-ui/core";
import {
	actionGroupRequest,
	editGroupURL,
	getGroupDetails,
	leaveTheGroupsURL,
	makeUserAdminURL,
	removeUserGroupURL,
	deleteGroupURL,
	joinGroupsURL,
} from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import axiosConfig from "../../../../Utils/axiosConfig";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const GroupInfo = (props) => {
	const [bio, setBio] = useState("");
	const [admin, setAdmin] = useState("");
	const [adminData, setAdminData] = useState({});
	const [group, setGroup] = useState({});
	const [avatar, setAvatar] = useState("");
	const [grpName, setGrpname] = useState("");
	const [public1, setPublic] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadImage, setuploadImage] = useState("");
	const [UploadUrl, setUploadUrl] = useState(null);
	const imgRef = React.createRef();
	const { showAlert } = useAlert();
	const [editName, setEditName] = useState(false);
	const [editBio, setEditBio] = useState(false);

	const [changed, setChanged] = useState(false);

	const groupID = props.groupId;
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const loggedinUserID = useSelector((state) => state.auth.user_data?.userID);

	useEffect(() => {
		if (editBio || editName || uploadImage) {
			setChanged(true);
		} else {
			setChanged(false);
		}
	}, [editBio, editName, uploadImage]);

	function onHide() {
		// history.push("/");
	}
	const handleUploadImage = (event) => {
		setuploadImage(event.target.files[0]);
		setUploadUrl(URL.createObjectURL(event.target.files[0]));
	};
	const handleCamClick = () => {
		imgRef.current.click();
	};
	const StyledSwitch = withStyles((theme) => ({
		root: {
			height: "3.3rem",
			width: "5rem",
		},
		checked: {
			transform: "translateX(28px) !important",
			"& + $track": {
				backgroundColor: "#4A517E !important",
				opacity: "1 !important",
			},
		},
		switchBase: {
			left: 4,
			top: 3,
		},
		track: {
			background: "#4A517E",
			borderRadius: 27,
			opacity: 1,
		},
		thumb: {
			width: "27px",
			height: "27px",
			background: "#fff",
		},
	}))(Switch);

	const getGroupInfo = () => {
		const form = new FormData();
		form.append("groupId", groupID ? groupID : 1);

		axiosConfig
			.post(getGroupDetails, form)
			.then((res) => {
				setGroup(res.data.body || res.data.result);
				// checkMemberGroup(res.data.body.member_list);
				if (res.data.body.group_details.type === "private")
					setPublic(false);
				setAdmin(res.data.body.member_list[0].user.userID);
				setAdminData(res.data.body.member_list[0]);
				setGrpname(res.data.body.group_details?.name);
				setAvatar(res.data.body.group_details?.avatar);
				setBio(res.data.body.group_details?.bio);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert(err.response?.data?.body, {
					header: err.response?.data?.error,
					callback: () => onHide(),
					buttonText: "Close",
				});
				setGroup([]);
				setAdmin(false);
				setGrpname("");
				setAvatar("");
				setBio("");
			});
	};
	const EditGroup = () => {
		const form = new FormData();
		setIsLoading(true);

		form.append("groupId", groupID);
		form.append("name", grpName);
		form.append("type", public1 ? "public" : "private");
		form.append("bio", bio);
		form.append("avatar", uploadImage);

		axiosConfig
			.patch(editGroupURL, form)
			.then((res) => {
				showAlert("Edit Group Successfully", {
					header: "",
					buttonText: "Close",
				});
				// props.cancel();
				setIsLoading(false);
			})
			.catch((err) => {
				showAlert(JSON.stringify(err.response.data?.error), {
					header: err.response.data?.message,
					buttonText: "Close",
				});
				setIsLoading(false);
			});
	};
	useEffect(() => {
		getGroupInfo();
    }, []);
    
	return (
		<div className={Styles.mainContainer}>
			<div className={Styles.navbar}>
				<div className={Styles.icon}>
					<img
						src={"/icons/close.svg"}
						onClick={() => {
							props.onClose();
                        }}
                        
					/>
				</div>
				<p className={Styles.header}>Group Info</p>
			</div>
			{props.type == "info" && (
				<>
					<div className={Styles.topBlock}>
						<div className={Styles.iconBox}>
							<Avatar
								className={Styles.ava}
								style={{
									width: "72px",
									height: "72px",

									background:
										theme === "dark"
											? "#002a55"
											: "#00C6FF",
								}}
								src={uploadImage ? UploadUrl : avatar}
								// src={uploadImage ? UploadUrl : ""}
							></Avatar>
							<input
								type="file"
								style={{ display: "none" }}
								ref={imgRef}
								onChange={handleUploadImage}
								accept="image/gif,image/jpeg,image/jpg,image/png"
							/>
							{admin == loggedinUserID && (
								<img
									src={"/sample/camera.svg"}
									className={Styles.camera}
									onClick={handleCamClick}
								/>
							)}
						</div>
						{admin == loggedinUserID ? (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									position: "relative",
								}}
							>
								{editName ? (
									<input
										className={Styles.grpName}
										style={{ border: "none", color: "" }}
										value={grpName}
										onChange={(e) => {
											setGrpname(e.target.value);
										}}
									/>
								) : (
									<div className={Styles.grpName}>
										{grpName}
									</div>
								)}
								{!editName && (
									<img
										src={"/sample/pencil.png"}
										width="18"
										height={"18"}
										style={{
											position: "absolute",
											top: "1.5rem",
											right: "0.5rem",
										}}
										onClick={() => {
											setEditName(true);
										}}
									/>
								)}
							</div>
						) : (
							<div className={Styles.grpName}>{grpName}</div>
						)}
					</div>

					<div className={Styles.block}>
						<span className={Styles.blockHead}>DESCRIPTION</span>
						{admin == loggedinUserID && (
							<>
								{!editBio && (
									<img
										onClick={() => {
											setEditBio(true);
										}}
										src={"/sample/pencil.png"}
										width="18"
										height="18"
									/>
								)}
							</>
						)}
					</div>
					{editBio ? (
						<textarea
							className={Styles.contentBlock}
							value={bio}
							onChange={(e) => {
								setBio(e.target.value);
							}}
						/>
					) : (
						<div className={Styles.contentBlock}>{props.bio}</div>
					)}
					<div className={Styles.block}>
						<span className={Styles.blockHead}>ADMIN</span>
						{/* {admin == loggedinUserID && (
							<img
								src={"/sample/pencil.png"}
								width="18"
								height="18"
							/>
						)} */}
					</div>

					<div className={Styles.userBlock}>
						<Avatar
							style={{
								background:
									theme === "dark" ? "#d9def4" : "#0d0f22",
								color: theme === "dark" ? "#0d0f22" : "#d9def4",
							}}
							className={Styles.userIcon}
							src={
								props.adminData &&
								props.adminData.user &&
								props.adminData.user.avatar
							}
						>
							{props.adminData &&
								props.adminData.user &&
								props.adminData.user.full_name?.toUpperCase()}
						</Avatar>
						<div className={Styles.usernameBlock}>
							<span className={Styles.username}>
								{`@${
									props.adminData &&
									props.adminData.user &&
									props.adminData.user.username
								}`}
							</span>
							<span className={Styles.userBio}></span>
						</div>
					</div>
					<div className={Styles.block}>
						<span className={Styles.blockHead}>VISIBILITY</span>
					</div>
					<div className={Styles.privateBlock}>
						<img
							src={"/sample/LOCK.svg"}
							style={{ margin: "auto 0" }}
						/>
						<span
							className={Styles.username}
							style={{ margin: "auto 0" }}
						>
							Private Group
						</span>
						<StyledSwitch
							onChange={() =>
								public1 ? setPublic(false) : setPublic(true)
							}
							checked={!public1}
						/>
					</div>
					<p className={Styles.note}>
						Text description here to make the user understand about
						the permission.
					</p>
					{changed && (
						<div
							style={{
								padding: "0 1rem",
							}}
						>
							<button
								type="submit"
								className={Styles.button1}
								style={{
									marginBottom: "1rem",
								}}
								onClick={() => {
									setEditBio(false);
									setEditName(false);
									getGroupInfo();
								}}
							>
								<div
									style={{
										display: "inline-block",
									}}
								>
									<span>Cancel</span>
								</div>
							</button>
							<button
								type="submit"
								style={{
									background:
										"linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%)",
								}}
								className={Styles.button}
								onClick={() => {
									EditGroup();
								}}
							>
								<div
									style={{
										display: "inline-block",
									}}
								>
									<span>Save Changes</span>
								</div>
							</button>
						</div>
					)}
				</>
			)}
			{props.type === "members" && (
				<>
					<span className={Styles.subHead}>
						MEMBERS (
						{props.group &&
							props.group.member_list &&
							props.group.member_list.length}
						)
					</span>
					<div className={Styles.addBlock}>
						<div className={Styles.addIconBox}>
							<div
								style={{
									textAlign: "center",
									marginTop: "5px",
								}}
							>
								<img src={"/sample/addmember.svg"} />
							</div>
						</div>
						<div className={Styles.usernameBlock}>
							<span className={Styles.addUser}>Add members</span>
							<span className={Styles.userBio}>
								By email, name or by sharing link
							</span>
						</div>
					</div>
					{props.group &&
						props.group.member_list &&
						props.group.member_list.map((member) => (
							<div className={Styles.memberBlock}>
								<Avatar
									style={{
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
									className={Styles.userIcon}
									src={member.user.avatar}
								></Avatar>
								<div className={Styles.usernameBlock}>
									<span className={Styles.username}>
										{`@${member.user.username}`}
									</span>
									<span className={Styles.userBio}></span>
								</div>
							</div>
						))}
				</>
			)}
		</div>
	);
};

export default GroupInfo;
