import { Dialog } from "@material-ui/core";
import React from "react";

const DialogModal = (props) => {
	return (
		<Dialog
			classes={{ paper: props.className }}
			style={{
				backgroundColor: "rgba(0, 0, 0, 0.06)",
				backdropFilter: "blur(1.7px)",
				borderRadius: props.rounded ? "16px" : "0px",
			}}
			open={props.show}
			onClose={props.onHide}
			maxWidth={props.width}
			fullWidth={props.maxWidth}
		>
			{props.children}
		</Dialog>
	);
};

export default DialogModal;
