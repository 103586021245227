export const FeedbackIcon = ({ style, color }) => {
	return (
		<svg
			style={style}
			width="15"
			height="17"
			viewBox="0 0 15 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_2288:43143"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="15"
				height="17"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.667969 0.5H14.9851V16.2829H0.667969V0.5Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_2288:43143)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.92653 2.18054L2.0807 11.993C1.9382 12.1714 1.8857 12.4014 1.9382 12.6222L2.5057 15.0264L5.0382 14.9947C5.27903 14.9922 5.50153 14.8847 5.64903 14.7014C8.32986 11.3472 13.4407 4.9522 13.5857 4.7647C13.7224 4.54304 13.7757 4.2297 13.704 3.92804C13.6307 3.61887 13.4382 3.35637 13.1607 3.18887C13.1015 3.14804 11.6974 2.05804 11.654 2.02387C11.1257 1.60054 10.3549 1.67387 9.92653 2.18054V2.18054ZM2.01236 16.283C1.7232 16.283 1.47153 16.0847 1.40403 15.8022L0.721531 12.9097C0.580697 12.3105 0.720697 11.6922 1.10486 11.2122L8.95486 1.39387C8.9582 1.39054 8.9607 1.38637 8.96403 1.38304C9.82486 0.353869 11.3815 0.202202 12.4315 1.0447C12.4732 1.0772 13.8674 2.16054 13.8674 2.16054C14.374 2.4622 14.7699 3.00137 14.9199 3.6397C15.069 4.27137 14.9607 4.92304 14.6132 5.47387C14.5874 5.5147 14.5649 5.5497 6.62486 15.483C6.24236 15.9597 5.66903 16.2372 5.0532 16.2447L2.0207 16.283H2.01236Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};
