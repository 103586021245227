/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useRef, useState } from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles"; 
import TextField from "@material-ui/core/TextField";
import axiosConfig, { bdittoAPIv2 } from "../../../Utils/axiosConfig";
import {
	editMyProfileURL,
	changeMyPasswordURL,
	getAllIndustry,
    checkUsername,
    getMyProfileURL
} from "../../../Utils/Constants";
import { resetUserData } from "../../../Redux/Actions/actionAuth";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import styles from "./MyProfile.module.css";
import { useLocationPredictions } from "../../Extras/PlacesAutoComplete";
import { LocationCity, Public } from "@material-ui/icons";
import { ClickAwayListener, IconButton } from "@material-ui/core";
import withAlert from "../../Extras/Alert/withAlert";
import { FormattedMessage } from "react-intl";
import Arrows from "../../../Icons/ArrowLeft";
import {  withRouter } from "react-router";
import CameraEditIcon from "../../../Icons/CameraEditIcon";
import { validateUrls } from "./urlValidatior";
import {  Tooltip } from "@material-ui/core";
import LinkedinIcon from "../../../Icons/Linkedin";
import InstagramIcon from "../../../Icons/Instagram";
import WorkIcon from "../../../Icons/Work";
import GitHubIcon from "../../../Icons/Github";
import TwitterIcon from "../../../Icons/Twitter";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import Button2 from "../../Extras/Buttons/Button2/Button2";
import { Autocomplete } from "@material-ui/lab";

const useStyles = (theme) => ({
	cancel: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		fontWeight: "400",
		marginRight: "1rem",
	},
	save: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		fontWeight: "400",
		marginLeft: "1rem",
	},
	username: {
		textAlign: "center",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: "bold",
		fontSize: "1.31rem",
		lineHeight: "1.563rem",
		marginTop: "0.2rem",
		color: "#FF6C5C ",
	},
});

const CustomInput1 = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#7f89be !important",
		},
		"& .MuiFormControl-root": {
			width: "100% !important",
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: "#7f89be",
			padding: "0 10px",
			zIndex: "5",
			background: "#121528",
		},
		"& .MuiInputLabel-outlined": {
			color: "#7f89be",
		},
		"& .MuiOutlinedInput-input": {
			color: "#d9def4",
			border: "1px solid #7f89be",
			borderRadius: "8px",
			padding: "20px",
		},
		"&::label": {
			color: "#7f89be !important",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#7f89be !important",
		},
		"& .MuiOutlinedInput-root": {
			fontFamily: "Poppins !important",
			"& fieldset": {
				borderColor: "#7f89be !important",
				color: "#7f89be !important",
			},
			"&:hover fieldset": {
				borderColor: "#7f89be !important",
				color: "#7f89be !important",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#7f89be !important",
				color: "#7f89be !important",
			},
			"& .MuiFormLabel-root , .MuiInputBase-root": {
				color: "#7f89be !important",
			},
			".MuiOutlinedInput-multiline": {
				padding: "0",
			},
		},
	},
})(TextField);

const CustomInput2 = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#4A517E !important",
		},
		"& .MuiFormControl-root": {
			width: "100% !important",
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: "#4A517E",
			padding: "0 10px",
			zIndex: "5",
			background: "#fff",
		},
		"& .MuiInputLabel-outlined": {
			color: "#4A517E",
		},
		"& .MuiOutlinedInput-input": {
			color: "#0d0f22",
			border: "1px solid #4A517E",
			borderRadius: "8px",
			padding: "20px",
		},
		"&::label": {
			color: "#4A517E !important",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#4A517E !important",
		},
		"& .MuiOutlinedInput-root": {
			fontFamily: "Poppins !important",
			"& fieldset": {
				borderColor: "#4A517E !important",
				color: "#4A517E !important",
			},
			"&:hover fieldset": {
				borderColor: "#4A517E !important",
				color: "#4A517E !important",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#4A517E !important",
				color: "#4A517E !important",
			},
			"& .MuiFormLabel-root , .MuiInputBase-root": {
				color: "#4A517E !important",
			},
			".MuiOutlinedInput-multiline": {
				padding: "0",
			},
		},
	},
})(TextField);

const IndustryInputText1 = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#7F89BE !important",
		},
		"& .MuiFormControl-root": {
			width: "100% !important",
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: "#7F89BE",
			padding: "0 10px",
			zIndex: "5",
			background: "#121528",
		},
		"& .MuiInputLabel-outlined": {
			color: "#7F89BE",
		},
		"& .MuiOutlinedInput-input": {
			color: "#D9DEF4",
		},
		"&::label": {
			color: "#7F89BE !important",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#7F89BE !important",
		},
		"& .MuiOutlinedInput-root": {
			fontFamily: "Poppins !important",
			"& fieldset": {
				borderColor: "#7F89BE !important",
				color: "#7F89BE !important",
			},
			"&:hover fieldset": {
				borderColor: "#7F89BE !important",
				color: "#7F89BE !important",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#7F89BE !important",
				color: "#7F89BE !important",
			},
			"& .MuiFormLabel-root , .MuiInputBase-root": {
				color: "#7F89BE !important",
			},
			".MuiOutlinedInput-multiline": {
				padding: "0",
			},
		},
	},
})(TextField);

const IndustryInputText2 = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#7F89BE !important",
		},
		"& .MuiFormControl-root": {
			width: "100% !important",
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			color: "#7F89BE",
			padding: "0 10px",
			zIndex: "5",
			background: "#fff",
		},
		"& .MuiInputLabel-outlined": {
			color: "#7F89BE",
		},
		"& .MuiOutlinedInput-input": {
			color: "#0d0f22",
		},
		"&::label": {
			color: "#7F89BE !important",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#7F89BE !important",
		},
		"& .MuiOutlinedInput-root": {
			fontFamily: "Poppins !important",
			"& fieldset": {
				borderColor: "#7F89BE !important",
				color: "#7F89BE !important",
			},
			"&:hover fieldset": {
				borderColor: "#7F89BE !important",
				color: "#7F89BE !important",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#7F89BE !important",
				color: "#7F89BE !important",
			},
			"& .MuiFormLabel-root , .MuiInputBase-root": {
				color: "#7F89BE !important",
			},
			".MuiOutlinedInput-multiline": {
				padding: "0",
			},
		},
	},
})(TextField);

var now = new Date();
var day = ("0" + now.getDate()).slice(-2);
var month = ("0" + (now.getMonth() + 1)).slice(-2);
var today = now.getFullYear() + "-" + (month) + "-" + (day);

class MyProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			errorFetchingProfile: false,
			username: "",
			oldUsername: "",
			usernamemsg: "",
			fullName: "",
			email: "",
			country: "",
			city: "",
			dateOfBirth: null,
			gender: "",
			avatar: null,
			isEdit: true,
			isLockCity: true,
			isChangePassword: false,
			isLoadingPass: false,
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			uploadImage: "",
			UploadUrl: "",
			bio: "",
			profession: "",
			industry: "",
			facebook: "",
			linkedin: "",
			github: "",
			twitter: "",
			instagram: "",
			others: "",
			facebookId: "",
			instagramId: "",
			linkedinId: "",
			githubId: "",
			twitterId: "",
			othersId: "",
			options: "",
			showCancelBtn: false,
            delete: false,
            authProvider: "",
            dateError:""
		};

		this.imgRef = React.createRef();
	}

	componentDidMount() {
		this.resetToOriginal();
		this.handleIndustry();
	}
  
	// Handle Switching from Edit to change pass and vice versa
	handleEdit = () => {
		this.setState({ isEdit: true, isChangePassword: false });
		if (this.state.country) {
			this.setState({ isLockCity: false });
		}
	};

	handleChangePassword = () => {
		if (this.props.user_data?.user?.authProvider === "email") {
			this.setState({ isEdit: false, isChangePassword: true });
		}
	};

	// Handle any input while editing
    handleChange = (event) => {
        function usernameRegex(str) {
            return /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(str);
        }

		if (event.target.name === "username") {
			this.setState({
				usernamemsg: "",
            });
             if (event.target.value.indexOf(" ") >= 0) {
                this.props.showAlert(
                    "username error",
                {
                    header: "username should not contain space",
                    buttonText: "close",
                }
                );
			} 
            else if (usernameRegex(event.target.value)) {
                this.props.showAlert(
                  "username error",

                {
                    header: "username should not contain special character",
                    buttonText: "close",
                });
			}
            else {
                this.setState({
				[event.target.name]: event.target.value,
				showCancelBtn: true,
			});
			const URL = `${checkUsername}/?username=${event.target.value}`;
			const form = new FormData();
			form.append("username", event.target.value);
			axiosConfig
				.get(URL, form)
				.then((res) => {
					if (res.status !== 200) throw new Error();
				})
				.catch((err) => {
					this.setState({
						usernamemsg: err.response.data.message,
					});
				});
            }
		}

        else {
            this.setState({
                [event.target.name]: event.target.value,
                showCancelBtn: true,
            });
        }
	};

	showAlert = (message) => {
		this.props.showAlert(" ", {
			header: `${message}`,
			buttonText: "Go back",
		});
	};

	setImgRef = () => {
		this.imgRef.current.click();
	};

    handleUploadImage = (event) => {
        if (event.target.files[0].size < 5242880) {
            this.setState({ showCancelBtn: true });
            this.setState({
                uploadImage: event.target.files[0],
                UploadUrl: URL.createObjectURL(event.target.files[0]),
            });
        }
        else {
            this.props.showAlert(
                "image size must be less than 5MB.",
                {
                    header: "File size too large",
                    buttonText: "Try another one",
                }
            );
        }
            
    }
	

	// Handle Submit Event for any

    hadleSubmitChangePassword = () => {
        const passwordRegex = new RegExp(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
        );
		if (
			!this.state.oldPassword ||
			!this.state.newPassword ||
			!this.state.confirmPassword
		) {
		    return	this.props.showAlert("Please fill out mandatory fields");
		}
		else if (this.state.newPassword !== this.state.confirmPassword) {
			return this.props.showAlert("Passwords should be the same");
		}
		else if (this.state.oldPassword === this.state.newPassword) {
		 	return this.props.showAlert("Old Password and new password cannot be same");
        }
        else if (this.state.newPassword.length < 12 || this.state.confirmPassword.length < 12) {
            return this.props.showAlert("Passwords should be minimum 12 characters");
        }
        else if (this.state.newPassword.indexOf(" ") >= 0 || this.state.confirmPassword.indexOf(" ") >= 0) {
            return this.props.showAlert("Passwords should not contain spaces");
        }
		else if (!passwordRegex.test(this.state.newPassword)) {
		 	return this.props.showAlert("passwords should contain atleast one number, special character, Upper case and lower case");
        }
            
        else {
            this.setState({ isLoadingPass: true });
            const form = new FormData();
            form.append("old_password", this.state.oldPassword);
            form.append("new_password", this.state.newPassword);
            form.append("confirm_password", this.state.confirmPassword);
		axiosConfig
			.post(changeMyPasswordURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.showAlert("Password changed successfully");
				this.setState({
					isChangePassword: false,
					oldPassword: "",
					newPassword: "",
					confirmPassword: "",
					isLoadingPass: false,
				});
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					}
				}

				const catchError = err.response.data;
				this.props.showAlert(catchError.message);
				this.setState({
					oldPassword: "",
					newPassword: "",
					confirmPassword: "",
					isLoadingPass: false,
				});
			});
        }
	};

	handleSubmitEditProfile = () => {
		const form = new FormData();
		if (
			!this.state.username ||
			!this.state.fullName ||
			!this.state.gender ||
			!this.state.bio ||
			!this.state.profession ||
			!this.state.industry
		) {
			return this.showAlert("Please fill out mandatory fields");
		} else if (!this.state.dateOfBirth) {
			return this.showAlert("Please enter the date of birth");
        }
        else if (this.state.username < 4) {
			return this.showAlert("username min 4 characters");
		}
		else if (this.state.city === "null" || this.state.country === "null") {
			return this.showAlert("Please enter city and country");
		}
		else if (this.state.bio?.length > 250) {
			return this.showAlert("Bio can not be more than 250 characters");
		}
        else if (this.state.dateOfBirth > today) {
            return this.showAlert("date of birth must be less than today's date");
        }

        else {
             
        form.append("username", this.state.username);
		form.append("full_name", this.state.fullName);
		form.append("city", this.state.city);
		form.append("country", this.state.country);
		form.append("gender", this.state.gender);
		form.append("dob", this.state.dateOfBirth);
		form.append("bio", this.state.bio);
		form.append("profession", this.state.profession);
		form.append("industry_id", this.state.industry.id);
        const UrlError = validateUrls(this.state, form);
        
		if (typeof UrlError == "string") {
			this.showAlert(UrlError);
			return 0;
		} 
		if (this.state.uploadImage) {
			form.append("avatar", this.state.uploadImage);
		}
		this.setState({ isLoadingPass: true });
		bdittoAPIv2
			.post(editMyProfileURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.showAlert("Profile Edit successfull");
				this.props.resetUserData(res.data.body);
                this.setState({ isLoadingPass: false, isEdit: true });
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else if (
						err.response.data.error ===
						"Username is not Available! Already taken."
					) {
						this.setState({
							usernamemsg: err.response.data.error,
						});
					} else {
						this.props.showAlert(err.response.data.message, {
							header: err.response.data.error,
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
				this.resetToOriginal();
			});}
	};

	onLocationSelect = ({ city, country }) => {
        this.setState({ city: city || "", country: country });
        this.setState({ showCancelBtn: true });

	};

	// Reseting the values of the fields to original
	resetToOriginal = () => {
        axiosConfig
		.get(getMyProfileURL)
		.then((res) => {
            let user_data = res.data.body;
            if (user_data.user) {
			this.setState({
				username: user_data.user.username,
				oldUsername: user_data.user.username,
				fullName: user_data.full_name,
                email: user_data.user.email,
                authProvider: user_data.user.auth_provider,
				gender: user_data.gender,
				dateOfBirth: "",
				city: user_data.city,
				country: user_data.country,
				avatar: user_data.avatar,
				isEdit: true,
				isChangePassword: false,
				isLoadingPass: false,
				oldPassword: "",
				newPassword: "",
				confirmPassword: "",
				bio: user_data.bio,
				profession: user_data.profession,
				industry: user_data.industry,
				showCancelBtn: false,
			});
		}

		if (user_data.WebUrls)
			user_data.WebUrls.forEach((url) => {
				this.setState({
					[url.key]: url.value,
					[url.key + "Id"]: url.id,
				});
			});

		if (user_data.date_of_birth) {
			let todayDate = new Date(user_data.date_of_birth)
				.toISOString()
				.slice(0, 10);
			this.setState({ dateOfBirth: todayDate });
		}
		})
        .catch((err) => {
            this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
			});
		});

	};

	async handleIndustry(word) {
		try {
			let URL = `${getAllIndustry}${word}`;
			let res = await bdittoAPIv2.get(URL);
			this.setState({
				options: [...res.data.body],
			});
		} catch (err) {
			console.log(err);
		}
	}

	handleSelect(e) {
		this.state.options.map((option) => {
			if (option.name === e.target.value)
				this.setState({
					industry: { ...option },
				});
		});
	}

	// Display HTML functions

	showChangePassword() {
		return (
			this.state?.authProvider === "email" && (
				<div className={styles.changePassContainer}>
					<div className={styles.passwordFields}>
						<label className={styles.label}>Old Password</label>
						<input
							className={styles.fieldEdit}
							type="password"
							name="oldPassword"
							onChange={this.handleChange}
							value={this.state.oldPassword}
							placeholder="Enter Your Old Password"
						/>
					</div>
					<div className={styles.passwordFields}>
						<label className={styles.label}>New Password</label>
						<input
							className={styles.fieldEdit}
							type="password"
							name="newPassword"
							onChange={this.handleChange}
							value={this.state.newPassword}
							placeholder="Enter Your New Password"
						/>
					</div>
					<div className={styles.passwordFields}>
						<label className={styles.label}>Confirm Password</label>
						<input
							className={styles.fieldEdit}
							type="password"
							name="confirmPassword"
							onChange={this.handleChange}
							value={this.state.confirmPassword}
							placeholder="Confirm Your New Password"
						/>
					</div>

					<div className={styles.buttons}>
						{this.state.authProvider ==="email"&& <Button2
							variant="contained"
							color="primary"
							style={{
								width: "54%",
								marginLeft: "-0.2rem",
								marginTop: "-1rem",
							}}
							className={this.props.classes.save}
							onClick={this.hadleSubmitChangePassword}
							disabled={this.state.isLoadingPass}
						>
							{this.state.isLoadingPass ? (
								<CircularProgress
									style={{ fontSize: "10px", color: "white" }}
								/>
							) : (
								<>Change Password</>
							)}
						</Button2>}
					</div>
				</div>
			)
		);
	}

	showUserDetails() {
		const { classes } = this.props;
		const firstField = {
			color: "#7F89BE",
			width: "50%",
			display: "flex",
			flexDirection: "column",
			marginBottom: "0",
			marginTop: "0",
		};
		return (
			<>
				{this.state.isEdit && (
					<>
						<div
							className={
								this.state.isEdit
									? styles.dataEdit
									: styles.data
							}
						>
							<div className={styles.flex}>
								{this.props.theme === "dark" ? (
									<CustomInput1
										label="Username*"
										name="username"
										autoComplete="invalidField"
										variant="outlined"
										color="primary"
										value={this.state.username}
										onChange={this.handleChange}
									/>
								) : (
									<CustomInput2
										label="Username*"
										name="username"
										autoComplete="invalidField"
										variant="outlined"
										color="primary"
										value={this.state.username}
										onChange={this.handleChange}
									/>
								)}
								<p
									className={
										this.props.theme === "dark"
											? styles.darkPText
											: styles.lgPText
									}
								>
									{this.state.usernamemsg}
								</p>
							</div>
						</div>

                        <div
                            className={
								this.state.isEdit
									? styles.dataEdit
									: styles.data
							}>
						<Autocomplete
                            fullWidth
							className={`${
								this.props.theme === "dark"
									? styles.industryInputdark
									: styles.industryInputlg
							}`}
							options={this.state.options}
							getOptionLabel={(option) => option.name}
							value={this.state.industry}
							onSelect={(e) => this.handleSelect(e)}
							renderInput={(params) => (
								<>
									{this.props.theme === "dark" ? (
										<IndustryInputText1
                                            fullWidth
											{...params}
											label="Industry*"
                                            variant="outlined"
											className={`${
												this.props.theme === "dark"
													? styles.industryInputTextdark
													: styles.industryInputTextlg
											}`}
											onChange={(e) =>
												this.handleIndustry(
													e.target.value
												)
											}
											onClick={(e) =>
												this.handleIndustry(
													e.target.value
												)
											}
										/>
									) : (
                                        <IndustryInputText2
                                            fullWidth
											{...params}
											label="Industry*"
											variant="outlined"
											className={`${
												this.props.theme === "dark"
													? styles.industryInputTextdark
													: styles.industryInputTextlg
											}`}
											onChange={(e) =>
												this.handleIndustry(
													e.target.value
												)
											}
											onClick={(e) =>
												this.handleIndustry(
													e.target.value
												)
											}
										/>
									)}
								</>
							)}
						/>
                        </div>
						<div
							className={
								this.state.isEdit
									? styles.dataEdit
									: styles.data
							}
						>
							{this.props.theme === "dark" ? (
								<CustomInput1
									label="What's your expertise?*"
									name="profession"
									autoComplete="invalidField"
									variant="outlined"
									color="primary"
									value={this.state.profession}
									onChange={this.handleChange}
								/>
							) : (
								<CustomInput2
									label="What's your expertise?*"
									name="profession"
									autoComplete="invalidField"
									variant="outlined"
									color="primary"
									value={this.state.profession}
									onChange={this.handleChange}
								/>
							)}
						</div>

						<div
							className={
								this.state.isEdit
									? styles.dataEdit
									: styles.data
							}
						>
							{this.props.theme === "dark" ? (
								<CustomInput1
									label="Bio*"
									value={this.state.bio}
									onChange={this.handleChange}
									multiline
									rows={4}
									name="bio"
									color="primary"
									variant="outlined"
								/>
							) : (
								<CustomInput2
									label="Bio*"
									value={this.state.bio}
									onChange={this.handleChange}
									multiline
									rows={4}
									name="bio"
									color="primary"
									variant="outlined"
								/>
							)}
						</div>
					</>
				)}

				<div
					className={
						this.state.isEdit ? styles.firstDataEdit : styles.data
					}
				>
					<div
						className={`${styles.headerText2} ${
							this.props.theme === "dark"
								? styles.darkPText
								: styles.lgPText
						}`}
					>
						<h2>
							<FormattedMessage
								id="basicInformation"
								defaultMessage={`Basic Information`}
							/>
						</h2>
					</div>
				</div>

				<div
					className={
						this.state.isEdit ? styles.dataEdit : styles.data
					}
				>
					{this.props.theme === "dark" ? (
						<CustomInput1
							label="Full Name*"
							name="fullName"
							autoComplete="invalidField"
							variant="outlined"
							color="primary"
							value={this.state.fullName}
							onChange={this.handleChange}
						/>
					) : (
						<CustomInput2
							label="Full Name*"
							name="fullName"
							autoComplete="invalidField"
							variant="outlined"
							color="primary"
							value={this.state.fullName}
                                onCha
                                nge={this.handleChange}
						/>
					)}
				</div>

				<div
					className={
						this.state.isEdit ? styles.dataEdit : styles.data
					}
				>
					{this.props.theme === "dark" ? (
                        <CustomInput1
                            id="date"
							// label="Date of Birth*"
							name="dateOfBirth"
							type="date"
							// autoComplete="invalidField"
							variant="outlined"
							color="primary"
							value={this.state.dateOfBirth}
							onChange={this.handleChange}
							className={styles.dateView}
                            InputProps={{ inputProps: { max: `${today}` }}}
		  				/>
					) : (
                            <CustomInput2
                            id="date"
							// label="Date of Birth*"
							name="dateOfBirth"
							type="date"
							// autoComplete="invalidField"
							variant="outlined"
							color="primary"
							value={this.state.dateOfBirth}
                            onChange={this.handleChange}
                            InputProps={{ inputProps: { max: `${today}` }}}
                               
						/>
					)}
				</div>

				<div
					className={`${
						this.state.isEdit ? styles.dataEdit : styles.data
					}`}
				>
					<LocationSelect
						theme={this.props.theme}
						currentLocation={{
							city: this.state.city,
							country: this.state.country,
						}}
						onLocationSelect={this.onLocationSelect}
						isEdit={this.state.isEdit}
					/>
				</div>

				<div
					className={
						this.state.isEdit ? styles.dataEdit : styles.data
					}
				>
					<select
						variant="outlined"
						name="gender"
						value={this.state.gender}
						onChange={this.handleChange}
						className={`${
							this.state.isEdit ? styles.fieldEdit : styles.field
						} ${
							this.props.theme === "dark"
								? styles.darkPText
								: styles.lgPText
						}`}
						disabled={!this.state.isEdit}
					>
						<option
							className={
								this.props.theme === "dark"
									? `${styles.darkPText} ${styles.darkbg}`
									: `${styles.lgPText} ${styles.lgbg}`
							}
							value={null}
						>
							Not specified
						</option>
						<option
							className={
								this.props.theme === "dark"
									? `${styles.darkPText} ${styles.darkbg}`
									: `${styles.lgPText} ${styles.lgbg}`
							}
							value="Female"
						>
							Female
						</option>
						<option
							className={
								this.props.theme === "dark"
									? `${styles.darkPText} ${styles.darkbg}`
									: `${styles.lgPText} ${styles.lgbg}`
							}
							value="Male"
						>
							Male
						</option>
						<option
							className={
								this.props.theme === "dark"
									? `${styles.darkPText} ${styles.darkbg}`
									: `${styles.lgPText} ${styles.lgbg}`
							}
							value="Other"
						>
							Other
						</option>
					</select>
				</div>
			</>
		);
	}

	showWeburls() {
		return (
			<>
				{/* {(this.state.email || this.state.isEdit) && (


          <div className={this.state.isEdit ? styles.dataUrlContainer : styles.hide}>
            <div className={this.state.isEdit ? styles.dataUrlEdit : styles.data1}>
					<div className={styles.icon}>
					<MailOutline  />
					</div>
					<Tooltip  placement="top" title={this.state.email}>
					<input
					className={this.state.isEdit ? styles.fieldUrlEdit : styles.fieldUrl}
					type="text"
					name="email"
					value={this.state.email}
					placeholder="Eg: name@mail.com "
					onChange={this.handleChange}
					disabled
					/>
					</Tooltip>
            </div>
          </div>
        )} */}
				{/* {(this.state.facebook || this.state.isEdit )&&
					<div  className={this.state.isEdit? styles.dataUrlContainer : ""}>
            <div
					className={this.state.isEdit ? styles.dataUrlEdit :styles.data1}
            >
					<FacebookIcon className={styles.icon} />
					<a href={this.state.isEdit ? null :this.state.facebook} target="_blank" >
					<Tooltip  placement="top"
					title={this.state.facebook}
					>
					<input
					className={this.state.isEdit ? styles.fieldUrlEdit :styles.fieldUrl}
					type="text"
					name="facebook"
					value={this.state.facebook}
					placeholder="Eg: http://www.facebook.com/ "
					onChange={this.handleChange}
					disabled={!this.state.isEdit}
					/>
					</Tooltip>
					</a>
					</div>
				</div>} */}
				{(this.state.instagram || this.state.isEdit) && (
					<div
						className={
							this.state.isEdit ? styles.dataUrlContainer : ""
						}
					>
						<div
							className={
								this.state.isEdit
									? styles.dataUrlEdit
									: styles.data1
							}
						>
							<div className={styles.icon}>
								<InstagramIcon />
							</div>

							<Tooltip
								placement="top"
								title={this.state.instagram}
							>
								<a
									href={
										this.state.isEdit
											? null
											: this.state.instagram
									}
									target="_blank" rel="noreferrer" 
								>
									<input
										className={`${
											this.state.isEdit
												? styles.fieldUrlEdit
												: styles.fieldUrl
										} ${
											this.props.theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
										type="text"
										name="instagram"
										value={this.state.instagram}
										placeholder="Eg: http://www.instagram.com/ "
										onChange={this.handleChange}
										disabled={!this.state.isEdit}
									/>
								</a>
							</Tooltip>
						</div>
					</div>
				)}

				{(this.state.github || this.state.isEdit) && (
					<div
						className={
							this.state.isEdit ? styles.dataUrlContainer : ""
						}
					>
						<div
							className={
								this.state.isEdit
									? styles.dataUrlEdit
									: styles.data1
							}
						>
							<div className={styles.icon}>
								<GitHubIcon />
							</div>

							<Tooltip placement="top" title={this.state.github}>
								<a
									href={
										this.state.isEdit
											? null
											: this.state.github
									}
									target="_blank" rel="noreferrer"
								>
									<input
										className={`${
											this.state.isEdit
												? styles.fieldUrlEdit
												: styles.fieldUrl
										} ${
											this.props.theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
										type="text"
										name="github"
										value={this.state.github}
										placeholder="Eg: http://www.github.com/ "
										onChange={this.handleChange}
										disabled={!this.state.isEdit}
									/>
								</a>
							</Tooltip>
						</div>
					</div>
				)}
				{(this.state.twitter || this.state.isEdit) && (
					<div
						className={
							this.state.isEdit ? styles.dataUrlContainer : ""
						}
					>
						<div
							className={
								this.state.isEdit
									? styles.dataUrlEdit
									: styles.data1
							}
						>
							<div className={styles.icon}>
								<TwitterIcon />
							</div>
							<Tooltip placement="top" title={this.state.twitter}>
								<a
									href={
										this.state.isEdit
											? null
											: this.state.twitter
									}
									target="_blank" rel="noreferrer"
								>
									<input
										className={`${
											this.state.isEdit
												? styles.fieldUrlEdit
												: styles.fieldUrl
										} ${
											this.props.theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
										type="text"
										name="twitter"
										value={this.state.twitter}
										placeholder="Eg: https://www.twitter.com"
										onChange={this.handleChange}
										disabled={!this.state.isEdit}
									/>{" "}
								</a>
							</Tooltip>
						</div>
					</div>
				)}
				{(this.state.linkedin || this.state.isEdit) && (
					<div
						className={
							this.state.isEdit ? styles.dataUrlContainer : ""
						}
					>
						<div
							className={
								this.state.isEdit
									? styles.dataUrlEdit
									: styles.data1
							}
						>
							<div className={styles.icon}>
								<LinkedinIcon />
							</div>

							<Tooltip
								placement="top"
								title={this.state.linkedin}
							>
								<a
									href={
										this.state.isEdit
											? null
											: this.state.linkedin
									}
									target="_blank" rel="noreferrer"
								>
									<input
										className={`${
											this.state.isEdit
												? styles.fieldUrlEdit
												: styles.fieldUrl
										} ${
											this.props.theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
										type="text"
										name="linkedin"
										value={this.state.linkedin}
										placeholder="Eg: https://www.linkedin.com/ "
										onChange={this.handleChange}
										disabled={!this.state.isEdit}
									/>
								</a>
							</Tooltip>
						</div>
					</div>
				)}

				{(this.state.others || this.state.isEdit) && (
					// <div  className={this.state.isEdit? styles.dataUrlContainer : ""}>

					//     <div
					//       className={this.state.isEdit ? styles.dataUrlEdit :styles.data1}>
					//       <span className={this.state.isEdit ? styles.icon :styles.icon1}>

					//       <div className={styles.icon}>
					//         <WorkIcon />
					//       </div>
					//       </span>
					//       <Tooltip  placement="top" title={this.state.others} >
					//       <a href={this.state.isEdit ? null :this.state.others} target="_blank" >   <input
					//         className={this.state.isEdit ? styles.fieldUrlEditWeb :styles.fieldUrl}
					//         type="text"
					//         name="others"
					//         value={this.state.others}
					//         placeholder="Eg: https://www.domain.com/"
					//         onChange={this.handleChange}
					//         disabled={!this.state.isEdit}
					//       /></a>
					//       </Tooltip>
					//        </div></div>

					<div
						className={
							this.state.isEdit ? styles.dataUrlContainer : ""
						}
					>
						<div
							className={
								this.state.isEdit
									? styles.dataUrlEdit
									: styles.data1
							}
						>
							<div className={styles.icon}>
								<WorkIcon />
							</div>

							<Tooltip placement="top" title={this.state.others}>
								<a
									href={
										this.state.isEdit
											? null
											: this.state.others
									}
                                    target="_blank" rel="noreferrer"
                                
								>
									<input
										className={`${
											this.state.isEdit
												? styles.fieldUrlEdit
												: styles.fieldUrl
										} ${
											this.props.theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
										type="text"
										name="others"
										value={this.state.others}
										placeholder="Eg: https://www.domain.com/"
										onChange={this.handleChange}
										disabled={!this.state.isEdit}
									/>
								</a>
							</Tooltip>
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		const isMobile = window.screen.width < 768;
		return (
			<>
				<div className={styles.coverContainer}>
					<div className={styles.mainContainer}>
						{!isMobile ? (
							<div className={styles.header}>
								{this.state.isChangePassword && (
									<IconButton
										size="small"
										variant="contained"
										onClick={
											this.state.isEdit
												? this.resetToOriginal
												: this.props.history.goBack
										}
									>
										<Arrows
											color={
												this.props.theme === "dark"
													? "#d9def4"
													: "#0d0f22"
											}
										/>
									</IconButton>
								)}

								<div
									className={`${styles.headerText} ${
										this.props.theme === "dark"
											? styles.darkPText
											: styles.lgPText
									}`}
								>
									<h2 style={{ margin: "0 0 0 5px" }}>
										<FormattedMessage
											id="editProfile"
											defaultMessage={`Edit Profile`}
										/>
									</h2>
								</div>
							</div>
						) : (
							<div className="my-3">
								{this.state.isChangePassword && (
									<IconButton
										size="small"
										variant="contained"
										onClick={
											this.state.isEdit
												? this.resetToOriginal
												: this.props.history.goBack
										}
									>
										<Arrows
											color={
												this.props.theme === "dark"
													? "#d9def4"
													: "#0d0f22"
											}
										/>
									</IconButton>
								)}
							</div>
						)}

						<div
							className={
								this.state.isChangePassword
									? styles.hide
									: styles.content
							}
						>
							<div
								className={
									this.state.isEdit
										? styles.topEdit
										: styles.top
								}
							>
								<div
									className={
										this.state.isEdit
											? styles.imageBoxEdit
											: styles.imageBox
									}
								>
									{(this.state.isLoading ||
										!this.state.avatar ||
										this.state.errorFetchingProfile) &&
									!this.state.uploadImage ? (
										<AccountCircleIcon
											style={{
												borderRadius: "50%",
												fontSize: !isMobile
													? "8.1rem"
													: "6rem",
												background:
													this.props.theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
										/>
									) : (
										<Avatar
											alt={this.state.fullName}
											style={{
												width: !isMobile
													? "8.1rem"
													: "6rem",
												height: !isMobile
													? "8.1rem"
													: "6rem",
												background:
													this.props.theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											src={
												this.state.uploadImage
													? this.state.UploadUrl
													: this.state.avatar
											}
										/>
									)}

									{this.state.isEdit && (
										<div
											className={styles.uploadPhoto}
											onClick={() => this.setImgRef()}
										>
											<CameraEditIcon
												isMobile={isMobile}
											/>
											<input
                                                type="file"
                                                accept="image/jpg, image/png, image/jpeg, image/gif"
												style={{ display: "none" }}
												onChange={(e) =>
													this.handleUploadImage(e)
												}
												ref={this.imgRef}
											/>
										</div>
									)}

									<div
										className={`${styles.userBox} ${
											this.props.theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<h2>{this.state.fullName}</h2>
										<p className="mb-1">
											{this.state.email}
										</p>
										{this.state.isEdit && this.state.authProvider ==="email"&& (
											<p
												onClick={() => {
													this.setState({
														isChangePassword: true,
													});
												}}
												style={{ cursor: "pointer" }}
												className="mb-1"
											>
												Change Password
											</p>
										)}
									</div>
								</div>

								{!this.state.isEdit && (
									<>
										<p className={styles.username}>
											@{this.state.username}
										</p>
										<div className={styles.profession}>
											{this.state.profession}
										</div>
									</>
								)}
							</div>

							<h1
								className={
									this.state.isEdit
										? styles.hide
										: styles.editText
								}
								onClick={this.handleEdit}
							>
								<FormattedMessage
									id="edit"
									defaultMessage={`Edit Profile`}
								/>
							</h1>
						</div>

						<div
							className={
								this.state.isEdit
									? styles.contentEdit
									: this.state.isChangePassword
									? ""
									: styles.content1
							}
						>
							<div
								className={
									this.state.isEdit
										? styles.leftEdit
										: this.state.isChangePassword
										? ""
										: styles.left
								}
							>
								<div
									className={
										this.state.isEdit
											? ""
											: this.state.isChangePassword
											? ""
											: styles.userDetails
									}
								>
									{this.state.isChangePassword
										? this.showChangePassword()
										: this.showUserDetails()}
								</div>
							</div>

							{!this.state.isChangePassword && (
								<>
									<div
										className={
											this.state.isEdit
												? styles.rightEdit
												: styles.right
										}
									>
										<div className={styles.userDls}>
											<div
												className={`${
													styles.headerText3
												} ${
													this.props.theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2>
													<FormattedMessage
														id="levelUp"
														defaultMessage={`Level Up`}
													/>
												</h2>
											</div>
											{this.state.isChangePassword
												? null
												: this.showWeburls()}
										</div>
									</div>

									{this.state.isEdit && !isMobile && (
										<div className={styles.buttons}>
											<div className="mx-3">
												<DeleteAccount />
											</div>
											<div className="d-flex ">
												{this.state.showCancelBtn && (
													<>
														<div
															onClick={
																this
																	.resetToOriginal
															}
															className={
																styles.cancelButton
															}
														>
															<Button1
																disabled={
																	this.state
																		.isLoadingPass
																}
															>
																<FormattedMessage
																	id="cancel"
																	defaultMessage={`Cancel Changes`}
																/>
															</Button1>
														</div>

														<div
															onClick={
																this
																	.handleSubmitEditProfile
															}
														>
															<Button2
																disabled={
																	this.state
																		.isLoadingPass
																}
															>
																<FormattedMessage
																	id="save"
																	defaultMessage={`Save Changes`}
																/>
															</Button2>
														</div>
													</>
												)}
											</div>
										</div>
									)}

									{this.state.isEdit && isMobile && (
										<div className={styles.buttons}>
											<div className="d-flex my-2 w-100">
												<div
													onClick={
														this.resetToOriginal
													}
													className={
														styles.cancelButton +
														" w-50"
													}
												>
													{this.state
														.showCancelBtn && (
														<Button1
															disabled={
																this.state
																	.isLoadingPass
															}
															padding={"8px 20px"}
														>
															<FormattedMessage
																id="cancel"
																defaultMessage={`Cancel `}
															/>
														</Button1>
													)}
												</div>
												<div
													onClick={
														this
															.handleSubmitEditProfile
													}
													className="w-50 d-flex justify-content-end"
												>
													<Button2
														disabled={
															this.state
																.isLoadingPass
														}
														padding={"8px 22px"}
													>
														<FormattedMessage
															id="save"
															defaultMessage={`Save `}
														/>
													</Button2>
												</div>
											</div>
											<div className="mt-5">
												<DeleteAccount />
											</div>
										</div>
									)}
								</>
							)}
						</div>

						{/* {!this.state.isEdit && !this.state.isChangePassword && (
							<>
								<div className={styles.buttons1}>
							{this.props.user_data?.user
							?.auth_provider === "email" && (
							<Button
							color="primary"
							variant="contained"
							style={{
							marginRight: "1rem",
							textTransform: "none",
							}}
							onClick={this.handleChangePassword}
							>
							<FormattedMessage
							id="changePassword"
							defaultMessage={`Change Password`}
							/>
							</Button>
							)}
							<DeleteAccount />
								</div>
							</>
						)} */}
					</div>
				</div>
			</>
		);
	}
}

const LocationSelect = ({
	onLocationSelect,
	currentLocation,
	isEdit,
	...props
}) => {
	const sessionToken = useRef();
	const [query, setQuery] = useState(
		currentLocation.city || currentLocation.country
	);
	const [searching, setSearching] = useState(false);
	const { predictions } = useLocationPredictions(query, {
		sessionToken,
		locationType: "(regions)",
	});
	const onSelect = (params) => {
		onLocationSelect(params);
		setSearching(false);
	};

	return (
		<ClickAwayListener onClickAway={() => setSearching(false)}>
			<div className={styles.locationSelector}>
				<input
					className={`${isEdit ? styles.fieldEdit : styles.field} ${
						props.theme === "dark"
							? styles.darkPText
							: styles.lgPText
					}`}
					type="text"
					name="city"
					onFocus={() => setSearching(true)}
					list="city"
					autoComplete="anyrandomstring"
					value={
						!searching
							? `${
									currentLocation.city
										? currentLocation.city + ","
										: ""
							  } ${currentLocation.country}`
							: query
					}
					onChange={(e) => setQuery(e.target.value)}
					placeholder="Enter Your Location"
					disabled={!isEdit}
				/>
				<div className={styles.locationSelectorBox}>
					{searching &&
						predictions.map((pred) => (
							<div
								className={styles.option}
								onClick={() => onSelect(pred.params)}
							>
								{pred.params.city ? (
									<LocationCity color="disabled" />
								) : (
									<Public color="disabled" />
								)}
								<div className={styles.optionText}>
									<span className={styles.primaryText}>
										{pred.label.main_text}
									</span>
									<span className={styles.secondaryText}>
										{pred.label.secondary_text}
									</span>
								</div>
							</div>
						))}
				</div>
			</div>
		</ClickAwayListener>
	);
};

const mapStateToProps = (state) => ({
	user_data: state.auth.user_data,
	webUrls: state.auth.webUrls,
	theme: state.theme.theme,
});
const mapDispatchToProps = (dispatch) => ({
	resetUserData: (payload) => dispatch(resetUserData(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(withAlert(withRouter(MyProfile))));
