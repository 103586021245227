/* eslint-disable react-hooks/exhaustive-deps */
// import TrendingUpIcon from "@material-ui/icons/TrendingUp";
// import UpdateIcon from "@material-ui/icons/Update";
// import WhatshotIcon from "@material-ui/icons/Whatshot";
import { Helmet } from "react-helmet";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
	generatePath,
	Redirect,
	Route,
	useHistory,
	useLocation,
	withRouter,
	Switch,
} from "react-router-dom";
import { countriesSelector } from "../../../Redux/selectors/locationSelector";
import { bdittoAPIv2 } from "../../../Utils/axiosConfig";
import {
	getBuzzingStatusURLV2,
	getRightNowStatusURLV2,
	getTrendingStatusURLV2,
} from "../../../Utils/Constants";
// import SearchCard from "../../Extras/SearchCard/SearchCard";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";
import Tags from "../Tags/TagsSidebar/TagsSidebar";
import TagsSuggestion from "../Tags/TagsSuggestion/TagsSuggestion";
import TagsPage from "../Tags/TagsPage";
import styles from "./Explore.module.css";
import { Loyalty } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import EmptyState from "../../Extras/EmptyState";
// import Arrows from "../../../Icons/Arrows";
import {
    Avatar,
    IconButton,
    // styled
} from "@material-ui/core";
import Clock from "../../../Icons/Clock";
import Buzzing from "../../../Icons/Buzzing";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupsSidebar from "../Groups/GroupsSidebar";

//Icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";

const FETCH_LIMIT = 28;
let allowedparams = ["latest", "popular", "buzzing", "all"];

const Explore = (props) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isActive, setIsActive] = useState(
		allowedparams.indexOf(props.match.params.type) + 1
	);
	const offset = useRef(0);
	const isEnd = useRef(false);
	const countries = useSelector(countriesSelector);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
	const isMobile = window.screen.width < 768;

	useEffect(() => {
		if (isMobile) {
			allowedparams = ["latest", "popular", "buzzing", "trending"];
		}
		fetchStatus(isActive, true);
	}, [countries]);
	useEffect(() => {
		fetchStatus(isActive, true);
    }, [groupDataFromStore]);
    
	useEffect(() => {
		if (props.match.params.type) {
			const newActive =
				allowedparams.indexOf(props.match.params.type) + 1;
			if (newActive !== -1) {
				fetchStatus(newActive, true);
				setIsActive(newActive);
			} else {
				return props.history.push("/explore/thoughts/latest");
			}
		}
	}, [props.match.params.type]);

	// const scrollLeft = (id) => {
	// 	var container = document.getElementById(id);

	// 	var scrollAmount = container.scrollLeft;

	// 	var scrollMax = container.clientWidth;

	// 	container.scrollTo({
	// 		top: 0,
	// 		left: Math.min((scrollAmount -= 300), scrollMax),
	// 		behavior: "smooth",
	// 	});
	// };

	const fetchStatus = async (isActive, reset) => {
		if (!reset && (isLoading || isEnd.current)) return;
		let fetchedData = [];
		let nextOffset = null;
		if (reset) {
			offset.current = 0;
			isEnd.current = false;
		}
		try {
			if (reset) setData([]);
			setIsLoading(true);
			switch (isActive) {
				case 1:
					fetchedData = await getRightNow(reset ? 0 : offset.current);
					break;
				case 2:
					fetchedData = await getTrendingStatus(
						reset ? 0 : offset.current
					);
					break;
				case 3:
					const data = await getBuzzingStatus(
						reset ? 0 : offset.current
					);
					fetchedData = data.thoughts;
					// nextOffset += data.offset;
					break;
				default:
					break;
			}
			if (nextOffset) {
				offset.current = nextOffset;
			} else {
				offset.current += FETCH_LIMIT;
			}
			if (isActive !== 3 && fetchedData.length < FETCH_LIMIT) {
				isEnd.current = true;
			}
			setData((prev) =>
				reset ? fetchedData : [...prev, ...fetchedData]
			);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	const getRightNow = async (skip) => {
		const URL =
			getRightNowStatusURLV2 + `?limit=${FETCH_LIMIT}&offset=` + skip;

		const res = await bdittoAPIv2.post(URL, {
			location: countries,
			groupId: groupDataFromStore.id,
		});
		if (res.status !== 200) throw new Error();
		return res.data.result || res.data.body;
	};

	const getTrendingStatus = async (skip) => {
		const URL =
			getTrendingStatusURLV2 + `?limit=${FETCH_LIMIT}&offset=` + skip;
		const res = await bdittoAPIv2.post(URL, {
			location: countries,
			groupId: groupDataFromStore.id,
		});
		if (res.status !== 200) throw new Error();
		return res.data.result || res.data.body;
	};

	const getBuzzingStatus = async (skip) => {
		const URL =
			getBuzzingStatusURLV2 + `?limit=${FETCH_LIMIT}&offset=` + skip;
		const res = await bdittoAPIv2.post(URL, {
			location: countries,
			groupId: groupDataFromStore.id,
		});
		if (res.status !== 200) throw new Error();
		return { thoughts: res.data.result, offset: res.data?.result?.length };
	};

	const DisplaySkeleton = () => {
		return (
			<>
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
				<CardSkeleton width={isDesktop ? "30.5%" : "80%"} />
			</>
		);
	};

	const Content = () => {
		if (props.match.params.type === "trending")
			return (
				<>
					{isLoading ? (
						DisplaySkeleton()
					) : (
						<>
							<div className={styles.para}>
								<h4>Trending</h4>
								<p>
									Click on any of your thoughts to view its
									thought matches in Workspace 1
								</p>
							</div>
							<Tags />
						</>
					)}
				</>
			);

		return (
			<>
				{data?.length
					? data.map((item, key) => {
							return (
								<StatusCard
									idx={key % FETCH_LIMIT}
									status={item}
									key={item.pk}
									width={isDesktop ? "30.5%" : "80%"}
									height="14rem"
								/>
							);
					  })
					: !isLoading && (
							<div className="mt-5 mx-auto">
								<EmptyState
									heading="No Thoughts Found!"
									imageUrl="/vectors/empty-states/emptyUserStatus.svg"
									subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
								/>
							</div>
					  )}

				{isLoading && DisplaySkeleton()}
			</>
		);
	};

	if (
		props.match.params.type !== "tags" &&
		!allowedparams.includes(props.match.params.type)
	) {
		return <Redirect to="/explore/thoughts/all" />;
	}

	return (
		<div>
			<ExploreHeader />
			<div className={styles.cards}>{Content()}</div>
		</div>
	);
};

const ExploreAll = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [latestData, setLatestData] = useState([]);
	const [trendingData, setTrendingData] = useState([]);
	const [buzzingData, setBuzzingData] = useState([]);
	const countries = useSelector(countriesSelector);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const theme = useSelector((state) => state.theme.theme);
	// const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
	// const isMobile = window.screen.width < 768;
	const history = useHistory();
	const goTo = (path) => {
		history.push(generatePath("/explore/thoughts/:type", { type: path }));
	};
	const [latestWidth, setLatestWidth] = useState(0);
	const [popularWidth, setPopularWidth] = useState(0);
	const [buzzingWidth, setBuzzingWidth] = useState(0);
	// const [latestMaxWidth, setLatestMaxWidth] = useState();
	// const [popularMaxWidth, setPopularMaxWidth] = useState(0);
	// const [buzzingMaxWidth, setBuzzingMaxWidth] = useState(0);

	useEffect(() => {
		fetchStatus(true);
	}, [countries, groupDataFromStore.id]);

	useEffect(() => {
		var container = document.getElementById("explore-latest-thoughts");
		container.scrollTo({
			top: 0,
			left: latestWidth,
			behavior: "smooth",
		});
	}, [latestWidth]);
	useEffect(() => {
		var container = document.getElementById("explore-popular-thoughts");
		container.scrollTo({
			top: 0,
			left: popularWidth,
			behavior: "smooth",
		});
	}, [popularWidth]);
	useEffect(() => {
		var container = document.getElementById("explore-buzzing-thoughts");
		container.scrollTo({
			top: 0,
			left: buzzingWidth,
			behavior: "smooth",
		});
	}, [buzzingWidth]);

	const scrollLatestRight = (id) => {
		var container = document.getElementById(id);
		var scrollAmount = container.scrollLeft;
		setLatestWidth(scrollAmount + 900);
	};
	const scrollLatestLeft = (id) => {
		var container = document.getElementById(id);
		var scrollAmount = container.scrollLeft;
		setLatestWidth(scrollAmount - 900);
	};
	const scrollPopularRight = (id) => {
		var container = document.getElementById(id);
		var scrollAmount = container.scrollLeft;
		setPopularWidth(scrollAmount + 900);
	};
	const scrollPopularLeft = (id) => {
		var container = document.getElementById(id);
		var scrollAmount = container.scrollLeft;
		setPopularWidth(scrollAmount - 900);
	};
	const scrollBuzzingRight = (id) => {
		var container = document.getElementById(id);
		var scrollAmount = container.scrollLeft;
		setBuzzingWidth(scrollAmount + 900);
	};
	const scrollBuzzingLeft = (id) => {
		var container = document.getElementById(id);
		var scrollAmount = container.scrollLeft;
		setBuzzingWidth(scrollAmount - 900);
	};

	const fetchStatus = async (reset) => {
		setIsLoading(true);
		if (reset) {
			setLatestData([]);
			setTrendingData([]);
			setBuzzingData([]);
		}
		let fetchedData = [];
		fetchedData[0] = await getLatestStatus(0);
		fetchedData[1] = await getTrendingStatus(0);
		const data = await getBuzzingStatus(0);
        fetchedData[2] = data.thoughts;
        
		setLatestData((prev) =>
			reset ? fetchedData[0] : [...prev, ...fetchedData[0]]
		);
		setTrendingData((prev) =>
			reset ? fetchedData[1] : [...prev, ...fetchedData[1]]
		);
		setBuzzingData((prev) =>
			reset ? fetchedData[2] : [...prev, ...fetchedData[2]]
		);
		setIsLoading(false);
	};

	const getLatestStatus = async (skip) => {
		const URL = getRightNowStatusURLV2 + `?limit=${10}&offset=` + skip;
		const res = await bdittoAPIv2.post(URL, {
			location: countries,
			groupId: groupDataFromStore.id,
		});

		if (res.status !== 200) throw new Error();
		return res.data.result || res.data.body;
	};

	const getTrendingStatus = async (skip) => {
		const URL =
			getTrendingStatusURLV2 + `?limit=${FETCH_LIMIT}&offset=` + skip;
		const res = await bdittoAPIv2.post(URL, {
			location: countries,
			groupId: groupDataFromStore.id,
		});

		if (res.status !== 200) throw new Error();
		return res.data.result || res.data.body;
	};

	const getBuzzingStatus = async (skip) => {
		const URL =
			getBuzzingStatusURLV2 + `?limit=${FETCH_LIMIT}&offset=` + skip;
		const res = await bdittoAPIv2.post(URL, {
			location: countries,
			groupId: groupDataFromStore.id,
		});
        if (res.status !== 200) throw new Error();
        console.log(res.data)

		return {
			thoughts: res.data.result || res.data.body,
			offset: res.data.body?.length,
		};
	};

	const DisplaySkeleton = () => {
		return (
			<>
				<CardSkeleton width="40%" />
				<CardSkeleton width="40%" />
				<CardSkeleton width="40%" />
			</>
		);
	};

	return (
		<>
			<div className={styles.rowContainer}>
				<div className={`${styles.rowHead}`}>
					<div className={`${styles.rowHeadText}`}>
						<Clock
							color={theme === "dark" ? "#D9DEF4" : "#0d0f22"}
							height="23"
							width="23"
						/>
						<h2
							className={`${styles.text} ${
								theme === "dark" ? styles.darkrow : styles.lgrow
							}`}
						>
							<FormattedMessage
								id="latest"
								defaultMessage={` Latest `}
							/>
						</h2>
					</div>
					<div className={styles.viewAll}>
                        <p onClick={() => goTo(allowedparams[0])}>
                            {latestData.length > 0 &&
                                <FormattedMessage
                                    id="viewAll"
                                    defaultMessage={` View All`}
                                />
                            }
						</p>
					</div>
                </div>
                

				<div className={styles.row} id="explore-latest-thoughts">
					<div>
                        {latestData.length > 0  ?
                            <div className={styles.row} id="explore-latest-thoughts">
                                {latestData.map((item, key) => {
                                    return (
                                        <div className={styles.card}>
                                            <StatusCard
                                                idx={key % FETCH_LIMIT}
                                                status={item}
                                                key={item.pk}
                                                width="23rem"
                                                height="14rem"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <div>
                                {!isLoading &&
                                    <p style={{
                                        color:
                                            theme === "dark"
                                                ? "#D9DEF4"
                                                : "#0d0f22",
                                            
                                    }}>
                                        no data available at this moment
                                    </p>
                                }
                                
                            </div>
                        }
                    </div>

					{isLoading && DisplaySkeleton()}
				</div>
				<div style={{ display: "flex" }}>
					{latestWidth !== 0 && (
						<div className={styles.rowArrowLeft}>
							{(!isLoading && latestData.length > 0)&& (
								<ArrowBack
									onClick={() => {
										scrollLatestLeft(
											"explore-latest-thoughts"
										);
									}}
									style={{
										color:
											theme === "dark"
												? "#D9DEF4"
												: "#0d0f22",
										cursor: "pointer",
									}}
								/>
							)}
						</div>
					)}
					<div className={styles.rowArrow}>
							{(!isLoading && latestData.length > 0)&& (
							<ArrowForwardIcon
								onClick={() => {
									scrollLatestRight(
										"explore-latest-thoughts"
									);
								}}
								style={{
									color:
										theme === "dark"
											? "#D9DEF4"
											: "#0d0f22",
									cursor: "pointer",
								}}
							/>
						)}
					</div>
				</div>
			</div>

			<div className={styles.rowContainer}>
				<div className={styles.rowHead}>
					<div className={styles.rowHeadText}>
						<ArrowUpwardIcon
							style={{
								color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
							}}
						/>
						<h2
							className={`${styles.text} ${
								theme === "dark" ? styles.darkrow : styles.lgrow
							}`}
						>
							<FormattedMessage
								id="popular"
								defaultMessage={` Popular `}
							/>
						</h2>
					</div>
					<div className={styles.viewAll}>
                        <p onClick={() => goTo(allowedparams[1])}>
                             {(trendingData.length > 0) &&
                                <FormattedMessage
                                    id="viewAll"
                                    defaultMessage={` View All`}
                                />
                            }
						</p>
					</div>
				</div>
                <div className={styles.row} id="explore-popular-thoughts">
					<div>
                        {(trendingData.length > 0 && !isLoading) ?
                            <div className={styles.row} id="explore-popular-thoughts">
                                {trendingData.map((item, key) => {
                                    return (
                                        <div className={styles.card}>
                                            <StatusCard
                                                idx={key % FETCH_LIMIT}
                                                status={item}
                                                key={item.pk}
                                                width="23rem"
                                                height="14rem"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            :
                             <div>
                                {!isLoading &&
                                    <p style={{
                                        color:
                                            theme === "dark"
                                                ? "#D9DEF4"
                                                : "#0d0f22",
                                            
                                    }}>
                                        no data available at this moment
                                    </p>
                                }
                                
                            </div>
                        }
                    </div>
					{isLoading && DisplaySkeleton()}
				</div>
				<div style={{ display: "flex" }}>
					{popularWidth !== 0 && (
						<div className={styles.rowArrowLeft}>
							{(!isLoading && trendingData.length > 0)&& (
								<ArrowBack
									onClick={() => {
										scrollPopularLeft(
											"explore-popular-thoughts"
										);
									}}
									style={{
										color:
											theme === "dark"
												? "#D9DEF4"
												: "#0d0f22",
										cursor: "pointer",
									}}
								/>
							)}
						</div>
					)}
					<div className={styles.rowArrow}>
						{(!isLoading && trendingData.length > 0)&& (
							<ArrowForwardIcon
								onClick={() => {
									scrollPopularRight(
										"explore-popular-thoughts"
									);
								}}
								style={{
									color:
										theme === "dark"
											? "#D9DEF4"
											: "#0d0f22",
									cursor: "pointer",
								}}
							/>
						)}
					</div>
				</div>
			</div>

			<div className={styles.rowContainer}>
				<div className={styles.rowHead}>
					<div className={styles.rowHeadText}>
						<Buzzing
							color={theme === "dark" ? "#D9DEF4" : "#0d0f22"}
							height="23"
							width="23"
						/>
						<h2
							className={`${styles.text} ${
								theme === "dark" ? styles.darkrow : styles.lgrow
							}`}
						>
							<FormattedMessage
								id="buzzing"
								defaultMessage={`Buzzing `}
							/>
						</h2>
					</div>
					<div className={styles.viewAll}>
						<p onClick={() => goTo(allowedparams[2])}>
                            {buzzingData.length > 0 &&
                                <FormattedMessage
                                    id="viewAll"
                                    defaultMessage={` View All`}
                                />
                            }
						</p>
					</div> 
				</div>
                <div className={styles.row} id="explore-buzzing-thoughts">
                    <div>
                        {(buzzingData.length > 0 && !isLoading) ?
                            <div className={styles.row} id="explore-buzzing-thoughts">
                                {buzzingData.map((item, key) => {
                                    return (
                                        <div className={styles.card}>
                                            <StatusCard
                                                idx={key % FETCH_LIMIT}
                                                status={item}
                                                key={item.pk}
                                                width="23rem"
                                                height="14rem"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <div >
                                {!isLoading &&
                                    <p style={{
                                        color:
                                            theme === "dark"
                                                ? "#D9DEF4"
                                                : "#0d0f22",
                                        textAlign: "center",width: "100%" 
                                            
                                    }}>
                                        no data available at this moment
                                    </p>
                                }
                                
                            </div>
                        }
                    </div>
					
					{isLoading && DisplaySkeleton()}
				</div>
				<div style={{ display: "flex" }}>
					{latestWidth !== 0 &&  (
						<div className={styles.rowArrowLeft}>
							{(!isLoading && buzzingData.length > 0)&&  (
								<ArrowBack
									onClick={() => {
										scrollBuzzingLeft(
											"explore-buzzing-thoughts"
										);
									}}
									style={{
										color:
											theme === "dark"
												? "#D9DEF4"
												: "#0d0f22",
										cursor: "pointer",
									}}
								/>
							)}
						</div>
					)}
					<div className={styles.rowArrow}>
						{(!isLoading &&  buzzingData.length > 0)&& (
							<ArrowForwardIcon
								onClick={() => {
									scrollBuzzingRight(
										"explore-buzzing-thoughts"
									);
								}}
								style={{
									color:
										theme === "dark"
											? "#D9DEF4"
											: "#0d0f22",
									cursor: "pointer",
								}}
								
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const ExploreRouter = (props) => {
	const [groupInfo, setGroupInfo] = useState(false);
	const [groupDisplay, setGroupDisplay] = useState(false);

	// const isTags = props.location.pathname.startsWith("/explore/tags");
	const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
	const isMobile = window.screen.width < 768;
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const theme = useSelector((state) => state.theme.theme);
	// const scrollRef = useRef(null);

	const tabs = [
		{
			id: 1,
			label: "Latest",
			type: "latest",
		},
		{
			id: 2,
			label: "Popular",
			type: "popular",
		},
		{
			id: 3,
			label: "Buzzing",
			type: "buzzing",
		},
		{
			id: 4,
			label: "Trending",
			type: "trending",
		},
	];

	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>{`Explore | bditto`} </title>
			</Helmet>
			<div
				className={styles.container}
				style={{
					background: theme !== "dark" ? "#ffffff" : "#121528",
				}}
			>
				{!isMobile ? (
					<div className={styles.header}>
						<div className={styles.grpContainer}>
							<div
								className={styles.groupName}
								onClick={() => setGroupInfo(!groupInfo)}
							>
								<Avatar
									style={{
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
									className="ml-2"
									src={groupDataFromStore.avatar}
								>
									{groupDataFromStore.name &&
										groupDataFromStore.name[0].toUpperCase()}
								</Avatar>
								<span
									className={`${styles.grpname} ${
										theme === "dark"
											? styles.darkrow
											: styles.lgrow
									}`}
								>
									{groupDataFromStore.name}
								</span>
							</div>
							<div onClick={() => setGroupDisplay(!groupDisplay)}>
								{groupDisplay ? (
									<ExpandMoreIcon
										style={{
											fontSize: "1.5rem",
											color:
												theme === "dark"
													? "#D9DEF4"
													: "#0d0f22",
											transform: "rotate(180deg)",
										}}
									/>
								) : (
									<ExpandMoreIcon
										style={{
											fontSize: "1.5rem",
											color:
												theme === "dark"
													? "#D9DEF4"
													: "#0d0f22",
										}}
									/>
								)}
							</div>
						</div>
					</div>
				) : (
					<div className={styles.exploreTab}>
						{tabs.map((tab, i) => {
							if (
								props.location.pathname ===
								`/explore/thoughts/${tab.type}`
							)
								return (
									<div
										className={styles.activeTab}
										onClick={() => {
											props.history.push(
												`/explore/thoughts/${tab.type}`
											);
										}}
									>
										{tab.label}
									</div>
								);

							return (
								<div
									className={styles.tab}
									onClick={() => {
										props.history.push(
											`/explore/thoughts/${tab.type}`
										);
									}}
								>
									{tab.label}
								</div>
							);
						})}
					</div>
				)}

				{groupDisplay && (
					<div
						className={`${styles.group} ${
							theme === "dark" ? styles.grpdark : styles.grplg
						}`}
					>
						<GroupsSidebar
							myGrp={groupDataFromStore?.name}
							setGroupsDisplay={setGroupDisplay}
                            top="true"
                            groupId={groupDataFromStore?.id}
						/>
					</div>
				)}

				{/* <div className={styles.body}>
					<div className={styles.left} ref={scrollRef}>
						<Switch>
					<Route
					exact
					path="/explore/thoughts/all"
					component={ExploreAll}
					/>
					<Route
					path="/explore/tags/:tagName/:tagId"
					component={TagsSuggestion}
					/>
					<Route
					path="/explore/thoughts/:type?"
					component={Explore}
					/>
					{!isDesktop && (
					<Route
					exact
					path="/explore/tags"
					component={TagsPage}
					/>
					)}
					{props.location.pathname === "/explore" && (
					<Redirect
					exact
					from="/explore"
					to="/explore/thoughts/latest"
					/>
					)}
					{props.location.pathname === "/explore/tags" &&
					isDesktop && (
					<Redirect
					exact
					from="/explore/tags"
					to="/explore/thoughts/latest"
					/>
					)}
						</Switch>
					</div>
					{isDesktop && <Tags />}
				</div> */}

				<div className="row">
					<div
						className={`${isDesktop ? "col-10" : "col"} ${
							theme === "dark" ? styles.leftDk : styles.leftLg
						}`}
						style={isMobile ? { marginTop: "0" } : {}}
					>
						<Switch>
							<Route
								exact
								path="/explore/thoughts/all"
								component={ExploreAll}
							/>
							<Route
								path="/explore/tags/:tagName/:tagId"
								component={TagsSuggestion}
							/>
							<Route
								path="/explore/thoughts/:type?"
								component={Explore}
							/>
							{!isDesktop && (
								<Route
									exact
									path="/explore/tags"
									component={TagsPage}
								/>
							)}
							{props.location.pathname === "/explore" && (
								<Redirect
									exact
									from="/explore"
									to="/explore/thoughts/latest"
								/>
							)}
							{props.location.pathname === "/explore/tags" &&
								isDesktop && (
									<Redirect
										exact
										from="/explore/tags"
										to="/explore/thoughts/latest"
									/>
								)}
						</Switch>
					</div>
					{isDesktop && (
						<div className={`${styles.right} col-2`}>
							{isDesktop && <Tags />}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const ExploreHeader = (props) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const goTo = (path) => {
		history.push(generatePath("/explore/thoughts/:type", { type: path }));
	};
	const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
	const isMobile = window.screen.width < 768;

	return (
		<>
			{!isMobile && (
				<div className={styles.heading}>
					{pathname.endsWith(allowedparams[0]) && (
						<div className={styles.headItem}>
							<div>
								<IconButton
									size="small"
									variant="contained"
									style={{
										// marginLeft: "0.2rem",
										// marginRight: "0.9rem",
										marginTop: "-.4rem",
									}}
									onClick={() =>
										history.push("/explore/thoughts/all")
									}
								>
									<img src="/Home/ArrowRight.png" alt="" />
								</IconButton>
							</div>
							<h1 className={styles.breadActive}>
								&nbsp;{" "}
								<FormattedMessage
									id="latest"
									defaultMessage={` Latest `}
								/>
							</h1>
						</div>
					)}

					{pathname.endsWith(allowedparams[1]) && (
						<div className={styles.headItem}>
							<div>
								<IconButton
									size="small"
									variant="contained"
									style={{
										marginLeft: "0.2rem",
										marginRight: "0.9rem",
										marginTop: "-.4rem",
									}}
									onClick={() =>
										history.push("/explore/thoughts/all")
									}
								>
									<img src="/Home/ArrowRight.png" alt="" />
								</IconButton>
							</div>
							<h1 className={styles.breadActive}>
								{/* <FormattedMessage
									id="trending"
									defaultMessage={` Popular `}
								/> */}
                                Popular
							</h1>
						</div>
					)}

					{pathname.endsWith(allowedparams[2]) && (
						<div className={styles.headItem}>
							<div>
								<IconButton
									size="small"
									variant="contained"
									style={{
										marginLeft: "0.2rem",
										marginRight: "0.9rem",
										marginTop: "-.4rem",
									}}
									onClick={() =>
										history.push("/explore/thoughts/all")
									}
								>
									<img src="/Home/ArrowRight.png" alt="" />
								</IconButton>
							</div>
							<h1 className={styles.breadActive}>
								&nbsp;&nbsp;
								<FormattedMessage
									id="buzzing"
									defaultMessage={`Buzzing `}
								/>
							</h1>
						</div>
					)}

					{!isDesktop && (
						<div
							id={styles.lasth1}
							className={styles.headItem}
							onClick={() => history.push("/explore/tags")}
						>
							<Loyalty
								className={
									pathname.endsWith("/explore/tags") &&
									styles.breadActive
								}
								style={{ fontSize: "1.6rem", color: "#ccc" }}
							/>
							<h1
								className={
									pathname.endsWith("/explore/tags") &&
									styles.breadActive
								}
							>
								&nbsp;&nbsp;tags
							</h1>
						</div>
					)}
				</div>
			)}
		</>
	);
};
export default withRouter(ExploreRouter);
