import React from "react";

const CheckedIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="16" height="16" rx="4" fill="url(#paint0_linear)" />
			<path
				d="M4 8L7 11L13 5"
				stroke="#D9DEF4"
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="0"
					y1="0"
					x2="16.5691"
					y2="0.612761"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FC466B" />
					<stop offset="1" stop-color="#3F5EFB" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default CheckedIcon;
