import React from "react";

const ThoughtsHubIcon = ({ color }) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="1"
				y="2"
				width="14"
				height="13"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.33203 2H14.3314V14.9993H1.33203V2Z"
					fill={color}
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.20199 9.87835L9.63999 13.8337C9.74666 14.007 9.91333 14.005 9.98066 13.9957C10.048 13.9864 10.21 13.945 10.2687 13.7484L13.3173 3.45169C13.3707 3.26969 13.2727 3.14569 13.2287 3.10169C13.186 3.05769 13.064 2.96369 12.8873 3.01369L2.58333 6.03102C2.38799 6.08835 2.34533 6.25235 2.33599 6.31968C2.32666 6.38835 2.32399 6.55835 2.49666 6.66702L6.49733 9.16902L10.032 5.59702C10.226 5.40102 10.5427 5.39902 10.7393 5.59302C10.936 5.78702 10.9373 6.10435 10.7433 6.30035L7.20199 9.87835ZM9.92866 14.9997C9.46466 14.9997 9.03933 14.7637 8.78866 14.3584L6.20399 10.1644L1.96666 7.51435C1.50999 7.22835 1.27133 6.71902 1.34533 6.18369C1.41866 5.64835 1.78599 5.22302 2.30199 5.07168L12.606 2.05435C13.08 1.91568 13.588 2.04702 13.9373 2.39502C14.2867 2.74635 14.4167 3.25969 14.2753 3.73569L11.2267 14.0317C11.074 14.5497 10.6473 14.9157 10.1133 14.987C10.0507 14.995 9.98999 14.9997 9.92866 14.9997Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default ThoughtsHubIcon;
