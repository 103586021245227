import React, { useEffect, useState } from "react";
import axiosConfig from "../../../Utils/axiosConfig";
import {
	actionRequest,
	setNotificationSeenURL,
} from "../../../Utils/Constants";
import styles from "./Notification.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
	getNotifications,
	isNotificationLoading,
} from "../../../Redux/selectors/notificationSelectors";
import { fetchNotifications } from "../../../Redux/Actions/notifications";
import throttle from "lodash/throttle";
import formatDistanceToNow from "date-fns/formatDistanceToNowStrict";
import NotificationScreenCard from "../../Extras/NotificationScreenCard/NotificationScreenCard";
import NotificationSkeleton from "../../Skeleton/NotificationSkeleton/NotificationSkeleton";
import { timeConverter } from "../../../Utils/timeConversion";
import {
	getContactRequests,
	isContactRequestsLoading,
} from "../../../Redux/selectors/ContactRequests";
import {
	fetchContactRequests,
	removeContactRequest,
} from "../../../Redux/Actions/notifications/contactRequests";
import useAlert from "../../Extras/Alert/useAlert";
import { Avatar, Dialog } from "@material-ui/core";
import Username from "../../Extras/Username";
import { Helmet } from "react-helmet";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

let fetch = null;

function Notification() {
	const dispatch = useDispatch();
	const notifications = useSelector(getNotifications);
	const notificationLoading = useSelector(isNotificationLoading);
	const [activeTab, setActiveTab] = useState(0);
	const contactRequests = useSelector(getContactRequests);
	const contactRequestsloading = useSelector(isContactRequestsLoading);
	const openNotifications = () => setActiveTab(0);
	const openContactRequests = () => setActiveTab(1);

	const theme = useSelector((state) => state.theme.theme);
	const history = useHistory();


    console.log("ada",notifications)
	useEffect(() => {
		if (activeTab === 1) {
			dispatch(fetchContactRequests());
		}
	}, [activeTab, dispatch]);

	useEffect(() => {
		dispatch(fetchNotifications());
		fetch = throttle(() => dispatch(fetchNotifications()), 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
        if (notifications.length > 0) {
            markAsSeen(notifications);
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications]);

	const markAsSeen = (notifications) => {
		var data = new FormData();

		notifications.forEach((item) => {
			if (!item.seen && item.created_at) {
				data.append("notifIds", item.pk);
			}
		});
		markNotificationAsSeen(data);
	};

	const markNotificationAsSeen = (data) => {
		axiosConfig
			.post(setNotificationSeenURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
			})
			.catch((err) => {});
	};

	const handleScroll = (e) => {
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 200;
		if (bottom) {
			fetch();
		}
	};

	const isLoading = contactRequestsloading || notificationLoading;

	const displayNotifications = () => {
		if (notifications.length !== 0) {
            return notifications.map((notification) => {
                if (notification.metas === null) return null;
                if (notification.created_at) {
                    const date = formatDistanceToNow(
                        new Date(notification?.created_at),
                        {
                            addSuffix: false,
                        }
                    );
    
                    return (
                        <NotificationScreenCard
                            key={notification.pk}
                            seen={notification.seen}
                            date={timeConverter(date)}
                            {...generateNotificationProps(notification)}
                        />
                    );
                }
			});
		} else {
			if (!notificationLoading) {
				return (
					<p className={styles.noNotifications}>
						No Notifications Yet
					</p>
				);
			}
		}
	};

	const displayRequests = () => {
		if (contactRequests?.length !== 0) {
			return contactRequests?.map((request, i) => {
				return (
					<FriendRequestCard
						key={request.requestId}
						request={request}
					/>
				);
			});
		} else {
			if (!notificationLoading) {
				return (
					<p className={styles.noNotifications}>
						No Requests Pending
					</p>
				);
			}
		}
	};

	const isMobile = window.screen.width < 768;

	const tabs = [
		{
			id: 1,
			label: "Notifications",
		},
		{
			id: 2,
			label: "Requests",
		},
	];

	return (
		<>
			{isMobile ? (
				<Dialog
					fullScreen={true}
					maxWidth={"100vw"}
					fullWidth={"100vw"}
					open={true}
					classes={{
						paper:
							theme === "dark" ? styles.rootDark : styles.rootLg,
					}}
				>
					{isMobile && (
						<div
							className={
								styles.notifNav +
								" d-flex align-items-center container-fluid "
							}
						>
							<div className="mr-3">
								<ArrowBackIosIcon
									onClick={() => {
										history.push("/");
									}}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
									}}
								/>
							</div>
							<div className="text-center w-100">
								<p
									className="m-0 p-0 mr-4 "
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
									}}
								>
									Notifications
								</p>
							</div>
						</div>
					)}

					<div className="mt-5">
						{isMobile && (
							<div className={styles.thoughtsTab}>
								{tabs.map((tab, i) =>
									tab.id === activeTab ? (
										<div className={styles.activeTab}>
											{tab.label}
										</div>
									) : (
										<div
											onClick={() => setActiveTab(tab.id)}
											className={styles.tab}
										>
											{tab.label}
										</div>
									)
								)}
							</div>
						)}

						<div
							className={styles.body}
							style={{ margin: "0 20px" }}
						>
							{activeTab === 1
								? displayNotifications()
								: displayRequests()}
							{isLoading && <NotificationsLoading />}
						</div>
					</div>
				</Dialog>
			) : (
				<div className={styles.wrapper}>
					<Helmet>
						<title>{`notifications | bditto`} </title>
					</Helmet>
					<div className={styles.container} onScroll={handleScroll}>
						<div className={styles.cont}>
							<div className={styles.header}>
								<h2
									className={
										activeTab === 0
											? styles.activeTab
											: styles.tab
									}
									onClick={openNotifications}
								>
									Notifications
								</h2>
								<h2
									className={
										activeTab === 1
											? styles.activeTab
											: styles.tab
									}
									onClick={openContactRequests}
								>
									Contact Requests
								</h2>
							</div>
							<div className={styles.body}>
								{activeTab === 0
									? displayNotifications()
									: displayRequests()}
								{isLoading && <NotificationsLoading />}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export const FriendRequestCard = ({ request }) => {
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
	const theme = useSelector((state) => state.theme.theme);

	const onAccept = () => {
		requestAction(request.requestId, "Accept", () => {
			dispatch(removeContactRequest(request.requestId));
		});
	};
	const onReject = () => {
		requestAction(request.requestId, "Reject", () => {
			dispatch(removeContactRequest(request.requestId));
		});
	};
	const isMobile = window.screen.width < 768;

	const requestAction = (id, action, onComplete) => {
		const request = new FormData();
		request.append("requestId", id);
		request.append("action", action);

		axiosConfig
			.post(actionRequest, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				onComplete();
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};
	return (
		<div className={styles.friendRequestCard}>
			<div className={styles.left}>
				<div className={styles.avatar}>
					<Avatar
						src={request.user.avatar}
						style={{
							height: "3rem",
							width: "3rem",
							background:
								theme === "dark" ? "#d9def4" : "#0d0f22",
							color: theme === "dark" ? "#0d0f22" : "#d9def4",
						}}
					>
						{request.user.username &&
							request.user.username[0].toUpperCase()}
					</Avatar>
				</div>
				<div
					className={styles.requestCont}
					style={{
						color: theme !== "dark" ? "#0d0f22" : "#d9def4",
					}}
				>
					<Username value={request.user.username} /> has sent you a
					friend Request
				</div>
			</div>
			<div className={styles.actions}>
				<Button1 onClick={onAccept} variant="contained" color="primary">
					{isMobile ? (
						<img src="/Mobile/accept.svg" alt="" srcset="" />
					) : (
						"Accept"
					)}
				</Button1>
				<Button1 onClick={onReject} variant="outlined" color="primary">
					{isMobile ? (
						<img src="/Mobile/reject.svg" alt="" srcset="" />
					) : (
						"Reject"
					)}
				</Button1>
			</div>
		</div>
	);
};

export const generateNotificationProps = (notification) => {
	switch (notification.notification_type) {
		case "friend_request_accepted":
			if (notification.metas.receiver)
				return {
					profileLink: `/profile/${notification.metas.receiver.userID}`,
					avatar: notification.metas.receiver.avatar,
					username: notification.metas.receiver.full_name,
					notificationLink: `/profile/${notification.metas.receiver.userID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "friend_request_sent":
			if (notification.metas.sender)
				return {
					profileLink: `/profile/${notification.metas.sender?.userID}`,
					avatar: notification.metas.sender?.avatar,
					username: notification.metas.sender?.username,
					notificationLink: "/myprofile/?active=requests",

					content: notification.content,
				};
			else {
				return {};
			}
		case "group_joined":
			if (notification.metas.user)
				return {
					profileLink: `/profile/${notification.metas.user.userID}`,
					avatar: notification.metas.user.avatar,
					username: notification.metas.user.username,
					notificationLink: `/chat/group/${notification.metas.status.pk}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "liked_message":
			if (notification.metas.sender) {
				const user = notification.metas.sender;
				return {
					profileLink: `/profile/${user.userID || user.senderID}`,
					avatar: user.avatar || "",
					username: user.username,
					notificationLink: `/chat/group/${notification.metas.message?.conversationID}?highlight=${notification.metas.message?.messageID}`,
					content: `${user.username} liked your message`,
				};
			} else {
				return {};
			}
		case "message":
			if (notification.metas)
				return {
					profileLink: `/profile/${notification.metas.message.userID}`,
					avatar: ``,
					username: notification.metas.username,
					notificationLink: `/chat/private/${notification.metas.message.userID}`,
					content: `You recieved a new message from ${notification.metas.username}`,
				};
			else {
				return {};
			}
		case "like":
			if (notification.metas.liked_by)
				return {
					profileLink: `/profile/${notification.metas.liked_by.pk}`,
					avatar: notification.metas.liked_by.avatar,
					username: notification.metas.liked_by.username,
					notificationLink: `/chat/group/${notification.metas.status.pk}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "create_status":
			if (notification.metas.user)
				return {
					profileLink: `/profile/${notification.metas.user.userID}`,
					avatar: notification.metas.user.avatar,
					username: notification.metas.user.username,
					notificationLink: `/chat/group/${notification.metas.status.pk}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "reply_to_message":
			if (notification.metas.message)
				return {
					profileLink: `/profile/${notification.metas.message.replierID}`,
					avatar: notification.metas.sender.avatar,
					username: notification.metas.sender.username,
					notificationLink: `/chat/group/${notification.metas.message.conversationID}?highlight=${notification.metas.message.messageID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "remove_from_thought":
			if (notification.metas.message)
				return {
					profileLink: `/profile/${notification.metas.message.replierID}`,
					avatar: notification.metas.sender.avatar,
					username: notification.metas.sender.username,
					notificationLink: `/chat/group/${notification.metas.message.conversationID}?highlight=${notification.metas.message.messageID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "group_request":
			if (notification.metas.message)
				return {
					profileLink: `/profile/${notification.metas.message.replierID}`,
					avatar: notification.metas.sender.avatar,
					username: notification.metas.sender.username,
					notificationLink: `/chat/group/${notification.metas.message.conversationID}?highlight=${notification.metas.message.messageID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "admin_accepted_group_join_request":
			if (notification.metas)
				return {
					profileLink: `/profile/${notification.metas.admin}`,
					avatar: notification.metas.avatar,
					username: notification.metas.name,
					notificationLink: `/group-info/${notification.metas.id}`,
					// notificationLink: `/chat/group/${notification.metas.message.conversationID}?highlight=${notification.metas.message.messageID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "make_subadmin":
			if (notification.metas.message)
				return {
					profileLink: `/profile/${notification.metas.message.replierID}`,
					avatar: notification.metas.sender.avatar,
					username: notification.metas.sender.username,
					notificationLink: `/chat/group/${notification.metas.message.conversationID}?highlight=${notification.metas.message.messageID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		case "subadmin_notifies_admin_on_accepting_request":
			if (notification.metas.message)
				return {
					profileLink: `/profile/${notification.metas.message.replierID}`,
					avatar: notification.metas.sender.avatar,
					username: notification.metas.sender.username,
					notificationLink: `/chat/group/${notification.metas.message.conversationID}?highlight=${notification.metas.message.messageID}`,
					content: notification.content,
				};
			else {
				return {};
			}
		default:
			return {};
	}
};

const NotificationsLoading = () => {
	return [1, 2, 3, 4, 5, 6, 7, 8].map((item) => <NotificationSkeleton />);
};

export default Notification;
