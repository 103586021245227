import React from "react";

const WorkIcon = () => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 21.15C16.3125 21.15 14.85 19.8 14.85 18C14.85 16.3125 16.2 14.85 18 14.85C19.6875 14.85 21.15 16.2 21.15 18C21.15 19.6875 19.6875 21.15 18 21.15Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.825 10.35H14.175C13.275 10.4625 12.825 10.575 12.4875 10.6875C12.0375 10.8 11.7 11.025 11.3625 11.3625C11.0954 11.6296 10.9693 11.8966 10.8167 12.2194C10.7766 12.3045 10.7344 12.3937 10.6875 12.4875C10.6701 12.5397 10.65 12.5946 10.6285 12.6535C10.5108 12.975 10.35 13.4142 10.35 14.175V21.825C10.4625 22.725 10.575 23.175 10.6875 23.5125C10.8 23.9625 11.025 24.3 11.3625 24.6375C11.6296 24.9046 11.8966 25.0307 12.2194 25.1832C12.3046 25.2235 12.3936 25.2655 12.4875 25.3125C12.5397 25.3299 12.5946 25.35 12.6535 25.3715C12.975 25.4892 13.4142 25.65 14.175 25.65H21.825C22.725 25.5375 23.175 25.425 23.5125 25.3125C23.9625 25.2 24.3 24.975 24.6375 24.6375C24.9046 24.3704 25.0307 24.1034 25.1832 23.7806C25.2235 23.6955 25.2656 23.6064 25.3125 23.5125C25.3299 23.4603 25.35 23.4054 25.3715 23.3465C25.4892 23.025 25.65 22.5858 25.65 21.825V14.175C25.5375 13.275 25.425 12.825 25.3125 12.4875C25.2 12.0375 24.975 11.7 24.6375 11.3625C24.3704 11.0954 24.1034 10.9693 23.7806 10.8167C23.6956 10.7766 23.6063 10.7344 23.5125 10.6875C23.4603 10.6701 23.4054 10.65 23.3465 10.6285C23.025 10.5108 22.5858 10.35 21.825 10.35ZM18 13.1625C15.3 13.1625 13.1625 15.3 13.1625 18C13.1625 20.7 15.3 22.8375 18 22.8375C20.7 22.8375 22.8375 20.7 22.8375 18C22.8375 15.3 20.7 13.1625 18 13.1625ZM24.075 13.05C24.075 13.6713 23.5713 14.175 22.95 14.175C22.3287 14.175 21.825 13.6713 21.825 13.05C21.825 12.4287 22.3287 11.925 22.95 11.925C23.5713 11.925 24.075 12.4287 24.075 13.05Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 5.625C0 2.5184 2.5184 0 5.625 0H30.375C33.4816 0 36 2.5184 36 5.625V30.375C36 33.4816 33.4816 36 30.375 36H5.625C2.5184 36 0 33.4816 0 30.375V5.625ZM14.175 8.6625H21.825C22.8375 8.775 23.5125 8.8875 24.075 9.1125C24.75 9.45 25.2 9.675 25.7625 10.2375C26.325 10.8 26.6625 11.3625 26.8875 11.925C27.1125 12.4875 27.3375 13.1625 27.3375 14.175V21.825C27.225 22.8375 27.1125 23.5125 26.8875 24.075C26.55 24.75 26.325 25.2 25.7625 25.7625C25.2 26.325 24.6375 26.6625 24.075 26.8875C23.5125 27.1125 22.8375 27.3375 21.825 27.3375H14.175C13.1625 27.225 12.4875 27.1125 11.925 26.8875C11.25 26.55 10.8 26.325 10.2375 25.7625C9.675 25.2 9.3375 24.6375 9.1125 24.075C8.8875 23.5125 8.6625 22.8375 8.6625 21.825V14.175C8.775 13.1625 8.8875 12.4875 9.1125 11.925C9.45 11.25 9.675 10.8 10.2375 10.2375C10.8 9.675 11.3625 9.3375 11.925 9.1125C12.4875 8.8875 13.1625 8.6625 14.175 8.6625Z"
				fill="white"
			/>
			<rect width="36" height="36" rx="4" fill="#D9DEF4" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.9922 22.974C17.6127 22.974 17.3047 22.666 17.3047 22.2865V19.9609C17.3047 19.5814 17.6127 19.2734 17.9922 19.2734C18.3717 19.2734 18.6797 19.5814 18.6797 19.9609V22.2865C18.6797 22.666 18.3717 22.974 17.9922 22.974"
				fill="black"
			/>
			<mask
				id="mask0_4906_27190"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="8"
				y="11"
				width="20"
				height="10"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.83203 11.1953H27.156V20.6471H8.83203V11.1953Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_4906_27190)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.207 17.4406C12.385 18.624 15.1314 19.2721 17.9895 19.2721C20.8532 19.2721 23.6023 18.624 25.7812 17.4406V14.6887C25.7812 13.52 24.8361 12.5703 23.6729 12.5703H12.3245C11.1567 12.5703 10.207 13.5163 10.207 14.6796V17.4406ZM17.9895 20.6471C14.7399 20.6471 11.6077 19.8606 9.17211 18.4342C8.96128 18.3114 8.83203 18.0859 8.83203 17.8411V14.6796C8.83203 12.7582 10.3986 11.1953 12.3245 11.1953H23.6729C25.5942 11.1953 27.1562 12.7619 27.1562 14.6887V17.8411C27.1562 18.0859 27.026 18.3114 26.8161 18.4342C24.3805 19.8606 21.2455 20.6471 17.9895 20.6471V20.6471Z"
					fill="black"
				/>
			</g>
			<mask
				id="mask1_4906_27190"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="14"
				y="8"
				width="8"
				height="5"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.0977 8.83203H21.8893V12.5683H14.0977V8.83203Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask1_4906_27190)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.2018 12.5684C20.8223 12.5684 20.5143 12.2604 20.5143 11.8809V11.5454C20.5143 10.8074 19.9139 10.207 19.176 10.207H16.811C16.0731 10.207 15.4727 10.8074 15.4727 11.5454V11.8809C15.4727 12.2604 15.1647 12.5684 14.7852 12.5684C14.4057 12.5684 14.0977 12.2604 14.0977 11.8809V11.5454C14.0977 10.0494 15.315 8.83203 16.811 8.83203H19.176C20.672 8.83203 21.8893 10.0494 21.8893 11.5454V11.8809C21.8893 12.2604 21.5813 12.5684 21.2018 12.5684"
					fill="black"
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.3123 26.9257H12.679C10.7769 26.9257 9.17551 25.4398 9.03159 23.5423L8.85743 21.2424C8.82901 20.8638 9.11318 20.5329 9.49176 20.5045C9.87218 20.4907 10.2003 20.7593 10.2297 21.1388L10.4029 23.4378C10.4928 24.6221 11.4919 25.5507 12.679 25.5507H23.3123C24.4994 25.5507 25.4995 24.6221 25.5884 23.4378L25.7626 21.1388C25.7919 20.7593 26.1283 20.4898 26.5005 20.5045C26.8791 20.5329 27.1623 20.8638 27.1339 21.2424L26.9598 23.5423C26.8158 25.4398 25.2144 26.9257 23.3123 26.9257"
				fill="black"
			/>
		</svg>
	);
};

export default WorkIcon;
