import { NOTIFICATION_FETCH_LIMIT } from "../../Actions/notifications";

export const setLoading = (state) => ({ ...state, loading: true });

export const fetchedNotifications = (state, action) => ({
  ...state,
  data: state.polledFromServer
    ? [...state.data, ...action.payload]
    : [...action.payload],
  fetchError: false,
  polledFromServer: true,
  isFinished: action.payload.length < NOTIFICATION_FETCH_LIMIT,
  loading: false,
  fetchOffset: state.fetchOffset + NOTIFICATION_FETCH_LIMIT,
});

export const onFetchError = (state) => ({
  ...state,
  fetchError: true,
  loading: false,
});
