/* eslint-disable import/no-anonymous-default-export */
import { GET_MY_GROUPS, UPDATE_MY_GROUPS,UPDATE_MY_SINGLE_GROUP } from "../Actions/actionTypes";

const initialState = {
	myGroups: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_MY_GROUPS:
			return {
				...state,
				myGroups: action.payload,
			};
		case UPDATE_MY_GROUPS:
			return {
				...state,
				myGroups: state.myGroups.filter(
					(group) => group.id !== action.payload
				),
			};
        case UPDATE_MY_SINGLE_GROUP:
            let latest =  state.myGroups.filter(
					(group) => group.id !== action.payload.id
				)
            return {
                ...state,
                myGroups: [ action.payload,...latest]
            }
		default:
			return state;
	}
}
