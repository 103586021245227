import React, { useRef, useState } from "react";

import {
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	TelegramIcon,
	TelegramShareButton,
	LinkedinIcon,
	LinkedinShareButton,
} from "react-share";

import styles from "./Share.module.css";
import { Popover } from "@material-ui/core";
// import { FormattedMessage } from "react-intl";
import ShareAppIcon from "../../../../../Icons/ShareAppIcon";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ShareCopyLink = ({ url }) => {
	const [openSnack, setOpenSnack] = useState(false);
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const handleCopyCommand = () => {
		navigator.clipboard.writeText(url);
		setOpenSnack(true);
	};

	const handleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack(false);
	};

	return (
		<div className={styles.footer}>
			<button onClick={handleCopyCommand} className={styles.copyButton}>
				<FileCopyIcon
					style={{
						color: theme === "dark" ? "white" : "black",
						fontSize: "1.5rem",
					}}
				/>
			</button>
			<Snackbar
				open={openSnack}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					style={{
						backgroundColor:
							theme === "dark" ? "#0d0f22" : "#d9def4",
						color: theme === "dark" ? "#d9def4" : "#0d0f22",
					}}
				>
					URL copied successfully
				</Alert>
			</Snackbar>
		</div>
	);
};

export const ShareSocial = ({ url, className, iconSize = 40 }) => {
	return (
		<div className={`${styles.container} ${className}`}>
			<TwitterShareButton url={url}>
				<TwitterIcon size={iconSize} round />
			</TwitterShareButton>
			<FacebookShareButton url={url}>
				<FacebookIcon size={iconSize} round />
			</FacebookShareButton>
			<WhatsappShareButton url={url}>
				<WhatsappIcon size={iconSize} round />
			</WhatsappShareButton>
			<LinkedinShareButton url={url}>
				<LinkedinIcon size={iconSize} round />
			</LinkedinShareButton>
			<TelegramShareButton url={url}>
				<TelegramIcon size={iconSize} round />
			</TelegramShareButton>
			<ShareCopyLink url={url} className={styles.btn} />
		</div>
	);
};

function Share(props) {
	const { type, id } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const popid = open ? "simple-popover" : undefined;

	const url =
		type && id
			? `https://bditto.com/chat/${type}/${id}`
			: `https://bditto.com/`;
	// const header =
	// 	type && id ? (
	// 		<FormattedMessage
	// 			id="SharethisStatus"
	// 			defaultMessage={`Share this Status`}
	// 		/>
	// 	) : (
	// 		<FormattedMessage
	// 			id="shareThisStatus"
	// 			defaultMessage={`Share the App`}
	// 		/>
	// 	);

	return (
		<>
			<div onClick={handleClick}>{props.children}</div>
			<Popover
				id={popid}
				className={`${styles.pop} ${props.className}`}
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				style={{ outline: "none" }}
			>
				<div className={`${styles.popover} ${props.className}`}>
					<div className={styles.heade}>
						{/* <span>{header}</span> */}

						<div className={styles.shareContainer}>
							<span className={styles.shareIcon}>
								<ShareAppIcon />
							</span>
							<span className={styles.shareText}>
								Share the fun by inviting your friends to the
								Chat.
							</span>
						</div>
					</div>

					<div className={styles.shareSocialIcons}>
						<ShareSocial
							url={url}
							className={styles.ShareSocial}
							iconSize={40}
						/>
					</div>
					<div className={styles.shareSocialCopy}>
						<ShareCopyLink url={url} />
					</div>
				</div>
			</Popover>
		</>
	);
}

export default Share;
