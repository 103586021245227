import React from "react";
import styles from "./Dropdown.module.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import DotIcon from "../../../Icons/Dot";
import DeleteIcon from "../../../Icons/Delete";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FAQIcon } from "../../../Icons/FAQ";
import { Star } from "../../../Icons/Star(Helpcenter)";
import { TicketIcon } from "../../../Icons/Ticket";
import { LockIcon } from "../../../Icons/lock";
import { DiscountIcon } from "../../../Icons/Discount";

const { useState, useEffect } = React;

// const data = [{id: 0, label: "Active", icon: <DotIcon /> },
//               {id: 1, label: "Deactivated", icon: <DotIcon color="#8C123A" />},
//               {id: 2, label: "Deleted", icon: <DeleteIcon />}];

const Dropdown = ({ data, name, isActive, StyledSidebarItem, ...props }) => {
	const [isOpen, setOpen] = useState(false);
	const [items, setItem] = useState(data);

	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const toggleDropdown = () => setOpen(!isOpen);
	const openDropdown = () => setOpen(true);
	const location = useLocation();
	const handleItemClick = (id) => {
		props.setSelectedDropdownItem(id);
	};

	useEffect(() => {
		if (props.check) {
			setOpen(true);
		}

		data.forEach((element) => {
			if (location.pathname === element.url) {
				openDropdown();
			}
		});
	}, [props.active]);

	const history = useHistory();

	const iconColor = theme === "dark" ? "#D9DEF4" : "black";

	function getDropdownIcon(selectedDropdownItem) {
		switch (selectedDropdownItem) {
			case 1:
				return <TicketIcon color={iconColor} />;

			case 2:
				return <DiscountIcon color={iconColor} />;

			case 3:
				return <LockIcon color={iconColor} />;

			default:
				return <Star color={iconColor} />;
		}
	}

	return (
		<div className={styles.dropdown}>
			<StyledSidebarItem
				className={`${styles.dropdownHeader}  ${
					isOpen && styles.selected
				}`}
				onClick={toggleDropdown}
				isActive={isActive}
				theme={theme}
			>
				<FAQIcon color={props.iconColor} style={props.iconStyle} />
				{name}
				<StyledIcon
					className={`fa fa-chevron-right ${styles.icon} ${
						isOpen && styles.open
					}`}
					theme={theme}
					hasBackground={isActive}
				/>
			</StyledSidebarItem>
			<div className={`${styles.dropdownBody} ${isOpen && styles.open}`}>
				{items.map((item) => (
					<StyledDropdownItem
						className={`${styles.dropdownItem} ${
							location.pathname === item.url &&
							styles.selectedDropdownItem
						}`}
						onClick={(e) => {
							history.push(item.url);
							handleItemClick(item.id);
						}}
						id={item.id}
						isActive={item.id === props.selectedDropdownItem}
						theme={theme}
					>
						<div>{getDropdownIcon(item.id)}</div>
						<div className={styles.label}>{item.label}</div>
					</StyledDropdownItem>
				))}
			</div>
		</div>
	);
};

export const StyledDropdownItem = styled.div`
	box-shadow: ${function ({ isActive, theme }) {
		if (isActive) {
			return theme === "dark"
				? "0px 0px 10px rgba(0, 0, 0, 0.7)"
				: "0px 0px 10px rgba(0, 0, 0, 0.1)";
		} else return "none";
	}};

	border-radius: 8px;

	color: ${function ({ isActive, theme }) {
		if (isActive) {
			return theme === "dark" ? "#D9DEF4" : "#0d0f22";
		} else return theme === "dark" ? "#7F89BE" : "#4A517E";
	}};

	background: ${function ({ theme }) {
		if (theme === "dark") {
			return window.location.pathname === "/info" ? "#121528" : "#0d0f22";
		} else return "white";
	}};
`;

export const StyledIcon = styled.i`
	color: ${function ({ theme, hasBackground }) {
		if (hasBackground) return "#D9DEF4";
		else return theme === "dark" ? "#7F89BE" : "#4A517E";
	}};
`;

export default Dropdown;
