import { slide as Menu } from "react-burger-menu";
import { Avatar } from "@material-ui/core";
import Styles from "./SideNavbar.module.css";
import {
	getGroups,
	getMyGroup,
	joinGroupsURL,
	getGroupDetails,
	leaveTheGroupsURL,
	deleteGroupURL,
} from "../../../Utils/Constants";
import axiosConfig from "../../../Utils/axiosConfig";
import useAlert from "../../Extras/Alert/useAlert";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import GroupInfo from "../../Home/Groups/Mobile/GroupInfo";
import AddGroup from "../../Home/Groups/Mobile/AddGroup";
import { initalizeGroup } from "../../../Redux/Actions/actionAuth";
import { setGroup as setGroupForDispatch } from "../../../Redux/Actions/groupsAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    updateMyGroups,
} from "../../../Redux/Actions/myGroupsAction";

const SideNavbar = (props) => {
	const [myGroups, setMygroups] = useState([]);
	const [allGroups, setAllGroups] = useState([]);
	const [allGroupsCount, setAllGroupsCount] = useState([]);
	const [skip, setSkip] = useState(10);
	const [pgLoading, setPgLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [isOpen, setIsOpen] = useState(props.isOpen);
	const [isLoading, setIsLoading] = useState(false);
	const [groupID, setGroupID] = useState(0);
	const [selectedGroupName, setSelectedGroupName] = useState("");
	const [selectedGroupData, setSelectedGroupData] = useState(null);
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const loggedinUserID = useSelector((state) => state.auth.user_data?.userID);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const [exploreGroups, setExploregroups] = useState([]);
	const [exploreSelectedGroup, setExploreSelectedGroup] = useState(null);
	const [allSelectedGroup, setAllSelectedGroup] = useState(null);
	const [isDiscoverOpen, setIsDiscoverOpen] = useState(false);
	const [isAllOpen, setIsAllOpen] = useState(false);
	const [bio, setBio] = useState("");
	const [admin, setAdmin] = useState("");
	const [group, setGroup] = useState({});
	const [avatar, setAvatar] = useState("");
	const [grpName, setGrpname] = useState("");
	const [public1, setPublic] = useState(true);
	const [adminData, setAdminData] = useState({});
	const [joined, setjoined] = useState(false);
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
    const [index, setIndex] = useState(0);
    let myGroupsData = useSelector((state) => state.myGroups.myGroups);


	const handleClose = () => {
		setIndex(0);
	};
	const handleClose1 = () => {
		setIndex(0);
		setIsAllOpen(false);
		setIsDiscoverOpen(false);
	};
	const onSuccess = () => {
		setIsOpen(false);
		props.handleSideNavbar(false);
	};
	const checkMemberGroup = (members) => {
		members.forEach((mem) => {
			if (mem.user?.userID == loggedinUserID) {
				setjoined(true);
			}
		});
	};
	const getMyGroups = (searchText) => {
		axiosConfig
			.get(
				getMyGroup +
					`?limit=10&offset=0&search_phrase=${
						searchText ? searchText : ""
					}`
			)
			.then((res) => {
				setMygroups(res.data.result || res.data.body);
				if (res.data.result.length > 0) {
					setGroupID(res.data.result[0].id);
					setSelectedGroupName(res.data.result[0].name);
					setSelectedGroupData(res.data.result[0]);
					props.getGroupId(res.data.result[0].id);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
			});
	};
	const getExploreGroups = () => {
		axiosConfig
			.get(getGroups + "?limit=10&offset=0&search_phrase=")
			.then((res) => {
				setExploregroups(res.data.result || res.data.body);
			})
			.catch((err) => {});
	};
	const getAllGroups = (searchText) => {
		setIsLoading(true);

		axiosConfig
			.get(
				getMyGroup +
					`?limit=10&offset=0&search_phrase=${
						searchText ? searchText : ""
					}`
			)
			.then((res) => {
				setAllGroups(res.data.result || res.data.body);
				setAllGroupsCount(res.data.count || 0);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
			});
	};
	const pagination = async (searchText) => {
		setPgLoading(true);

		let data = await axiosConfig
			.get(
				getMyGroup +
					`?limit=10&offset=${skip}&search_phrase=${
						searchText ? searchText : ""
					}`
			)
			.then((res) => {
				if (res.status !== 200) return;
				return res.data;
			})
			.catch((err) => {
				setPgLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
				return "error";
			});
		return data;
	};
	const fetchData = () => {
		pagination()
			.then((data) => {
				if (data === "error") return;
				setAllGroups([...allGroups, ...data.result]);
				setSkip(skip + 10);
				console.log("ada", skip, hasMore, data.count);
				if (skip >= data?.count) {
					setHasMore(false);
				}
				setPgLoading(false);
			})
			.catch((err) => {
				console.log("ada", err.message);
				setPgLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
				setPgLoading(false);
			});
	};
	useEffect(() => {
		getMyGroups();
		getExploreGroups();
		getAllGroups();
    }, []);
    
	useEffect(() => {
		getGroupInfo();
    }, [groupID]);
    
	const getGroupInfo = () => {
		const form = new FormData();
		form.append("groupId", groupID ? groupID : 1);

		axiosConfig
			.post(getGroupDetails, form)
			.then((res) => {
				setGroup(res.data.body || res.data.result);
				checkMemberGroup(res.data.body.member_list);
				if (res.data.body.group_details.type === "public")
					setPublic(true);
				setAdmin(res.data.body.member_list[0].user.userID);
				setAdminData(res.data.body.member_list[0]);
				setGrpname(res.data.body.group_details?.name);
				setAvatar(res.data.body.group_details?.avatar);
				setBio(res.data.body.group_details?.bio);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert(err.response?.data?.body, {
					header: err.response?.data?.error,
					// callback: () => onHide(),
					buttonText: "Close",
				});
				setGroup([]);
				setAdmin(false);
				setGrpname("");
				setAvatar("");
				setBio("");
			});
	};
	const leaveGroup = () => {
		const form = new FormData();
		form.append("groupId", groupID);
		axiosConfig
			.post(leaveTheGroupsURL, form)
			.then((res) => {
                if (res.data.status_code !== 200) return;
                dispatch(updateMyGroups(Number(groupID)));
				showAlert("Group left Successfully", {
					header: "Left Successfully",
					buttonText: "Close",
				});
				dispatch(initalizeGroup());
				
				// onHide();
			})
			.catch((err) => {
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};

	const joinGroup = () => {
		if (joined) {
			return 0;
		}

		const form = new FormData();
		form.append("groupId", groupID);
		axiosConfig
			.post(joinGroupsURL, form)
			.then((res) => {
				getGroupInfo();

				if (
					res.data.message ===
					"Request forwarded to admin or subadmin."
				) {
					showAlert("Request Sent", {
						header: res.data.message,
						buttonText: "Close",
					});
				} else {
					showAlert("Joined Group", {
						header: "Joined Group Successfully",
						buttonText: "Close",
					});
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					if (
						err.response.data.message &&
						err.response.data.message.length > 0 &&
						err.response.data.message[0] ===
							"Request already pending."
					) {
						showAlert(err.response.data.message[0], {
							header: "Request Pedning",
							buttonText: "Close",
						});
					}
				} else {
					showAlert("Bad Request", {
						header: err.response.data?.error,
						buttonText: "Close",
					});
				}
			});
	};

	const deleteGroup = () => {
		axiosConfig
			.delete(deleteGroupURL + `${groupID}`)
            .then((res) => {
                dispatch(updateMyGroups(Number(groupID)));
				dispatch(
					setGroupForDispatch({ id: 1, name: "Global", avatar: "" })
				);
				showAlert("Group Deleted", {
					header: "Deleted Successfully",
					buttonText: "Close",
				});
				if (groupDataFromStore.id === groupID) {
					dispatch(initalizeGroup());
				}

				// onHide();
			})
			.catch((err) => {
				showAlert("Bad Request", {
					header: err.response?.data?.error,
					buttonText: "Close",
				});
			});
	};
	return (
		<React.Fragment>
			{index === 0 && (
				<Menu
					isOpen={isOpen}
					disableOverlayClick={!isOpen}
					onClose={() => {
						setIsOpen(false);
						props.handleSideNavbar(false);
					}}
				>
					{!isDiscoverOpen && !isAllOpen && (
						<div className={Styles.container}>
							<div className={Styles.left}>
								{myGroups.map((grp) => (
									<div className={Styles.groupIconBox}>
										{!isDiscoverOpen &&
											grp.id === groupID && (
												<div
													className={
														Styles.groupIconBorder
													}
												></div>
											)}
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											className={Styles.groupIcon}
											src={grp.avatar}
											onClick={() => {
												setIsDiscoverOpen(false);
												setExploreSelectedGroup(null);
												setGroupID(grp.id);
												setSelectedGroupName(grp.name);
												// setGroupID(grp.id);
												setSelectedGroupData(grp);
												props.getGroupId(grp.id);
											}}
										>
											{grp.name[0]?.toUpperCase()}
										</Avatar>
									</div>
								))}
								<div
									className={Styles.groupIconBox}
									onClick={() => {
										if (isDiscoverOpen === true) {
											setExploreSelectedGroup(null);
										}
										setIsAllOpen(true);
									}}
								>
									<div className={Styles.groupAddBox}>
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											className={Styles.groupIcon}
										>
											{"ALL".toUpperCase()}
										</Avatar>
									</div>
								</div>

								<div
									className={Styles.groupIconBox}
									onClick={() => {
										if (isDiscoverOpen === true) {
											setExploreSelectedGroup(null);
										}
										setIsDiscoverOpen(true);
									}}
								>
									{isDiscoverOpen && (
										<div
											className={Styles.groupIconBorder}
										></div>
									)}
									<img
										src={"/sample/discover.svg"}
                                        className={Styles.groupIcon}
                                        alt=""
									/>
								</div>
								<div
									className={Styles.groupIconBox}
									onClick={() => {
										if (isDiscoverOpen === true) {
											setExploreSelectedGroup(null);
										}
										setIsAllOpen(true);
									}}
								>
									<div
										className={Styles.groupAddBox}
										onClick={() => {
											setIndex(3);
										}}
									>
										<img
											src={"/sample/plus.png"}
											style={{
												marginTop: "10px",
                                            }}
                                            alt=""

										/>
									</div>
								</div>
							</div>
							<div className={Styles.right}>
								<p className={Styles.groupName}>
									{selectedGroupName}
								</p>
								<div className={Styles.groupInfoBox}>
									<div
										className={Styles.groupInfo}
										onClick={() => {
											setIndex(1);
										}}
									>
										<span>Workspace Info</span>
										<img src={"/sample/arrow.svg"}                                         alt=""
 />
									</div>
									<div
										className={Styles.groupInfo}
										onClick={() => {
											setIndex(2);
										}}
									>
										<span>
											Members
											{` (${
												group &&
												group.member_list ?
												group.member_list.length : "0"
											})`}
										</span>
										<img src={"/sample/arrow.svg"}                                         alt=""
 />
									</div>
								</div>
								<div className={Styles.bottomBlock}>
									<div className={Styles.button}>
										<img src={"/sample/link.svg"}                                         alt=""
 />
										<span className={Styles.buttonLabel}>
											Copy invite link
										</span>
									</div>
									{joined === true ? (
										<>
											{admin === loggedinUserID ? (
												<div
													className={Styles.button1}
													style={{
														marginTop: "1rem",
													}}
													onClick={() => {
														setIsOpen(false);
														deleteGroup();
													}}
												>
													<img
														src={
															"/sample/leave.svg"
														}
                                                        alt=""
													/>
													<span
														className={
															Styles.buttonLabel
														}
													>
														Delete Group
													</span>
												</div>
											) : (
												<div
													className={Styles.button1}
													style={{
														marginTop: "1rem",
													}}
													onClick={() => {
														setIsOpen(false);
														leaveGroup();
													}}
												>
													<img
														src={
															"/sample/leave.svg"
														}
                                                                                                    alt=""

													/>
													<span
														className={
															Styles.buttonLabel
														}
													>
														Leave Group
													</span>
												</div>
											)}
										</>
									) : (
										<div
											className={Styles.button1}
											style={{ marginTop: "1rem" }}
											onClick={() => {
												setIsOpen(false);
												props.handleSideNavbar(false);
												joinGroup();
											}}
										>
											<img src={"/sample/leave.svg"}                                         alt=""
 />
											<span
												className={Styles.buttonLabel}
											>
												Join Group
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{isDiscoverOpen && (
						<div className={Styles.exploreContainer}>
							{exploreSelectedGroup ? (
								<div className={Styles.right}>
									<div className={Styles.exploreHeader}>
										<img
											src={"/sample/back.svg"}
											onClick={() => {
												setExploreSelectedGroup(null);
                                            }}
                                                                                    alt=""

										/>
										<p style={{ textAlign: "center" }}>
											{selectedGroupName}
										</p>
									</div>
									<div className={Styles.groupInfoBox}>
										<div
											className={Styles.groupInfo}
											onClick={() => {
												setIndex(1);
											}}
										>
											<span>Workspace Info</span>
											<img src={"/sample/arrow.svg"}                                         alt=""
                                                 />
										</div>
										<div
											className={Styles.groupInfo}
											onClick={() => {
												setIndex(2);
											}}
										>
											<span>
												Members
												{` (${
													group &&
													group.member_list &&
													group.member_list.length
												})`}
											</span>
											<img src={"/sample/arrow.svg"}                                         alt=""
 />
										</div>
									</div>
									<div className={Styles.bottomBlock}>
										<div className={Styles.button}>
											<img src={"/sample/link.svg"}                                         alt=""
 />
											<span
												className={Styles.buttonLabel}
											>
												Copy invite link
											</span>
										</div>
										{joined === true ? (
											<>
												{admin === loggedinUserID ? (
													<div
														className={
															Styles.button1
														}
														style={{
															marginTop: "1rem",
														}}
														onClick={() => {
															setIsOpen(false);
															props.handleSideNavbar(
																false
															);
															deleteGroup();
														}}
													>
														<img
															src={
																"/sample/leave.svg"
															}
                                                            alt=""
														/>
														<span
															className={
																Styles.buttonLabel
															}
														>
															Delete Group
														</span>
													</div>
												) : (
													<div
														className={
															Styles.button1
														}
														style={{
															marginTop: "1rem",
														}}
														onClick={() => {
															setIsOpen(false);
															props.handleSideNavbar(
																false
															);
															leaveGroup();
														}}
													>
														<img
															src={
																"/sample/leave.svg"
															}
                                                            alt=""

														/>
														<span
															className={
																Styles.buttonLabel
															}
														>
															Leave Group
														</span>
													</div>
												)}
											</>
										) : (
											<div
												className={Styles.button1}
												style={{
													marginTop: "1rem",
												}}
												onClick={() => {
													setIsOpen(false);
													props.handleSideNavbar(
														false
													);
													joinGroup();
												}}
											>
												<img
                                                        src={"/sample/leave.svg"}
                                                                                                alt=""

												/>
												<span
													className={
														Styles.buttonLabel
													}
												>
													Join Group
												</span>
											</div>
										)}
									</div>
								</div>
							) : (
								<div className={Styles.right}>
									<div className={Styles.exploreHeader}>
										<img
											src={"/sample/back.svg"}
											onClick={() => {
												setGroupID(
													myGroups[0]?.id
												);
												setSelectedGroupName(
													myGroups[0]?.name
												);
												setIsDiscoverOpen(false);
                                            }}
                                            alt=""

										/>
										<p style={{ textAlign: "center" }}>
											Discover Groups
										</p>
									</div>

									{exploreGroups?.map((grp) => (
										<div
											className={Styles.exploreBlock}
											onClick={() => {
												setjoined(false);
												setGroupID(grp.id);
												setSelectedGroupName(grp.name);
												setExploreSelectedGroup(grp.id);
											}}
										>
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<Avatar
													style={{
														background:
															theme === "dark"
																? "#d9def4"
																: "#0d0f22",
														color:
															theme === "dark"
																? "#0d0f22"
																: "#d9def4",
														border: "1px solid #fff",
													}}
													className="mr-2"
													src={grp.avatar}
												>
													{grp.name[0]?.toUpperCase()}
												</Avatar>

												<span
													className={Styles.grpname}
													style={{
														pointer: "cursor",
													}}
												>
													{grp?.name}
												</span>

												{grp.type === "private" && (
													<span
														className={
															Styles.editPhoto
														}
													>
														<img src="/Home/Lock.svg"                                         alt=""
 />
													</span>
												)}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					)}
					{isAllOpen && (
						<div>
							<div className={Styles.exploreContainer}>
								<InfiniteScroll
									dataLength={allSelectedGroup.length}
									next={fetchData}
									hasMore={hasMore}
									height={500}
								>
									<div>
										{allSelectedGroup ? (
											<div className={Styles.right}>
												<div
													className={
														Styles.exploreHeader
													}
												>
													<img
														src={"/sample/back.svg"}
														onClick={() => {
															setExploreSelectedGroup(
																null
															);
															setAllSelectedGroup(
																null
															);
                                                        }}
                                                        alt=""
													/>
													<p
														style={{
															textAlign: "center",
														}}
													>
														{selectedGroupName}
													</p>
												</div>
												<div
													className={
														Styles.groupInfoBox
													}
												>
													<div
														className={
															Styles.groupInfo
														}
														onClick={() => {
															setIndex(1);
														}}
													>
														<span>
															Workspace Info
														</span>
														<img
                                                            src={
                                                                "/sample/arrow.svg"
                                                            }
                                                            alt=""
														/>
													</div>
													<div
														className={
															Styles.groupInfo
														}
														onClick={() => {
															setIndex(2);
														}}
													>
														<span>
															Members
															{` (${
																group &&
																group.member_list &&
																group
																	.member_list
																	.length
															})`}
														</span>
														<img
															src={
																"/sample/arrow.svg"
															}
                                                            alt=""
														/>
													</div>
												</div>
												<div
													className={
														Styles.bottomBlock
													}
												>
													<div
														className={
															Styles.button
														}
													>
														<img
															src={
																"/sample/link.svg"
															}
                                                            alt=""
														/>
														<span
															className={
																Styles.buttonLabel
															}
														>
															Copy invite link
														</span>
													</div>
													{joined === true ? (
														<>
															{admin ===
															loggedinUserID ? (
																<div
																	className={
																		Styles.button1
																	}
																	style={{
																		marginTop:
																			"1rem",
																	}}
																	onClick={() => {
																		setIsOpen(
																			false
																		);
																		props.handleSideNavbar(
																			false
																		);
																		deleteGroup();
																	}}
																>
																	<img
																		src={
																			"/sample/leave.svg"
																		}
                                                                        alt=""
																	/>
																	<span
																		className={
																			Styles.buttonLabel
																		}
																	>
																		Delete
																		Group
																	</span>
																</div>
															) : (
																<div
																	className={
																		Styles.button1
																	}
																	style={{
																		marginTop:
																			"1rem",
																	}}
																	onClick={() => {
																		setIsOpen(
																			false
																		);
																		props.handleSideNavbar(
																			false
																		);
																		leaveGroup();
																	}}
																>
																	<img
																		src={
																			"/sample/leave.svg"
																		}
                                                                        alt=""

																	/>
																	<span
																		className={
																			Styles.buttonLabel
																		}
																	>
																		Leave
																		Group
																	</span>
																</div>
															)}
														</>
													) : (
														<div
															className={
																Styles.button1
															}
															style={{
																marginTop:
																	"1rem",
															}}
															onClick={() => {
																setIsOpen(
																	false
																);
																props.handleSideNavbar(
																	false
																);
																joinGroup();
															}}
														>
															<img
																src={
																	"/sample/leave.svg"
																}
                                                                alt=""

															/>
															<span
																className={
																	Styles.buttonLabel
																}
															>
																Join Group
															</span>
														</div>
													)}
												</div>
											</div>
										) : (
											<div className={Styles.right}>
												<div
													className={
														Styles.exploreHeader
													}
												>
													<img
                                                        src={"/sample/back.svg"}
                                                        alt=""
														onClick={() => {
															setGroupID(
																myGroups[0]?.id
															);
															setSelectedGroupName(
																myGroups[0]
																	?.name
															);
															setIsDiscoverOpen(
																false
															);
                                                            setIsAllOpen(false);
                                                            
														}}
													/>
													<p
														style={{
															textAlign: "center",
														}}
													>
														My Groups
													</p>
												</div>

												{allGroups?.map((grp) => (
													<div
														className={
															Styles.exploreBlock
														}
														onClick={() => {
															setjoined(false);
															setGroupID(
																grp.id
															);
															setSelectedGroupName(
																grp.name
															);
															setAllSelectedGroup(
																grp.id
															);
														}}
													>
														<div
															style={{
																display: "flex",
																alignItems:
																	"center",
															}}
														>
															<Avatar
																style={{
																	background:
																		theme ===
																		"dark"
																			? "#d9def4"
																			: "#0d0f22",
																	color:
																		theme ===
																		"dark"
																			? "#0d0f22"
																			: "#d9def4",
																	border: "1px solid #fff",
																}}
																className="mr-1"
																src={grp.avatar}
															>
																{grp.name[0]?.toUpperCase()}
															</Avatar>

															<span
																className={
																	Styles.grpname
																}
																style={{
																	pointer:
																		"cursor",
																}}
															>
																{grp?.name}
															</span>

															{grp.type ===
																"private" && (
																<span
																	className={
																		Styles.editPhoto
																	}
																>
																	<img src="/Home/Lock.svg"                                         alt=""
 />
																</span>
															)}
														</div>
													</div>
												))}
											</div>
										)}
									</div>
								</InfiniteScroll>
							</div>
						</div>
					)}
				</Menu>
			)}
			{index ===1 && (
				<GroupInfo
					type={"info"}
					onClose={handleClose}
					group={group}
					bio={bio}
					adminData={adminData}
					groupId={groupID}
				/>
			)}
			{index === 2 && (
				<GroupInfo
					type={"members"}
					onClose={handleClose}
					group={group}
					bio={bio}
					adminData={adminData}
					// handleGroupInfo={this.handleGroupInfo}
					// handleSideNavbar={this.handleSideNavbar}
					groupId={groupID}
				/>
			)}
			{index === 3 && (
				<AddGroup onClose={handleClose1} onSuccess={onSuccess} />
			)}
		</React.Fragment>
	);
};

export default SideNavbar;
