import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import axiosChatConfig from "../../../../Utils/axiosChatConfig";
import { getMediaByID } from "../../../../Utils/Constants";

import Media from "./Media";
import styles from "./MediaPreview.module.css";

import { Chevron } from "../../../../Icons";

import { CircularProgress } from "@material-ui/core";

const MediaPreview = ({ conversationID, isGroup, onExpand }) => {
	const [docs, setDocs] = useState([]);
	const [mediaLoading, setMediaLoading] = useState(false);
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const groupMedia = () => {
		const data = {
			conversationID,
			isGroup,
			skip: 0,
			limit: 3,
		};
		setMediaLoading(true);
		axiosChatConfig
			.post(getMediaByID, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setDocs(res.data.chats);
				setMediaLoading(false);
			})
			.catch((err) => {
				setMediaLoading(false);
			});
	};

	useEffect(() => {
		if (conversationID) groupMedia();
	}, [conversationID]);

	return (
		<div className={styles.container}>
			<div className={`${styles.buttonHeader}`} onClick={onExpand}>
				<h1
					className={styles.title}
					style={{ color: theme === "dark" ? "#D9DEF4" : "#0d0f22" }}
				>
					Shared Media
					{/* <FormattedMessage
						id="sharedMedia"
						defaultMessage={`Shared Media`}
					/> */}
				</h1>
				<Chevron.Right />
			</div>
			<div className={styles.mediaPreviewContainer}>
				{mediaLoading ? (
					<div className={styles.mediaLoaderContainer}>
						<CircularProgress
							thickness={5}
							size="1.5rem"
							style={{
								color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
							}}
						/>
					</div>
				) : docs.length > 0 ? (
					docs.map((doc) => (
						<div className={styles.mediaPreview}>
							<Media data={doc} key={doc._id} />
						</div>
					))
				) : (
					<div className={styles.mediaLoaderContainer}>
						<span
							className={styles.noMediaTypography}
							style={{
								color: theme === "dark" ? "#D9DEF4" : "#0d0f22",
							}}
						>
							{/* <FormattedMessage
								id="noMediaAvailable"
								defaultMessage={`No Media Shared`}
							/> */}
							No Media Shared
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default MediaPreview;
