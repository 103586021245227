import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import Picker from "emoji-picker-react";
import { pure } from "recompose";
import { Markup } from "interweave";
import { format } from "@flasd/whatsapp-formatting";

import { sendTextMessage } from "../../../../../Redux/Actions/messages/actions";
import styles from "./InputBox.module.css";

// Utils
import * as Socket from "../../../../../Utils/socket";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import {
	CHAT_META_SERVICES,
	joinGroupURL,
	MESSAGE_MAX_LENGTH,
} from "../../../../../Utils/Constants";

//Material UI
import {
	Avatar,
	ClickAwayListener,
	useMediaQuery,
	makeStyles,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import CloseIcon from "@material-ui/icons/Close";

// Components
import TagMember from "./TagMember";
import { MsgTime, urlExtractor } from "../MessageBox/Helpers";
import useAlert from "../../../../Extras/Alert/useAlert";
import Button1 from "../../../../Extras/Buttons/Button1/Button1";
import VoiceDialog from "../AttachmentView/VoiceUploadDialog";

import { SendArrow, Smiley, Paperclip } from "../../../../../Icons";

function setCaretPosition(ctrl, pos) {
	if (ctrl.setSelectionRange) {
		ctrl.focus();
		ctrl.setSelectionRange(pos, pos);
	} else if (ctrl.createTextRange) {
		var range = ctrl.createTextRange();
		range.collapse(true);
		range.moveEnd("character", pos);
		range.moveStart("character", pos);
		range.select();
	}
}

const useStyles = makeStyles((theme) => ({
	files: {
		cursor: "pointer",
	},
	post: {
		textTransform: "capitalize",
		fontWeight: 500,
		fontSize: "1rem",
		padding: "0 2.4rem",
		borderRadius: "100px",
		fontFamily: "Poppins",
		background: "linear-gradient(to right,#FC466B, #3F5EFB);",
		[theme.breakpoints.down(768)]: {
			padding: " 0 0",
		},
	},
	close: {
		position: "absolute",
		right: "5px",
		top: "5px",
		cursor: "pointer",
		color: "#D9DEF4",
		width: "30px",
		height: "30px",
	},
}));

const acceptedExtensions = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

const ReplyBox = ({ data, resetReply }) => {
	const classes = useStyles();

	const theme = useSelector((reduxState) => reduxState.theme.theme);

	if (!data) return <></>;
	return (
		<div className={styles.replyBox}>
			<Avatar
				src={data.profileURL}
				className={styles.avatar}
				style={{
					width: "2rem",
					height: "2rem",
					marginRight: ".1rem",
					background: theme === "dark" ? "#d9def4" : "#0d0f22",
					color: theme === "dark" ? "#0d0f22" : "#d9def4",
				}}
			>
				{data.username && data.username[0].toUpperCase()}
			</Avatar>
			<div className={styles.replyCont}>
				<CloseIcon className={classes.close} onClick={resetReply} />
				<p className={styles.replyUser}>
					@{data.username}
					&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
					{<MsgTime timestamp={data.timeStamp} />}{" "}
				</p>
				{data.type === "TEXT" ? (
					<div
						className={styles.replyText}
						style={{
							color: theme === "dark" ? "white" : "#0D0F22",
						}}
					>
						<Markup content={format(data.message)} />
					</div>
				) : data.type === "IMAGE" ? (
					<div className={styles.replyImgCont}>
						<img
							className={styles.replyImg}
							src={data.media}
							alt={data.username}
						/>
						<p className={styles.replyText}>{data.message}</p>
					</div>
				) : (
					data.type === "VIDEO" && (
						<div className={styles.replyImgCont}>
							<video
								className={styles.replyImg}
								src={data.media}
								alt={data.username}
							/>
							<p className={styles.replyText}>{data.message}</p>
						</div>
					)
				)}
			</div>
		</div>
	);
};

const MetaTagsBox = ({ data, close }) => {
	const classes = useStyles();
	if (!data) return <></>;
	return (
		<a
			target="_blank"
			href={data.url}
			rel="noopener noreferrer"
			className={styles.metaTagsCont}
		>
			<CloseIcon className={classes.close} onClick={close} />
			{data.image && (
				<div className={styles.metaMedia}>
					<img alt="ogImage" src={data.image.url} />
				</div>
			)}
			<div className={styles.metaContent}>
				<h2>{data.title}</h2>
				<p>{data.description}</p>
			</div>
		</a>
	);
};

const INITIAL_META_STATE = { url: "", data: null };

const AUDIO_PERMISSIONS = { audio: true };
let mediaRecorder;
let dataArray = [];

const InputBox = (props) => {
	const { showAlert } = useAlert();
	const [file, setFile] = useState(null);
	const [message, setMessage] = useState("");
	const [joining, setJoining] = useState(false);
	const [openEmoji, setOpenEmoji] = useState(false);
	const [userMessage, setUserMessage] = useState("");
	const [meta, setMeta] = useState(INITIAL_META_STATE);
	const [isRecording, setIsRecording] = useState(false);
	const [sendAudioDialogOpen, setSendAudioDialogOpen] = useState(false);
	const [mentionsState, setMentionsState] = useState({
		isOpen: false,
		searchTerm: "",
		mentions: {},
	});
	const [isLoaded, setisLoaded] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [time, setTime] = useState(0);
	const [recordedFile, setRecordedFile] = useState(null);

	const audioRecRef = useRef();
	const audioPlayRef = useRef();
	const mediaStream = useRef(null);
	const intervalHandler = useRef();
	let typingTimer = useRef(null);
	let input = useRef();
	const componentRef = useRef();

	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const closeMeta = () => setMeta(INITIAL_META_STATE);

	const classes = useStyles();

	useEffect(() => {
		if (input.current) input.current.focus();
	}, []);

	const handleJoinConv = (e) => {
		e.preventDefault();

		if (!userMessage.trim()) return;
		const data = { statusID: props.chatID };

		setJoining(true);

		axiosChatConfig
			.post(joinGroupURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				setJoining(false);

				Socket.emitJoinGroup(props.chatID, (data) => {
					props.setJoinSuccess(data);
					handlePost(e);
				});
				input.current.focus();
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
				setJoining(false);
			});
	};

	const setUpAudioRecorder = async () => {
		try {
			const mediaStreamObj = await navigator.mediaDevices.getUserMedia(
				AUDIO_PERMISSIONS
			);
			mediaStream.current = mediaStreamObj;
			mediaRecorder = new MediaRecorder(mediaStreamObj);
			mediaRecorder.ondataavailable = function (ev) {
				dataArray.push(ev.data);
			};

			audioPlayRef.current.addEventListener("ended", () => {
				setIsPlaying(false);
			});

			mediaRecorder.onstop = function (ev) {
				let audioData = new Blob(dataArray, { type: "audio/mp3;" });
				dataArray = [];
				let audioSrc = window.URL.createObjectURL(audioData);
				setRecordedFile(audioData);
				audioPlayRef.current.src = audioSrc;
			};

			if ("srcObject" in audioRecRef.current) {
				audioRecRef.current.srcObject = mediaStreamObj;
			} else {
				audioRecRef.current.src =
					window.URL.createObjectURL(mediaStreamObj);
			}
			setisLoaded(true);
		} catch (err) {}
	};

	const startRecording = () => {
		if (isLoaded) {
			setTime(0);
			intervalHandler.current = setInterval(() => {
				setTime((time) => time + 1);
			}, 1000);
			setRecordedFile(null);
			setIsRecording(true);
			mediaRecorder.start();
		}
	};

	const stopRecording = () => {
		setIsRecording(false);
		clearInterval(intervalHandler.current);
		mediaRecorder !== null && mediaRecorder.stop();
	};

	useEffect(() => {
		if (sendAudioDialogOpen) {
			setUpAudioRecorder();
			setRecordedFile(null);
		} else {
			mediaRecorder = null;
			if (mediaStream.current) {
				mediaStream.current
					.getTracks()
					.forEach((track) => track.stop());
			}
		}
	}, [sendAudioDialogOpen]);

	// const playAudio = () => {
	// 	setIsPlaying(true);
	// 	audioPlayRef.current.play();
	// };
	// const pauseAudio = () => {
	// 	setIsPlaying(false);
	// 	audioPlayRef.current.pause();
	// };
	// const stopAudio = () => {
	// 	setIsPlaying(false);
	// 	audioPlayRef.current.pause();
	// 	audioPlayRef.current.currentTime = 0;
	// };

	const sendAudio = () => {
		handleAudioFile(recordedFile);
		setSendAudioDialogOpen(false);
	};

	const handleMessageChange = (e) => {
		const message = e.target.value;
		if (message.length <= MESSAGE_MAX_LENGTH) {
			if (!props.replyMessage) getMetaTags(message);

			setUserMessage(message);
		}
	};

	const onCaretPositionChange = (e) => {
		if (props.isGroup) handleMentions();
	};

	const getCurrentWordExtent = (message, currentCursorPos) => {
		let start = currentCursorPos - 1;
		let end = currentCursorPos;
		while (message[end] !== " " && end < message.length) {
			end++;
		}
		while (message[start] !== " " && start > -1) {
			start--;
		}
		return { start: start + 1, end };
	};

	const handleMentions = () => {
		const currentCursorPos = input.current.selectionStart;
		/**@type {String} */
		const message = input.current.value;
		const lastChar = message[currentCursorPos - 1];
		if (lastChar === "@") {
			setMentionsState((prev) => ({
				...prev,
				isOpen: true,
				searchTerm: "",
			}));
		} else if (lastChar === " ") {
			setMentionsState((prev) => ({
				...prev,
				isOpen: false,
				searchTerm: "",
			}));
		} else {
			const { start, end } = getCurrentWordExtent(
				message,
				currentCursorPos
			);
			const currentWord = message.slice(start, end);
			if (currentWord.startsWith("@")) {
				setMentionsState((prev) => ({
					...prev,
					isOpen: true,
					searchTerm: currentWord.slice(1),
				}));
			} else {
				setMentionsState((prev) => ({
					...prev,
					isOpen: false,
					searchTerm: "",
				}));
			}
		}
	};

	const mentionUser = (username, userID) => {
		const message = input.current.value;
		const currentCursorPos = input.current.selectionStart;
		const { start, end } = getCurrentWordExtent(message, currentCursorPos);
		setCaretPosition(input.current, start + username.length);
		setUserMessage(
			message.slice(0, start) + "@" + username + " " + message.slice(end)
		);
		setMentionsState((prev) => ({
			...prev,
			mentions: { ...prev.mentions, [username]: userID },
		}));
	};

	/**@param {String} message */
	const inflateMentions = (message) => {
		return Object.keys(mentionsState.mentions).reduce(
			(message, username) => {
				return message.replace(
					`@${username}`,
					`<User pk={${mentionsState.mentions[username]}}>@${username}</User>`
				);
			},
			message
		);
	};

	const getMetaTags = async (message) => {
		const urls = urlExtractor(message);
		if (urls) {
			const metaUrl = urls[0];
			if (metaUrl !== meta.url) {
				try {
					const { data } = await axiosChatConfig.get(
						CHAT_META_SERVICES.OPEN_GRAPH,
						{ params: { url: metaUrl } }
					);
					const { meta: openGraphTags } = data;
					setMeta({
						url: metaUrl,
						data: !openGraphTags.insufficient
							? openGraphTags
							: null,
					});
				} catch (err) {}
			}
		} else {
			if (meta.url) {
				closeMeta();
			}
		}
	};

	const handleEmojiClick = (e, emojiClick) => {
		setUserMessage(userMessage + emojiClick.emoji);
	};

	const handleTyping = (e) => {
		const isGroup = props.chatType === "group";
		if (e.key === "Enter" && !e.shiftKey) {
			props.isMember ? handlePost(e) : handleJoinConv(e);
			return;
		}

		if (!typingTimer.current) {
			typingTimer.current = setTimeout(() => {
				Socket.emitTyping(props.chatID, isGroup);
				clearTimeout(typingTimer.current);
				typingTimer.current = null;
			}, 2000);
		}
	};

	const handlePost = (event) => {
		event.preventDefault();
		const isGroup = props.chatType === "group";

		if (!isGroup && !props.friendshipExists) {
			showAlert("You can no longer send messages to this chat.", {
				header: "Friendship Broken!!",
			});
			return;
		}
		let message = userMessage.trim();
		if (message) {
			if (props.isGroup) {
				message = inflateMentions(message);
			}

			const mentions = Object.keys(mentionsState.mentions).reduce(
				(prev, curr) =>
					message.includes(curr)
						? [...prev, mentionsState.mentions[curr]]
						: prev,
				[]
			);
			scroll.scrollToBottom({ containerId: "chatbox" });
			let data = {
				conversationID: `${props.chatID}`,
				userID: props.userID,
				isGroup,
				message: {
					mentions: mentions.length > 0 ? mentions : null,
					message,
					type: "TEXT",
					replyMessage: props.replyMessage,
					replyMessageID: props.replyMessage?._id || null,
					meta: !props.replyMessage && meta.data,
				},
			};
			props.resetReply();
			props.sendMessage(data);
			closeMeta();
		}
		setUserMessage("");
		setOpenEmoji(false);
		input.current.focus();
	};

	const handleFileDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setFile(e.dataTransfer.files[0]);
	};

	const handleAudioFile = (file) => {
		const isGroup = props.chatType === "group";
		const TYPE = "AUDIO";
		let data = {
			conversationID: `${props.chatID}`,
			isGroup,
			userID: props.userID,
			message: {
				message: message.trim(),
				type: TYPE,
				file: file,
				media: URL.createObjectURL(file),
				filename: `${props.chatID}-${
					props.userID
				}-${new Date().toISOString()}`,
			},
		};
		props.sendMessage(data);
	};

	const isMobile = useMediaQuery("(max-width:768px)");

	// function tabIndex(index) {
	// 	return {
	// 		id: `scrollable-auto-tab-${index}`,
	// 		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	// 	};
	// }

	const handleCaptionChange = (e) => {
		const message = e.target.value;
		setMessage((prev) =>
			message.length <= MESSAGE_MAX_LENGTH ? message : prev
		);
	};

	const isActive =
		props.statusState === undefined || props.statusState === "active";
	const errorText = `You cannot join the conversation, since it is ${
		props.statusState === "deleted" ? "deleted" : "closed"
	} by the creator. create your new one to start interacting with people`;

	const fileRef = useRef(null);

	const setFileRef = () => {
		fileRef.current.click();
	};

	const [files, setFiles] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];		
		if (file.size > 5242880) {
			return showAlert("File size is large", {
				header: "Reduce the size",
				buttonText: "Go Back",
			});
		}
		 else {
			setFiles(file);
			setImageUrl(URL.createObjectURL(file));
		}
	};

	const handleClose = () => {
		fileRef.current.value = "";
		setFiles(null);
		setMessage("");
	};

	const renderFile = () => {
		if (!files) return;
		const fileType = files.type.split("/");
		const fileParts = files.name.split(".");
		const extension =
			fileParts.length === 1 ? "" : fileParts[fileParts.length - 1];
		if (fileType[0] === "image") {
			return <img src={imageUrl} alt="icon" className={styles.image} />;
		} else if (
			fileType[1] === "pdf" ||
			acceptedExtensions.includes(extension)
		) {
			return (
				<div className={styles.doc}>
					<p className={styles.fileName}>{files.name}</p>
				</div>
			);
		}
	};

	const handlePost1 = (event) => {
		event.preventDefault();

		const fileGen = files.type.split("/")[0];
		const fileType = files.type.split("/")[1];

		let type;
		if (fileGen === "image") {
			if (fileType === "gif") type = "GIF";
			else type = "IMAGE";
		} else if (fileType === "pdf") {
			type = "PDF";
		}
		const isGroup = props.chatType === "group";

		let data = {
			conversationID: `${props.chatID}`,
			isGroup,
			userID: props.userID,
			message: {
				message: message.trim(),
				type,
				file: files,
				media: URL.createObjectURL(files),
				filename: files.name,
			},
		};
		props.sendMessage(data);
		handleClose();
	};

	return (
		<>
			{!isMobile ? (
				<div
					className={styles.inputCont}
					disabled={props.groupLoading}
					ref={componentRef}
				>
					<hr
						className={styles.inputbox_breakline}
						style={{
							color: theme === "dark" ? "#2E2E48" : "#7F89BE",
						}}
					/>
					<ReplyBox
						data={props.replyMessage}
						resetReply={props.resetReply}
					/>
					<MetaTagsBox data={meta.data} close={closeMeta} />
					{props.isGroup && (
						<TagMember
							members={props.members}
							open={mentionsState.isOpen}
							search={mentionsState.searchTerm}
							mentionUser={mentionUser}
						/>
					)}

					{openEmoji && (
						<ClickAwayListener
							onClickAway={() => {
								if (input.current !== document.activeElement)
									setOpenEmoji(false);
							}}
						>
							<div className={styles.emoji}>
								<Picker
									onEmojiClick={handleEmojiClick}
									preload
								/>
							</div>
						</ClickAwayListener>
					)}

					<form className={styles.secContainer}>
						{(!props.isMember && !isActive) ||
						props.statusState === "deleted" ? (
							<button className={styles.joinConv} disabled={true}>
								<span className={styles.errorInfo}>
									{errorText}
								</span>
							</button>
						) : (
							<div
								className={styles.inputBox}
								onDrop={handleFileDrop}
							>
								<button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
									}}
									onClick={(e) => {
										e.preventDefault();
										if (openEmoji) {
											setOpenEmoji(false);
										} else setOpenEmoji(true);
									}}
								>
									<Smiley
										style={{ marginRight: "0.5rem" }}
										theme={theme}
									/>
								</button>

								<button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
									}}
									onClick={(e) => {
										e.preventDefault();
										setFileRef();
									}}
								>
									<Paperclip theme={theme} />
								</button>

								<input
									type="file"
									style={{ display: "none" }}
									onChange={(e) => handleFileChange(e)}
									ref={fileRef}
									autoFocus
                                    multiple={false}
                                    accept="image/jpg, image/jpeg, image/png, application/pdf, application/docx, application/doc, application/ppt, application/pptx, application/xls, application/xlsx"
								/>
								<Modal
									show={files}
									onHide={handleClose}
									className={styles.modal1}
									centered
								>
									<CloseIcon
										className={classes.close}
										onClick={handleClose}
									/>
									<Modal.Body
										className={
											theme === "dark"
												? styles.darkm
												: styles.lgm
										}
									>
										{renderFile()}
										<div className={styles.inputCon}>
											<textarea
												onPaste={(e) => {
													if (
														e.clipboardData.items[0]
															.type !==
														"text/plain"
													) {
														setFile(
															e.clipboardData.items[0].getAsFile()
														);
													}
												}}
												className={styles.input}
												value={message}
												onChange={handleCaptionChange}
												onKeyPress={(e) => {
													if (e.key === "Enter") {
														handlePost(e);
														return;
													}
													handleTyping(e);
												}}
												placeholder="Caption"
												autoFocus
												style={{
													margin: "!rem 0",
													height: "3rem",
													border: `1px solid ${
														theme === "dark"
															? "#7F89BE"
															: "#4A517E"
													}`,
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
											&nbsp; &nbsp;
											<Button1
												style={{
													margin: "auto",
													height: "3rem",
												}}
												onClick={(e) =>
													handlePost1(e, files)
												}
											>
												Post
											</Button1>
										</div>
									</Modal.Body>
								</Modal>

								<button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
									}}
									onClick={(e) => {
										e.preventDefault();
										setSendAudioDialogOpen(
											!sendAudioDialogOpen
										);
										startRecording();
									}}
								>
									{sendAudioDialogOpen ? (
										<CloseIcon
											style={{
												margin: "0 0.5rem ",
												fontSize: "2rem",
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#0D0F22",
											}}
										/>
									) : (
										<MicIcon
											style={{
												margin: "0 0.5rem ",
												fontSize: "2rem",
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#0D0F22",
											}}
										/>
									)}
								</button>

								{sendAudioDialogOpen ? (
									<>
										<VoiceDialog
											open={sendAudioDialogOpen}
											onClick={() => {
												setSendAudioDialogOpen(
													!sendAudioDialogOpen
												);
												stopRecording();
											}}
											onClose={() =>
												setSendAudioDialogOpen(false)
											}
											onSend={handleAudioFile}
										/>
									</>
								) : (
									<>
										<textarea
											onPaste={(e) => {
												if (
													e.clipboardData.items[0]
														.type !== "text/plain"
												) {
													setFile(
														e.clipboardData.items[0].getAsFile()
													);
												}
											}}
											ref={input}
											value={!joining ? userMessage : ""}
											className={styles.input}
											disabled={props.groupLoading}
											onChange={handleMessageChange}
											onKeyPress={(e) => handleTyping(e)}
											placeholder={
												!joining
													? !props.isMember &&
													  props.chatType === "group"
														? "message to join the Group."
														: "Write a message"
													: "Please wait while you join the group.."
											}
											onSelect={onCaretPositionChange}
											autoFocus
											style={{
												border: `1px solid ${
													theme === "dark"
														? "#7F89BE"
														: "#4A517E"
												}`,
												color:
													theme === "dark"
														? "#7F89BE"
														: "#4A517E",
											}}
										/>
										&nbsp; &nbsp;
										{isActive && (
											<Button1
												size="small"
												type="submit"
												classes={{ root: classes.post }}
												theme={theme}
												style={{
													marginTop: isMobile
														? "-0.3rem"
														: "0",
													fontWeight: "900",
												}}
												onClick={
													props.chatType !==
														"group" ||
													props.isMember
														? recordedFile
															? sendAudio
															: handlePost
														: handleJoinConv
												}
												height="3rem"
												disabled={props.groupLoading}
											>
												{/* {isMobile ? (
											<SendArrow.Outline />
										) : (
											)} */}
												Send
											</Button1>
										)}
									</>
								)}
							</div>
						)}
					</form>
				</div>
			) : (
				<>
					<div
						className={styles.inputCont}
						disabled={props.groupLoading}
						ref={componentRef}
					>
						<ReplyBox
							data={props.replyMessage}
							resetReply={props.resetReply}
						/>
						<MetaTagsBox data={meta.data} close={closeMeta} />
						{props.isGroup && (
							<TagMember
								members={props.members}
								open={mentionsState.isOpen}
								search={mentionsState.searchTerm}
								mentionUser={mentionUser}
							/>
						)}
						{openEmoji && (
							<ClickAwayListener
								onClickAway={() => {
									if (
										input.current !== document.activeElement
									)
										setOpenEmoji(false);
								}}
							>
								<div className={styles.emoji}>
									<Picker
										onEmojiClick={handleEmojiClick}
										preload
									/>
								</div>
							</ClickAwayListener>
						)}

						<form className={styles.secContainer}>
							{(!props.isMember && !isActive) ||
							props.statusState === "deleted" ? (
								<button
									className={styles.joinConv}
									disabled={true}
								>
									<span className={styles.errorInfo}>
										{errorText}
									</span>
								</button>
							) : (
								<div
									className={styles.inputBox}
									onDrop={handleFileDrop}
								>
									<button
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
										}}
										onClick={(e) => {
											e.preventDefault();
											if (openEmoji) {
												setOpenEmoji(false);
											} else setOpenEmoji(true);
										}}
									>
										<Smiley
											style={{ marginRight: "0" }}
											theme={theme}
										/>
									</button>

									<button
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
										}}
										onClick={(e) => {
											e.preventDefault();
											setFileRef();
										}}
									>
										<Paperclip theme={theme} />
									</button>

									<input
										type="file"
										style={{ display: "none" }}
										onChange={(e) => handleFileChange(e)}
										ref={fileRef}
										autoFocus
                                        multiple={false}
                                        accept="image/jpg, image/jpeg, image/png, application/pdf, application/docx, application/doc, application/ppt, application/pptx, application/xls, application/xlsx"
                                    />

									<Modal
										style={{
											zIndex: "10000",
										}}
										show={files}
										onHide={handleClose}
										className={styles.modal1}
										centered
									>
										<CloseIcon
											className={classes.close}
											onClick={handleClose}
										/>
										<Modal.Body
											className={
												theme === "dark"
													? styles.darkm
													: styles.lgm
											}
										>
											{renderFile()}
											<div className={styles.inputCon}>
												<textarea
													onPaste={(e) => {
														if (
															e.clipboardData
																.items[0]
																.type !==
															"text/plain"
														) {
															setFile(
																e.clipboardData.items[0].getAsFile()
															);
														}
													}}
													className={styles.input}
													value={message}
													onChange={
														handleCaptionChange
													}
													onKeyPress={(e) => {
														if (e.key === "Enter") {
															handlePost(e);
															return;
														}

														handleTyping(e);
													}}
													placeholder="Caption"
													autoFocus
													style={{
														margin: "!rem 0",
														height: "3rem",
														border: `1px solid ${
															theme === "dark"
																? "#7F89BE"
																: "#4A517E"
														}`,
														color:
															theme === "dark"
																? "#7F89BE"
																: "#4A517E",
													}}
												/>
												&nbsp; &nbsp;
												<Button1
													style={{
														margin: "auto",
														height: "3rem",
													}}
													onClick={(e) =>
														handlePost1(e, files)
													}
												>
													Post
												</Button1>
											</div>
										</Modal.Body>
									</Modal>

									<>
										<textarea
											onPaste={(e) => {
												if (
													e.clipboardData.items[0]
														.type !== "text/plain"
												) {
													setFile(
														e.clipboardData.items[0].getAsFile()
													);
												}
											}}
											ref={input}
											value={!joining ? userMessage : ""}
											className={styles.input}
											disabled={props.groupLoading}
											onChange={handleMessageChange}
											onKeyPress={(e) => handleTyping(e)}
											placeholder={
												!joining
													? !props.isMember &&
													  props.chatType === "group"
														? "message to join the Group."
														: "Write a message"
													: "Please wait while you join the group.."
											}
											onSelect={onCaretPositionChange}
											autoFocus
											style={{
												border: `1px solid ${
													theme === "dark"
														? "#7F89BE"
														: "#4A517E"
												}`,
												color:
													theme === "dark"
														? "#7F89BE"
														: "#4A517E",
											}}
										/>

										{isActive && (
											<button
												size="small"
												type="submit"
												// classes={{ root: classes.post }}
												// theme={theme}
												className="btn p-0"
												onClick={
													props.chatType !==
														"group" ||
													props.isMember
														? recordedFile
															? sendAudio
															: handlePost
														: handleJoinConv
												}
												// height="3rem"
												// padding={"0 0"}
												disabled={props.groupLoading}
											>
												<SendArrow.Outline />
											</button>
										)}
									</>
								</div>
							)}
						</form>
					</div>
				</>
			)}
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	sendMessage: (message) => dispatch(sendTextMessage(message)),
});

export default connect(null, mapDispatchToProps)(pure(InputBox));
