import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import styles from "./Alert.module.css";
import Button1 from "../Buttons/Button1/Button1";
import Button2 from "../Buttons/Button2/Button2";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		width: "35rem",
		borderRadius: "6px",
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(6, 6, 3),
	},
}));

export default function Alert(props) {
	const classes = useStyles();
	const { show, message, hideAlert, callback, header, buttonText } = props;

	const handleAgree = async () => {
		await hideAlert();

		if (callback) callback();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={show}
			onClose={hideAlert}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
            }}
            style={{margin:5}}
		>
			<Fade in={show}>
				<div className={classes.paper}>
					<h2 className={styles.title}>{header}</h2>
					<p className={styles.message}>{message}</p>
					<div className={styles.btns}>
						{props.linkText && (
							<Button1
								href="#link"
								className={styles.link}
								onClick={async (e) => {
									e.preventDefault();
									props.hideAlert();
									props.linkClick && props.linkClick();
								}}
							>
								{props.linkText}
							</Button1>
                        )}
                        &nbsp; &nbsp;
                        <Button2
							onClick={handleAgree}
							className={styles.button}
						>
							{buttonText || "CLOSE"}
						</Button2>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}
