import React from "react";

const TwitterIcon = ({ color = "#D9DEF4" }) => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 5.625C0 2.5184 2.5184 0 5.625 0H30.375C33.4816 0 36 2.5184 36 5.625V30.375C36 33.4816 33.4816 36 30.375 36H5.625C2.5184 36 0 33.4816 0 30.375V5.625ZM24.8625 12.9375C25.65 12.825 26.325 12.7125 27 12.375C26.55 13.1625 25.875 13.8375 25.0875 14.2875C25.3125 19.575 21.4875 25.3125 14.625 25.3125C12.6 25.3125 10.6875 24.6375 9 23.625C10.9125 23.85 12.9375 23.2875 14.2875 22.275C12.6 22.275 11.25 21.15 10.8 19.6875C11.3625 19.8 11.925 19.6875 12.4875 19.575C10.8 19.125 9.5625 17.55 9.5625 15.8625C10.125 16.0875 10.6875 16.3125 11.25 16.3125C9.675 15.1875 9.1125 13.05 10.125 11.3625C12.0375 13.6125 14.7375 15.075 17.775 15.1875C17.2125 12.9375 19.0125 10.6875 21.375 10.6875C22.3875 10.6875 23.4 11.1375 24.075 11.8125C24.975 11.5875 25.7625 11.3625 26.4375 10.9125C26.2125 11.8125 25.65 12.4875 24.8625 12.9375Z"
				fill={color}
			/>
		</svg>
	);
};

export default TwitterIcon;
