import { TUTORIAL_COMPLETED, TUTORIAL_SET } from "./actionTypes";

export const setTutorial = (enabled) => {
  return {
    type: TUTORIAL_SET,
    payload: { enabled },
  };
};

export const completeTutorial = () => ({ type: TUTORIAL_COMPLETED });
