import React from "react";

const Clock = ({ width = "22", height = "12", color = "#4A517E" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.47255 1.04297C1.99672 1.04297 1.04297 2.05389 1.04297 3.61897V8.38364C1.04297 9.94872 1.99672 10.9596 3.47255 10.9596H8.52889C10.0053 10.9596 10.9596 9.94872 10.9596 8.38364V3.61897C10.9596 2.05389 10.0059 1.04297 8.52947 1.04297H3.47255ZM8.52889 11.8346H3.47255C1.49564 11.8346 0.167969 10.4475 0.167969 8.38364V3.61897C0.167969 1.55514 1.49564 0.167969 3.47255 0.167969H8.52947C10.5064 0.167969 11.8346 1.55514 11.8346 3.61897V8.38364C11.8346 10.4475 10.5064 11.8346 8.52889 11.8346Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.97808 7.61462C7.90167 7.61462 7.82467 7.59479 7.75408 7.55337L5.776 6.37329C5.64417 6.29396 5.5625 6.15104 5.5625 5.99762V3.45312C5.5625 3.21162 5.7585 3.01562 6 3.01562C6.2415 3.01562 6.4375 3.21162 6.4375 3.45312V5.74912L8.20267 6.80087C8.40975 6.92512 8.478 7.19346 8.35433 7.40112C8.27208 7.53821 8.12683 7.61462 7.97808 7.61462Z"
				fill={color}
			/>
		</svg>
	);
};

export default Clock;
