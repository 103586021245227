import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { placesLibraryLoaded } from "../../../Redux/Actions/deviceParamsActions";
import { loadScript } from "../PlacesAutoComplete";
let geocoder;

function handleScriptLoad() {
	geocoder = new window.google.maps.Geocoder();
}

export const useReverseGeoCoder = ({ location }) => {
	const libraryLoading = useSelector(
		(state) => !state.deviceParameters.mapsPlacesLoaded
	);
	const [loading, setLoading] = useState(false);
	const [geocodedLocation, setGeocodedLocation] = useState({
		city: null,
		country: null,
	});
	const dispatch = useDispatch();
	const libraryLoaded = () => dispatch(placesLibraryLoaded());
	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv_qdmw0do3DOf000yOSSv1iTqi2hEMw&libraries=places`,
			() => {
				libraryLoaded();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!libraryLoading) {
			handleScriptLoad();
		}
	}, [libraryLoading]);

	const geocode = (location) => {
		const locationCoordinatesObj = new window.google.maps.LatLng(
			location.lat,
			location.long
		);
		setLoading(true);
		geocoder.geocode(
			{ location: locationCoordinatesObj },
			(result, status) => {
				const resultToExtractCountry = result[result.length - 1];
				const country = resultToExtractCountry.address_components.find(
					(res) => res.types.includes("country")
				);
				const city = resultToExtractCountry.address_components.find(
					(res) => res.types.includes("locality")
				);
				setGeocodedLocation({
					city: city?.long_name,
					country: country?.long_name,
				});
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		if (!libraryLoading && location.lat && location.long) {
			geocode(location);
		}
	}, [location, libraryLoading]);

	return { location: geocodedLocation, loading };
};
