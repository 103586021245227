import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Link, useHistory, useLocation } from "react-router-dom";

import Arrows from "../../Icons/Arrows.js";
import styles from "./Info.module.css";
import { Dialog, IconButton } from "@material-ui/core";
import { Helmet } from "react-helmet";

import Chevron from "../../Icons/Chevron.js";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { PrivacyIcon } from "../../Icons/Privacy.js";
import { ContentIcon } from "../../Icons/Content.js";
import { UserAggIcon } from "../../Icons/UserAgg.js";

export default function Policies(props) {
	const [active, setActive] = useState(0);
	const isMobile = useMediaQuery({ query: "(max-width: 724px)" });
	const history = useHistory();
	const location = useLocation();
	const theme = useSelector((state) => state.theme.theme);

	const textcolorClass =
		theme === "dark" ? styles.textDarkTheme : styles.textLightTheme;
	const textPColor = theme === "dark" ? styles.textDarkP : styles.textLightP;

	const iconStyles = {
		width: "1rem",
		height: "1rem",
		marginRight: "0.5rem",
	};

	function getIconColor(activeID) {
		if (activeID === active) return "white";
		else return theme === "dark" ? "#7F89BE" : "#0D0F22";
	}

	const tab = [
		{
			id: 0,
			label: "User Agreement"
		},
		{
			id: 1,
			label: "Content Policy"
		},
		{
			id: 2,
			label: "Privacy Policy"
		}
	];

	function SideBar({ isMobile }) {
		useEffect(() => {
			// if (!isMobile && location.pathname === "/policies") {
			//   history.push("/policies/user-agreement");
			// }
		}, []);

		return (
			<>
				{isMobile && (
					<div className={styles.header}>
						{/* <IconButton size="small" variant="contained" style={{ color: "#ff5d5d" }} onClick={history.goBack}>
              <Arrows.Left viewBox="0 0 27 27" />
            </IconButton> */}
						<h2 className={textcolorClass}>Terms and Policies</h2>
					</div>
				)}
				<ul className={styles.sidebar}>
					<StyledSidebarItem
						className={styles.links}
						onClick={() => setActive(0)}
						replace={!isMobile}
						isActive={active === 0}
						theme={theme}
					>
						<li
							className={
								active === 0 ? styles.selected : styles.link
							}
						>
							<UserAggIcon
								style={iconStyles}
								color={getIconColor(0)}
							/>
							<span
								// className={textcolorClass}
							>
								User Agreement
							</span>
							{isMobile && (
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										float: "right",
										marginRight: "-1.8rem",
									}}
								>
									<Chevron.Right viewBox="0 0 27 27" />
								</IconButton>
							)}
						</li>
					</StyledSidebarItem>

					<StyledSidebarItem
						className={styles.links}
						onClick={() => setActive(1)}
						replace={!isMobile}
						isActive={active === 1}
						theme={theme}
					>
						<li
							className={
								active === 1 ? styles.selected : styles.link
							}
						>
							<ContentIcon
								style={iconStyles}
								color={getIconColor(1)}
							/>
							Content Policy
							{isMobile && (
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										float: "right",
										marginRight: "-1.8rem",
									}}
								>
									<Chevron.Right viewBox="0 0 27 27" />
								</IconButton>
							)}
						</li>
					</StyledSidebarItem>

					<StyledSidebarItem
						className={styles.links}
						onClick={() => setActive(2)}
						replace={!isMobile}
						isActive={active === 2}
						theme={theme}
					>
						<li
							className={
								active === 2 ? styles.selected : styles.link
							}
						>
							{/* <img
								className={styles.iconImg}
								src="/icons/HelpIcons/privacy.svg"
							/> */}
							<PrivacyIcon
								style={iconStyles}
								color={getIconColor(2)}
							/>
							Privacy Policy
							{isMobile && (
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										float: "right",
										marginRight: "-1.8rem",
									}}
								>
									<Chevron.Right viewBox="0 0 27 27" />
								</IconButton>
							)}
						</li>
					</StyledSidebarItem>
				</ul>
			</>
		);
	}

	return ( <>
		{!isMobile ? <Dialog
			open={true}
			fullScreen={true}
			maxWidth={"100vw"}
			fullWidth={"100vw"}
			classes={{
				paper: theme === "dark" ? styles.rootDark : styles.rootLg,
			}}
								 >
			<Helmet>
				<title>{`Policies | bditto`} </title>
			</Helmet>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					{!isMobile && <div className={styles.policyHeader}>
						<h1
							style={{
								color: theme === "dark" ? "#D9DEF4" : "#0D0F22",
							}}
						>
							Terms & Policies
						</h1>
						<img
							style={{ cursor: "pointer" }}
							onClick={() => {
								history.goBack();
							}}
							src="/icons/squaredClose.svg"
							alt=""
						/>
					</div>}

					<div className={styles.policyBody}>
						{!isMobile && (
							<>
								<div className={styles.left}>
									<SideBar
										active={active}
										setActive={setActive}
									/>
								</div>
								<div className={styles.right}>
									{active === 0 ? (
										<UserAgreement
											isMobile={isMobile}
											textcolorClass={textcolorClass}
											textPColor={textPColor}
										/>
									) : active === 1 ? (
										<ContentPolicy
											textcolorClass={textcolorClass}
											textPColor={textPColor}
											isMobile={isMobile}
										/>
									) : (
										active === 2 && (
											<PrivacyPolicy
												textcolorClass={textcolorClass}
												textPColor={textPColor}
												isMobile={isMobile}
											/>
										)
									)}
								</div>
							</>
						)
						}
					</div>

					{/* <div className={styles.right}>
            <Route exact path="/policies">
              <SideBar isMobile={isMobile} />
            </Route>



            <Route exact path="/policies/content-policy">
              <ContentPolicy isMobile={isMobile} />
            </Route>
            <Route exact path="/policies/privacy-policy">
              <PrivacyPolicy isMobile={isMobile} />
            </Route>
            <Route exact path="/policies/user-agreement">
              <UserAgreement isMobile={isMobile} />
            </Route>

            <div className={styles.blank} style={{ height: "5rem" }}></div>
          </div> */}
				</div>
			</div>
		</Dialog> :
		<div className={styles.right}>

			<div className={styles.ploiciesTab}>
				{tab.map((tab, i)=>{

					return <div className={active === tab.id ? styles.activeTab : styles.tab } onClick={()=>setActive(tab.id)}>
						{tab.label}
					</div>
				})}

			</div>

			<div className={styles.right}>
				<div className={styles.contentMob}>
					{active === 0 ? (
						<UserAgreement
							isMobile={isMobile}
							textcolorClass={textcolorClass}
							textPColor={textPColor}
						/>
					) : active === 1 ? (
						<ContentPolicy
							textcolorClass={textcolorClass}
							textPColor={textPColor}
							isMobile={isMobile}
						/>
					) : (
						active === 2 && (
							<PrivacyPolicy
								textcolorClass={textcolorClass}
								textPColor={textPColor}
								isMobile={isMobile}
							/>
						)
					)}
				</div>
			</div>
		</div>}
	</>
	);
}

function ContentPolicy({ isMobile, textcolorClass, textPColor }) {
	let history = useHistory();

	return (
		<>
			<div className={styles.header}>
				{/* <IconButton size="small" variant="contained" onClick={history.goBack}>
          <Arrows.Left viewBox="0 0 27 27" />
        </IconButton> */}

				<h2 className={textcolorClass}>Content Policy</h2>
			</div>
			<div className={styles.rightFaqWrapper}>
				<p className={`${styles.content} ${textPColor}`}>
					We created Bditto (A Bharg Technologies GmbH company) to
					help people come together to make things happen. We expect
					users will have a great experience meeting with millions of
					diverse communities and connect with old friends and new. We
					can't wait to see what's next.
				</p>
				<p className={`${styles.content} ${textPColor}`}>
					Our guidelines are meant to explain what is and isn’t
					allowed on Bditto and ensure that everyone has a good
					experience. If you come across a message that appears to
					break these rules, please report it to us. User communities
					should create a sense of belonging for their members, not
					try to diminish it for others. Likewise, everyone on Bditto
					should have an expectation of privacy and safety, so please
					respect the privacy and safety of others.We may take a
					number of steps, including issuing a warning, removing the
					content, or removing the accounts responsible.
				</p>
				<p className={`${styles.content} ${textPColor}`}>
					Bditto and its communities are only what we make of them
					together and can only exist if we operate by a shared set of
					rules. We ask that you abide by not just the letter of these
					rules, but the spirit as well.
				</p>
				<p className={`${styles.content} ${textPColor}`}>
					We want to be clear about the expectations for our users.
					Every user of Bditto should feel like their voice can be
					heard, but not at the expense of someone else.
				</p>

				<p className={`${styles.content} ${textPColor}`}>
					This Bditto User Agreement (“Terms”) applies to your access
					to and use of the website, widgets, APIs, emails, and other
					online products and services (collectively, the “Services”)
					provided by Bditto, which is part of Bharg Technologies GmbH
					(“Bditto,” “we,” “us,” or “our”).
					<br />
					Remember Bditto is for fun and is intended to be a place for
					your entertainment or getting things done together, but we
					still need some basic rules. By accessing or using our
					Services, you agree to be bound by these Terms. If you do
					not agree to these Terms, you may not access or use our
					Services.
					<br />
					Please take a look at Bditto’s Privacy Policy too—it
					explains how and why we collect, use, and share information
					about you when you access or use our Services.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					HERE ARE SOME RULES FOR INTERACTING WITH OTHERS:
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					<ol>
						<li>
							<b>
								Do not organize, participate in, or encourage
								harassment of others.
							</b>{" "}
							Disagreements happen and are normal, but continuous,
							repetitive, or severe negative comments may cross
							the line into harassment and are not okay.
						</li>
						<li>
							<b>
								Do not organize, promote, or coordinate groups
								around hate speech.
							</b>{" "}
							It’s unacceptable to attack a person or a community
							based on attributes such as their race, ethnicity,
							national origin, sex, gender, sexual orientation,
							religious affiliation, or disabilities.
						</li>
						<li>
							<b>
								Do not make threats of violence or threaten to
								harm others.
							</b>{" "}
							This includes indirect threats, as well as sharing
							or threatening to share someone’s private personal
							information (also known as doxxing).
						</li>
						<li>
							<b>Do not evade user blocks.</b> Do not send
							unwanted, repeated friend requests or messages,
							especially after they’ve made it clear they don’t
							want to talk to you anymore. Do not try to hide your
							identity in an attempt to contact someone who has
							blocked you, or otherwise circumvent the tools we
							have which enable users to protect themselves.
						</li>
						<li>
							<b>Do not send others viruses or malware,</b>{" "}
							attempt to phish others, or hack or DDoS them.
						</li>
					</ol>
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					HERE ARE SOME RULES FOR CONTENT ON BDITTO:
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					<ol>
						<li>
							<b>
								You must apply the #NSFW label to statuses if
								there is adult content in that channel.
							</b>{" "}
							You may not share or post any sexually explicit or
							pornographic content.
						</li>
						<li>
							<b>You may not sexualize minors in any way.</b> This
							includes sharing content or links which depict
							minors in a pornographic, sexually suggestive, or
							violent manner, and includes illustrated or
							digitally altered pornography that depicts minors
							(such as lolicon, shotacon, or cub). We report
							illegal content to the &nbsp;
							<a
								target="_blank"
								href="https://www.missingkids.org/gethelpnow/cybertipline"
							>
								National Center for Missing and Exploited
								Children.
							</a>
						</li>
						<li>
							<b>
								You may not share sexually explicit content of
								other people without their consent,
							</b>{" "}
							or share or promote sharing of non-consensual
							intimate imagery (also known as revenge porn) in an
							attempt to shame or degrade someone.
						</li>
						<li>
							<b>
								You may not share content that glorifies or
								promotes suicide or self-harm,{" "}
							</b>
							including any encouragement to others to cut
							themselves, or embrace eating disorders such as
							anorexia or bulimia.
						</li>
						<li>
							<b>
								You may not share images of sadistic gore or
								animal cruelty.
							</b>
						</li>
						<li>
							<b>
								You may not use Bditto for the organization,
								promotion, or support of violent extremism.
							</b>
						</li>
						<li>
							<b>
								You may not operate or engage in any discussion
								that sells or facilitates the sales of
								prohibited or potentially dangerous goods.
							</b>
							This includes firearms, ammunition, drugs, and
							controlled substances.
						</li>
						<li>
							<b>
								You may not promote, distribute, or provide
								access to content involving the hacking,
								cracking, or distribution of pirated software or
								stolen accounts.
							</b>
							This includes sharing or selling cheats or hacks
							that may negatively affect others in multiplayer
							games.
						</li>
						<li>
							In general,
							<b>
								you should not promote, encourage or engage in
								any illegal behavior.
							</b>
							This is very likely to get you kicked off Bditto,
							and may get you reported to law enforcement.
						</li>
					</ol>
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					FINALLY, WE ASK THAT YOU RESPECT BDITTO ITSELF:
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					<ol>
						<li>
							<b>You may not sell your account.</b>
						</li>
						<li>
							<b>
								You may not use self-bots or user-bots to access
								Bditto.
							</b>
						</li>
						<li>
							<b>
								You may not share content that violates anyone's
								intellectual property or other rights.
							</b>{" "}
						</li>
						<li>
							<b>18. You may not spam Bditto, </b> especially our
							Customer Support and Trust & Safety teams. Making
							false and malicious reports, sending multiple
							reports about the same issue, or asking a group of
							users to all report the same content may lead to
							action being taken on your account.
						</li>
					</ol>
				</p>

				<p className={`${styles.content} ${textPColor}`}>
					If you see any activity that violates these guidelines, you
					can report it to us at{" "}
					<a href="mailto:admin@bditto.com">admin@bditto.com</a>
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					Enforcement
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					We have a variety of ways of enforcing our rules, including,
					but not limited to
					<ul>
						<li>Asking you nicely to knock it off</li>
						<li>Asking you less nicely</li>
						<li>
							Temporary or permanent suspension of content and
							accounts
						</li>
						<li>
							Removal of privileges from, or adding restrictions
							to, accounts
						</li>
						<li>
							Adding restrictions to Bditto group chats, such as
							adding NSFW tags
						</li>
						<li>Removal of content</li>
						<li>Banning of Bditto statuses and groups</li>
					</ul>
				</p>
			</div>
		</>
	);
}

function PrivacyPolicy({ isMobile, textcolorClass, textPColor }) {
	let history = useHistory();

	return (
		<>
			<div className={styles.header}>
				{/* <IconButton size="small" variant="contained" onClick={history.goBack}>
          <Arrows.Left viewBox="0 0 27 27" />
        </IconButton> */}

				<h2 className={textcolorClass}> Bditto Privacy Policy</h2>
			</div>
			<div className={styles.rightFaqWrapper}>
				<p className={`${styles.content} ${textPColor}`}>
					Last updated and effective: January 29, 2021
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					WELCOME TO BDITTO!
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					Bharg Technologies GmbH, provides a social online and mobile
					chat platform via the BDITTO website (the “Site”), and
					related Internet services (collectively, the “Service(s)”).
					The Service is operated by Bharg Technologies GmbH(the
					“Company”, “we” or “us”) for users of the Service (“you”).
					This Privacy Policy sets forth our policy with respect to
					information that is collected from visitors to the Site and
					users of the Webapp and/or the Services. Under applicable
					law, Bharg Technologies GmbH is the “data controller” of
					personal data collected through the Services.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					INFORMATION WE COLLECT
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					When you interact with us through the Services, we may
					collect information from you, as further described below:
					Information You Provide: We collect information from you
					when you voluntarily provide such information, such as when
					you register for access to the Services or use certain
					Services. Information we collect may include but not be
					limited to username, name, email address, age, location, and
					any messages, images, transient VOIP data (to enable
					communication delivery only) or other content you send via
					the chat feature.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					OTHER INFORMATION:
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					<ul>
						<li>
							<b>Data We Collect Automatically</b>: When you
							interact with us through the Services, we receive
							and store certain information such as an IP address,
							device ID, and your activities within the Services.
							We may store such information or such information
							may be included in databases owned and maintained by
							affiliates, agents or service providers. The
							Services may use such information and pool it with
							other information to track, for example, the total
							number of visitors to our Site, the number of
							messages users have sent, as well as the sites which
							refer visitors to Bditto.
						</li>
						<li>
							<b>Aggregated Information</b>: In an ongoing effort
							to better understand and serve the users of the
							Services, we may conduct research on our customer
							demographics, interests and behavior based on the
							information collected. This research may be compiled
							and analyzed on an aggregate basis, and we may share
							this aggregate data with our affiliates, agents and
							business partners. We may also disclose aggregated
							user statistics in order to describe our services to
							current and prospective business partners, and to
							other third parties for other lawful purposes.
						</li>
						<li>
							<b>Info through other Services</b>: You may give us
							permission to collect your information in other
							services. For example, you may connect a social
							networking service ("SNS") such as Google, Facebook
							or Twitter to your BDITTO account. When you do this,
							it allows us to obtain information from those
							accounts (for example, your friends or contacts).
						</li>
						<li>
							<b>Cookies</b>: We employ cookies and similar
							technologies to keep track of your local computer’s
							settings such as which account you have logged into
							and notification settings. Cookies are pieces of
							data that sites and services can set on your browser
							or device that can be read on future visits. We may
							expand our use of cookies to save additional data as
							new features are added to the Service. In addition,
							we use technologies such as web beacons and
							single-pixel gifs to record log data such as open
							rates for emails sent by the system.
						</li>
						<li>
							We may use third party web site analytic tools such
							as Google Analytics on our website that employ
							cookies to collect certain information concerning
							your use of our Services. However, you can disable
							cookies by changing your browser settings. Further
							information about the procedure to follow in order
							to disable cookies can be found on your Internet
							browser provider's website via your help screen.
						</li>
						<li>
							<b>Advertisements</b>: You may see our Service
							advertised in other applications or websites. After
							clicking on one of these advertisements and
							installing our Service, you will become a user of
							the Service. Advertising platforms, which include
							Twitter and Facebook (and whose SDKs are integrated
							within our Service), may collect information for
							optimizing advertising campaigns outside of the
							Service.
						</li>
					</ul>
					If you do not wish to receive personalized advertising that
					is delivered by third parties outside of the BDITTO Service,
					you may be able to exercise that choice through opt-out
					programs that are administered by third parties, including
					the Network Advertising Initiative (NAI), the Digital
					Advertising Alliance (DAA). Our Services currently do not
					respond to “Do Not Track” (DNT) signals and operate as
					described in this Privacy Policy whether or not a DNT signal
					is received, as there is no consistent industry standard for
					compliance.
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					WHERE INFORMATION IS PROCESSED
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					The Company is based in Switzerland. No matter where you are
					located, you consent to the processing and transferring of
					your information in and to Switzerland and other countries.
					The laws of Switzerland and other countries governing data
					collection and use may not be as comprehensive or protective
					as the laws of the country where you live.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					OUR USE OF YOUR INFORMATION
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					We use the information you provide in a manner that is
					consistent with this Privacy Policy. If you provide
					information for a certain reason, we may use the information
					in connection with the reason for which it was provided. For
					instance, if you contact us by email, we will use the
					information you provide to answer your question or resolve
					your problem. Also, if you provide information in order to
					obtain access to the Services, we will use your information
					to provide you with access to such services and to monitor
					your use of such services. The Company and its subsidiaries
					and affiliates (the “Related Companies”) may also use your
					information collected through the Services to help us
					improve the content and functionality of the Services, to
					better understand our users and to improve the Services. The
					Company and its affiliates may use this information to
					contact you in the future to tell you about services we
					believe will be of interest to you. If we do so, each
					marketing communication we send you will contain
					instructions permitting you to "opt-out" of receiving future
					marketing communications. In addition, if at any time you
					wish not to receive any future marketing communications or
					you wish to have your name deleted from our mailing lists,
					please contact us as indicated below.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					OUR LEGAL BASES FOR HANDLING OF YOUR PERSONAL DATA
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					The laws in some jurisdictions require companies to tell you
					about the legal ground they rely on to use or disclose your
					personal data. To the extent those laws apply, our legal
					grounds are as follows:
					<ul>
						<li>
							To honor our contractual commitments to you: Much of
							our processing of personal data is to meet our
							contractual obligations to our users, or to take
							steps at users’ request in anticipation of entering
							into a contract with them. For example, we handle
							personal data on this basis to create your account
							and provide our Services.
							<ul>
								<li type="circle">
									o Legitimate interests: In many cases, we
									handle personal data on the ground that it
									furthers our legitimate interests in ways
									that are not overridden by the interests or
									fundamental rights and freedoms of the
									affected individuals: This includes:
									<ul>
										<li type="square">Customer service;</li>
										<li type="square">
											Marketing, e.g. sending emails or
											other communications to let you know
											about new features;
										</li>
										<li type="square">
											Protecting our users, personnel, and
											property;
										</li>
										<li type="square">
											Analyzing and improving our
											business, e.g. collecting
											information about how you use our
											Services to optimize the design and
											placement of certain features;{" "}
										</li>
										<li type="square">
											{" "}
											Processing job applications;{" "}
										</li>
										<li type="square">
											{" "}
											Managing legal issues.{" "}
										</li>
										<li type="square">
											Legal compliance: We need to use and
											disclose personal data in certain
											ways to comply with our legal
											obligations.
										</li>
										<li type="square">
											To protect the vital interests of
											the individual or others: For
											example, we may collect or share
											personal data to help resolve an
											urgent medical situation.
										</li>
									</ul>
								</li>
								<li type="circle">
									Consent: Where required by law, and in some
									other cases, we handle personal data on the
									basis of your implied or express consent.
								</li>
							</ul>
						</li>
					</ul>
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					OUR DISCLOSURE OF YOUR INFORMATION
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					The Company is not in the business of selling your personal
					information. We consider this information to be a vital part
					of our relationship with you. There are, however, certain
					circumstances in which we may share your information with
					certain third parties, as set forth below:
					<ul>
						<li>
							<b>Business Transfers</b>: As we develop our
							business, we might sell or buy businesses or assets.
							In the event of a corporate sale, merger,
							reorganization, bankruptcy, dissolution or similar
							event, your information may be part of the
							transferred assets.
						</li>
						<li>
							<b>Consent</b>: We may transfer your information
							with your consent.
						</li>
						<li>
							<b>Related Companies</b>: We may also share your
							information with our Related Companies for purposes
							consistent with this Privacy Policy.
						</li>
						<li>
							<b>Developers</b>: Developers using our SDK or API
							will have access to their end users’ information,
							including message content, message metadata, and
							voice metadata. Developers must use such information
							only to provide the SDK/API functionality within
							their applications and/or services.
						</li>
						<li>
							<b>Agents, Consultants and Related Third Parties</b>
							:Like many businesses, we sometimes hire other
							companies or individuals to perform certain
							business-related functions. Examples of such
							functions include mailing information, maintaining
							databases and processing payments.
						</li>
						<li>
							<b>Legal Requirements</b>: We may disclose your
							information if required to do so by law or in the
							good faith belief that such action is necessary to
							(i) comply with a legal obligation, (ii) protect and
							defend the rights or property of the Company or
							Related Companies, (iii) protect the personal safety
							of users of the Services or the public, or (iv)
							protect against legal liability.
						</li>
						<li>
							<b>Aggregated or Non-identifiable Data</b>: We may
							also share aggregated or non-personally identifiable
							information with our partners or others for business
							purposes.
						</li>
					</ul>
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					UNSOLICITED INFORMATION
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					You may provide us with ideas for new products or
					modifications to existing products, and other unsolicited
					submissions (collectively, “Unsolicited Information”). All
					Unsolicited Information shall be deemed to be
					non-confidential and we shall be free to reproduce, use,
					disclose, and distribute such Unsolicited Information to
					others without limitation or attribution.
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					CHILDREN
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					Our Services are for users age 13 and over and we do not
					knowingly collect personal information from children under
					the age of 13. If you are a parent or guardian of a child
					under the age of 13 and believe he or she has disclosed
					personal information to us please contact us at
					admin@bditto.com. Note: In some countries, the age of
					digital consent is older than 13 (In that case, please
					verify the legal requirement in your respective country). If
					you are in those countries, you must be at least that age to
					use the Services. For example, for residents of the EEA,
					where processing of personal information is based on
					consent, Bditto will not knowingly engage in that processing
					for users under the age of consent established by applicable
					data protection law. If we learn that we are engaged in that
					processing with such users, we will halt such processing and
					will take reasonable measures to promptly remove applicable
					information from our records.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					LINKS TO OTHER WEB SITES
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					This Privacy Policy applies only to the Services. The
					Services may contain links to other web sites not operated
					or controlled by us (the “Third Party Sites”). The policies
					and procedures we described here do not apply to the Third
					Party Sites. The links from the Services do not imply that
					we endorse or have reviewed the Third Party Sites. We
					suggest contacting those sites directly for information on
					their privacy policies.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					DATA RETENTION
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					We generally retain personal data for so long as it may be
					relevant to the purposes identified herein. To dispose of
					personal data, we may anonymize it, delete it or take other
					appropriate steps. Data may persist in copies made for
					backup and business continuity purposes for additional time.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					SECURITY
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					We take reasonable steps to protect the information provided
					via the Services from loss, misuse, and unauthorized access,
					disclosure, alteration, or destruction. However, no Internet
					or email transmission is ever fully secure or error free. In
					particular, email sent to or from the Services may not be
					secure. Therefore, you should take special care in deciding
					what information you send to us via email. Please keep this
					in mind when disclosing any information via the Internet.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					YOUR DATA RIGHTS AND CHOICES
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					We believe that users should be treated equally no matter
					where they are, and so we are making the following options
					to control your data available to all users, regardless of
					their location.
				</p>
				<p className={`${styles.content} ${textPColor}`}>
					Individuals in California, the European Economic Area,
					Canada, Costa Rica and some other jurisdictions outside the
					United States have certain legal rights to obtain
					confirmation of whether we hold personal data about them, to
					access personal data we hold about them (including, in some
					cases, in portable form), and to obtain its correction,
					update, amendment or deletion in appropriate circumstances.
					They may also object to our uses or disclosures of personal
					data, to request a restriction on its processing, or
					withdraw any consent, though such actions typically will not
					have retroactive effect. They also will not affect our
					ability to continue processing data in lawful ways.
				</p>
				<p className={`${styles.content} ${textPColor}`}>
					<ul>
						<li>
							<b>
								How can I access the personal data you have
								about me?
							</b>
						</li>
						<li style={{ listStyleType: "none" }}>
							If you would like to submit a data access request,
							you can request via sending an email to
							admin@bditto.com. We will then start the process and
							provide you the personal data that Bditto has on you
							within 30 days.
						</li>
						<li>
							<b>
								How do I correct, update, amend, or delete the
								personal data you have about me?
							</b>
						</li>
						<li style={{ listStyleType: "none" }}>
							Through the “Settings” of the Services, you can
							correct, update, amend, or delete certain personal
							data, you can also request other modifications from
							us directly. Please write us at
							<a href="mailto:admin@bditto.com">
								admin@bditto.com
							</a>{" "}
							with the words “Personal Data Request” in the
							subject or body of your message, along with an
							explanation of what data subject right you are
							seeking to exercise. For your protection, we may
							take steps to verify identity before responding to
							your request.
						</li>
						<li>
							<b>
								How do I object or restrict the manner in which
								Bditto processes my personal data?
							</b>
						</li>
						<li style={{ listStyleType: "none" }}>
							You have a right to ask us to stop using or limit
							our use of your personal data in certain
							circumstances—for example, if we have no lawful
							basis to keep using your data, or if you think your
							personal data is inaccurate.<br></br>
							Bditto offers you the ability to restrict the
							processing of your data for specific uses, which you
							can find in the “Settings” page of the services.
							Individuals in the European Economic Area have the
							right to opt out of all of our processing of their
							personal data for direct marketing purposes by
							deleting their account. To exercise this right,
							please see the “Settings” page for your Account and
							option to delete the account.
							<br></br>
							The rights and options described above are subject
							to limitations and exceptions under applicable law.
							In addition to those rights, you have the right to
							lodge a complaint with the relevant supervisory
							authority. However, we encourage you to contact us
							first, and we will do our very best to resolve your
							concern.
						</li>
					</ul>
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					WHO IS BDITTO’S EEA REPRESENTATIVE?
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					For data protection matters, pursuant to Article 27 of the
					General Data Protection Regulation of the European Economic
					Area, only on matters related to the processing of personal
					data.
					<br />
					<br />
					You can contact Bditto at the following address:
					<br />
					Bharg Technologies GmbH,
					<br />
					Pfaeffikonerstrasse 27,
					<br />
					8834 Schindellegi,
					<br />
					Switzerland
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					YOUR CALIFORNIA PRIVACY RIGHTS
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					Consumers residing in California are afforded certain
					additional rights with respect to their personal information
					under the California Consumer Privacy Act or (“CCPA”) and
					the “Shine the Light” Law. If you are a California resident,
					this section applies to you.
					<br />
					<b>California Consumer Privacy Act</b>
					<br />
					<b>Our Collection and Use of Personal Information</b>: We
					collect the following categories of personal information:
					identifiers (such as your username, the email address you
					use to sign up, and your phone number, if you’ve chosen to
					provide it); internet or other network information (how you
					interact with the application); location information
					(because your IP address may indicate your general
					location); inference data about you; and other information
					that identifies or can be reasonably associated with you.
					For examples of the precise data points, we collect and the
					sources of such collection, please see the “INFORMATION WE
					COLLECT” section above. We collect personal information for
					the business and commercial purposes described in “OUR USE
					OF YOUR INFORMATION” above.
					<br />
					<b>Disclosure of Personal Information</b>: We may share your
					personal information with third parties as described in the
					“OUR DISCLOSURE OF YOUR INFORMATION” section above. We
					disclose the categories of personal information mentioned
					above for business or commercial purposes.
					<br />
					<b>No Sale of Personal Information</b>: The CCPA sets forth
					certain obligations for businesses that sell personal
					information. We do not sell the personal information of our
					users.
					<br />
					<b>Exercising Your Consumer Rights: </b> If you are a
					California resident, you have the right to request (1) more
					information about the categories and specific pieces of
					personal information we have collected and disclosed for a
					business purpose in the last 12 months, (2) deletion of your
					personal information, and (3) to opt out of sales of your
					personal information, if applicable. Details on how to make
					these requests are in the “YOUR DATA RIGHTS AND CHOICES”
					section above. We will not discriminate against you if you
					exercise your rights under the CCPA.
					<br />
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					CHANGES TO THIS PRIVACY POLICY
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					We reserve the right to update or modify this Privacy Policy
					at any time and from time to time without prior notice.
					Please review this policy periodically, and especially
					before you provide any information. This Privacy Policy was
					last updated on the date indicated above. Your continued use
					of the Services after any changes or revisions to this
					Privacy Policy shall indicate your agreement with the terms
					of such revised Privacy Policy.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					CONTACTING US
				</h2>

				<p className={`${styles.content} ${textPColor}`}>
					Please also feel free to contact us if you have any
					questions about this Privacy Policy or the information
					practices of the Services. You may contact us as follows:{" "}
					<a href="mailto:admin@bditto.com">admin@bditto.com</a>.
				</p>
			</div>
		</>
	);
}

function UserAgreement({ isMobile, textcolorClass, textPColor }) {
	let history = useHistory();

	return (
		<>
			<div className={styles.header}>
				{/* <IconButton size="small" variant="contained" onClick={history.goBack}>
          <Arrows.Left viewBox="0 0 27 27" />
        </IconButton> */}

				<h2 className={textcolorClass}> Bditto User Agreement</h2> 
			</div>

			<div className={styles.rightFaqWrapper}>
				<p className={`${styles.content} ${textPColor}`}>
					Last updated and effective: January 29, 2021
				</p>

				<p className={`${styles.content} ${textPColor}`}>
					This Bditto User Agreement (“Terms”) applies to your access
					to and use of the website, widgets, APIs, emails, and other
					online products and services (collectively, the “Services”)
					provided by Bditto, which is part of Bharg Technologies GmbH
					(“Bditto,” “we,” “us,” or “our”).
					<br />
					Remember Bditto is for fun and is intended to be a place for
					your entertainment or getting things done together, but we
					still need some basic rules. By accessing or using our
					Services, you agree to be bound by these Terms. If you do
					not agree to these Terms, you may not access or use our
					Services.
					<br />
					Please take a look at Bditto’s Privacy Policy too—it
					explains how and why we collect, use, and share information
					about you when you access or use our Services.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					1. YOUR ACCESS TO THE SERVICES
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					Children under the age of 13 are not allowed to create an
					Account or otherwise use the Services. Additionally, you
					must be over the age required by the laws of your country to
					create an account or otherwise use the Services, or we need
					to have received verifiable consent from your parent or
					legal guardian.
					<br />
					In addition, certain of our Services or portions of our
					Services require you to be 18 years of age or older, so
					please read all notices and any Additional Terms carefully
					when you access the Services.
					<br />
					If you are accepting these Terms on behalf of another legal
					entity, including a business or government entity, you
					represent that you have full legal authority to bind such
					entity to these Terms.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					2. YOUR USE OF THE SERVICES
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					Bditto grants you a personal, non-transferable,
					non-exclusive, revocable, limited license to use and access
					the Services solely as permitted by these Terms. We reserve
					all rights not expressly granted to you by these Terms.
					Except as permitted through the Services or as otherwise
					permitted by us in writing, your license does not include
					the right to: license, sell, transfer, assign, distribute,
					host, or otherwise commercially exploit the Services or
					Content; modify, prepare derivative works of, disassemble,
					decompile, or reverse engineer any part of the Services or
					Content; or access the Services or Content in order to build
					a similar or competitive website, product, or service We
					reserve the right to modify, suspend, or discontinue the
					Services (in whole or in part) at any time, with or without
					notice to you. Any future release, update, or other addition
					to functionality of the Services will be subject to these
					Terms, which may be updated from time to time. You agree
					that we will not be liable to you or to any third party for
					any modification, suspension, or discontinuation of the
					Services or any part thereof.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					3. YOUR BDITTO ACCOUNT AND ACCOUNT SECURITY
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					To use our Services, you may be required to create a Bditto
					account (an “Account”) and provide us with a username,
					password, and certain other information about yourself as
					set forth in the Privacy Policy.
					<br />
					You are solely responsible for the information associated
					with your Account and anything that happens related to your
					Account. You must maintain the security of your Account and
					immediately notify Bditto (admin@bditto.com) if you discover
					or suspect that someone has accessed your Account without
					your permission. We recommend that you use a strong password
					that is used only with your Account.
					<br />
					You will not license, sell, or transfer your Account without
					our prior written approval.
					<br />
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					4. YOUR CONTENT
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					The Services may contain information, text, links, graphics,
					photos, videos, or other materials (“Content”), including
					Content created or submitted to the Services by you or
					through your Account (“Your Content”). We take no
					responsibility for and we do not expressly or implicitly
					endorse, support, or guarantee the completeness,
					truthfulness, accuracy, or reliability of any of Your
					Content.
					<br />
					By submitting Your Content to the Services, you represent
					and warrant that you have all rights, power, and authority
					necessary to grant the rights to Your Content contained
					within these Terms. Because you alone are responsible for
					Your Content, you may expose yourself to liability if you
					post or share Content without all necessary rights.
					<br />
					You retain any ownership rights you have in Your Content,
					but you grant Bditto the following license to use that
					Content:
					<br />
					When Your Content is created with or submitted to the
					Services, you grant us a worldwide, royalty-free, perpetual,
					irrevocable, non-exclusive, transferable, and sublicensable
					license to use, copy, modify, adapt, prepare derivative
					works of, distribute, store, perform, and display Your
					Content and any name, username, voice, or likeness provided
					in connection with Your Content in all media formats and
					channels now known or later developed anywhere in the world.
					This license includes the right for us to make Your Content
					available for syndication, broadcast, distribution, or
					publication by other companies, organizations, or
					individuals who partner with Bditto. You also agree that we
					may remove metadata associated with Your Content, and you
					irrevocably waive any claims and assertions of moral rights
					or attribution with respect to Your Content.
					<br />
					Any ideas, suggestions, and feedback about Bditto or our
					Services that you provide to us are entirely voluntary, and
					you agree that Bditto may use such ideas, suggestions, and
					feedback without compensation or obligation to you.
					<br />
					Although we have no obligation to screen, edit, or monitor
					Your Content, we may, in our sole discretion, delete or
					remove Your Content at any time and for any reason,
					including for violating these Terms, violating our Content
					Policy, or if you otherwise create or are likely to create
					liability for us.
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					5. THIRD-PARTY CONTENT, ADVERTISEMENTS, AND PROMOTIONS
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					The Services may contain links to third-party websites,
					products, or services, which may be posted by advertisers,
					our affiliates, our partners, or other users (“Third-Party
					Content”). Third-Party Content is not under our control, and
					we are not responsible for any third party’s websites,
					products, or services. Your use of Third-Party Content is at
					your own risk and you should make any investigation you feel
					necessary before proceeding with any transaction in
					connection with such Third-Party Content.
					<br />
					The Services may also contain sponsored Third-Party Content
					or advertisements. The type, degree, and targeting of
					advertisements are subject to change, and you acknowledge
					and agree that we may place advertisements in connection
					with the display of any Content or information on the
					Services, including Your Content.
					<br />
					If you choose to use the Services to conduct a promotion,
					including a contest or sweepstakes (“Promotion”), you alone
					are responsible for conducting the Promotion in compliance
					with all applicable laws and regulations at your own risk.
					Your Promotion must state that the Promotion is not
					sponsored by, endorsed by, or associated with Bditto, and
					the rules for your Promotion must require each entrant or
					participant to release Bditto from any liability related to
					the Promotion.
				</p>

				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					6. THINGS YOU CANNOT DO
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					When using or accessing Bditto, you must comply with these
					Terms and all applicable laws, rules, and regulations.
					Please review the Content Policy, which are part of these
					Terms and contain Bditto’s rules about prohibited content
					and conduct. In addition to what is prohibited in the
					Content Policy, you may not do any of the following:
					<br />
					Use the Services in any manner that could interfere with,
					disable, disrupt, overburden, or otherwise impair the
					Services.
					<br />
					Gain access to (or attempt to gain access to) another user’s
					Account or any non-public portions of the Services,
					including the computer systems or networks connected to or
					used together with the Services.
					<br />
					Upload, transmit, or distribute to or through the Services
					any viruses, worms, malicious code, or other software
					intended to interfere with the Services, including its
					security-related features.
					<br />
					Use the Services to violate applicable law or infringe any
					person’s or entity's intellectual property rights or any
					other proprietary rights.
					<br />
					Access, search, or collect data from the Services by any
					means (automated or otherwise) except as permitted in these
					Terms or in a separate agreement with Bditto. We
					conditionally grant permission to crawl the Services in
					accordance with the parameters set forth in our robots.txt
					file, but scraping the Services without Bditto’s prior
					consent is prohibited.
					<br />
					Use the Services in any manner that we reasonably believe to
					be an abuse of or fraud on Bditto or any payment system.
					<br />
					We encourage you to report content or conduct that you
					believe violates these Terms or our Content Policy. We also
					support the responsible reporting of security
					vulnerabilities. To report a security issue, please email
					admin@bditto.com.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					7. COPYRIGHT, TRADEMARK, THE DMCA, AND TAKEDOWNS
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					Bditto respects the intellectual property of others and
					requires that users of our Services do the same. We have a
					policy that includes the removal of any infringing material
					from the Services and for the termination, in appropriate
					circumstances, of users of our Services who are repeat
					infringers. If you believe that anything on our Services
					infringes a copyright or a trademark that you own or
					control, you may notify Bditto Designated Agent by emailing
					to admin@bditto.com
					<br />
					Also, please note that if you knowingly misrepresent that
					any activity or material on our Service is infringing, you
					may be liable to Bditto for certain costs and damages.
					<br />
					If we remove Your Content in response to a copyright or
					trademark notice, we will notify you via email. If you
					believe Your Content was wrongly removed due to a mistake or
					misidentification in a copyright notice, you can send a
					counter notification via admin@bditto.com. Please see 17
					U.S.C. § 512(g)(3) for the requirements of a proper counter
					notification.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					8. PAID SERVICES AND PAYMENT INFORMATION
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					There are no fees for the use of many aspects of the
					Services.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					9. INDEMNITY
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					Except to the extent prohibited by law, you agree to defend,
					indemnify, and hold us, our directors, officers, employees,
					affiliates, agents, contractors, third-party service
					providers, and licensors (the “Bditto Entities”) harmless
					from any claim or demand, including costs and attorneys’
					fees, made by any third party due to or arising out of (a)
					your use of the Services, (b) your violation of these Terms,
					(c) your violation of applicable laws or regulations, or (d)
					Your Content. We reserve the right to control the defense of
					any matter for which you are required to indemnify us, and
					you agree to cooperate with our defense of these claims.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					10. DISCLAIMERS
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT
					WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
					INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
					MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
					AND NON-INFRINGEMENT. BDITTO ENTITIES DO NOT WARRANT THAT
					THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR
					ERROR FREE. BDITTO DOES NOT CONTROL, ENDORSE, OR TAKE
					RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE
					SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER. WHILE
					BDITTO ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR
					SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR
					SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
					COMPONENTS.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					11. LIMITATION OF LIABILITY
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					IN NO EVENT AND UNDER NO THEORY OF LIABILITY, INCLUDING
					CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR
					OTHERWISE, WILL THE BDITTO ENTITIES BE LIABLE TO YOU FOR ANY
					INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
					PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING
					TO THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM
					OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT
					IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS
					TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND
					RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
					YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING
					THEREFROM. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE
					BDITTO ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S.
					DOLLARS ($100) OR ANY AMOUNT YOU PAID BDITTO IN THE PREVIOUS
					SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE
					LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF
					LIABILITY, INCLUDING THOSE BASED ON WARRANTY, CONTRACT,
					STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND EVEN
					IF THE BDITTO ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY
					OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH HEREIN
					IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. THE FOREGOING
					LIMITATION OF LIABILITY WILL APPLY TO THE FULLEST EXTENT
					PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					12. GOVERNING LAW AND VENUE
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					We want you to enjoy Bditto, so if you have an issue or
					dispute, you agree to raise it and try to resolve it with us
					informally. You can contact us with feedback and concerns at
					admin@bditto.com
					<br />
					Any claims arising out of or relating to these Terms or the
					Services will be governed by the laws of Canton Schwyz,
					without regard to its conflict of laws rules. All disputes
					related to these Terms or the Services will be brought
					solely in the federal or state courts located in Canton
					Schwyz, Switzerland and you consent to personal jurisdiction
					in these courts.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					13. CHANGES TO THESE TERMS
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					We may make changes to these Terms from time to time. If we
					make changes, we will post the revised Terms and update the
					Effective Date above. If the changes, in our sole
					discretion, are material, we may also notify you by sending
					an email to the address associated with your Account (if you
					have chosen to provide an email address) or by otherwise
					providing notice through our Services. By continuing to
					access or use the Services on or after the Effective Date of
					the revised Terms, you agree to be bound by the revised
					Terms. If you do not agree to the revised Terms, you must
					stop accessing and using our Services before the changes
					become effective.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					14. ADDITIONAL TERMS
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					Because we offer a variety of Services, you may be asked to
					agree to additional terms before using a specific product or
					service offered by Bditto (“Additional Terms”). To the
					extent any Additional Terms conflict with these Terms, the
					Additional Terms govern with respect to your use of the
					corresponding Service.
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					15. TERMINATION
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					You may terminate these Terms at any time and for any reason
					by deleting your Account and discontinuing use of all
					Services. If you stop using the Services without
					deactivating your Account, your Account may be deactivated
					due to prolonged inactivity.
					<br />
					We may suspend or terminate your Account, moderator status,
					or ability to access or use the Services at any time for any
					or no reason, including for violating these Terms or our
					Content Policy.
					<br />
					The following sections will survive any termination of these
					Terms or of your Account: 4 (Your Content), 6 (Things You
					Cannot Do), 9 (Indemnity), 10 (Disclaimers), 11 (Limitation
					of Liability), 12 (Governing Law and Venue), 15
					(Termination), and 16 (Miscellaneous).
				</p>
				<h2 className={`${styles.subHeading} ${textcolorClass}`}>
					16. MISCELLANEOUS
				</h2>
				<p className={`${styles.content} ${textPColor}`}>
					These Terms constitute the entire agreement between you and
					us regarding your access to and use of the Services. Our
					failure to exercise or enforce any right or provision of
					these Terms will not operate as a waiver of such right or
					provision. If any provision of these Terms is, for any
					reason, held to be illegal, invalid, or unenforceable, the
					rest of the Terms will remain in effect. You may not assign
					or transfer any of your rights or obligations under these
					Terms without our consent. We may freely assign any of our
					rights and obligations under these Terms.
					<br />
					Contact Information:
					<br />
					Bharg Technologies GmbH.
					<br />
					Pfaeffikonerstrasse 27
					<br />
					8834 Schindellegi
					<br />
					Switzerland
				</p>
			</div>
		</>
	);
}

const StyledSidebarItem = styled.div`
	background: ${function ({ isActive }) {
		return isActive
			? "linear-gradient(112.23deg, rgba(196, 196, 196, 0.17) 0%, #00C6FF 0.01%, #0072FF 100%)"
			: "none";
	}};

	border-radius: 8px;

	cursor: pointer;

	color: ${function ({ isActive, theme }) {
		if (isActive) return "#D9DEF4";
		else return theme === "dark" ? "#7F89BE" : "#0D0F22";
	}};
`;
