import React from "react";

const DeleteIcon = ({ width = "16", height = "16", color = "#7F89BE" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 13 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 3.66667H11.6667M5 6.33333V10.3333M7.66667 6.33333V10.3333M1.66667 3.66667L2.33333 11.6667C2.33333 12.0203 2.47381 12.3594 2.72386 12.6095C2.97391 12.8595 3.31304 13 3.66667 13H9C9.35362 13 9.69276 12.8595 9.94281 12.6095C10.1929 12.3594 10.3333 12.0203 10.3333 11.6667L11 3.66667M4.33333 3.66667V1.66667C4.33333 1.48986 4.40357 1.32029 4.5286 1.19526C4.65362 1.07024 4.82319 1 5 1H7.66667C7.84348 1 8.01305 1.07024 8.13807 1.19526C8.2631 1.32029 8.33333 1.48986 8.33333 1.66667V3.66667"
				stroke={color}
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default DeleteIcon;
