import { NEW_MESSAGE } from "../../Actions/actionTypes";
import {
  CLEAR_UNREAD_COUNT,
  SET_UNREAD_COUNT_MULTIPLE_CONVERSATIONS,
} from "../../Actions/messages/types";
import createReducer from "../utils/createReducer";

const initialState = {};

const onNewMessage = (state, action) => {
  const isOwnMessage =
    action.payload.message.type !== "INFO" &&
    action.payload.message.from == action.context.userID;

  if (!isOwnMessage) {
    const key = action.payload.isGroup
      ? action.payload.conversationID + "group"
      : action.payload.message.from + "private";
    const path = action.payload.isGroup
      ? "/group/" + action.payload.conversationID
      : "/private/" + action.payload.message.from;
    if (!window.location.pathname.endsWith(path)) {
      return { ...state, [key]: (state[key] || 0) + 1 };
    } else {
      return state;
    }
  } else {
    return state;
  }
};

const setUnreadCounts = (state, action) => {
  return {
    ...state,
    ...action.payload.reduce(
      (acc, item) => ({ ...acc, [item.key]: item.unreadCount }),
      {}
    ),
  };
};

const clearUnreadCount = (state, action) => {
  const { key } = action.payload;
  const { [key]: removed, ...newState } = state;
  return newState;
};

const unreadCountReducer = createReducer(initialState, {
  [NEW_MESSAGE]: onNewMessage,
  [SET_UNREAD_COUNT_MULTIPLE_CONVERSATIONS]: setUnreadCounts,
  [CLEAR_UNREAD_COUNT]: clearUnreadCount,
});

export default unreadCountReducer;
