import { ReactComponent as Icon } from "../assets/icons/chats/ArrowBendUpLeft.svg";
import { ReactComponent as SolidIcon } from "../assets/icons/chats/ArrowBendUpLeftSolid.svg";

import genIcons from "./genIcons";

const ArrowBendUpLeft = {
  Solid: genIcons(SolidIcon,{ style: { width: "25", height: "25", fill: "transparent" } }),
  Outline: genIcons(Icon, { htmlColor: "transparent", viewBox: "0 0 27 27", style: { width: "25", height: "25" } })
};

export default ArrowBendUpLeft;
