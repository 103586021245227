import createReducer from "../utils/createReducer";
import {
	FETCH_FOLDERS,
	SET_LOADING,
	SET_ERROR,
	ADD_FOLDER,
	REMOVE_FOLDER,
	ADD_STATUS_TO_FOLDER,
	REMOVE_STATUS_FROM_FOLDER,
	EDIT_FOLDER,
} from "../../Actions/bookmarks/types";
const INITIAL_STATE = {
	isLoading: false,
	isFetched: false,
	error: { errorCode: null, error: null },
	data: [],
};

const handleLoading = (state, { isLoading }) => ({ ...state, isLoading });

const handleError = (state, { payload: error }) => ({
	...state,
	error: { errorCode: error.code, message: error.message },
});

const handleNewFolder = (state, { payload: folder }) => ({
	...state,
	data: [folder, ...state.data],
});

const handleRemoveFolder = (state, { payload: folderID }) => ({
	...state,
	data: state.data.filter((item) => item.id !== Number(folderID)),
});

const handleNewStatusInFolder = (state, { payload }) => ({
	...state,
	data: state.data.map((item) =>
		Number(item.id) === Number(payload)
			? { ...item, count: item.count + 1 }
			: item
	),
});
const handleRemovingStatusFromFolder = (state, { payload }) => ({
	...state,
	data: state.data.map((item) =>
		Number(item.id) === Number(payload)
			? { ...item, count: item.count - 1 }
			: item
	),
});

const handleEdit = (state, { payload }) => ({
	...state,
	data: state.data.map((item) =>
		Number(item.id) === Number(payload.id)
			? { ...item, foldername: payload.foldername }
			: item
	),
});

const setFolders = (state, { payload }) => ({
	...state,
	isFetched: true,
	data: payload,
});
const FoldersReducer = createReducer(INITIAL_STATE, {
	[FETCH_FOLDERS]: setFolders,
	[SET_LOADING]: handleLoading,
	[SET_ERROR]: handleError,
	[ADD_FOLDER]: handleNewFolder,
	[REMOVE_FOLDER]: handleRemoveFolder,
	[ADD_STATUS_TO_FOLDER]: handleNewStatusInFolder,
	[REMOVE_STATUS_FROM_FOLDER]: handleRemovingStatusFromFolder,
	[EDIT_FOLDER]: handleEdit,
});

export default FoldersReducer;
