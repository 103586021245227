import React from "react";
import { SnackbarProvider } from "notistack";
import Notifier from "./Notifier";
import NewNotification from "./NewNotification";
const NotificationProvider = (props) => {
	return (
		<SnackbarProvider
			transitionDuration={200}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			content={(key, message) => {
				if (typeof message === "object")
					return (
						<NewNotification id={key} message={message} key={key} />
					);
			}}
		>
			<Notifier />

			{props.children}
		</SnackbarProvider>
	);
};

export default NotificationProvider;
