import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./SettingSidebar.module.css";
import { Route, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Dialog, IconButton } from "@material-ui/core";
import Arrows from "../../Icons/Arrows";
import Chevron from "../../Icons/Chevron";
import NotificationSettings from "./NotificationSettings";
import { Helmet } from "react-helmet";
import Themes from "./Themes/Themes";
import { useSelector } from "react-redux";

const SettingSidebar = (props) => {
	const [active, setActive] = useState(0);
	const history = useHistory();
	const theme = useSelector((state) => state.theme.theme);
	const isMobile = useMediaQuery({ query: "(max-width: 724px)" });
	const [activeTab, setactiveTab] = useState("Notifications");

	const tab = [
		{
			id: 1,
			label: "Notifications",
		},
		{
			id: 2,
			label: "Themes",
		},
	];

	function SideBar({ isMobile }) {
		return (
			<div>
				{isMobile && (
					<div className={styles.header}>
						<IconButton
							size="small"
							variant="contained"
							style={{ color: "#ff5d5d" }}
							onClick={history.goBack}
						>
							<Arrows.Left viewBox="0 0 27 27" />
						</IconButton>
						<h2>
							{/* <FormattedMessage
								id="settings"
								defaultMessage={`Settings`}
							/> */}
							Settings
						</h2>
					</div>
				)}

				<ul className={styles.sidebar}>
					<li
						className={active === 0 ? styles.selected : styles.link}
						onClick={() => setActive(0)}
					>
						{theme === "dark" ? (
							active === 1 ? (
								<img
									className={styles.imgIcon}
									src="/settings/PrefBell-dk.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.imgIcon}
									src="/settings/PrefBell-lg.svg"
									alt=""
								/>
							)
						) : active === 1 ? (
							<img
								className={styles.imgIcon}
								src="/settings/PrefBell-lg.svg"
								alt=""
							/>
						) : (
							<img
								className={styles.imgIcon}
								src="/settings/PrefBell-dk.svg"
								alt=""
							/>
						)}
						{/* <FormattedMessage
							id="editProfile"
							defaultMessage={`Notifications`}
						/> */}
						Notifications
						{isMobile && (
							<IconButton
								size="small"
								variant="contained"
								style={{
									color: "#ff5d5d",
									float: "right",
									marginRight: "-1.8rem",
								}}
							>
								<Chevron.Right viewBox="0 0 27 27" />
							</IconButton>
						)}
					</li>
					<li
						className={active === 1 ? styles.selected : styles.link}
						onClick={() => setActive(1)}
					>
						{theme === "dark" ? (
							active === 1 ? (
								<img
									className={styles.imgIcon}
									src="/settings/PrefThemes-lg.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.imgIcon}
									src="/settings/PrefThemes-dk.svg"
									alt=""
								/>
							)
						) : active === 1 ? (
							<img
								className={styles.imgIcon}
								src="/settings/PrefThemes-dk.svg"
								alt=""
							/>
						) : (
							<img
								className={styles.imgIcon}
								src="/settings/PrefThemes-lg.svg"
								alt=""
							/>
						)}
						{/* <FormattedMessage
							id="editProfile"
							defaultMessage={`Themes`}
						/> */}
						Themes
						{isMobile && (
							<IconButton
								size="small"
								variant="contained"
								style={{
									color: "#ff5d5d",
									float: "right",
									marginRight: "-1.8rem",
								}}
							>
								<Chevron.Right viewBox="0 0 27 27" />
							</IconButton>
						)}
					</li>
				</ul>
			</div>
		);
	}

	return (
		<>
			{!isMobile ? (
				<Dialog
					open={true}
					fullScreen={true}
					maxWidth={"100vw"}
					fullWidth={"100vw"}
					classes={{
						paper:
							theme === "dark" ? styles.rootDark : styles.rootLg,
					}}
				>
					<div className={styles.wrapper}>
						<Helmet>
							<title>{`Settings | bditto`} </title>
						</Helmet>

						<div className={styles.container}>
							<div
								className={`${styles.header} ${
									theme === "dark"
										? styles.darkText
										: styles.lgText
								}`}
							>
								<h1>Preferences</h1>
								{/* {active === 0 ? (
								<h1>Settings</h1>
							) : (
								active === 1 && <h1>Themes</h1>
							)} */}
								<img
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.goBack();
									}}
									src="/icons/squaredClose.svg"
									alt=""
								/>
							</div>

							<div className={styles.main}>
								{!isMobile && (
									<>
										<div className={styles.left}>
											<SideBar
												active={active}
												setActive={setActive}
											/>
										</div>
										<div className={styles.right}>
											{active === 0 ? (
												<NotificationSettings
													isMobile={isMobile}
												/>
											) : (
												active === 1 && (
													<Themes
														isMobile={isMobile}
													/>
												)
											)}
										</div>
									</>
								)}
								{isMobile && (
									<div className={styles.right}>
										<Route exact path="/settings">
											<SideBar isMobile={isMobile} />
										</Route>
										<Route
											exact
											path="/settings/notificationSettings"
										>
											<NotificationSettings
												isMobile={isMobile}
											/>
										</Route>
										<Route exact path="/settings/themes">
											<Themes isMobile={isMobile} />
										</Route>
									</div>
								)}
							</div>
						</div>
					</div>
				</Dialog>
			) : (
				<div className={styles.right}>
					<div className={styles.settingTab}>
						{tab.map((tab, i) => {
							return (
								<div
									className={
										activeTab !== tab.label
											? styles.tab
											: styles.activeTab
									}
									onClick={() => {
										setactiveTab(tab.label);
									}}
								>
									{tab.label}
								</div>
							);
						})}
					</div>

					{activeTab === "Notifications" ? (
						<NotificationSettings isMobile={isMobile} />
					) : (
						<Themes isMobile={isMobile} />
					)}
				</div>
			)}
		</>
	);
};

export default SettingSidebar;
