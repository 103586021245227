import React from "react";

const MyFeedIcon = ({ width = "28", height = "28", color = "#D9DEF4" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.16797 9.91797L11.668 13.418"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.16797 13.418L11.668 9.91797"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.332 9.91797L19.832 13.418"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.332 13.418L19.832 9.91797"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.082 17.5C11.4622 17.888 11.916 18.1963 12.4168 18.4067C12.9177 18.6172 13.4555 18.7256 13.9987 18.7256C14.5419 18.7256 15.0797 18.6172 15.5806 18.4067C16.0814 18.1963 16.5352 17.888 16.9154 17.5"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default MyFeedIcon;
