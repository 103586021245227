import {

    SOCIAL_LOGIN_ERROR,
    SOCIAL_LOGIN_ERROR_CLOSE
} from "./actionTypes";


export const socialLoginError = (error) => ({
    type: SOCIAL_LOGIN_ERROR,
    payload: error
})

export const socialLoginErrorClose = () => ({

    type: SOCIAL_LOGIN_ERROR_CLOSE,
})
