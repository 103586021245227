export default function TermsIcon(props) {
	return (
		<svg
			style={{ margin: "auto 10px auto 0", width: 17, height: 17 }}
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 1V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H15M10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V6M10 1L15 6"
				stroke={props.color}
				strokeWidth="1.3"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
