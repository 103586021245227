import {
	Avatar,
	DialogContent,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close } from "../../Icons";
import { useSelector } from "react-redux";
import axiosConfig from "../../Utils/axiosConfig";
import DialogModal from "../Extras/DialogModal";
import Username from "../Extras/Username";
import { getStatusLikers } from "../../Utils/Constants";
import styles from "./LikersDialog.module.scss";
import { Link } from "react-router-dom";
import UserCardSkeleton from "../Skeleton/UserSkeleton/UserSkeleton";
import { SentimentVeryDissatisfied } from "@material-ui/icons";
const LikersDialog = ({ show, onClose, statusID }) => {
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const [likers, setLikers] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchLikers = async () => {
		const data = new FormData();
		data.append("status_id", statusID);
		setLoading(true);
		axiosConfig
			.post(getStatusLikers, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setLikers(res.data.result || res.data.body);
				setLoading(false);
			})
			.catch((err) => {});
		// try {
		// 	const {
		// 		data: { body },
		// 	} = await axiosConfig.post(getStatusLikers, data);
		// 	setLikers(body);
		// 	setLoading(false);
		// } catch (err) {
		// console.log(err);
		// }
	};
	useEffect(() => {
		if (statusID) {
			setLikers([]);
			fetchLikers(statusID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusID]);
	return (
		<DialogModal
			width="xs"
			show={show}
			className={styles.dialog}
			onHide={onClose}
			maxWidth="false"
		>
			<IconButton
				size="small"
				className={styles.closeBtn}
				onClick={onClose}
			>
				<Close.Outline />
			</IconButton>
			<div
				className={styles.header}
				style={{
					backgroundColor: theme === "dark" ? "#0d0f22" : "white",
					color: theme === "dark" ? "#d9def4" : "black",
				}}
			>
				<h2>Liked By...</h2>
			</div>
			<DialogContent
				className={styles.likersContainer}
				style={{
					backgroundColor: theme === "dark" ? "#0d0f22" : "white",
				}}
			>
				{likers.length > 0 &&
					likers.map((user) => <User user={user} theme={theme} />)}
				{!loading && likers.length === 0 && (
					<div className={styles.empty}>
						<div className={styles.empty__icon}>
							<SentimentVeryDissatisfied />
						</div>
						<p>No One has liked your Thought</p>
					</div>
				)}
				{loading && (
					<>
						<UserCardSkeleton radius={40} />
						<UserCardSkeleton radius={40} />
						<UserCardSkeleton radius={40} />
					</>
				)}
			</DialogContent>
		</DialogModal>
	);
};

const useStyles = makeStyles({
	avatarBackGround: { backgroundColor: "#ff5d5d" },
	avatarSize: { width: "2.5rem", heght: "2.5rem", fontSize: "1.1rem" },
});
const User = ({ user, theme }) => {
	const classes = useStyles();
	return (
		<Link
			to={`/profile/${user.pk}`}
			className={styles.userCard}
			style={{
				borderBottom:
					theme === "dark" ? "1px solid black" : "1px solid #eee",
			}}
		>
			<div className={styles.avatarContainer}>
				<Avatar
					classes={{
						colorDefault: classes.avatarBackGround,
						root: classes.avatarSize,
					}}
					src={user.avatar}
					style={{
						background: theme === "dark" ? "#d9def4" : "#0d0f22",
						color: theme === "dark" ? "#0d0f22" : "#d9def4",
					}}
				>
					{user.username[0].toUpperCase()}
				</Avatar>
			</div>
			<div className={styles.rightContainer}>
				<p
					className={styles.username}
					style={{
						color: theme === "dark" ? "#d9def4" : "black",
					}}
				>
					<Username value={user.username} />
				</p>
				{user.profession && (
					<p className={styles.profession}>{user.profession}</p>
				)}
			</div>
		</Link>
	);
};

export default LikersDialog;
