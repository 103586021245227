import { RECENT_SEARCHES } from "../Actions/actionTypes";

const initialState = {
    recentSearches: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RECENT_SEARCHES:
            return {
                ...state,
                recentSearches: action.payload,
            };
        default:
            return state;
    }
}
