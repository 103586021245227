import { Popover } from "@material-ui/core";
import React from "react";

const ClickPopover = ({ children, trigger, ...props }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		props.onOpen && props.onOpen();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const popid = open ? "simple-popover" : null;

	return (
		<>
			<div onClick={handleClick}>{trigger}</div>
			<Popover
				id={popid}
				anchorPosition={props.anchorPosition}
				classes={{ paper: props.className }}
				className={`${props.className}`}
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={
					props.transformOrigin || {
						horizontal: "center",
						vertical: "top",
					}
				}
				style={{ outline: "none" }}
			>
				{typeof children === "function"
					? children({ onClose: handleClose })
					: children}
			</Popover>
		</>
	);
};

export default ClickPopover;
