import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { FormattedMessage } from "react-intl";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import {
	completeTutorial,
	setTutorial,
} from "../../../../Redux/Actions/actionTutorial";
//Utils
import axiosConfig from "../../../../Utils/axiosConfig";
import {
	getAllStatusURL,
	changeStatusURL,
	deleteStatusURL,
} from "../../../../Utils/Constants";

// material UI
import {
	Button,
	List,
	ListItem,
	IconButton,
	Snackbar,
} from "@material-ui/core";
import { Add, Close, Search } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";

//Components
import withAlert from "./../../../Extras/Alert/withAlert";
import AddStatusModal from "../../../Extras/AddStatusModal/AddStatusModal";
import EditStatusModal from "../../../Extras/EditStatusModal/EditStatusModal";
import EmptyState from "../../../Extras/EmptyState";
import Alert from "../../../Extras/Alert/Alert";
import StatusCard from "../../../Extras/StatusCard/StatusCard";
import SmallSkeleton from "../../../Skeleton/SmallSkeleton/SmallSkeleton";
import dummyStatus from "../../../../dummyData/dummyStatus";
import TourComponent from "../../Tour";
import styles from "./Sidebar.module.css";
import "./Sidebar.scss";

const useStyles = () => ({
	addStatus: {
		background: " linear-gradient(225deg, #FC466B 0%, #3F5EFB 100%)",
		borderRadius: "2rem",
		opacity: "1",
		fontSize: "17px",
		color: "#ffffff",
		fontFamily: "Roboto",
		textTransform: "none",
		height: "45px",
		outline: "none !important",
		marginBottom: "1.5rem",
		width: "100%",
		fontStyle: "normal",
		lineHeight: "19.9px",
		fontWeight: "400",
		"@media  (max-width: 768px)": {
			width: "30px",
			height: "65px",
			position: "fixed",
			bottom: "7%",
			right: "6%",
			zIndex: "1",
			marginLeft: "0",
			borderRadius: "50%",
		},
	},
});

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStatusLoading: false,
			allMyStatus: [],
			popAddStatus: false,
			statusContent: "",
			popEditStatus: false,
			editStatusContent: null,
			alert: false,
			searchTerm: "",
			offset: 10,
			hasMore: true,
			open: false,
			pgLoading: false, //pagination loading
		};
		this.searchInputRef = createRef();
	}

	componentDidMount() {
		this.fetchMyStatus();
	}
	componentDidUpdate(prevProps) {
		if (
			this.props.groupDataFromStore !== prevProps.groupDataFromStore &&
			this.props.groupDataFromStore.id
		) {
			this.fetchMyStatus();
		}
	}

	fetchMyStatus = (searchTerm = "") => {
		this.setState({ isStatusLoading: true });

		const form = new FormData();
		form.append("status", this.props.type ? this.props.type : "All");
		form.append("search_phrase", searchTerm);
		if (!this.props.groupDataFromStore.id) return;

		axiosConfig
			.post(
				getAllStatusURL +
					"?groupId=" +
					this.props.groupDataFromStore.id +
					`&limit=10&offset=0`,
				form
			)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					allMyStatus: res.data.result || res.data.body,
					isStatusLoading: false,
				});
				if (
					!this.props.tutorialCompleted &&
					searchTerm === "" &&
					res.data.result.length === 0
				) {
					this.props.setTutorialActive(true);
				}

				if (
					// window.screen.width > 768 && 
					res.data.result.length !== 0) {
						if(window.screen.width <= 768){
							this.props.setSuggestionId(-1);
						}else{
							this.props.setSuggestionId(res.data.result[0].pk);
						}
				
					this.props.setThoughtsPresent(true);
				} else {
					this.props.setThoughtsPresent(false);
				}
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response;

					alert(catchError.error);
				}

				this.setState({ isStatusLoading: false });
			});
	};

	pagination = async (searchTerm = "") => {
		this.setState({ pgLoading: true });

		const form = new FormData();

		form.append("status", this.props.type ? this.props.type : "All");
		form.append("search_phrase", searchTerm);
		if (!this.props.groupDataFromStore.id) return;

		let data = await axiosConfig
			.post(
				getAllStatusURL +
					"?groupId=" +
					this.props.groupDataFromStore.id +
					`&limit=10&offset=${this.state.offset}`,
				form
			)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				const data = res.data;
				return data;
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response;

					alert(catchError.error);
				}

				this.setState({ pgLoading: false });
				return "error";
			});

		return data;
	};

	fetchData = () => {
		this.pagination()
			.then((data) => {
				setTimeout(() => {
					this.setState((prev) => ({
						allMyStatus: [...prev.allMyStatus, ...data.result],
						pgLoading: false,
						offset: this.state.offset + 10,
					}));
				}, [500]);
				if (this.state.offset >= data.count) {
					this.setState({ hasMore: false });
				}
			})
			.catch((err) => {
				this.setState({ pgLoading: false });
			});
	};

	setPopAddStatus = () => {
		this.setState({ popAddStatus: true, anchorEl: null });
	};

	setCloseAddStatus = () => {
		this.setState({ popAddStatus: false });
	};

	setCloseEditStatus = () => {
		this.setState({ popEditStatus: false });
	};

	setSuggestionId = (id) => {
		this.props.setSuggestionId(id);
		this.props.history.push("/");
	};

	onDeactivate = (id) => {
		const form = new FormData();
		form.append("statusId", id);

		axiosConfig
			.post(changeStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState((prev) => ({
					allMyStatus: prev.allMyStatus.map((item) =>
						item.pk === id
							? item.current_status === "active"
								? { ...item, current_status: "inactive" }
								: { ...item, current_status: "active" }
							: item
					),
				}));
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	onDelete = (id) => {
		const form = new FormData();
		form.append("statusId", id);

		axiosConfig
			.post(deleteStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				this.setState({
					alert: true,
					alertMessage: "Status Deleted",
				});
				this.fetchMyStatus();
				// let newData = this.state.allMyStatus.filter(
				// 	(item) => item.pk !== id
				// );

				// this.setState({ allMyStatus: newData });
				// if (this.props.suggestionId === id) {
				// 	if (newData.length)
				// 		this.props.setSuggestionId(newData[0].pk);
				// 	else {
				// 		this.props.setSuggestionId("");
				// 		this.props.setThoughtsPresent(false);
				// 	}
				// }
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	onEdit = (status) => {
		this.setState({ popEditStatus: true, editStatusContent: status });
	};

	handleSearchInput = (e) => {
		this.setState({ searchTerm: e.target.value });
		this.searchStatus(e.target.value);
	};

	searchStatus = debounce((searchTerm) => {
		this.fetchMyStatus(searchTerm);
	}, 400);

	handleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ open: false });
	};

	displaySkeleton = () => {
		return (
			<List>
				<ListItem>
					<SmallSkeleton />
				</ListItem>
				<ListItem>
					<SmallSkeleton />
				</ListItem>
				<ListItem>
					<SmallSkeleton />
				</ListItem>
				<ListItem>
					<SmallSkeleton />
				</ListItem>
			</List>
		);
	};

	render() {
		const { classes } = this.props;

		const { isStatusLoading, allMyStatus } = this.state;
		const isMobile = window.screen.width < 768;
		const status = allMyStatus;

		return (
			<>
				{!isMobile && (
					<>
						<div className={styles.addContainer}>
							<div
								id="Add_Status"
								className={styles.addStatusContainer}
							>
								<div
									className={`${styles.searchBox} searchBox`}
								>
									<img
										src="/Search.svg"
										className="mr-2 ml-1"
										alt=""
									/>
									<input
										type="text"
										value={this.state.searchTerm}
										onChange={this.handleSearchInput}
										ref={this.searchInputRef}
										className={`${styles.searchField} searchField`}
										placeholder="Search your thoughts"
										onClick={() => {
											this.setState((prev) => ({
												searchOpen: !prev.searchOpen,
											}));
											this.searchInputRef.current.focus();
										}}
									/>
								</div>

								<Button
									variant="contained"
									className={classes.addStatus}
									onClick={() => this.setPopAddStatus()}
								>
									<FormattedMessage
										id="addThoughts"
										defaultMessage={`Add a Thought `}
									/>
									<Add
										style={{
											fontSize: "1.2rem",
											marginLeft: "10px",
										}}
									/>
								</Button>

								<div className={`${styles.subHead} subHead`}>
									{this.props.theme === "dark" ? (
										<img
											src="/Home/mythoughts.svg"
											alt=""
										/>
									) : (
										<img
											src="/Home/mythoughts-dark.svg"
											alt=""
										/>
									)}
									<h2>My Thoughts</h2>
								</div>

								<AddStatusModal
									show={this.state.popAddStatus}
									onHide={this.setCloseAddStatus}
									fetchMyStatus={this.fetchMyStatus}
                                    open={this.state.open}
                                    fetchHashtags={this.props.fetchHashtags}
								/>

								<EditStatusModal
									show={this.state.popEditStatus}
									onHide={this.setCloseEditStatus}
									status={this.state.editStatusContent}
									fetchMyStatus={this.fetchMyStatus}
								/>
							</div>

							<MediaQuery maxDeviceWidth={600}>
								<div className={styles.mobileSearchBox}>
									<input
										type="text"
										value={this.state.searchTerm}
										onChange={this.handleSearchInput}
										className={`${styles.searchField} searchField`}
										placeholder="Search for your status..."
									/>
									{this.state.searchTerm === "" ? (
										<IconButton size="small">
											<Search
												fontSize="small"
												color="disabled"
											/>
										</IconButton>
									) : (
										<IconButton
											size="small"
											onClick={() => {
												this.setState((prev) => ({
													searchTerm: "",
												}));
												this.fetchMyStatus();
											}}
										>
											<Close
												fontSize="small"
												color="disabled"
											/>
										</IconButton>
									)}
								</div>
							</MediaQuery>

							{isStatusLoading ? (
								<>{this.displaySkeleton()}</>
							) : (
								<>
									{status.length > 0 ? (
										<div
											id="Edit-delete"
											className={
												this.props.theme === "dark"
													? styles.statusListDk
													: styles.statusListLg
											}
										>
											<InfiniteScroll
												dataLength={status.length} //This is important field to render the next data
												next={() => this.fetchData()}
												hasMore={this.state.hasMore}
												loader={
													this.state.pgLoading &&
													this.displaySkeleton()
												}
												scrollableTarget="Edit-delete"
											>
												{status.map((status, index) => {
													if (
														status.current_status ===
														"active"
													) {
														return (
															<StatusCard
																status={status}
																key={status.pk}
																offset={index}
																suggestionId={
																	this.props
																		.suggestionId
																}
																onDeactivate={
																	this
																		.onDeactivate
																}
																onDelete={
																	this
																		.onDelete
																}
																onEdit={
																	this.onEdit
																}
																onMobileClick={
																	this.props
																		.onClickStatus
																}
																handleIsActive={
																	this.props
																		.handleIsActive
																}
																setSuggestionId={
																	this.props
																		.setSuggestionId
																}
																height="12rem"
															/>
														);
													}
													return null;
												})}

												<div
													className={`${styles.subHead} subHead`}
												>
													{this.props.theme ===
													"dark" ? (
														<img
															src="/Home/deactive.svg"
															alt=""
														/>
													) : (
														<img
															src="/Home/deactive-dark.svg"
															alt=""
														/>
													)}
													<h2>Deactivated</h2>
												</div>
												{status.map((status, index) => {
													if (
														status.current_status !==
														"active"
													) {
														return (
															<StatusCard
																status={status}
																key={status.pk}
																offset={index}
																suggestionId={
																	this.props
																		.suggestionId
																}
																onDeactivate={
																	this
																		.onDeactivate
																}
																onDelete={
																	this
																		.onDelete
																}
																onEdit={
																	this.onEdit
																}
																onMobileClick={
																	this.props
																		.onClickStatus
																}
																onClick={() => {
															
																	this.props.handleIsActive(
																		true
																	);
																	this.props.setSuggestionId(
																		status.pk
																	);
																
																}}
																height="12rem"
															/>
														);
													}
													return null;
												})}
											</InfiniteScroll>
										</div>
									) : (
										<MediaQuery maxDeviceWidth={600}>
											<EmptyState
												className={
													classes.noStatusContainer
												}
												heading="Hey there! What are you upto?"
												imageUrl="/vectors/empty-states/noThoughts.svg"
												subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
											/>
										</MediaQuery>
									)}
								</>
							)}
							{/* </InfiniteScroll> */}

							<TourComponent
								isMobile={isMobile}
								run={this.props.tutorial}
								onClose={() => {
									this.props.setTutorial(false);
									this.props.setTutorialActive(false);
									if (this.state.allMyStatus.length === 0) {
										this.props.setThoughtsPresent(false);
									}
								}}
							/>
						</div>
						<Alert
							show={this.props.askTutorial}
							header="Hi, Welcome!"
							message={
								"Welcome to the Bditto family! Follow along this short tutorial to see how it works."
							}
							hideAlert={() =>
								this.props.setTutorialActive(false)
							}
							callback={() => {
								this.props.setTutorialActive(false);
								this.props.setTutorial(true);
							}}
							buttonText="Start Tour!"
							linkText="No, Thanks"
							linkClick={() => {
								this.props.setTutorialActive(false);
								this.props.completeTutorial();
								if (this.state.allMyStatus.length === 0) {
									this.props.setThoughtsPresent(false);
								}
							}}
						/>
						<Snackbar
							open={this.state.open}
							autoHideDuration={6000}
							onClose={this.handleClose}
						>
							<MuiAlert
								onClose={this.handleClose}
								severity="success"
							>
								This is a success message!
							</MuiAlert>
						</Snackbar>
					</>
				)}

				{/* <div className={styles.addContainer}> */}
				{isMobile &&
					(isStatusLoading ? (
						this.displaySkeleton()
					) : status.length > 0 ? (
						<>
							<Button
								id="Add_Status_mobile"
								variant="contained"
								className={classes.addStatus}
								onClick={() => this.setPopAddStatus()}
							>
								<Add />
							</Button>
							<AddStatusModal
								show={this.state.popAddStatus}
								onHide={this.setCloseAddStatus}
								fetchMyStatus={this.fetchMyStatus}
                                open={this.state.open}
                                fetchHashtags={this.props.fetchHashtags}

							/>
							<div
								id="Edit-delete"
								className={
									this.props.theme === "dark"
										? styles.statusListDk
										: styles.statusListLg
								}
							>
								<div className={styles.para}>
									<h3 className="">View matches</h3>
									<p className="">
										Click on any of your thoughts to view
										its thought matches in Workspace 1
									</p>
								</div>
								<TourComponent
									isMobile={isMobile}
									run={this.props.tutorial}
									onClose={() => {
										this.props.setTutorial(false);
										this.props.setTutorialActive(false);
										if (
											this.state.allMyStatus.length === 0
										) {
											this.props.setThoughtsPresent(
												false
											);
										}
									}}
								/>
								<Alert
									show={this.props.askTutorial}
									header="Hi, Welcome!"
									message={
										"Welcome to the Bditto family! Follow along this short tutorial to see how it works."
									}
									hideAlert={() =>
										this.props.setTutorialActive(false)
									}
									callback={() => {
										this.props.setTutorialActive(false);
										this.props.setTutorial(true);
									}}
									buttonText="Start Tour!"
									linkText="No, Thanks"
									linkClick={() => {
										this.props.setTutorialActive(false);
										this.props.completeTutorial();
										if (
											this.state.allMyStatus.length === 0
										) {
											this.props.setThoughtsPresent(
												false
											);
										}
									}}
								/>
								<InfiniteScroll
									dataLength={status.length} //This is important field to render the next data
									next={() => this.fetchData()}
									hasMore={this.state.hasMore}
									loader={
										this.state.pgLoading &&
										this.displaySkeleton()
									}
									scrollableTarget="Edit-delete"
									className="d-flex flex-column align-items-center"
								>
									{status.map((status, index) => {
										if (
											this.props.type === "Active" &&
											status.current_status === "active"
										) {
											
											return (
												<StatusCard
													status={status}
													key={status.pk}
													offset={index}
													suggestionId={
														this.props.suggestionId
													}
													onDeactivate={
														this.onDeactivate
													}
													onDelete={this.onDelete}
													onEdit={this.onEdit}
													onMobileClick={
														this.props.onClickStatus
													}
													handleIsActive={
														this.props
															.handleIsActive
													}
													setSuggestionId={
														this.props
															.setSuggestionId
													}
													handleMobileSuggestions={this.props.handleMobileSuggestions}
													setThoughtContent={this.props.setThoughtContent}
													height="15rem"
													// width="50%"
												/>
											);
										}
										return null;
									})}

									{status.map((status, index) => {
										if (
											this.props.type === "Deactive" &&
											status.current_status === "inactive"
										) {
											return (
												<StatusCard
													status={status}
													key={status.pk}
													offset={index}
													suggestionId={
														this.props.suggestionId
													}
													onDeactivate={
														this.onDeactivate
													}
													onDelete={this.onDelete}
													onEdit={this.onEdit}
													onMobileClick={
														this.props.onClickStatus
													}
													onClick={() => {
														this.props.handleIsActive(
															true
														);
														this.props.setSuggestionId(
															status.pk
														);
													}}
													height="15rem"
													// width="50%"
												/>
											);
										}
										return null;
									})}
								</InfiniteScroll>
							</div>
						</>
					) : (
						<MediaQuery maxDeviceWidth={768}>
							<EmptyState
								className={classes.noStatusContainer}
								heading="Hey there! What are you upto?"
								imageUrl="/vectors/empty-states/noThoughts.svg"
								subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
							/>
						</MediaQuery>
					))}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	tutorial: state.tutorial.enabled,
	tutorialCompleted: state.tutorial.completed,
	groupDataFromStore: state.groups.groupData,
	theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
	setTutorial: (enabled) => dispatch(setTutorial(enabled)),
	completeTutorial: () => dispatch(completeTutorial()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(withRouter(withAlert(Sidebar))));
