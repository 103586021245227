import { createSelector } from "./helper";

const getContactRequestsBlock = (state) => state.contactRequests;

export const isContactRequestsLoading = createSelector(getContactRequestsBlock, (requests) => requests.loading);

export const isContactRequestsError = createSelector(getContactRequestsBlock, (requests) => requests?.error);
export const isContactRequestsPolled = createSelector(getContactRequestsBlock, (requests) => requests?.isFetched);

export const getContactRequests = createSelector(getContactRequestsBlock, (requests) => requests.data);
export const getTop5ContactRequests = createSelector(getContactRequestsBlock, (requests) => requests.data.slice(0,5));

export const getRequestsCount = createSelector(getContactRequestsBlock, (req) => req.data.length);
