import { MESSAGE_STATUS } from "../../../Utils/Constants";
import { encryptMessage } from "../../../Utils/Encryption";
import store from "../../store";
import { READ_MESSAGES } from "../actionTypes";
import {
  CREATE_MESSAGE,
  DELETE_FROM_QUEUE,
  MESSAGE_RECEIVED,
  MESSAGE_SENT,
  SET_CHAT_LOADING,
  UPDATE_DISPATCH_QUEUE,
  MESSAGE_DELETE,
} from "./types";

export const sendTextMessage = (data) => {
  const userData = store.getState().auth?.user_data;
  const { message } = data;
  return {
    type: CREATE_MESSAGE,
    payload: {
      ...data,
      message: {
        ...message,
        status: MESSAGE_STATUS.PENDING,
        profileURL: userData?.avatar,
        from: userData?.userID,
        timeStamp: new Date().toISOString(),
        hash: encryptMessage(message.message, message.conversationID),
        username: userData?.user?.username,
      },
    },
  };
};

export const sendMediaMessage = (data) => {
  const userData = store.getState().auth?.user_data;
  const { message } = data;
  return {
    type: CREATE_MESSAGE,
    payload: {
      ...data,
      message: {
        ...message,
        status: MESSAGE_STATUS.PENDING,
        profileURL: userData?.avatar,
        from: userData?.userID,
        timeStamp: new Date().toISOString(),
        hash: encryptMessage(message.message, message.conversationID),
        username: userData?.user?.username,
      },
    },
  };
};

export const mediaUploadSuccess = (hash) => ({
  type: MESSAGE_SENT,
  payload: { hash },
});

export const updateDispatchQueue = (queue) => ({
  type: UPDATE_DISPATCH_QUEUE,
  payload: queue,
});

export const messageReceived = (message) => ({
  
  type: MESSAGE_RECEIVED,
  payload: message,
  context: { userID: store.getState().auth?.user_data?.userID },
});

export const deleteMesage = (messageID, conversationID) => ({
  type: MESSAGE_DELETE,
  payload: { messageID, conversationID },
});

export const removeFromQueue = (chatID) => ({
  type: DELETE_FROM_QUEUE,
  payload: { chatID },
});

export const readConversation = (data) => ({
  type: READ_MESSAGES,
  payload: data,
});

export const setChatLoading = (state) => ({ type: SET_CHAT_LOADING, payload: state });
