import { Popover } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import EmptyState from "../../Extras/EmptyState";
import SearchCard from "../../Extras/SearchCard/SearchCard";
import UserPreview from "../../Extras/UserPreview/UserPreview";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";
import UserSkeleton from "../../Skeleton/UserSkeleton/UserSkeleton";
import LikedMessageBox from "../../Home/Chats/Extra/LikedMessage/LikedMessageBox";
import { useSelector } from "react-redux";

import Chevron from "../../../Icons/Chevron";
import styles from "./Mobile.module.css";
import { useHistory, useLocation } from "react-router";
import { Avatar, Dialog, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function Mobile(props) {
	const theme = useSelector((state) => state.theme.theme);
	const user_data = useSelector((state) => state.auth.user_data);

	const history = useHistory();
	const location = useLocation();

	const { userResult, isUserLoading, handleStatusScroll, handleUserScroll } =
		props;

	return (
		<>
			{!location.search ? (
				props.isSugg ? (
					<>
						<Typography
							variant="body1"
							style={{
								color:
									theme === "light" ? "#4A517E" : "#7F89BE",
								fontSize: "1rem",
							}}
						>
							Suggestions
						</Typography>
						{props.noSugg && (
							<p
								style={{
									margin: "1.5rem 0",
									color:
										theme === "light"
											? "#4A517E"
											: "#7F89BE",
									fontSize: "1rem",
								}}
							>
								No suggestions
							</p>
						)}
						{props.thoughtsSugg &&
							props.thought === 1 &&
							props.thoughtsSugg.slice(0, 5).map((thought, i) => (
								<div key={i} className={styles.recentSearch}>
									{theme === "dark" ? (
										<img
											src="/search/thoughtIcon.svg"
											alt=""
										/>
									) : (
										<img src="/thoughtsLg.svg" alt="" />
									)}
									<p
										style={{
											margin: "0 1rem",
											color:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											cursor: "pointer",
										}}
										onClick={() => {
											props.perSearch(thought.content);
										}}
									>
										{thought.content}
									</p>
								</div>
							))}
						{props.usersSugg &&
							props.thought === 2 &&
							props.usersSugg.slice(0, 5).map((user, i) => (
								<div className={styles.recentSearch} key={i}>
									{theme === "dark" ? (
										<img src="/search/Profile.svg" alt="" />
									) : (
										<img src="/profileLg.svg" alt="" />
									)}
									<p
										style={{
											margin: "0 1rem",
											color:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											cursor: "pointer",
										}}
										onClick={() => {
											props.perSearch(user.username);
										}}
									>
										{user.username}
									</p>
								</div>
							))}
						{props.msgsSugg &&
							props.thought === 3 &&
							props.msgsSugg.slice(0, 5).map((msg, i) => (
								<div className={styles.recentSearch} key={i}>
									{theme === "dark" ? (
										<img src="/search/msgIcon.svg" alt="" />
									) : (
										<img src="/msgLg.svg" alt="" />
									)}
									<p
										style={{
											margin: "0 1rem",
											color:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											cursor: "pointer",
										}}
										onClick={() => {
											props.perSearch(msg.message);
										}}
									>
										{msg.message}
									</p>
								</div>
							))}
					</>
				) : (
					props.recentSearches &&
					props.recentSearches.length > 0 && (
						<div className={styles.recentSearchContainer}>
							<Typography
								variant="body1"
								style={{
									color:
										theme === "light"
											? "#4A517E"
											: "#7F89BE",
									fontSize: "1rem",
								}}
							>
								Recent Searches
							</Typography>
							{props.recentSearches &&
								props.recentSearches.length > 0 &&
								props.recentSearches
									.slice(0, 5)
									.map((search, i) => (
										<div
											key={i}
											className={styles.recentSearch}
											onMouseOver={() =>
												props.setMouseOver(i)
											}
										>
											{search.thought === 1 ? (
												<img
													src="/search/thoughtIcon.svg"
													alt=""
												/>
											) : search.thought === 2 ? (
												<img
													src="/search/Profile.svg"
													alt=""
												/>
											) : (
												<img
													src="/search/msgIcon.svg"
													alt=""
												/>
											)}
											<p
												onClick={() => {
													props.perSearch(
														search.text
													);
												}}
												style={{
													margin: "0 1rem",
													color:
														theme === "dark"
															? "#d9def4"
															: "#0d0f22",
													cursor: "pointer",
												}}
											>
												{search.text}
											</p>
											{props.mouseOver === i && (
												<Close
													onClick={() =>
														props.removeRecent(
															search
														)
													}
													style={{
														marginLeft: "auto",
														color:
															theme === "dark"
																? "#7F89BE"
																: "#4A517E",
														cursor: "pointer",
													}}
												/>
											)}
										</div>
									))}
						</div>
					)
				)
			) : (
				<div>
					<Typography
						variant="body1"
						style={{
							color: theme === "light" ? "#4A517E" : "#7F89BE",
							fontSize: "1rem",
						}}
					>
						Results
					</Typography>
					<div className={styles.resultBox}>
						{!props.isEmpty.status ||
						!props.isEmpty.users ||
						!props.isEmpty.message ? (
							<>
								{props.thought === 1 ? (
									<div
										className={styles.status}
										onScroll={handleStatusScroll}
									>
										<div className={styles.cards}>
											{typeof props.statusResult !==
												"string" &&
												props.statusResult.map(
													(item, index) => (
														<>
															<StatusCard
																status={item}
																key={index}
																height="13.5rem"
																className={
																	styles.card
																}
															/>
														</>
													)
												)}

											{props.isStatusLoading && (
												<>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
													<CardSkeleton
														width={"100%"}
													/>
												</>
											)}
										</div>
									</div>
								) : props.thought === 2 ? (
									<div
										className={
											props.userResult.length > 0
												? styles.user
												: null
										}
										onScroll={handleUserScroll}
									>
										{userResult.length > 0 &&
											userResult.map((item, index) => {
												return (
													<div
														className={
															props.userResult
																.length == 1
																? styles.resultuserDiv1
																: styles.resultuserDiv
														}
													>
														<UserPreview
															{...props}
															user={item}
															key={index}
															online={
																user_data.online
															}
															theme={theme}
														/>
													</div>
												);
											})}

										{isUserLoading && (
											<>
												<UserSkeleton radius={60} />
												<UserSkeleton radius={60} />
												<UserSkeleton radius={60} />
												<UserSkeleton radius={60} />
											</>
										)}
									</div>
								) : (
									<div className={styles.msgResult}>
										{props.messageResult &&
											props.messageResult.map(
												(message) => (
													<div className={styles.msg}>
														<LikedMessageBox
															data={message}
															isGroup={
																message.groupID
															}
															isOwnMessage={false}
															conversationID={
																message._id
															}
														/>
													</div>
												)
											)}
									</div>
								)}
							</>
						) : (
							<EmptyState
								heading="No Results!"
								imageUrl="/vectors/empty-states/emptySearch.svg"
								subHeading="We cannot find what you’re looking for, maybe a little spelling mistake?"
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
}
