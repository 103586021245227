import React from "react";

const TrendingIcon = ({ color = "#D9DEF4", width = "28", height = "28" }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 5.83203L24.5 9.33203L21 12.832"
				stroke="#D9DEF4"
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.5 20.9987H6.2965C7.23441 20.9986 8.15847 20.7724 8.99035 20.3392C9.82222 19.906 10.5374 19.2786 11.0752 18.5102L15.7582 11.8205C16.296 11.0521 17.0111 10.4247 17.843 9.99153C18.6749 9.55833 19.5989 9.3321 20.5368 9.33203H24.5"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default TrendingIcon;
