import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosConfig from "../../../Utils/axiosConfig";
import {
	PasswordResetTokenURL,
	PasswordResetURL,
} from "../../../Utils/Constants";
import styles from "./ChangePassword.module.css";
import "./changepassword.scss";
import { useEffect } from "react";
import InputField from "../../Extras/InputField";
import BackToLandingBtn from "../BackToLandingBtn";
// import { FormattedMessage } from "react-intl";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import DialogModal from "../../Extras/DialogModal";


const Success = () => {
	return (
		<div className={styles.card}>
			<div className={styles.titleCont}>
				<h1 className={styles.titleSuccess}>Success</h1>
				<CheckCircleIcon
					style={{ color: "#5bffb3", fontSize: "2.4rem" }}
				/>
			</div>

			<h3 className={styles.subHeading}>Your Password has be changed</h3>

			<p className={styles.text}>
				Click the link below to back to the login page
			</p>

			<Link to="/login">
				<Button1 className={styles.buttonSuccess}>Login</Button1>
			</Link>
		</div>
	);
};




const Faliure = (props) => {
	return (
		<div className={styles.card}>
			<div className={styles.titleCont}>
				<h1 className={styles.titleFailure}>Faliure</h1>
				<ErrorIcon style={{ color: "#FF5B5B", fontSize: "2.4rem" }} />
			</div>
            <h3 className={styles.subHeading}>{ props.error}</h3>
			<Link to="/">
				<Button1 className={styles.buttonFailure}>
					Go back to Homepage
				</Button1>
			</Link>
		</div>
	);
};

const Loading = () => {
	return <CircularProgress color="primary" style={{ margin: "auto" }} />;
};

export default function ChangePassword(props) {
	const [loading, setloading] = useState(true);
	const [token, setToken] = useState(null);
	const [uuid, setUuid] = useState(null);
	const [showForm, setShowForm] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
    const [submit, setSubmit] = useState(false);
    const [error, setError] = useState("")
    const [errors, setErrors] = useState({
        newPassword: "",
        confirmPassword: "",
    })
    const [match, setMatch] = useState(true);

    const history = useHistory();
    

    const passwordRegex = new RegExp(
		"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
	);
    

    useEffect(() => {
        fetch()
        localStorage.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    
    const fetch = async () => {
        const { tag, token } = props.rootParams.params;
		return await axiosConfig
			.get(PasswordResetTokenURL + tag + "/" + token)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setShowForm(true);
				setloading(false);
				setToken(token);
                setUuid(tag);
			})
            .catch((err) => {
                setError(err.response.data.message)
				setIsSuccess(false);
				setloading(false);
			});
    }


    	const validate = () => {
		if (
			newPassword.length > 0 &&
			confirmPassword.length > 0 
        ) {
            if (newPassword !== confirmPassword) {
                setMatch(false)
            }
            else if (newPassword.length < 12) {
                setErrors({newPassword: "Password should be minimum 12 characters", confirmPassword: ""})
			}
            else if (newPassword.indexOf(" ") >= 0) {
                setErrors({newPassword: "Password should not contain spaces", confirmPassword: ""})
			}
            else if (!passwordRegex.test(newPassword)) {
                setErrors({newPassword:"password should contain atleast one number, special character, Upper case and lower case", confirmPassword: ""})
            }
            else if (confirmPassword.length < 12) {
                setErrors({newPassword:"", confirmPassword: "Comfirm password should be minimum 12 characters"})
			}
            else if (confirmPassword.indexOf(" ") >= 0) {
                setErrors({newPassword:"", confirmPassword: "Comfirm password should not contain spaces"})
			}
            else if (!passwordRegex.test(confirmPassword)) {
                setErrors({newPassword:"", confirmPassword:"Comfirm password should contain atleast one number, special character, Upper case and lower case"})
            }
            else {
				return true;
			}
            } 
            else {
                setErrors({newPassword:"password required", confirmPassword:"confirm password required"})
            }
	};

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setSubmit(true);
            const form = new FormData();
            form.append("token", token);
            form.append("uidb64", uuid);
            form.append("new_password", newPassword);
            form.append("confirm_password", confirmPassword);
            axiosConfig
                .patch(PasswordResetURL, form)
                .then((res) => {
                    if (res.status !== 200) throw new Error();
                    history.push("/login");
                })
                .catch((err) => {
                    setIsSuccess(false)
                    setShowForm(false)
                });
         }
	};

	

	return (
		<div className="auth-page-Container">
			<BackToLandingBtn />
			<div className="pinkOval"></div>
			<div className="yellowDiv"></div>
			<div className="container">
				<div className="form-container reset-password-form-container">
					{loading ? (
						<Loading />
					) : (
						!showForm && (isSuccess ? <Success /> : <Faliure error={error} />)
                    )}

                    {!match &&
                        <DialogModal>
                            <div className={styles.card}>
                                <div className={styles.titleCont}>
                                    <h1 className={styles.titleFailure}>please try again!</h1>
                                    <ErrorIcon style={{ color: "#FF5B5B", fontSize: "2.4rem" }} />
                                </div>
                                <p className={styles.text}>
                                    Password and Confirm PasswordNot Matched
                                </p>
                                <Button1 onClick={()=>setMatch(true)} className={styles.buttonFailure}>
                                    Close
                                </Button1>
                            </div>
                        </DialogModal>
                    }
                    
					{showForm && (
						<>
							<div className="headerN">
								<h1>
									{/* <FormattedMessage
										id="login.heading1"
										defaultMessage={`Recover your password`}
									/> */}
                                    Reset your password
								</h1>
								<p>
									{/* <FormattedMessage
										id="login.heading2"
										defaultMessage={`Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.`}
									/> */}
                                    Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.
								</p>
							</div>

							<div className="form">
								<div className="left">
									<img
										className="logoM"
                                        src="/logoMain.svg"
                                        alt=""
									/>
									<div className="heading">
										<h1>
											Where people{" "}
											<span className="gradienth1">
												connect
											</span>{" "}
											&{" "}
											<span className="gradienth1">
												share...
											</span>
										</h1>
									</div>
									<div className="launchImg">
										<img src="/icons/Launch.svg" alt="" />
									</div>
								</div>

								<div className="right">
									<div className="login">
										<img src="/icons/login.svg" alt="" />
										<p>Reset Password</p>
									</div>

									<div className="underline"></div>

									<div style={{ margin: "4rem 0" }}>
										<div className="heading">
											<h5>Change Password</h5>
                                        </div>
                                        <div className="signup-form">
                                            <form noValidate onSubmit={handleSubmit}>
                                                <div className="inputDiv">
                                                    <div className="input">
                                                        <InputField
                                                            className="password-field"
                                                            type="password"
                                                            placeholder="Enter password"
                                                            name="password"
                                                            required
                                                            errorText={errors.newPassword}
                                                            value={newPassword}
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="label">
                                                        <p>Enter new password</p>
                                                    </div>
                                                </div>
                                                <div className="inputDiv">
                                                    <div className="input">
                                                        <InputField
                                                            className="password2-field"
                                                            type="password"
                                                            placeholder="Re-enter Password"
                                                            name="password2"
                                                            required
                                                            errorText={errors.confirmPassword}
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="label">
                                                        <p>Let's confirm your password</p>
                                                    </div>
                                                </div>

                                                <div className="inputDiv">
                                                    <button className="login-btn" type="submit">
                                                        {/* <FormattedMessage
                                                            id="signIn"
                                                            defaultMessage={`Change Password`}
                                                        /> */}
                                                        Change Password
                                                        {submit && (
                                                            <CircularProgress
                                                                className="loader"
                                                                color="primary"
                                                                size={20}
                                                            />
                                                        )}
                                                    </button>
                                                    <p className="link">
                                                        {/* <FormattedMessage
                                                            id="login.heading4"
                                                            defaultMessage={` Don’t have an account? `}
                                                        /> */}
                                                        Don’t have an account?
                                                        &nbsp;
                                                        <Link to="/register">
                                                            <span className="forget">
                                                                {/* <FormattedMessage
                                                                    id="login.heading3"
                                                                    defaultMessage={`Sign up here`}
                                                                /> */}
                                                                Sign up here
                                                            </span>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </form>
		                                </div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
