export function PrivacyIcon({ color, style }) {
	return (
		<svg
			style={style}
			width="15"
			height="18"
			viewBox="0 0 15 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.1008 7.50111C10.7558 7.50111 10.4758 7.22111 10.4758 6.87611V5.08694C10.4758 3.34027 9.055 1.91944 7.30833 1.91944H7.295C6.45083 1.91944 5.66 2.24444 5.0625 2.83694C4.46083 3.43194 4.12833 4.22611 4.125 5.07277V6.87611C4.125 7.22111 3.845 7.50111 3.5 7.50111C3.155 7.50111 2.875 7.22111 2.875 6.87611V5.08694C2.88 3.88694 3.34417 2.77944 4.18167 1.94944C5.02 1.11861 6.12667 0.637774 7.31083 0.669441C9.74417 0.669441 11.7258 2.65111 11.7258 5.08694V6.87611C11.7258 7.22111 11.4458 7.50111 11.1008 7.50111Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.11703 7.44141C2.7187 7.44141 1.58203 8.57807 1.58203 9.97641V13.5506C1.58203 14.9489 2.7187 16.0856 4.11703 16.0856H10.4845C11.882 16.0856 13.0195 14.9489 13.0195 13.5506V9.97641C13.0195 8.57807 11.882 7.44141 10.4845 7.44141H4.11703ZM10.4845 17.3356H4.11703C2.02953 17.3356 0.332031 15.6381 0.332031 13.5506V9.97641C0.332031 7.88891 2.02953 6.19141 4.11703 6.19141H10.4845C12.572 6.19141 14.2695 7.88891 14.2695 9.97641V13.5506C14.2695 15.6381 12.572 17.3356 10.4845 17.3356Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.30078 13.3157C6.95578 13.3157 6.67578 13.0357 6.67578 12.6907V10.8398C6.67578 10.4948 6.95578 10.2148 7.30078 10.2148C7.64578 10.2148 7.92578 10.4948 7.92578 10.8398V12.6907C7.92578 13.0357 7.64578 13.3157 7.30078 13.3157Z"
				fill={color}
			/>
		</svg>
	);
}
