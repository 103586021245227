import React from "react";
import classes from "./Button1.module.css";
import { useSelector } from "react-redux";

function Button1(props) {
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const isMobile = window.screen.width < 768;

	return (
		<button
			{...props}
			className={classes.Violet_Border_Button}
			disabled={props.disabled}
			style={{
				boxShadow: `2px 1000px 1px ${
					theme === "dark" ? "#0D0F22" : "white"
				} inset`,
				height: props.height && props.height,
				padding: props.padding && props.padding,
				width: props.width && props.width,
				width: isMobile && "100%",
			}}
		>
			<p
				className={classes.button_text}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{props.children}
			</p>
		</button>
	);
}

export default Button1;
