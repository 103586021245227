export function TicketIcon({ color }) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="1"
				y="2"
				width="14"
				height="13"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1.33203 2H14.6654V14.1107H1.33203V2Z"
					fill={color}
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M2.33203 9.986V11.3493C2.33203 11.82 2.5147 12.262 2.84736 12.5947C3.18003 12.9273 3.6227 13.1107 4.0927 13.1107H11.9034C12.874 13.1107 13.6647 12.3213 13.6654 11.3507V9.98533C12.808 9.76333 12.1727 8.98267 12.172 8.05667C12.172 7.13067 12.8074 6.35 13.6647 6.12733L13.6654 4.764C13.666 3.792 12.8774 3.00067 11.906 3H4.0947C3.12336 3 2.3327 3.79 2.33203 4.76133V6.17267C2.65603 6.25 2.9567 6.40933 3.2067 6.64267C3.58603 6.99667 3.8047 7.47667 3.82336 7.99533C3.82536 8.97267 3.19003 9.762 2.33203 9.986ZM11.9034 14.1107H4.09336C3.35536 14.1107 2.66203 13.824 2.14003 13.302C1.6187 12.78 1.33203 12.0867 1.33203 11.3493V9.54933C1.33203 9.27333 1.55603 9.04933 1.83203 9.04933C2.38136 9.04867 2.82536 8.60333 2.8247 8.05667C2.81536 7.778 2.7087 7.546 2.5247 7.374C2.34136 7.202 2.10403 7.108 1.84936 7.122C1.71136 7.12333 1.58203 7.076 1.4847 6.982C1.38736 6.888 1.33203 6.75733 1.33203 6.622V4.762C1.3327 3.23867 2.57203 2 4.0947 2H11.9027C13.4294 2.00067 14.6667 3.242 14.6654 4.76467V6.564C14.6654 6.84 14.4414 7.064 14.1654 7.064C13.618 7.064 13.172 7.50933 13.172 8.056C13.1727 8.604 13.618 9.04933 14.1654 9.04933C14.4414 9.04933 14.6654 9.27333 14.6654 9.54933V11.3493C14.6647 12.872 13.4254 14.1107 11.9034 14.1107Z"
					fill={color}
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.7334 7.58597L7.07273 7.91664C7.25673 8.09731 7.34006 8.35464 7.29606 8.60597L7.21606 9.07131L7.6354 8.85064C7.8614 8.72997 8.13273 8.73064 8.36073 8.85197L8.7774 9.07064L8.6974 8.60464C8.6554 8.34931 8.7394 8.09397 8.9214 7.91664L9.26073 7.58597L8.79073 7.51797C8.53873 7.48131 8.32006 7.32264 8.2074 7.09264L7.9974 6.66797L7.7874 7.09331C7.67473 7.32264 7.45606 7.48131 7.20273 7.51797L6.7334 7.58597ZM9.0766 10.2567C8.9526 10.2567 8.8286 10.2267 8.71393 10.1661L7.99793 9.79072L7.28127 10.1667C7.01727 10.3047 6.70327 10.2827 6.46327 10.1081C6.2226 9.93272 6.1046 9.64205 6.1546 9.34872L6.29127 8.55139L5.71193 7.98672C5.49927 7.77939 5.42327 7.47472 5.5146 7.19205C5.6066 6.90872 5.8466 6.70539 6.1406 6.66272L6.94327 6.54539L7.30127 5.81939C7.4326 5.55272 7.69927 5.38672 7.99793 5.38672C8.29527 5.38672 8.5626 5.55272 8.69393 5.82005L9.0526 6.54539L9.85393 6.66272C10.1486 6.70539 10.3893 6.90872 10.4806 7.19205C10.5719 7.47472 10.4966 7.77939 10.2833 7.98739L9.70327 8.55205L9.8406 9.34872C9.8906 9.64272 9.77193 9.93405 9.5306 10.1087C9.3946 10.2067 9.23593 10.2567 9.0766 10.2567Z"
				fill={color}
			/>
		</svg>
	);
}
