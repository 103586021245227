import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import Routing from "./Router/Routing";
import { setOnline } from "./Redux/Actions/actionLocation";
import "./App.css";
import "./App.scss";
import ScrollToTop from "./Router/ScrollToTop";
import {
	connectToSocket,
	listenForCalls,
	ListenMessages,
} from "./Utils/socket";
import { messageReceived } from "./Redux/Actions/messageActions";
import accountChangeListener from "./Redux/actors/accountChangeListener";
import { history } from "./Router/history";
import * as NotificationsSocket from "./notificationsSocket";
import NotificationProvider from "./Components/Extras/Notification/NotificationProvider";
import AlertProvider from "./Components/Extras/Alert/AlertProvider";
import SessionTimeout from "./Components/IdleTimer/SessionTimeout";
import {
	CircularProgress,
	createTheme,
	ThemeProvider,
} from "@material-ui/core";
import { fetchProfile } from "./Redux/Actions/actionAuth";
import InternationalizationWrapper from "./Internationalization/IntlWrapper";
import NewCallNotification from "./Components/calls/NewCallNotification/NewCallNotification";
import { ThemeSelector } from "./Themes/ThemeSelector";

const Loader = (props) => {
	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				background: props.theme === "dark" ? "#121528" : "#fff",
			}}
		>
			<CircularProgress
				size="5rem"
				style={{
					color: props.theme === "dark" ? "#d9def4" : "#0d0f22",
				}}
			/>
		</div>
	);
};

const theme = createTheme({
	palette: {
		primary: {
			main: "#FF5B5B",
		},
		green: {
			main: "#F5F94A",
		},
		yellow: {
			main: "#FF5B5B",
		},
	},
});

class App extends React.Component {
	componentDidMount = () => {
		window.addEventListener("storage", accountChangeListener);
		window.addEventListener("online", () => {
			NotificationsSocket.connect(this.props.groupData);
		});
		window.addEventListener("offline", () => {
			NotificationsSocket.disconnect(this.props.groupData);
			this.props.setOnline({ value: false });
		});
		if (this.props.isAuthenticated) {
			connectToSocket(this.props.isAuthenticated);
			this.props.setOnline({ value: true });
			ListenMessages(this.props.newMessage);
			listenForCalls();
			NotificationsSocket.connect(this.props.groupData);
			this.props.fetchProfile();
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.groupData.id !== this.props.groupData.id) {
			NotificationsSocket.disconnect(this.props.groupData);
			NotificationsSocket.connect(this.props.groupData);
		}

		if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
			if (this.props.isAuthenticated) {
				connectToSocket(this.props.isAuthenticated);
				NotificationsSocket.connect(this.props.groupData);
			}
		}
	}

	render() {
		return (
			<ThemeSelector theme={this.props.theme}>
				<ThemeProvider theme={theme}>
					<Router history={history}>
						<Suspense
							fallback={<Loader theme={this.props.theme} />}
						>
							<ScrollToTop>
								<AlertProvider>
									<InternationalizationWrapper>
										<NotificationProvider>
											<SessionTimeout
												isAuthenticated={
													this.props.isAuthenticated
												}
											>
												<div
													className={`App ${
														this.props
															.isAuthenticated &&
														"full-height"
													} ${
														this.props.theme ===
														"dark"
															? "theme--dark"
															: "theme--default"
													}`}
													style={{
														background:
															this.props.theme ===
															"dark"
																? "#121528"
																: "#fff",
													}}
												>
													<Routing
														theme={this.props.theme}
													/>
													<div id="finish"></div>
													{this.props
														.isAuthenticated &&
														this.props
															.incomingCall && (
															<NewCallNotification
																open={
																	this.props
																		.incomingCall
																}
															/>
														)}
												</div>
											</SessionTimeout>
										</NotificationProvider>
									</InternationalizationWrapper>
								</AlertProvider>
							</ScrollToTop>
						</Suspense>
					</Router>
				</ThemeProvider>
			</ThemeSelector>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	newestNotification: state.notifications.newestData,
	incomingCall: state.videoCalls.incomingCall,
	groupData: state.groups?.groupData,
	theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
	setOnline: (payload) => dispatch(setOnline(payload)),
	newMessage: (payload) => dispatch(messageReceived(payload)),
	fetchProfile: () => dispatch(fetchProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
