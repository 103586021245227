import React from "react";

const AddIcon = ({ color }) => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.34375 6.5H11.6562"
				stroke={color}
				strokeLinecap="round"
				stroke-linejoin="round"
				strokeWidth="1.5"
			/>
			<path
				d="M6.5 1.34375V11.6562"
				stroke={color}
				strokeLinecap="round"
				stroke-linejoin="round"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export default AddIcon;
