import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { pure } from "recompose";

import styles from "./Liked.module.css";
import useAlert from "./../../../Extras/Alert/useAlert";
import axiosChatConfig from "../../../../Utils/axiosChatConfig";
import LikedMessageBox from "../Extra/LikedMessage/LikedMessageBox";
import {
	getLikedMessages,
	getStarredMessages,
	likeMessageURL,
	unstarMessageURL,
} from "../../../../Utils/Constants";

import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import CloseIconNew from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

function Liked({ open, category, ...props }) {
	const { showAlert } = useAlert();
	const [liked, setLiked] = useState([]);
	const [starred, setStarred] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [isLiked, setIsLiked] = useState(true);
	const [isLoading, setLoading] = useState(false);
	const theme = useSelector((state) => state.theme.theme);
	const userID = useSelector((state) => state.auth?.user_data?.userID);

	useEffect(() => {
		if (category === "liked") fetchLiked();
		if (category === "starred") fetchStarred();
	}, [category]);

	useEffect(() => {
		setIsLiked(category === "liked");
	}, [category]);

	useEffect(() => {
		if (category === "liked") searchText ? searchLikeMsg() : fetchLiked();
		if (category === "starred")
			searchText ? searchStarredMsg() : fetchStarred();
	}, [searchText]);

	const fetchStarred = () => {
		setLoading(true);
		axiosChatConfig
			.get(getStarredMessages)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setStarred(res.data.messages);
				setLoading(false);
			})
			.catch((err) => {
				showAlert("Please Try again later", {
					header: "Something went wrong",
					buttonText: "Go Back",
				});
			});
	};

	const fetchLiked = () => {
		setLoading(true);
		axiosChatConfig
			.get(getLikedMessages)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				setLiked(res.data.likedMessages);
				setLoading(false);
			})
			.catch((err) => {
				showAlert("Please Try again later", {
					header: "Something went wrong",
					buttonText: "Go Back",
				});
			});
	};

	const searchLikeMsg = () => {
		const searchLiked = liked.filter((value) => {
			return value.message
				.toLowerCase()
				.includes(searchText.toLowerCase());
		});
		setLiked(searchLiked);
	};

	const searchStarredMsg = () => {
		const searchSaved = starred.filter((value) => {
			return value.message
				.toLowerCase()
				.includes(searchText.toLowerCase());
		});
		setStarred(searchSaved);
	};

	const likeMessage = (id, groupId) => {
		const data = {
			groupID: groupId,
			messageID: id,
		};

		setLiked((prev) => prev.filter((message) => message._id !== id));

		axiosChatConfig
			.post(likeMessageURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const unstarMessage = (messageID) => {
		const data = { messageID };

		setStarred((prev) =>
			prev.filter((message) => message._id !== messageID)
		);

		axiosChatConfig
			.post(unstarMessageURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.status === 200) showAlert("Message unsaved");
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const Messages = () => {
		return (
			<>
				{isLoading ? (
					<div className={styles.loading}>
						<CircularProgress
							style={{
								margin: "auto",
								color: theme === "dark" ? "#7F89BE" : "#4A517E",
							}}
						/>
					</div>
				) : isLiked ? (
					liked.map((data) => (
						<div className={styles.message} key={data._id}>
							<LikedMessageBox
								data={data}
								key={data._id}
								isGroup={!!data.groupID}
								conversationID={
									userID === Number(data.from)
										? data.to
										: data.from
								}
								button={"Dislike"}
								func={() => likeMessage(data._id, data.groupID)}
								menu={true}
							/>
						</div>
					))
				) : (
					starred.map((data) => (
						<div className={styles.message} key={data._id}>
							<LikedMessageBox
								data={data}
								key={data._id}
								isGroup={!!data.groupID}
								conversationID={
									userID === Number(data.from)
										? data.to
										: data.from
								}
								button={"Unsave"}
								func={() => unstarMessage(data._id)}
								menu={true}
							/>
						</div>
					))
				)}
			</>
		);
	};

	const Text = isLiked ? "liked" : "saved";

	const isMobile = window.screen.width < 768;

	return (
		<>
			{!isMobile && open && (
				<div className={styles.container}>
					<div className={styles.header}>
						<IconButton
							size="small"
							style={{ margin: "0" }}
							onClick={props.handleClose}
						>
							<CloseIconNew
								style={{ color: "#7F89BE", fontSize: "1.5rem" }}
								fontSize="small"
							/>
						</IconButton>
						<span className={styles.select}>
							{isLiked ? "Liked " : "Saved "} messages
						</span>
					</div>

					<form>
						<div className={styles.searchContainer}>
							<img src="/Search.svg" className="m-2 " alt="" />
							<input
								type="text"
								className={styles.search}
								placeholder={`Search ${Text} messages`}
								onChange={(e) => setSearchText(e.target.value)}
								value={searchText}
							/>
							{searchText && (
								<IconButton
									size="small"
									onClick={() => setSearchText("")}
								>
									<CloseIcon
										style={{ color: "#ddd" }}
										fontSize="small"
									/>
								</IconButton>
							)}
						</div>
					</form>

					<div className={styles.status}>
						<div
							className={
								theme === "dark"
									? styles.resultDivDk
									: styles.resultDivLg
							}
						>
							{Messages()}
						</div>
					</div>
				</div>
			)}
			{isMobile && (
				<>
					<form>
						<div className={styles.searchContainer}>
							<img src="/Search.svg" className="m-2 " alt="" />
							<input
								type="text"
								className={styles.search}
								placeholder={`Search ${Text} messages`}
								onChange={(e) => setSearchText(e.target.value)}
								value={searchText}
							/>
							{searchText && (
								<IconButton
									size="small"
									onClick={() => setSearchText("")}
								>
									<CloseIcon
										style={{ color: "#ddd" }}
										fontSize="small"
									/>
								</IconButton>
							)}
						</div>
					</form>
					<div className={styles.status}>
						<div
							className={
								theme === "dark"
									? styles.resultDivDk
									: styles.resultDivLg
							}
						>
							{Messages()}
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default pure(Liked);
