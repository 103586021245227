export default {
  ENV: "production",
  DJANGO_API_BASE_URL: process.env.REACT_APP_DJANGO_API_URL || "https://api.bditto.com",
  DJANGO_API_RESOURCE_URL: "/api",
  CHAT_API: process.env.REACT_APP_CHAT_API || "https://chats.bditto.com",
  GOOGLE_CLIENT_ID: "30849691257-6umq7k45i6r7rb2n8i4d86m4rjni870u.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "705486270306718",
  TWITTER: {
    KEY: "vt0JXLD4Dsd8l8CkauurZCwJV",
    SECRET: "W3ZZ55MHL9N8osN0XJR1ZFYhyx5JqPEpn03RyoMdmtFVSbAWDa",
  },
  NOTIFICATION_SOCKET_URL:
    process.env.REACT_APP_NOTIFICATION_SOCKET_URL || "wss://api.bditto.com:8001/ws/notifications/",
};
