let prev = null;

const accountChangeListener = () => {
  const authToken = localStorage.getItem("authToken");
  if (authToken && prev !== authToken) {
    if (prev) {
      prev = authToken;
      window.location.reload();
    } else {
      prev = authToken;
    }
  }
};

export default accountChangeListener;
