import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { scroller, Element as ScrollElement } from "react-scroll";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

import InputBox from "../../Extra/InputBox/InputBox";
import PrivateInfo from "../../GroupInfo/PrivateInfo/PrivateInfo";
import MessageBox from "../../Extra/MessageBox";

import * as Socket from "../../../../../Utils/socket";
import axiosConfig from "../../../../../Utils/axiosConfig";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import withAlert from "../../../../Extras/Alert/withAlert";
import {
	getUserProfileURL,
	getConversationID,
	getPersonalChatsURL,
} from "../../../../../Utils/Constants";
import {
	MoreVert,
	Close,
	MoreHoriz as MoreHorizIcon,
	ArrowBackIos,
	Search,
} from "@material-ui/icons";

import styles from "./PrivateChat.module.css";
import SearchInConv from "../../SearchInConv/SearchInConv";
import throttle from "lodash/throttle";
import { IconButton, Slide, Tooltip, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { getUrlParams } from "../../../../../Utils/useQuery";
import { clearUnReadMessageCount } from "../../../../../Redux/Actions/messages/unreadMessageActions";
import Username from "../../../../Extras/Username";
import OnlineBadge from "../../../../Extras/StyledBadge";
import MediaQuery from "react-responsive";
import { Info, Videocall, Audiocall } from "../../../../../Icons";
import { FormattedMessage } from "react-intl";
import {
	deleteMesage,
	removeFromQueue,
} from "../../../../../Redux/Actions/messages/actions";
import MessageForwardDialog from "../../Extra/MessageForwardDialog/MessageForward";
import {
	Call,
	MoreVertOutlined,
	Videocam,
	VideocamOutlined,
} from "@material-ui/icons";
import { toggleVideo } from "../../../../../Redux/Actions/videoCalls/actions";
import CloseIcon from "@material-ui/icons/Close";
import PhoneCallIcon from "../../../../../Icons/PhoneCallIcon";
import VideoCallIcon from "../../../../../Icons/VideoCallIcon";

const useStyles = (theme) => ({
	avatar: {
		width: "2.5rem",
		height: "2.5rem",
	},
	online: {
		position: "absolute",
		top: "-0.2rem",
		left: "-0.2rem",
		fontSize: "1rem",
		color: "rgb(81 202 46)",
	},
	offline: {
		position: "absolute",
		top: "-0.2rem",
		left: "-0.2rem",
		fontSize: "1rem",
		color: "#bfbfbf",
	},

	callButtons: {
		padding: "0.4rem",
		// border: "2px solid black",
		margin: "0 0.45rem",
		// color: "black",
	},
	// voiceCall: { color: "#42DA93", borderColor: "#42DA93" },
});

class PrivateChat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			groupLoading: true,
			chatLoading: false,

			chats: [],
			userID: this.props.chatID,
			chatID: "",

			userMessage: "",
			replyMessage: null,
			memberTyping: "",
			moreChats: true,

			online: false,
			chatPrivateInfo: {},
			replyHighlight: "",

			isLiked: false,
			isSaved: false,
			showSearch: false,
			activeTab: 1,

			forwardMessage: { open: false, messageID: null },
		};

		this.timer = null;
		this.typingTimer = null;
		this.scrollRef = React.createRef();
	}

	componentDidMount = () => {
		const urlParams = getUrlParams(this.props.location.search);
		this.getPersonalInfo(this.state.userID, urlParams.highlight);
		Socket.listenTyping(this.setMemberTyping);
		Socket.listenReads(this.listenReads);
	};

	listenReads = (data) => {
		if (data.conversationID === this.state.chatID) {
			this.setState((prev) => ({
				chats: prev.chats.map((chat) => ({ ...chat, seen: true })),
			}));
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { chatID, onlineMessage } = this.props;
		const urlParams = getUrlParams(this.props.location.search);
		const prevUrlParams = getUrlParams(prevProps.location.search);
		if (chatID !== prevState.userID && !urlParams.highlight) {
			this.setState({ userID: chatID });
			this.props.handleRightBar({ searchConv: false, chatInfo: false });

			this.getPersonalInfo(chatID);
		} else {
			if (chatID !== prevState.userID) {
				this.setState({ userID: chatID });
				this.props.handleRightBar({
					searchConv: false,
					chatInfo: false,
				});

				this.getPersonalInfo(chatID, urlParams.highlight);
			} else if (
				chatID === prevState.userID &&
				urlParams.highlight !== prevUrlParams.highlight
			) {
				this.highlightReply(urlParams.highlight);
			}
		}

		if (onlineMessage && onlineMessage !== prevProps.onlineMessage) {
			if (onlineMessage.isOnline !== this.state.online)
				this.setState({ online: onlineMessage.isOnline });
		}

		if (prevProps.newChats.length < this.props.newChats.length) {
			Socket.emitRead(
				this.state.chatID,
				this.props.newChats[0].message._id,
				false
			);
		}
	};

	listenMessageDeletes = (deletedMessage) => {
		this.setState((prev) => ({
			chats: prev.chats.map((chat) =>
				deletedMessage.messageID !== chat._id
					? chat
					: { ...chat, isDeleted: true }
			),
		}));
		this.props.deleteMessage(deletedMessage.messageID, this.state.chatID);
	};

	fetchChats = throttle((ID, skip, requiredMessageId, append, callback) => {
		this.setState({ chatLoading: true });

		const data = { conversationID: ID, skip };
		if (requiredMessageId) {
			data["requiredMessageID"] = requiredMessageId;
		}

		axiosChatConfig
			.post(getPersonalChatsURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				if (this.state.chats.length === 0) {
					Socket.emitRead(this.state.chatID, "", false);
					this.props.clearUnreadMessageCount(this.state.userID);
				}
				this.setState(
					{
						chats: append
							? [...this.state.chats, ...res.data.chats]
							: res.data.chats,
						chatLoading: false,
						moreChats: res.data.chats.length > 0,
					},
					() => {
						if (callback) {
							callback();
						}
					}
				);
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response;

					this.props.showAlert(catchError.error, {
						buttonText: "Go Back",
					});
				}

				this.setState({ chatLoading: false });
			});
	}, 500);

	setMemberTyping = (data) => {
		if (data.conversationID !== this.state.chatID) return;

		if (!this.state.memberTyping) {
			this.setState({ memberTyping: data.message });

			this.timer = setTimeout(
				() => this.setState({ memberTyping: "" }),
				2000
			);
		} else if (this.state.memberTyping === data.message) {
			clearTimeout(this.timer);
			this.timer = setTimeout(
				() => this.setState({ memberTyping: "" }),
				2000
			);
		}
	};

	getPersonalInfo = (ID, highlightMessageID) => {
		this.setState({ groupLoading: true });
		const form = {
			friendID: ID,
		};

		axiosChatConfig
			.post(getConversationID, form)
			.then((res) => {
				if (res.data.conversationID) {
					this.setState({
						chatID: res.data.conversationID,
						chats: [],
						friendshipExists: res.data.friendshipExists,
					});
					this.props.clearFromQueue(res.data.conversationID);
					if (highlightMessageID) {
						this.fetchChats(
							res.data.conversationID,
							0,
							highlightMessageID,
							false,
							() => {
								this.setState({
									replyHighlight: highlightMessageID,
								});
								this.scrollToMessage(highlightMessageID);
							}
						);
					} else {
						this.fetchChats(res.data.conversationID, 0);
					}
					Socket.listenMessageDeletes(
						this.listenMessageDeletes,
						res.data.conversationID
					);
					const form = new FormData();

					form.append("userId", ID);

					axiosConfig
						.post(getUserProfileURL, form)
						.then((res) => {
							if (res.status !== 200) throw new Error();

							this.setState({
								chatPrivateInfo: res.data.body,
								groupLoading: false,
								online: res.data.body.online,
							});
						})
						.catch((err) => {
							if (err.response) {
								const catchError = err.response.data;

								this.props.showAlert(catchError.error, {
									buttonText: "Go Back",
									callback: () =>
										this.props.history.push("/"),
								});
							}
						});
				} else {
					this.props.showAlert(res.data.message, {
						buttonText: "Go Back",
						callback: () => this.props.history.push("/"),
					});
					return;
				}
			})

			.catch((err) => {
				this.props.history.push("/");
				this.setState({ groupLoading: false });
			});
	};

	showGroupInfo = () => {
		this.props.handleRightBar({
			searchConv: false,
			chatInfo: !this.props.chatInfoOpen,
		});
	};

	setJoinSuccess = () => {
		let temp = this.state.chatGroupInfo;

		temp.isMember = true;

		this.setState({
			chatGroupInfo: temp,
		});
	};

	scrollToBottom = () => {
		this.scrollRef.current.scrollIntoView({ behavior: "smooth" });
	};

	handleSearchConv = () => {
		this.props.handleRightBar({
			chatInfo: false,
			searchConv: !this.props.searchOpen,
		});
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	handleScroll = (event) => {
		const bottom =
			event.target.scrollHeight + event.target.scrollTop <
			event.target.clientHeight + 2;

		if (bottom && this.state.moreChats) {
			this.fetchChats(
				this.state.chatID,
				this.state.chats.length,
				null,
				true
			);
		}
	};

	handleSeeConvList = () => {};

	isMessagePresent = (messageId) => {
		return this.state.chats.some((message) => message._id === messageId);
	};

	scrollToMessage = (id) => {
		scroller.scrollTo(id, {
			containerId: "chatbox",
			smooth: true,
			offset: -100,
		});
		setTimeout(() => this.setState({ replyHighlight: "" }), 3000);
	};

	highlightReply = (replyID) => {
		this.setState({ replyHighlight: replyID });
		if (this.isMessagePresent(replyID)) {
			this.scrollToMessage(replyID);
		} else {
			this.fetchChats(
				this.state.chatID,
				this.state.chats.length,
				replyID,
				true,
				() => {
					this.scrollToMessage(replyID);
				}
			);
		}
	};

	displayChats = () => {
		const { chatLoading, groupLoading } = this.state;
		const chats = this.state.chats;

		return (
			<div
				className={
					this.props.theme === "dark"
						? styles.mainChatBoxDk
						: styles.mainChatBoxLG
				}
				onScroll={this.handleScroll}
				id="chatbox"
			>
				<div ref={this.scrollRef} className={styles.typing}>
					{this.state.memberTyping}
				</div>
				{!this.state.chatLoading &&
					!this.state.groupLoading &&
					this.props.newChats.map((val, key) => {
						const { message } = val;
						return (
							<ScrollElement
								name={message._id}
								className={`${styles.messageCont}`}
							>
								<MessageBox
									highlightReply={this.highlightReply}
									data={message}
									isHighlighted={
										message._id &&
										this.state.replyHighlight ===
											message._id
									}
									showUsername={
										key < this.props.newChats.length - 2
											? this.props.newChats[key + 1]
													?.type !== "INFO"
												? this.props.newChats.length[
														key
												  ]?.message?.from !==
												  this.props.newChats.length[
														key + 1
												  ]?.message?.from
												: true
											: chats.length > 0
											? this.props.newChats[key]?.message
													?.from != chats[0].from
											: true
									}
									showAvatar={
										key !== this.props.newChats.length - 1
											? key > 0
												? this.props.newChats[key]
														.message.from !==
												  this.props.newChats[key - 1]
														.message.from
												: true
											: chats.length > 0
											? this.props.newChats[key].message
													.from === chats[0].from
											: true
									}
									key={message._id || message.hash}
									listenMessageDeletes={
										this.listenMessageDeletes
									}
									forwardMessage={() =>
										this.openMessageForward(val._id)
									}
									isGroup={false}
									chatID={this.state.chatID}
									isMember={true}
									setReply={(data) =>
										this.setState({ replyMessage: data })
									}
								/>
							</ScrollElement>
						);
					})}
				{chats.map((val, key) => {
					return (
						<ScrollElement
							name={val._id}
							className={`${styles.messageCont}`}
						>
							<MessageBox
								highlightReply={this.highlightReply}
								data={val}
								isHighlighted={
									this.state.replyHighlight === val._id
								}
								isMember
								showUsername={
									key < chats.length - 1
										? chats[key + 1].type !== "INFO"
											? chats[key].from !==
											  chats[key + 1].from
											: true
										: true
								}
								showAvatar={
									key > 0
										? chats[key].from !==
										  chats[key - 1].from
										: this.props.newChats.length === 0 ||
										  this.props.newChats[
												this.props.newChats.length - 1
										  ].message.from != chats[key].from
								}
								key={val._id}
								listenMessageDeletes={this.listenMessageDeletes}
								isGroup={false}
								chatID={this.state.chatID}
								forwardMessage={() => {
									this.openMessageForward(val._id);
								}}
								setReply={(data) =>
									this.setState({ replyMessage: data })
								}
							/>
						</ScrollElement>
					);
				})}
				<Slide
					direction="down"
					in={chatLoading || groupLoading}
					mountOnEnter
					unmountOnExit
				>
					<div className={styles.loaderContainer}>
						<CircularProgress
							size="2.5rem"
							thickness="5"
							style={{ color: "#ff5b5b" }}
							className={styles.loader}
						/>
					</div>
				</Slide>
			</div>
		);
	};

	startVoiceCall = (e) => {
		e.stopPropagation();
		Socket.privateVideoCall(this.state.chatID, "AUDIO", (err, res) => {
			if (err) {
				this.props.showAlert(err.message, {
					buttonText: "close",
				});
				return;
			}
			if (res.callState === "PLACED") {
				this.props.history.push(
					`/call?callID=${res.callID}&startCall=true&userID=${this.state.userID}&username=${this.state.chatPrivateInfo?.user?.username}&type=AUDIO`
				);
			} else {
				this.props.showAlert(res.message);
			}
		});
	};

	startVideoCall = (e) => {
		e.stopPropagation();
		Socket.privateVideoCall(this.state.chatID, "VIDEO", (err, res) => {
			if (err) {
				this.props.showAlert(err.message, {
					buttonText: "close",
				});
				return;
			}
			if (res.callState === "PLACED") {
				this.props.history.push(
					`/call?callID=${res.callID}&startCall=true&userID=${this.state.userID}&username=${this.state.chatPrivateInfo?.user?.username}&type=VIDEO`
				);
			} else {
				this.props.showAlert(res.message);
			}
		});
	};

	closeMessageForward = () => {
		this.setState({
			forwardMessage: {
				open: false,
				// messageID: null
			},
		});
	};

	openMessageForward = (messageID) => {
		this.setState({ forwardMessage: { open: true, messageID } });
	};

	render() {
		const { classes } = this.props;
		const { chatPrivateInfo, online } = this.state;

		const isMobile = window.screen.width < 768;

		const tabs = [
			{
				id: 1,
				label: "Messages",
			},
			{
				id: 2,
				label: "Info",
			},
			{
				id: 3,
				label: "Files",
			},
		];

		const MobTabHandler = (tab) => {
			this.setState({ activeTab: tab.id });
			if (tab.id === 2) {
				this.props.handleRightBar({
					searchConv: false,
					chatInfo: true,
				});
			}
		};

		return (
			<>
				{!isMobile ? (
					<div className={styles.container}>
						{chatPrivateInfo.user?.username && (
							<Helmet>
								<title>
									{`${chatPrivateInfo.user.username} | bditto`}{" "}
								</title>
							</Helmet>
						)}
						<div
							className={
								this.props.searchOpen || this.props.chatInfoOpen
									? styles.chatBoxHalf
									: styles.chatBox
							}
						>
							{/* <div className={styles.buttons}>
            <div className={styles.chatButton} onClick={this.handleSeeConvList}>
              <Link to="/chat">
                <ArrowBackIcon style={{ color: "#ff5b5b" }} />
              </Link>
            </div>

            <div className={styles.textIcon} onClick={this.handleSearchConv}>
              <SearchIcon />
              <p>Search in conversation</p>
            </div>
          </div> */}
							<hr
								className={`${styles.titletop_breakline} ${
									this.props.theme === "dark"
										? styles.darkhr
										: styles.lghr
								}`}
							/>
							<div
								className={styles.title}
								// onClick={this.showGroupInfo}
							>
								<OnlineBadge invisible={!online}>
									<Avatar
										style={{
											background:
												this.props.theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											color:
												this.props.theme === "dark"
													? "#0d0f22"
													: "#d9def4",
										}}
										className={classes.avatar}
										src={chatPrivateInfo.avatar}
									>
										{chatPrivateInfo.full_name &&
											chatPrivateInfo?.full_name[0].toUpperCase()}
									</Avatar>
								</OnlineBadge>

								<h1
									className={`${styles.content} ${
										this.props.theme === "dark"
											? styles.darktext
											: styles.lgtext
									}`}
								>
									{chatPrivateInfo.user && (
										<Username
											value={
												chatPrivateInfo.user.username
											}
										/>
									)}
								</h1>
								<IconButton
									onClick={this.startVideoCall}
									className={classes.callButtons}
									size="small"
								>
									<VideoCallIcon
										color={
											this.props.theme === "dark"
												? "#d9def4"
												: "#0d0f22"
										}
									/>
								</IconButton>
								<IconButton
									onClick={this.startVoiceCall}
									className={`${classes.callButtons} ${classes.voiceCall}`}
									size="small"
								>
									<PhoneCallIcon
										color={
											this.props.theme === "dark"
												? "#d9def4"
												: "#0d0f22"
										}
									/>
								</IconButton>
								<IconButton
									onClick={this.handleSearchConv}
									className={`${classes.callButtons} `}
									size="small"
								>
									{/* <SearchIcon
								className={styles.textIcon}
								style={{
									color:
										this.props.theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							/> */}
									{this.props.theme === "dark" ? (
										<img src="/chat/Search-dk.svg" alt="" />
									) : (
										<img src="/chat/Search-lg.svg" alt="" />
									)}
								</IconButton>
								<IconButton
									onClick={this.showGroupInfo}
									className={`${classes.callButtons} ${classes.voiceCall}`}
									size="small"
								>
									{this.props.chatInfoOpen ? (
										<CloseIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#d9def4"
														: "#0d0f22",
											}}
										/>
									) : (
										<MoreVertOutlined
											style={{
												color:
													this.props.theme === "dark"
														? "#d9def4"
														: "#0d0f22",
											}}
										/>
									)}
								</IconButton>
								{/* <div className={styles.textIcon} onClick={this.handleSearchConv}>
              <SearchIcon />
            </div> */}

								{/* <MediaQuery minDeviceWidth={768}>
              <Tooltip title={<FormattedMessage id="groupInfo" defaultMessage={`group info`} />}>
                <Info.Outline viewBox="0 0 37 37" fontSize="large" />
              </Tooltip>
            </MediaQuery> */}
							</div>
							<hr
								className={`${styles.titletop_breakline} ${
									this.props.theme === "dark"
										? styles.darkhr
										: styles.lghr
								}`}
							/>

							{this.displayChats()}

							<InputBox
								chatID={this.state.chatID}
								isGroup={false}
								userID={this.state.userID}
								chatType={this.state.chatType}
								friendshipExists={this.state.friendshipExists}
								groupLoading={this.state.groupLoading}
								isMember={
									chatPrivateInfo &&
									chatPrivateInfo.friendship === "Friends"
								}
								setJoinSuccess={this.setJoinSuccess}
								replyMessage={this.state.replyMessage}
								resetReply={() => {
									this.setState({ replyMessage: null });
								}}
							/>
						</div>

						<PrivateInfo
							show={this.props.chatInfoOpen}
							isLoading={this.state.groupLoading}
							data={chatPrivateInfo}
							conversationID={this.state.chatID}
							hideSlider={this.showGroupInfo}
						/>

						<MessageForwardDialog
							{...this.state.forwardMessage}
							chatID={this.state.chatID}
							closeModal={this.closeMessageForward}
							isGroup={false}
						/>

						<SearchInConv
							show={this.props.searchOpen}
							ID={this.state.chatID}
							type={this.state.chatType}
							resetLiked={this.resetLiked}
							handleSearchConv={this.handleSearchConv}
							highlightMessage={this.highlightReply}
						/>
					</div>
				) : (
					<>
						<Dialog
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							open={true}
							classes={{
								paper:
									this.props.theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							<div className={styles.container}>
								{chatPrivateInfo.user?.username && (
									<Helmet>
										<title>
											{`${chatPrivateInfo.user.username} | bditto`}
										</title>
									</Helmet>
								)}
							</div>
							<div className={styles.mobChatNav}>
								<div className="d-flex align-content-center">
									<span className="d-flex align-items-center mr-3">
										<ArrowBackIos
											onClick={() => {
												this.props.history.push("/");
											}}
										/>
									</span>

									<OnlineBadge invisible={!online}>
										<Avatar
											style={{
												background:
													this.props.theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											className={classes.avatar}
											src={chatPrivateInfo.avatar}
										>
											{chatPrivateInfo.full_name &&
												chatPrivateInfo?.full_name[0].toUpperCase()}
										</Avatar>
									</OnlineBadge>

									<span
										className={`${styles.author} ${
											this.props.theme === "dark"
												? styles.darktext
												: styles.lgtext
										} d-flex align-items-center mx-2`}
									>
										{chatPrivateInfo.user && (
											<Username
												value={
													chatPrivateInfo.user
														.username
												}
											/>
										)}
									</span>
								</div>
								<div>
									<span className="mr-3">
										<Search />
									</span>
									<span>
										<img src="/Mobile/Call.svg" alt="" />
									</span>
								</div>
							</div>

							<div className={styles.thoughtsTab}>
								{tabs.map((tab, i) =>
									tab.id === this.state.activeTab ? (
										<div className={styles.activeMobTab}>
											{tab.label}
										</div>
									) : (
										<div
											onClick={() => MobTabHandler(tab)}
											className={styles.mobtab}
										>
											{tab.label}
										</div>
									)
								)}
							</div>

							{this.state.activeTab === 1 && (
								<>
									{this.displayChats()}
									<InputBox
										chatID={this.state.chatID}
										isGroup={false}
										userID={this.state.userID}
										chatType={this.state.chatType}
										friendshipExists={
											this.state.friendshipExists
										}
										groupLoading={this.state.groupLoading}
										isMember={
											chatPrivateInfo &&
											chatPrivateInfo.friendship ===
												"Friends"
										}
										setJoinSuccess={this.setJoinSuccess}
										replyMessage={this.state.replyMessage}
										resetReply={() => {
											this.setState({
												replyMessage: null,
											});
										}}
									/>
								</>
							)}

							{(this.state.activeTab === 2 ||
								this.state.activeTab === 3) && (
								<PrivateInfo
									show={this.props.chatInfoOpen}
									isLoading={this.state.groupLoading}
									data={chatPrivateInfo}
									conversationID={this.state.chatID}
									hideSlider={this.showGroupInfo}
									activeTab={this.state.activeTab}
								/>
							)}
						</Dialog>
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	newChats: state.messages.dispatchQueue.filter(
		(message) => message.userID == ownProps.chatID && !message.isGroup
	),
	theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
	clearUnreadMessageCount: (id) =>
		dispatch(clearUnReadMessageCount(id, false)),
	clearFromQueue: (chatID) => dispatch(removeFromQueue(chatID)),
	deleteMessage: (messageID, conversationID) =>
		dispatch(deleteMesage(messageID, conversationID)),
	toggleVideo: () => dispatch(toggleVideo()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(withRouter(withAlert(PrivateChat))));
