import React, { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Avatar from "@material-ui/core/Avatar";

import styles from "./Private.module.css";
import Username from "../../../../Extras/Username";
import isToday from "date-fns/isToday";
import formatDate from "date-fns/format";
import isYesterday from "date-fns/isYesterday";

const Private = forwardRef((props, ref) => {
	const { data, newMessage, chatID } = props;

	const [username, setUsername] = useState(null);
	const [lastMessage, setLastMessage] = useState({});

	const count = useSelector(
		(state) => state.messages.unreadMessages[data.userID + "private"]
	);
	const theme = useSelector((state) => state.theme.theme);
	const { userData } = useSelector((state) => ({
		userData: state.auth.user_data,
	}));

	useEffect(() => {
		setUsername(data.username);
		if (data.lastMessage) {
			const username =
				userData?.userID === Number(data.lastMessage.from)
					? "You"
					: data.username;
			setLastMessage({ ...data.lastMessage, username });
		}
	}, []);

	useEffect(() => {
		if (newMessage) {
			const latestMessage = newMessage[0];
			if (data.conversationID === latestMessage.conversationID) {
				setLastMessage(latestMessage.message);
			}
		}
	}, [newMessage]);

	function getMessagePreview() {
		let messagePreview;
		if (lastMessage.type) {
			const mediaMessageLabel = lastMessage.type;
			// lastMessage.type !== "IMAGE" || lastMessage.type !== "TEXT" || lastMessage.type !== "AUDIO"
			//   ? "FILE"
			//   : lastMessage.type;
			const username =
				lastMessage.from == userData?.userID ? "You" : data.username;
			messagePreview = username + ": ";
			messagePreview += !lastMessage.isDeleted
				? decodeURIComponent(escape(lastMessage.message)) ||
				  mediaMessageLabel
				: "This message is deleted";
		} else {
			messagePreview = "Click Here to start chatting";
		}
		return messagePreview;
	}

	const lastMessageDate =
		lastMessage.type && new Date(lastMessage?.timeStamp);

	return (
		<Link
			ref={ref}
			to={`/chat/private/${data.userID}`}
			style={{ height: "fit-content" }}
		>
			<div
				className={`${styles.container} ${
					props.isActive && styles.activeChat
				}`}
			>
				<Avatar
					style={{
						background: theme === "dark" ? "#d9def4" : "#0d0f22",
						color: theme === "dark" ? "#0d0f22" : "#d9def4",
					}}
					src={data.profileURL}
					alt={data.username}
				>
					{data.username[0].toUpperCase()}
				</Avatar>

				<div className={styles.namemessage}>
					<h1
						className={`${styles.heading} ${
							theme === "dark" ? styles.darkPText : styles.lgPText
						}`}
					>
						<Username value={username} />
					</h1>
					<p
						className={`${styles.lastMessage} ${
							theme === "dark" ? styles.darkSText : styles.lgSText
						}`}
					>
						{getMessagePreview()}
					</p>
				</div>

				<div
					className={`${styles.privatedate} ${
						theme === "dark" ? styles.darkSText : styles.lgSText
					}`}
				>
					{lastMessage.type && (
						<p className={styles.date}>
							{isToday(lastMessageDate)
								? formatDate(lastMessageDate, "hh:mm a")
								: isYesterday(lastMessageDate)
								? "Yesterday"
								: formatDate(lastMessageDate, "dd/MM/yyyy")}
						</p>
					)}
					{count > 0 && !props.isActive && chatID !== data.userID && (
						<p
							className={`${styles.newMessage} ${
								theme === "dark"
									? styles.darkGText
									: styles.lgGText
							}`}
						>
							{count}
						</p>
					)}
				</div>
			</div>
		</Link>
	);
});

export default Private;
