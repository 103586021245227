import React from "react";
import { useLocation } from "react-router-dom";

export const getUrlParams = (querystring) => {
  const urlSearchParams = new URLSearchParams(querystring);
  const URLParamsIterator = urlSearchParams.entries();
  const params = {};
  for (const [key, value] of URLParamsIterator) {
    params[key] = value;
  }
  return params;
};

const useQuery = () => {
  const location = useLocation();
  return getUrlParams(location.search);
};

export const withQuery = (Component) => (props) => {
  const queryParams = useQuery();
  return <Component queryParams={queryParams} {...props} />;
};

export default useQuery;
