import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function UserSkeleton(props) {
	const width = props.width ? props.width : "100%";

	return (
		<div
			style={{
				width,
				padding: "20px",
				display: "flex",
				alignItems: "center",

				// justifyContent: "space-between",
			}}
		>
			<Skeleton
				variant="circle"
				width={props.radius}
				height={props.radius}
			/>
			<div style={{ width: "70%", marginLeft: "10px" }}>
				<Skeleton variant="text" width="100%" height={10} />
				<Skeleton variant="text" width="80%" height={10} />
			</div>
		</div>
	);
}
