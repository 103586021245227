const carouselData = [
	{
		imgUri: "/images/carousel/gettingStarted.jpeg",
		heading: "Getting Started",
		subHeading:
			"Creating an account is easy. All you need is to Sign up an account with your email and activate it to join Bditto. No downloads needed. Bditto is a great place to have fun, share interests and get found.",
	},
	{
		imgUri: "/images/carousel/createStatus.jpeg",
		heading: "Create Status",
		subHeading:
			"Bditto connects people based on statuses. To get matched and discovered, simply go to “Thoughts” section, tap on “+ Add New Status” and enter your status ! No matter talking about life, travel, business, sports and health etc.., anything goes in Bditto.  You are set to start  interesting conversations.",
	},
	{
		imgUri: "/images/carousel/chat.jpeg",
		heading: "Chat",
		subHeading:
			"Start chatting with anyone matching your status. Chat is public and also one to one private chat is possible with connected friends.",
	},
	{
		imgUri: "/images/carousel/explore.jpeg",
		heading: "Explore",
		subHeading:
			"Explore world thoughts at one place and indulge in any conversation that interests you. We are in for open communication.",
	},
	{
		imgUri: "/images/carousel/likeShare.jpeg",
		heading: "Like, Favourite and share",
		subHeading:
			"Never miss any interesting conversations, you can always like and favourite them and they are available for you anytime.",
	},
	{
		imgUri: "/images/carousel/makeFriends.jpeg",
		heading: " Meet and make friends",
		subHeading:
			"Bditto is all about connecting people around the world. Make friends and meet people with similar thoughts and interests.",
	},
	{
		imgUri: "/images/carousel/searchFilter.jpeg",
		heading: "Search and Location Filter",
		subHeading:
			" Searching on Bditto is easier than ever. Find anything in seconds, like statuses, users or even search within conversations - with the tap of a button. Location filter allows you to find statuses nearby or by any specific location.",
	},
];

export default carouselData;
