import axiosConfig from "../../../Utils/axiosConfig";
import { socialLoginURLs } from "../../../Utils/Constants";
import ReduxStore from "../../../Redux/store";
import { setToken, socialLogin } from "../../../Redux/Actions/actionAuth";
import { socialLoginError } from "./../../../Redux/Actions/actionSocialLogin";
import Log from "../../../Utils/logger";

const onGoogleSuccess =
	({ onRequireSignUp }) =>
	async (res) => {
		if (res.tokenId) {
			try {
				const form = new FormData();
				form.append("auth_token", res.tokenId);
				const { data: userData, status } = await axiosConfig.post(
					socialLoginURLs.google,
					form
				);
				if (status !== 200) throw new Error();
				if (userData.userID !== undefined) {
					ReduxStore.dispatch(socialLogin(userData));
				} else {
					setToken(userData.token);
					onRequireSignUp();
				}
			} catch (err) {
				if (err.response) {
					const catchError = err.response.data;
					ReduxStore.dispatch(socialLoginError(catchError.detail));
				}
			}
		}
	};

const onGoogleFailure = (res) => {};

export const facebookCallback =
	({ onRequireSignUp }) =>
	async (res) => {
		Log.debug(res);
		if (res.accessToken) {
			try {
				const form = new FormData();

				form.append("auth_token", res.accessToken);

				const { data: userData, status } = await axiosConfig.post(
					socialLoginURLs.facebook,
					form
				);
				if (status !== 200) throw new Error();
				if (userData.userID !== undefined) {
					ReduxStore.dispatch(socialLogin(userData));
				} else {
					setToken(userData.token);
					onRequireSignUp();
				}
			} catch (err) {
				if (err.response) {
					const catchError = err.response.data;
					ReduxStore.dispatch(socialLoginError(catchError.detail));
				}
			}
		} else {
			let msg = "Could not log in";
			ReduxStore.dispatch(socialLoginError(msg));
		}
	};

export const googleCallbacks = {
	onSuccess: onGoogleSuccess,
	onFailure: onGoogleFailure,
};

export const twitterCallback =
	({ onRequireSignUp }) =>
	async (err, data) => {
		if (err) {
			const error = err.data;
			if (error) {
				ReduxStore.dispatch(
					socialLoginError(
						error.detail ||
							"Something Went Wrong! Please Try Later."
					)
				);
			}
		}
		try {
			if (data.userID !== undefined) {
				ReduxStore.dispatch(socialLogin(data));
			} else {
				setToken(data.token);
				onRequireSignUp();
			}
		} catch (err) {
			if (err.response) {
				const catchError = err.response.data;
				// alert(catchError.detail);
				ReduxStore.dispatch(socialLoginError(catchError.detail));
			}
		}
	};
