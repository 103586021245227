export default function Report({ theme }) {
	const mainColor = theme === "dark" ? "white" : "#D9DEF4";

	return (
		<svg
			width="26"
			height="27"
			viewBox="0 0 27 27"
			fill={mainColor}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 4V7M9 10V10.01M1 17V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H14C14.7956 1 15.5587 1.31607 16.1213 1.87868C16.6839 2.44129 17 3.20435 17 4V10C17 10.7956 16.6839 11.5587 16.1213 12.1213C15.5587 12.6839 14.7956 13 14 13H5L1 17Z"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
