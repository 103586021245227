import { combineReducers } from "redux";
import { NEW_MESSAGE } from "../Actions/actionTypes";
import authReducer from "./authReducer/auth";
import socialLoginReducer from "./authReducer/socialLogin";
import BookmarksReducer from "./Bookmarks";
import unReadMessagesReducer from "./ChatsReducer.js/unReadMessagesReducer";
import contactReducer from "./contactReducer";
import deviceParametersReducer from "./deviceParametersReducer";
import groupsReducer from "./groupsReducer";
import locationReducer from "./LocationReducer/Location";
import messagesReducer from "./messages";
import notificationsReducer from "./NotificationsReducer";
import { RequestsReducer } from "./NotificationsReducer/contactRequests";
import recentSearchesReducer from "./recentSearchesReducer";
import themeReducer from "./themeReducer";
import tutorialReducer from "./tutorialReducer/tutorialReducer";
import videoCallsReducer from "./videoCallsReducer";
import myGroupsReducer from "./myGroupsReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	socialLogin: socialLoginReducer,
	contacts: contactReducer,
	location: locationReducer,
	tutorial: tutorialReducer,
	unreadMessages: unReadMessagesReducer,
	deviceParameters: deviceParametersReducer,
	messages: messagesReducer,
	notifications: notificationsReducer,
	contactRequests: RequestsReducer,
	bookmarks: BookmarksReducer,
	videoCalls: videoCallsReducer,
	groups: groupsReducer,
	theme: themeReducer,
	myGroups: myGroupsReducer,
	recentSearch: recentSearchesReducer,
	unreadCount: (state = 0, action) => {
		switch (action.type) {
			case NEW_MESSAGE:
				const conversation = action.payload;
				if (
					Number(conversation.message.from) !== action.context.userID
				) {
					return state++;
				} else {
					return state;
				}
			default:
				return state;
		}
	},
});

export default rootReducer;
