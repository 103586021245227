import React, { useEffect, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import thought from "../../../assets/idea.svg";
import explore from "../../../assets/explore.svg";
import feed from "../../../assets/feed.svg";
import chat from "../../../assets/chat.svg";

import styles from "./MobileNavbar.module.css";
import AddStatusModal from "../../Extras/AddStatusModal/AddStatusModal";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ListenMessages } from "../../../Utils/socket";
import { connect } from "react-redux";
import { Badge, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

const StyledBadge = withStyles({
	badge: { top: 0, right: 15 },
	root: { width: "20%" },
})(Badge);

function MobileNavbar(props) {
	const [popAddStatus, setpopAddStatus] = useState(false);
	const [messageCount, setMessageCount] = useState(0);
	const history = useHistory();
	const path = useLocation().pathname;
	const [paths, setpath] = React.useState(0);
	const handleClick = (e) => {
		setpopAddStatus(true);
	};

	const messageListener = (data) => {
		if (data.message.username !== props.user_data.user.username) {
			setMessageCount((prev) => prev + 1);
		}
	};
	const clearMessageCount = () => setMessageCount(0);

	useEffect(() => {
		ListenMessages(messageListener);
	}, []);

	const setCloseAddStatus = () => {
		history.goBack(0);
		setpopAddStatus(false);
	};

	return (
		<>
			<div className={styles.bottomNav}>
				<Link to="/" className={styles.nav + " home"}>
					{path === "/" ? (
						<>
							<img
								src="/Mobile/BottomNavbar/activeThoughtsHub.svg"
								alt="thoughts"
							/>
							<p className={styles.activeNavText}>ThoughtsHub</p>
						</>
					) : (
						<>
							<img
								src="/Mobile/BottomNavbar/thoughtsHub.png"
								alt="thoughts"
							/>
							<p className={styles.navText}>ThoughtsHub</p>
						</>
					)}
				</Link>
				<Link to="/explore" className={styles.nav} id="Explore_mobile">
					{path.substring(0, 8) === "/explore" ? (
						<>
							<img
								src="/Mobile/BottomNavbar/activeExplore.svg"
								alt=""
							/>
							<p className={styles.activeNavText}>Explore</p>
						</>
					) : (
						<>
							<img
								src="/Mobile/BottomNavbar/explore.svg"
								alt=""
							/>
							<p className={styles.navText}>Explore</p>
						</>
					)}
				</Link>
				<Link to="/myfeed" className={styles.nav} id="newsfeed_mobile">
					{path === "/myfeed" ? (
						<>
							<img
								src="/Mobile/BottomNavbar/activeFeed.svg"
								alt=""
							/>
							<p className={styles.activeNavText}>Feed</p>
						</>
					) : (
						<>
							<img src="/Mobile/BottomNavbar/feed.svg" alt="" />
							<p className={styles.navText}>Feed</p>
						</>
					)}
				</Link>
				<Link to="/chat" className={styles.nav} id="Chats_mobile">
					{path === "/chat" || path === "/chat/" ? (
						<>
							<img
								src="/Mobile/BottomNavbar/activeChats.svg"
								alt=""
							/>
							<p className={styles.activeNavText}>Chats</p>
						</>
					) : (
						<>
							<img src="/Mobile/BottomNavbar/chats.svg" alt="" />
							<p className={styles.navText}>Chats</p>
						</>
					)}
				</Link>
				<Link to="/myprofile" className={styles.nav} id="Settings">
					{path === "/myprofile" ? (
						<>
							<span
								style={{
									padding: "0.2px",
									backgroundColor: "#6e88fc",
									borderRadius: "50%",
								}}
							>
								<Avatar
									style={{ width: "25px", height: "25px" }}
									src={props.user_data.avatar}
								/>
							</span>
							<p className={styles.activeNavText}>You</p>
						</>
					) : (
						<>
							<Avatar
								style={{ width: "25px", height: "25px" }}
								src={props.user_data.avatar}
							/>
							<p className={styles.navText}>You</p>
						</>
					)}
				</Link>
			</div>
			{/* <div className={styles.navbar}>
			<Link to="/" className={styles.btns}>
				<img width="22px" height="22px" src={thought} alt="thoughts" />
				<p className={styles.txt}>THOUGHTS</p>
			</Link>
			<Link
				to="/explore/thoughts"
				className={styles.btns}
				id="Explore_mobile"
			>
				<img src={explore} alt="explore" />
				<p className={styles.txt}>EXPLORE</p>
			</Link>
			<div
				className={styles.btns}
				variant="contained"
				onClick={(e) => handleClick(e)}
			>
				<div className={styles.circle} id="Add_Status_mobile">
					<AddIcon className={styles.plus} />
				</div>
			</div>
			<Link to="/myfeed" className={styles.btns} id="newsfeed_mobile">
				<img height="22px" width="22px" src={feed} alt="tags" />
				<p className={styles.txt}>MY FEED</p>
			</Link>

			<AddStatusModal show={popAddStatus} onHide={setCloseAddStatus} />
			<StyledBadge badgeContent={messageCount} color="error">
				<Link
					onClick={clearMessageCount}
					style={{ width: "100%" }}
					to="/chat"
					className={styles.btns}
					id="Chats_mobile"
				>
					<img src={chat} alt="chat" />
					<p className={styles.txt}>CHAT</p>
				</Link>
			</StyledBadge>
		</div> */}
		</>
	);
}

const mapStateToProps = (state) => ({
	user_data: state.auth.user_data,
	online: state.location.online,
});

export default connect(mapStateToProps)(MobileNavbar);
