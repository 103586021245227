import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import styles from "./Info.module.css";
import {
	ShareCopyLink,
	ShareSocial,
} from "../Home/Chats/Extra/ShareURL/Share.jsx";
import { Dialog, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Arrows from "../../Icons/Arrows";
import ShareAppIcon from "../../Icons/ShareAppIcon";

export default function ShareTheApp({ isMobile }) {
	const classes = makeStyles((theme) => ({
		dialog: {
			padding: "1.5rem 1.6rem",
			width: "100%",
			[theme.breakpoints.up("md")]: {
				width: "30vw",
			},
		},

		title: { paddingLeft: 0, marginTop: "-0.5rem" },
	}))();
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleOpen = () => setDialogOpen(true);
	const handleClose = () => setDialogOpen(false);
	const url = "https://www.bditto.com";
	const history = useHistory();
	return (
		<>
			<div className={styles.wrapper}>
				<Helmet>
					<title>{`Share | bditto`} </title>
				</Helmet>
				<div className={styles.container}>
					<div className={styles.heading}>
						<div className={styles.header}>
							<IconButton
								size="small"
								variant="contained"
								className={isMobile ? " " : styles.head}
								onClick={history.goBack}
							>
								<Arrows.Left viewBox="0 0 27 27" />
							</IconButton>

							<h2>Invite Your Friends</h2>
						</div>
						<div className={styles.rightWrapper}>
							<p
								className={styles.content1}
								style={{ textAlign: "justify" }}
							>
								Bditto is a great platform to share and
								collaborate and it is easy to create an online
								space where you and your friends or any users
								with similar interests can come together.
								Finding people who you know or finding people
								with similar interests is a lot easier on
								bditto. And bditto has all the information you
								need in one place, making collaboration simple
								and instant. You can invite your friends or
								anyone to participate in Bditto and have fun
								sharing thoughts.
							</p>
							<button
								onClick={handleOpen}
								className={styles.btn1}
							>
								Invite
							</button>
							<Dialog
								open={dialogOpen}
								aria-labelledby="share-dialog"
								onClose={handleClose}
								classes={{ paper: classes.dialog }}
							>
								{" "}
								<IconButton
									size="small"
									className={styles.shareClose}
								>
									<Close onClick={handleClose} />
								</IconButton>
								<DialogTitle
									id="share-dialog"
									classes={{ root: classes.title }}
								>
									<div className={styles.shareContainer}>
										<span className={styles.shareIcon}>
											<ShareAppIcon />
										</span>
										<span className={styles.shareText}>
											Share the fun by inviting your
											friends to the app.
										</span>
									</div>
								</DialogTitle>
								<div className={styles.shareSocialIcons}>
									<ShareSocial
										url={url}
										className={styles.ShareSocial}
										iconSize={40}
									/>
								</div>
								<div className={styles.shareSocialCopy}>
									<ShareCopyLink url={url} />
								</div>
							</Dialog>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
