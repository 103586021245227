import { ReactComponent as Right } from "../assets/icons/chats/ChevronRight.svg";
import { ReactComponent as Left } from "../assets/icons/chats/ChevronLeft.svg";
import { ReactComponent as LeftDark } from "../assets/icons/chats/ChevronLeftDark.svg";
import { ReactComponent as RedDown } from "../assets/icons/ChevronDown.svg";
import genIcons from "./genIcons";

const getFillForTheme = (theme) => (theme === "dark" ? "#D9DEF4" : "#4A517E");

const Chevron = {
	Right: function ({ theme }) {
		return (
			<svg
				width="10"
				height="18"
				viewBox="0 0 10 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 1.5L8.5 9L1 16.5"
					stroke={getFillForTheme(theme)}
					strokeWidth="2"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		);
	},
	Left: function ({ theme }) {
		return (
			<svg
				width="10"
				height="25"
				viewBox="0 0 10 25"
				style={{ marginRight: "1rem" }}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8.5 16L1 8.5L8.5 1"
					stroke={getFillForTheme(theme)}
					strokeWidth="2"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		);
	},
	leftDark: genIcons(LeftDark, { htmlColor: "transparent" }),
	Down: genIcons(RedDown, { htmlColor: "transparent" }),
};

export default Chevron;
