import React from "react";

const users = ({ theme }) => {
	const color = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4332 7.08475C10.2185 7.08475 10.0312 6.92667 10.0003 6.70792C9.96708 6.46817 10.1333 6.24708 10.3731 6.21383C10.9797 6.12867 11.4377 5.6025 11.4388 4.98883C11.4388 4.38042 11.0025 3.86592 10.4011 3.76733C10.1631 3.72825 10.0015 3.50308 10.0406 3.2645C10.0802 3.02592 10.3037 2.86608 10.5434 2.904C11.5689 3.07258 12.3138 3.94992 12.3138 4.99C12.3115 6.03708 11.5292 6.93542 10.4938 7.08067C10.4734 7.08358 10.453 7.08475 10.4332 7.08475Z"
				fill={color}
			/>
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="11"
				y="8"
				width="3"
				height="3"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.0703 8.16797H13.2835V10.8102H11.0703V8.16797Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.1689 10.8102C11.9927 10.8102 11.8265 10.7029 11.76 10.5285C11.6736 10.3027 11.7874 10.0495 12.0131 9.9638C12.4086 9.8133 12.4086 9.64705 12.4086 9.56713C12.4086 9.31163 12.0837 9.13313 11.4432 9.03747C11.2046 9.0013 11.0396 8.77905 11.0751 8.5393C11.1107 8.30013 11.3324 8.14088 11.5733 8.1718C12.9867 8.38355 13.2836 9.04738 13.2836 9.56713C13.2836 9.88447 13.1588 10.4637 12.3246 10.7816C12.2733 10.8009 12.2208 10.8102 12.1689 10.8102Z"
					fill={color}
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.93311 9.28125C5.54711 9.28125 3.89453 9.45158 3.89453 10.2624C3.89453 11.0797 5.54711 11.2512 6.93311 11.2512C8.31911 11.2512 9.97111 11.0814 9.97111 10.2723C9.97111 9.45333 8.31911 9.28125 6.93311 9.28125ZM6.93311 12.1262C5.96536 12.1262 3.01953 12.1262 3.01953 10.2624C3.01953 8.40625 5.96536 8.40625 6.93311 8.40625C7.90086 8.40625 10.8461 8.40625 10.8461 10.2723C10.8461 12.1262 8.00353 12.1262 6.93311 12.1262Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.93422 3.20703C5.94722 3.20703 5.14455 4.01028 5.14455 4.99728C5.1428 5.47561 5.32597 5.92303 5.66139 6.26078C5.9968 6.59853 6.44364 6.78578 6.91964 6.78753L6.93422 7.22503V6.78753C7.92122 6.78753 8.72447 5.98486 8.72447 4.99728C8.72447 4.01028 7.92122 3.20703 6.93422 3.20703ZM6.93422 7.66253H6.91847C6.2068 7.6602 5.54005 7.38136 5.04072 6.87736C4.54022 6.37395 4.26664 5.70545 4.26955 4.99553C4.26955 3.52786 5.4648 2.33203 6.93422 2.33203C8.40422 2.33203 9.59947 3.52786 9.59947 4.99728C9.59947 6.4667 8.40422 7.66253 6.93422 7.66253Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.43086 7.08486C3.41103 7.08486 3.39061 7.08369 3.3702 7.08078C2.33478 6.93553 1.55311 6.03719 1.55078 4.99128C1.55078 3.95003 2.2957 3.07269 3.3212 2.90411C3.56678 2.86561 3.78436 3.02719 3.82403 3.26461C3.86311 3.50319 3.70153 3.72836 3.46353 3.76744C2.86211 3.86603 2.42578 4.38053 2.42578 4.99011C2.42695 5.60261 2.88486 6.12936 3.49095 6.21394C3.7307 6.24719 3.89695 6.46828 3.8637 6.70803C3.83278 6.92678 3.64553 7.08486 3.43086 7.08486Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.69736 10.8101C1.64545 10.8101 1.59295 10.8008 1.54161 10.7815C0.706865 10.463 0.582031 9.88376 0.582031 9.56701C0.582031 9.04785 0.878948 8.38343 2.29295 8.17168C2.53386 8.14134 2.75436 8.30001 2.79053 8.53918C2.82611 8.77893 2.66103 9.00118 2.42245 9.03735C1.78195 9.13301 1.45703 9.31151 1.45703 9.56701C1.45703 9.64693 1.45703 9.8126 1.85311 9.96368C2.07886 10.0494 2.19261 10.3026 2.10628 10.5283C2.03978 10.7028 1.87353 10.8101 1.69736 10.8101Z"
				fill={color}
			/>
		</svg>
	);
};

export default users;
