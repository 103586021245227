import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axiosConfig from "../../../../Utils/axiosConfig";
import { turnedOffUsers } from "../../../../Utils/Constants";
import EmptyState from "../../../Extras/EmptyState";
import SmallCardSkeleton from "../../../Skeleton/SmallCardSkeleton/SmallCardSkeleton";
import styles from "../Contacts.module.css";
import { TurnedOffCard } from "./TurnedOffCards";

export default function Requested(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [isRequestsLoaded, setRequestsLoaded] = useState(false);

	useEffect(() => {
		getTurnedOffUsers();
	}, [isRequestsLoaded]);

	const getTurnedOffUsers = () => {
		if (isRequestsLoaded) {
			return;
		}
		setIsLoading(true);
		axiosConfig
			.get(turnedOffUsers)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				let data = res.data.result || res.data.body;
				setUsers(data);
				setRequestsLoaded(true);
				setIsLoading(false);
			})
			.catch((err) => {});
	};

	const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });

	// const filterBySearchTerm = (searchText) =>
	// 	Requests.filter((friend) =>
	// 		friend.friend.username
	// 			.toLowerCase()
	// 			.includes(searchText.toLowerCase())
	// 	);

	if (isLoading) {
		return (
			<div className={styles.cards}>
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
			</div>
		);
	} else {
		// const filteredRequests = filterBySearchTerm(props.searchText);
		return (
			<div className={styles.cards}>
				{users.length > 0 ? (
					users.map((user, i) => (
						<TurnedOffCard
							key={i}
							user={user}
							setRequestsLoaded={setRequestsLoaded}
						/>
					))
				) : (
					<EmptyState
						heading="No Turned Off Users"
						className={"users"}
						imageUrl="/vectors/empty-states/emptyPendingUsers.svg"
						subHeading="Looks like you haven't turned off status from any user. When you do, this is where you’ll find them."
					/>
				)}
				{/* <h1>Turn Off</h1> */}
			</div>
		);
	}
}
